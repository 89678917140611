import React, { Component } from "react"

// React-Redux reducers
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton"
import Print from "@material-ui/icons/Print";

// core Theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

// custom Components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { fetchTemplatemenus } from "actions/templatemenu";
import { addMenu, fetchMenus, updateMenu } from "actions/menu";
import { fetchOrdinazioni } from "actions/ordinazioni";
import { fetchGruppi } from "actions/gruppi";

import { getWeekStartDay } from "utils/date";
import { getBigData, filterParser } from "utils/api";

import { stampaMenuSettimanale } from "utils/print"
import { setValoreCampoFiltro } from "utils/filter";
import { addDays, formatDateToYYYYMMDD } from "utils/date";

class PlanningMenu extends Component {

    state = {
        giornoCorrente: new Date(),
        giornoInizio: null,
        giornoSelezionato: null,
        giornoInizioSelezionato: null,
        giornoFineSelezionato: null,
        templateSelezionato: "",
        giorniPerSettimana: 7,
        nascondiFestivi: true,
        settimaneDaVisualizzare: 4,
        settimane: null,
        openDialog: false,
        dialogMessage: ""
    }

    componentDidMount() {

        const { azienda, caricaTemplates, caricaGruppi } = this.props

        const filtroTemplate = [ { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id}]
        
        let filtroGruppi = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id}]
        caricaGruppi(filtroGruppi)
        caricaTemplates(filtroTemplate)

        const giornoInizio = getWeekStartDay(this.state.giornoCorrente)

        this.creaSettimane(giornoInizio)
        .then( (settimane) => {
            this.setState({ giornoInizio, settimane})
        })
    }

    apriDettaglioMenu = (giorno, pasto) => {
        const { history } = this.props

        const menu = giorno[pasto]

        if (menu) {
            history.push(`/dashboard/menu/${menu._id}/edit`)
        }
        else {
            let giornoMenu = new Date(giorno['giorno'])
            giornoMenu.setMilliseconds(0)
            giornoMenu.setSeconds(0)
            giornoMenu.setMinutes(0)
            giornoMenu.setHours(0)
            this.creaNuovoMenu(giornoMenu, pasto)
        }
    }

    creaNuovoMenu = ( giorno, pasto ) => {
        const { azienda } = this.props
    
        let menu = {
          "Giorno" : giorno,
          "Data": formatDateToYYYYMMDD(giorno),
          "Pasto": pasto,
          "Azienda" : azienda._id
        }
    
        Promise.resolve()
        .then( () => {
          const { aggiungiMenu } = this.props
          return aggiungiMenu(menu) 
        })
        .then( (menu) => {
          if (menu) {
            const { history } = this.props
            history.push(`/dashboard/menu/${menu._id}/edit`)
          }
        })
      }

    // crea l'array dei giorni da visualizzare
    async creaSettimane(giornoInizio) {
        const { giorniPerSettimana, settimaneDaVisualizzare, nascondiFestivi } = this.state

        let res = []

        // devo recuperare i menu dal giorno d'inizio fino al giorno di fine
        res = await Promise.resolve()
        .then( () => {

            let giornoInizioFiltro = formatDateToYYYYMMDD(new Date(giornoInizio))

            let giornoFineFiltro = formatDateToYYYYMMDD(addDays(new Date(), ( giorniPerSettimana* settimaneDaVisualizzare)))

            let filtroMenu = []
            // aggiungo il giorno d'inizio al filtro
            filtroMenu.push({ 'campo': 'Data', 'operatore': '_gte', 'valore': giornoInizioFiltro})

            // aggiungo il giorno di fine al filtro
            filtroMenu.push({ 'campo': 'Data', 'operatore': '_lte', 'valore': giornoFineFiltro})

            let queryString = filterParser(filtroMenu)

            if (queryString.length > 0 )
                queryString = `?${queryString}`

            return getBigData('menus', queryString)
        })
        .then( (menus) => {

            // ciclo sulle settimane da visualizzare ed inserisco i giorni
            for (let i = 0; i < settimaneDaVisualizzare; i++) {
                let giorni = []
                for (let j = 0; j < giorniPerSettimana; j++) {
                    if (nascondiFestivi === true && j >= 5)
                        continue

                    let rigaGiorno = {}

                    let giorno = addDays(giornoInizio, (( i* giorniPerSettimana)+j))

                    const menusDelGiorno = menus.filter( (m) => {
                        let g = new Date(m["Data"])
                        return g.getTime() === giorno.getTime()
                    })

                    rigaGiorno['giorno'] = giorno
                    rigaGiorno['template'] = null
                    rigaGiorno['pranzo'] = null
                    rigaGiorno['cena'] = null

                    // scorro i menu del giorno e imposto il menu del pranzo e/o della cena
                    menusDelGiorno.forEach( (m) => {

                        if (m['Pasto'] === 'pranzo') {
                            rigaGiorno['pranzo'] = m
                            rigaGiorno['template'] = m['Templatemenu']
                        }
                        else if ( m['Pasto'] === 'cena') {
                            rigaGiorno['cena'] = m

                            if (!rigaGiorno['template'])
                                rigaGiorno['template'] = m['Templatemenu']
                        }
                    })

                    giorni.push(rigaGiorno)
                }

                res.push(giorni)
            }
            return res
        })
        .then( (settimane) => {
            return settimane
        })

        return res
    }

    apriMenuGiorno = (giorno) => {
        const giornoDaAprire = new Date(parseInt(giorno))

        const giornoInizioSelezionato = getWeekStartDay(giornoDaAprire)
        let giornoFineSelezionato = addDays(new Date(giornoInizioSelezionato), 4)

        this.setState({ giornoSelezionato: giornoDaAprire, giornoInizioSelezionato, giornoFineSelezionato, openDialog: true})
    }

    async assegnaTemplate(giornoInizio, template) {

        if (!template || template.trim().length <= 0 ) {
            this.setState({ dialogMessage: "Seleziona un template da assegnare alla settimana"})
            return
        }
        
        const { azienda, aggiungiMenu, aggiornaMenu } = this.props
        const { giorniPerSettimana, nascondiFestivi } = this.state

        let giornoCorrente = new Date(giornoInizio)
        const giorniDaScorrere = giorniPerSettimana - (nascondiFestivi === true ? 2 : 0 )

        for (let i = 0; i < (giorniDaScorrere); i++) {
            giornoCorrente = addDays(giornoInizio, i)

            let idxGiornoCorrente = giornoCorrente.getDay()
            idxGiornoCorrente -= 1

            const ordinazioniDelGiorno = await this.recuperaOrdinazioniGiorno(giornoCorrente)

            if ( !ordinazioniDelGiorno || ordinazioniDelGiorno.length === 0) {
                //assegno il template ai menu del giorno

                let aggiungiMenuPranzo = true
                let aggiungiMenuCena = true


                // cerco se esiste il menu pranzo del giorno
                let menusPranzo = await this.recuperaMenuPastoGiorno('pranzo', giornoCorrente)
                let menuPranzo = null

                if ( menusPranzo && menusPranzo.length > 0 ) {
                    aggiungiMenuPranzo = false
                    menuPranzo = menusPranzo[0]
                }
                else {
                    menuPranzo = {
                        "Giorno" : giornoCorrente,
                        "Data": formatDateToYYYYMMDD(giornoCorrente),
                        "Pasto": 'pranzo',
                        "Azienda" : azienda._id
                    }
                }

                if (menuPranzo !== null) {
                    this.assegnaValoriTemplateMenu(idxGiornoCorrente, menuPranzo, 'pranzo', template)
                }

                // cerco se esiste il menu della cena del giorno
                let menusCena = await this.recuperaMenuPastoGiorno('cena', giornoCorrente)
                let menuCena = null

                if ( menusCena && menusCena.length > 0 ) {
                    aggiungiMenuCena = false
                    menuCena = menusCena[0]
                }
                else {
                    menuCena = {
                        "Giorno" : giornoCorrente,
                        "Data": formatDateToYYYYMMDD(giornoCorrente),
                        "Pasto": 'cena',
                        "Azienda" : azienda._id
                    }
                }

                if (menuCena !== null) {
                    this.assegnaValoriTemplateMenu(idxGiornoCorrente, menuCena, 'cena', template)
                }    

                // creo un nuovo menu pranzo
                if (aggiungiMenuPranzo === true) {
                    aggiungiMenu(menuPranzo)
                }
                else {
                    // modifico il menu pranzo esistente
                    aggiornaMenu(menuPranzo._id, menuPranzo)
                }

                // creo un nuovo menu cena
                if (aggiungiMenuCena === true) {
                    aggiungiMenu(menuCena)
                }
                else {
                    // aggiorno il menu cena esistente
                    aggiornaMenu(menuCena._id, menuCena)
                }
            }
        }

        const giornoInizioPlanning = getWeekStartDay(this.state.giornoCorrente)

        this.creaSettimane(giornoInizioPlanning)
        .then( (settimane) => {
            this.setState({ giornoInizioPlanning, 
                settimane, 
                giornoSelezionato: null, 
                giornoInizioSelezionato: null, 
                giornoFineSelezionato: null, 
                templateSelezionato: "", 
                openDialog: false, 
                dialogMessage: ""  })
        })


        
    }

    async assegnaValoriTemplateMenu(idxGiorno, menu, pasto, idtemplate){

        const { templates } = this.props

        menu['Templatemenu'] = idtemplate

        let templateCorrente = templates.find( (t) => {
            return t._id === idtemplate
        })

        if (templateCorrente && templateCorrente['Giorni']) {
            menu['Pietanze'] = templateCorrente['Giorni'][idxGiorno][pasto]
        }
    }

    // recupera le ordinazioni del giorno per valutare se aggiornare il menu oppure no
    async recuperaOrdinazioniGiorno(giorno){

        const { azienda, loadOrdinazioni } = this.props

        let giornoFiltro = formatDateToYYYYMMDD(giorno)

        let filtro = [
            { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id },
            { 'campo': 'Data', 'operatore': '=', 'valore': giornoFiltro }
        ]

        return await loadOrdinazioni(filtro)
    }

    async recuperaMenuPastoGiorno(pasto, giorno) {
        const { loadMenus, azienda } = this.props

        let giornoFiltro = formatDateToYYYYMMDD(giorno)

        let filtro = [
            { 'campo': 'Data', 'operatore': '=', 'valore': giornoFiltro },
            { 'campo': 'Pasto', 'operatore': '=', 'valore': pasto },
            { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id },
        ]

        return await loadMenus(filtro)
    }

    chiudiModale = () => {
        this.setState({ giornoSelezionato: null, 
                        giornoInizioSelezionato: null, 
                        giornoFineSelezionato: null, 
                        templateSelezionato: "", 
                        openDialog: false, 
                        dialogMessage: "" })
    }

    // gestisce il cambio di template nel dialog
    onChangeTemplate = (e) => {
        const idTemplate = e.target.value
        this.setState({ templateSelezionato: idTemplate })
    }

    apriDettaglioTemplate = (idTemplate) => {
        const { history } = this.props

        history.push(`/dashboard/template-menu/${idTemplate}/edit`)
    }

    stampaMenuSettimanale = (giornoSelezionato) => {

        const { giorniPerSettimana, nascondiFestivi } = this.state
        const { azienda, loadMenus, gruppi } = this.props

        let giornoInizio = new Date(getWeekStartDay(giornoSelezionato))

        let giornoFine = addDays( new Date(giornoInizio), ((giorniPerSettimana - (nascondiFestivi === true ? 2 : 0) - 1 )))

        let giornoInizioLabel = new Date(giornoInizio).toLocaleDateString('it-IT', { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric'})
        let giornoFineLabel = new Date(giornoFine).toLocaleDateString('it-IT', { weekday: 'long', month: '2-digit', day: '2-digit', year: 'numeric' })

        giornoInizio = formatDateToYYYYMMDD(giornoInizio)
        giornoFine = formatDateToYYYYMMDD(giornoFine)

        let filtro = [
            { 'campo': 'Data', 'operatore': '_gte', 'valore': giornoInizio},
            { 'campo': 'Data', 'operatore': '_lte', 'valore': giornoFine},
            { 'campo': 'Azienda', 'operatore': '=', 'valore' : azienda._id },
            { 'campo': 'Pasto', 'operatore': '=', 'valore': 'pranzo'}
        ]

        let menu = {}

        Promise.resolve()
        .then( () => {
            // recupero i menu per il pranzo
            let menuPranzo = loadMenus(filtro)
            return menuPranzo
        })
        .then( (menuPranzo) => {

            // ordino i menu in base al giorno
            let menuPranzoOrdinati = menuPranzo.sort( (a,b) => {
                if (new Date(a['Data']).getTime() > new Date(b['Data']).getTime())
                    return 1

                if (new Date(a['Data']).getTime() < new Date(b['Data']).getTime())
                     return -1

                return 0
            })

            menu['pranzo'] = menuPranzoOrdinati

        })
        .then( () => {
            // recupero i menu per la cena
            filtro = setValoreCampoFiltro('campo', 'Pasto', 'cena', filtro)

            let menuCena = loadMenus(filtro)
            //return { 'pranzo': menuPranzo, 'cena': menuCena }
            return menuCena
        })
        .then( (menuCena) => {
            let menuCenaOrdinati = menuCena.sort( (a,b) => {
                if (new Date(a['Data']).getTime() > new Date(b['Data']).getTime())
                    return 1

                if (new Date(a['Data']).getTime() < new Date(b['Data']).getTime())
                     return -1

                return 0
            })

            menu['cena'] = menuCenaOrdinati

        })
        .then( () => {

            // ordino i gruppi in base all'ordinamento
            const gruppiOrdinati = gruppi.sort( (a, b) => {
                if (a['Ordinamento'] > b['Ordinamento'])
                    return 1
                
                if (a['Ordinamento'] < b['Ordinamento'])
                    return -1 
                return 0
            })

            // ordino le pietanze in base ai gruppi
            let data = { pranzo: [], cena: [] }

            menu['pranzo'].forEach( (giorno) => {
                let rigaGiorno = { 'giorno': giorno['Data'], 'pietanze': []}
                const pietanze = giorno['Pietanze']

                gruppiOrdinati.forEach( (gruppo) => {

                    const pietanzeGruppo = pietanze.filter( (p) => {
                        return gruppo._id === p['Gruppo']._id || gruppo._id === p['Gruppo']
                    }).sort( (a, b) => {
                        if (a['Nome'] > b['Nome'])
                            return 1
                        if (a['Nome'] < b['Nome'])
                            return -1
                        return 0
                    })

                    if (pietanzeGruppo.length > 0 )
                        rigaGiorno['pietanze'].push({ tipo: 'gruppo', valore: gruppo["Nome"]})

                    pietanzeGruppo.forEach( (pg) => {
                        rigaGiorno['pietanze'].push({ tipo: 'pietanza', valore: pg['Nome']})
                    })
                })

                data['pranzo'].push(rigaGiorno)
            })

            menu['cena'].forEach( (giorno) => {
                let rigaGiorno = { 'giorno': giorno['Data'], 'pietanze': []}

                const pietanze = giorno['Pietanze']

                gruppiOrdinati.forEach( (gruppo) => {

                    const pietanzeGruppo = pietanze.filter( (p) => {
                        return gruppo._id === p['Gruppo']._id || gruppo._id === p['Gruppo']
                    }).sort( (a, b) => {
                        if (a['Nome'] > b['Nome'])
                            return 1
                        if (a['Nome'] < b['Nome'])
                            return -1
                        return 0
                    })

                    if (pietanzeGruppo.length > 0 )
                        rigaGiorno['pietanze'].push({tipo: 'gruppo', valore: gruppo["Nome"]})

                    pietanzeGruppo.forEach( (pg) => {
                        rigaGiorno['pietanze'].push({tipo: 'pietanza', valore: pg['Nome']})
                    })
                })

                data['cena'].push(rigaGiorno)
            })

            data['giornoInizio'] = giornoInizioLabel
            data['giornoFine'] = giornoFineLabel

            stampaMenuSettimanale(data)
        })
    }

    render() {

        const { templates, azienda } = this.props
        const { settimane, giornoInizioSelezionato, giornoFineSelezionato, templateSelezionato, dialogMessage } = this.state

        return (
            <ScaffoldBase title="Planning menu" subtitle="Gestisci il menu settimanale">
                <GridContainer   >
                    <GridItem xs={12} sm={12} md={2} lg={2}  >
                        <h3>Template menu</h3>
                        { templates && templates.length > 0 && (
                            <List>
                                { templates.sort( (a, b) => {
                                    if (a['Ordinamento'] > b['Ordinamento'])
                                        return 1

                                    if ( a['Ordinamento'] < b['Ordinamento'])
                                        return -1

                                    return 0
                                })
                                .map( (template) => {
                                    return (
                                        <ListItem key={template._id} id={template._id} >
                                            <ListItemText  primary={template["Nome"]} onClick={() => this.apriDettaglioTemplate(template._id) } />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10} lg={10}  >
                       { settimane && settimane.length > 0 && settimane.map( (settimana, idx) => {
                           return(
                               <div style={{width: '100%', display: 'flex'}} key={idx}>
                                   { settimana && settimana.map( (giorno) => {

                                       console.log(giorno)

                                       // se c'è impostato il menu ma non il template inserisco la label con l'asterisco
                                       let nomeMenuPranzo = giorno['pranzo'] && !giorno['pranzo']['Templatemenu'] ? 'Modificato' : ''
                                       let nomeMenuCena = giorno['cena'] && !giorno['cena']['Templatemenu'] ? 'Modificato' : ''

                                        const giornoTime = new Date(giorno['giorno']).getTime()

                                       return (
                                           <div style={{ minHeight: '150px', border: '1px solid grey', flex: 1, cursor: 'pointer' }} key={giornoTime} id={giornoTime}>
                                                <p style={{ paddingLeft: '5px', backgroundColor: azienda["Colore primario"] }}>{ giorno['giorno'].toLocaleDateString('it-IT', { weekday: 'short'})}</p>
                                                <Tooltip title="Assegna un template di menu al giorno">
                                                    <p style={{ fontSize: '1.5em' }} onClick={() => this.apriMenuGiorno(giornoTime)}>{ giorno['giorno'].toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit' })}</p>
                                                </Tooltip>
                                                 
                                                { giorno['template'] && (
                                                    <Chip label={giorno['template']['Nome']} onClick={() => this.apriDettaglioTemplate(giorno['template']._id) }/>
                                                )}
                                                <Tooltip
                                                    id="tooltip-top"
                                                    title="Stampa menu settimanale"
                                                    placement="top"
                                                >
                                                    <IconButton
                                                    aria-label="Stampa"
                                                    >
                                                        <Print fontSize="small" onClick={() => this.stampaMenuSettimanale(giorno['giorno'])} />
                                                    </IconButton>
                                                </Tooltip>
                                                <List>
                                                    <ListItem disableGutters>
                                                    <Tooltip title="Modifica il menu del giorno">
                                                        <ListItemText primary="Pranzo" secondary={`${nomeMenuPranzo}`} onClick={() => this.apriDettaglioMenu(giorno, 'pranzo')}/>
                                                    </Tooltip>
                                                        
                                                    </ListItem>
                                                    <ListItem disableGutters>
                                                        <Tooltip title="Modifica il menu del giorno">
                                                            <ListItemText primary="Cena" secondary={`${nomeMenuCena}`} onClick={() => this.apriDettaglioMenu(giorno, 'cena')}/>
                                                        </Tooltip>
                                                    </ListItem>
                                                </List>
                                           </div>
                                       )  
                                   })}
                               </div>
                           )
                       })}
                    </GridItem>
                    <Dialog
                        open={this.state.openDialog}
                        onClose={this.chiudiModaleInserimento}
                        aria-labelledby="form-dialog-title"
                        fullWidth={true}
                    >
                        <DialogTitle id="form-dialog-title">Assegna template menu</DialogTitle>
                        <DialogContent>
                            {giornoInizioSelezionato && giornoFineSelezionato && (
                                <div>
                                    <p>Settimana dal {giornoInizioSelezionato.toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric' })} al {giornoFineSelezionato.toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year:'numeric' })}</p>
                                    <form>
                                        <FormControl style={{ marginTop: "27px"}} fullWidth={true}>
                                            <InputLabel htmlFor="Template">Template</InputLabel>
                                            <Select
                                                value={templateSelezionato}
                                                inputProps={{
                                                    name: 'Template',
                                                    id: 'Template',
                                                    onChange:this.onChangeTemplate
                                                }}
                                            >
                                                <MenuItem value="">
                                                <em>Nessuno</em>
                                                </MenuItem>
                                                    { templates && templates.length > 0 && templates.sort( (a, b) => {
                                                        if (a['Ordinamento'] > b['Ordinamento'])
                                                            return 1
                                                        
                                                        if (a['Ordinamento'] < b['Ordinamento'])
                                                            return -1

                                                        return 0
                                                    })
                                                    .map( (t) => {
                                                            return (
                                                                <MenuItem key={t._id} value={t._id}>{t["Nome"]}</MenuItem>
                                                            )
                                                        })
                                                    }
                                            </Select>
                                        </FormControl>
                                    </form>
                                </div>
                            )}
                            { dialogMessage && dialogMessage.trim().length > 0 && (
                                <Paper style={{ backgroundColor: '#e57373'}}>
                                    <Typography>Devi selezionare il template per poterlo assegnare ai menu</Typography>
                                </Paper>
                                
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.chiudiModale}>Annulla</Button>
                            <Button onClick={() => this.assegnaTemplate(giornoInizioSelezionato, templateSelezionato)}>Assegna</Button>
                        </DialogActions>
                    </Dialog>
                </GridContainer>
            </ScaffoldBase>
        )
    }
}

function mapDispatchToProps( dispatch ) {
    return {
        caricaTemplates: (filtro) => dispatch(fetchTemplatemenus(filtro)),
        aggiungiMenu: ( menu ) => dispatch(addMenu(menu)),
        aggiornaMenu: ( id , menu ) => dispatch(updateMenu(id, menu)),
        loadOrdinazioni: (filtro) => dispatch(fetchOrdinazioni(filtro)),
        loadMenus: (filtro) => dispatch(fetchMenus(filtro)),
        caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro))
    }
  }
  
function mapStateToProps({ authentication, templatemenu, gruppi }) {
    return { 
        azienda: authentication.user["Azienda"],
        templates: templatemenu.items,
        gruppi: gruppi.items
    }
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(PlanningMenu));