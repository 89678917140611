import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";

// core components
import GridItem from "components/Grid/GridItem.jsx";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

// React-Redux reducers
import { connect } from "react-redux";

import { fetchGruppi } from "actions/gruppi";

import { compose } from "redux";
import { withRouter } from "react-router";

class ListaGruppi extends React.Component {

  state = {
  }

  componentDidMount() {
      const { caricaGruppi, azienda } = this.props

      const filtro = [ {'campo' : 'Azienda', 'operatore': '=', 'valore' : azienda._id}]

      caricaGruppi(filtro)
  }

  apriModificaGruppo(id) {
    const { history } = this.props

    history.push('/dashboard/gruppi/'+id+'/edit')
  }

  render() {
    const { classes, gruppi } = this.props;

    return (
      <div>
        <ScaffoldBase title="Lista gruppi" subtitle="Dettaglio gruppi">
          { gruppi.items && gruppi.items.length > 0 &&
          <GridItem xs={12} sm={12} md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Azione</TableCell>
                  <TableCell>Nome</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                    gruppi.items && gruppi.items.map( (gruppo) => 
                      <TableRow key={gruppo._id}>
                        <TableCell>
                            <Tooltip
                              id="tooltip-top"
                              title="Modifica"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes.tableActionButton}
                              >
                                <Edit
                                  className={
                                    classes.tableActionButtonIcon + " " + classes.edit
                                  }
                                  onClick={() => this.apriModificaGruppo(gruppo._id)}
                                />
                              </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{gruppo["Nome"]}</TableCell>
                      </TableRow>
                    ) 
                  }
              </TableBody>
            </Table>
          </GridItem>
          }

          { (!gruppi.items || gruppi.items.length <= 0) && 
            <GridItem>
              <p>Gruppi non disponibili</p>
            </GridItem> 
          }
        </ScaffoldBase>
      </div>
    );
  }
}

ListaGruppi.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
      caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro))
  }
}

function mapStateToProps({ authentication, gruppi}) {
  return { 
      azienda: authentication.user['Azienda'],
      gruppi
  };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaGruppi));
