import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addCliente, updateCliente, fetchClienti, fetchCliente } from "actions/clienti";
import { fetchGiriconsegna } from "actions/giriconsegna"
import { fetchCombinazioni, addCombinazione, deleteCombinazione, updateCombinazione, resetCombinazioni } from "actions/combinazioni"
import { fetchGruppi } from "actions/gruppi";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Add from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from "@material-ui/core/Checkbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Clear from "@material-ui/icons/Clear";
import Edit from "@material-ui/icons/Edit";

// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { getCostoGruppo, setCostoGruppo, inizializzaCostiGruppi } from "utils/costigruppi";

const newCliente = {
  "Ragione sociale" : "",
  "Indirizzo" : "",
  "CAP" : "",
  "Citta" : "",
  "Provincia" : "",
  "Telefono" : "",
  "Partita IVA" : "",
  "Ora ultima prenotazione pranzo" : "",
  "Ora ultima prenotazione cena" : "",
  "Tipologia pasti": "",
  "Stato": "",
  "Giroconsegna" : "",
  "Contatto amministrativo": "",
  "Riferimento tecnico": "",
  "Costi pasti": null
}

const newCombinazione = {
  Descrizione: "",
  Gruppi: [],
  Prezzoazienda: 0,
  Prezzodipendente: 0,
  Ordinamento: 0,
  Azienda: "",
  Aziendariferimento: "",
  Stato: "attivo"
}

class DettaglioCliente extends Component {
  state = {
    errore: null,
    azione: "Modifica",
    messaggio: "",
    cliente: newCliente,
    showDialogCombinazione: false,
    newCombinazione: newCombinazione
  }

  componentDidMount() {
    const { match, caricaCliente, caricaGiriConsegna, caricaCombinazioni, azzeraCombinazioni, caricaGruppi, azienda } = this.props

    const idCliente = match.params.id

    azzeraCombinazioni()

    if ( idCliente && idCliente.length > 0 ) {
      Promise.resolve()
      .then( () => caricaCliente(idCliente))
      .then( () => {
        const { currentCliente } = this.props
        this.setState({ cliente: currentCliente })
      })
      .then( () => {
        const { cliente } = this.state

        let filtroGiri = [ { 'campo': 'Azienda riferimento', 'operatore': '=', 'valore': cliente['Azienda riferimento']}]
        let filtroCombinazioni = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': cliente.id }]
        let filtroGruppi = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda.id }]

        caricaGiriConsegna(filtroGiri)
        caricaCombinazioni(filtroCombinazioni)
        caricaGruppi(filtroGruppi)
      })
    }
    else {
      // il cliente non è valorizzato quindi sto inserendo un nuovo cliente.
      const { cliente } = this.state

      Promise.resolve()
      .then( () => {

        let filtroGruppi = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda.id }]
        return caricaGruppi(filtroGruppi)
        
      })
      .then( (gruppi) => {

        const newCliente = JSON.parse( JSON.stringify( cliente) )

        // inizializzo il campo Costi pasti
        newCliente["Costi pasti"] = inizializzaCostiGruppi( gruppi, newCliente["Costi pasti"])

        this.setState( { cliente: newCliente })
        
      })
    }
  }

  componentWillUnmount() {
    const { azzeraCombinazioni } = this.props

    azzeraCombinazioni()
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {
    if (!e)
      return;

    e.preventDefault()
    const { currentCliente, addCliente, updateCliente, ricaricaClienti, history } = this.props
    const { cliente } = this.state

    const values = serializeForm(e.target, { hash: true })

    // setto il valore in caso di campo azzerato dall'utente
    if (values["Ora ultima prenotazione cena"] === undefined)
      values["Ora ultima prenotazione cena"] = ""
    
    // setto il valore in caso di campo azzerato dall'utente
    if (values["Ora ultima prenotazione pranzo"] === undefined)
      values["Ora ultima prenotazione pranzo"] = ""

    // setto il valore del Giro consegna in caso di campo azzerato dall'utente
    if (values["Giroconsegna"] === undefined)
      values["Giroconsegna"] = null

    if (values["Contatto amministrativo"] === undefined)
      values["Contatto amministrativo"] = null

    if (values["Riferimento tecnico"] === undefined)
      values["Riferimento tecnico"] = null

    // prendo i valori dei costi dei gruppi
    values["Costi pasti"] = cliente["Costi pasti"]
      
    if ( currentCliente) {
      // Modifico il cliente esistente
        updateCliente(currentCliente["_id"], values);
    }
    else {
      addCliente(values);
    }

    ricaricaClienti();
    history.push('/dashboard/clienti')
  }

  // gestisco la modifica dei campi
  handleChange = (e) => {
    let nomeProprieta = e.target.name;
    let valoreProprieta = e.target.value;
    let updatedCliente = {};

    updatedCliente[nomeProprieta] = valoreProprieta;

    if ( nomeProprieta === 'Giroconsegna' && valoreProprieta === undefined)
      valoreProprieta = null

    this.setState({ cliente: { ...this.state.cliente, ...updatedCliente } })
  }

  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // apre il dialog combinazione
  apriDialogCombinazione = (comb=null) => {

    if (comb !== null) {
      let newComb = JSON.parse( JSON.stringify( comb))

      newComb["Gruppi"] = comb["Gruppi"].map( (g) => { return g.id })
      //comb["Gruppi"] = comb["Gruppi"].map( (g) => { return g.id })
      this.setState({ showDialogCombinazione: true, newCombinazione: newComb })
    }
    else {
      const newComb = JSON.parse(JSON.stringify( newCombinazione))
      this.setState({ showDialogCombinazione: true, newCombinazione: newComb })  
    }
    
  }

  // chiude il dialog della combinazione
  chiudiDialogCombinazione = () => {
    this.setState({ showDialogCombinazione: false, newCombinazione: newCombinazione })
  }

  // gestisce i cambiamenti della combinazione
  handleChangeCombinazione = (e) => {
    const { newCombinazione } = this.state
    let nomeProprieta = e.target.name
    let valoreProprieta = e.target.value

    let updatedCombinazione = newCombinazione
    updatedCombinazione[nomeProprieta] = valoreProprieta

    this.setState({ newCombinazione: updatedCombinazione })
  }

  // gestisco il check sui gruppi della combinazione
  handleCheckGruppoCombinazione = (idGruppo, valore) => {
    const { newCombinazione } = this.state

    let updatedCombinazione = newCombinazione

    if (valore === true) {
      updatedCombinazione["Gruppi"].push(idGruppo)
    }
    else {
      updatedCombinazione["Gruppi"] = updatedCombinazione["Gruppi"].filter( (id) => {
        return id !== idGruppo
      })
    }

    this.setState({ newCombinazione: updatedCombinazione })
  }

  // gestisco l'aggiunta di una nuova combinazione
  handleSubmitCombinazione = (e) => {

    if (!e)
      return

    e.preventDefault()

    const { newCombinazione, cliente } = this.state
    const { azienda, aggiungiCombinazione, aggiornaCombinazione, caricaCombinazioni } = this.props

    if ( newCombinazione ) {
      if (newCombinazione.id && newCombinazione.id.length > 0) {
        // aggiorno la combinazione esistente
        Promise.resolve()
        .then( () => {
          return aggiornaCombinazione(newCombinazione.id, newCombinazione)
        })
        .then( () => { 
          let filtroCombinazioni = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': cliente.id }]
          caricaCombinazioni(filtroCombinazioni)
          this.setState({ messaggio: "Combinazione aggiornata", showDialogCombinazione: false })
        })
        .catch( () => { this.setState({ messaggio: "Errore nel salvataggio della combinazione" }) })
      }
      else {
        // inserisco la nuova combinazione
        let combinazioneDaSalvare = newCombinazione
        combinazioneDaSalvare['Azienda'] = cliente.id
        combinazioneDaSalvare['Aziendariferimento'] = azienda.id

        Promise.resolve()
        .then( () => {
          return aggiungiCombinazione(combinazioneDaSalvare)
        })
        .then( () => {
          let filtroCombinazioni = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': cliente.id }]
          caricaCombinazioni(filtroCombinazioni)
          this.setState({ messaggio: "Combinazione aggiunta", showDialogCombinazione: false })
        })
        .catch( () => {
          this.setState({ messaggio: "Errore nel salvataggio della combinazione" })
        })
      }
    }
  }

  // gestisce la cancellazione della combinazione
  handleDeleteCombinazione = (id) => {
    const { cancellaCombinazione, caricaCombinazioni } = this.props
    const { cliente } = this.state

    Promise.resolve()
    .then( () => {
      return cancellaCombinazione(id)
    })
    .then( () => {
      let filtroCombinazioni = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': cliente.id }]
      caricaCombinazioni(filtroCombinazioni)
      this.setState({ messaggio: "Combinazione cancellata" })
    })
    .catch( () => {
      this.setState({ messaggio: "Errore nella cancellazione della combinazione" })
    })
  }

  // controllo se il gruppo è presente nella combinazione
  checkGruppoCombinazione(gruppi, idGruppo) {
    let res = false

    if (gruppi.length > 0 )
    {
      let valore = gruppi[0]

      if (typeof valore === 'string') {
        res = gruppi.includes(idGruppo)
      }
      else {
        let gruppiId = gruppi.map( (g) => { return g.id })

        res = gruppiId.includes(idGruppo)
      }
    }

    return res
  }

  // gestisco la variazione del costo del gruppo
  handleChangeCostoGruppo = (e) => {
    const { cliente } = this.state
    let nomeProprieta = e.target.name
    let valoreProprieta = e.target.value

    let updatedCostiGruppi = cliente["Costi pasti"]
    setCostoGruppo(updatedCostiGruppi, nomeProprieta, { Misura: "", Valore: valoreProprieta }, 'azienda')

    this.setState({ cliente: { ...cliente, updatedCostiGruppi } })

  }

  render() {

    const { cliente } = this.state
    const { giriconsegna, gruppi, classes, combinazioni } = this.props

    return (
      <div>
        <ScaffoldBase title="Aggiungi nuovo cliente" subtitle="Dettaglio cliente">
        <form onSubmit={this.handleSubmit}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Ragione sociale"
                id="ragioneSociale"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: "Ragione sociale",
                  required: true,
                  value: cliente["Ragione sociale"],
                  onChange: this.handleChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Indirizzo"
                  id="indirizzo"
                  formControlProps={{ fullWidth: true }}
                  inputProps = {{
                    name: "Indirizzo",
                    defaultValue: cliente["Indirizzo"]
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput
                  labelText="CAP"
                  id="cap"
                  formControlProps={{ fullWidth: true }}
                  inputProps = {{
                    name: "CAP",
                    value: cliente["CAP"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <CustomInput
                  labelText="Città"
                  id="citta"
                  formControlProps={{ fullWidth: true }}
                  inputProps = {{
                    name: "Citta",
                    value: cliente["Citta"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={1}>
                <CustomInput
                  labelText="Provincia"
                  id="provincia"
                  formControlProps={{ fullWidth: true }}
                  inputProps = {{
                    name: "Provincia",
                    value: cliente["Provincia"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput
                  labelText="Telefono"
                  id="telefono"
                  formControlProps={{ fullWidth: true }}
                  inputProps = {{
                    name: "Telefono",
                    value: cliente["Telefono"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <CustomInput
                  labelText="Partita IVA"
                  id="partitaIVA"
                  formControlProps={{ fullWidth: true }}
                  inputProps = {{
                    name: "Partita IVA",
                    value: cliente["Partita IVA"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <FormControl style={{ marginTop: "27px"}} fullWidth={true}>
                  <InputLabel htmlFor="tipologiaPasti">Pasti abilitati</InputLabel>
                  <Select
                    value={cliente["Tipologia pasti"]}
                    inputProps={{
                      name: 'Tipologia pasti',
                      id: 'tipologiaPasti',
                      onChange: this.handleChange
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    <MenuItem value={'pranzo'}>Pranzo</MenuItem>
                    <MenuItem value={'cena'}>Cena</MenuItem>
                    <MenuItem value={'pranzo/cena'}>Pranzo e cena</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <FormControl style={{ marginTop: "27px"}} fullWidth={true}>
                  <InputLabel htmlFor="stato">Azienda attiva</InputLabel>
                  <Select
                    value={cliente["Stato"]}
                    inputProps={{
                      name: 'Stato',
                      id: 'stato',
                      onChange: this.handleChange
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    <MenuItem value={'in fase di attivazione'}>In fase di attivazione</MenuItem>
                    <MenuItem value={'attivo'}>Attivo</MenuItem>
                    <MenuItem value={'sospeso'}>Sospeso</MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              { cliente && cliente["Tipologia pasti"].trim().length > 0 && (cliente["Tipologia pasti"] === 'pranzo' || cliente["Tipologia pasti"] === 'pranzo/cena' ) && (
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Ora ultima prenotazione pranzo"
                      id="oraUltimaPrenotazionePranzo"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "Ora ultima prenotazione pranzo",
                        type: "time",
                        value: cliente["Ora ultima prenotazione pranzo"],
                        onChange: this.handleChange
                      }}
                      labelProps={{
                        shrink: true
                      }}
                  />
                  </GridItem>
              )}

              { cliente && cliente["Tipologia pasti"].trim().length > 0 && (cliente["Tipologia pasti"] === 'cena' || cliente["Tipologia pasti"] === 'pranzo/cena' ) && (
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Ora ultima prenotazione cena"
                    id="oraUltimaPrenotazioneCena"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: "Ora ultima prenotazione cena",
                      type: "time",
                      value: cliente["Ora ultima prenotazione cena"],
                      onChange: this.handleChange
                    }}
                    labelProps={{
                      shrink: true
                    }}
                  />
                </GridItem>
              )}

              { giriconsegna && giriconsegna.length > 0 && (
                <GridItem xs={12} sm={12} md={4}>
                  <FormControl style={{ marginTop: "27px"}} fullWidth={true}>
                    <InputLabel htmlFor="giroConsegna">Giro di consegna</InputLabel>
                    <Select
                      value={cliente["Giroconsegna"] ? cliente["Giroconsegna"]._id && cliente["Giroconsegna"]._id.length > 0 ? cliente["Giroconsegna"]._id : cliente["Giroconsegna"] : "" }
                      inputProps={{
                        name: 'Giroconsegna',
                        id: 'giroConsegna',
                        onChange: this.handleChange
                      }}
                    >
                      <MenuItem>
                        <em>Nessuno</em>
                      </MenuItem>
                      { giriconsegna && giriconsegna.map( (giro) => {
                        return (
                          <MenuItem value={giro.id} key={giro.id}>{`${giro["Descrizione"]} - ${giro["Orario"]}`}</MenuItem>
                        )
                        })
                      }
                    </Select>
                  </FormControl>
                </GridItem>
              )}
              
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12}>
              <h3>Contatti</h3>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6} >
                <TextField
                  fullWidth={true}
                  id="contattoAmministrativo"
                  label="Contatto amministrativo"
                  name="Contatto amministrativo"
                  multiline
                  rows="4"
                  value={cliente["Contatto amministrativo"]}
                  onChange={this.handleChange}
                  margin="normal" />
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6} >
                <TextField
                  fullWidth={true}
                  id="riferimentoTecnico"
                  label="Riferimento tecnico"
                  name="Riferimento tecnico"
                  multiline
                  rows="4"
                  value={cliente["Riferimento tecnico"]}
                  onChange={this.handleChange}
                  margin="normal" />
            </GridItem>
          </GridContainer>
          { cliente && cliente.id && cliente.id.length > 0 && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                    <GridContainer>
                        <GridItem xs={8} sm={8} md={8} lg={8}>
                          <h3>Combinazioni pasti</h3>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4} lg={4} style={{ marginTop: "13px" }}>
                          <Tooltip
                                id="tooltip-top"
                                title="Aggiungi combinazione"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <IconButton
                                  aria-label="Aggiungi combinazione"
                                  className={classes.tableActionButton}
                                >
                                  <Add
                                    className={
                                      classes.tableActionButtonIcon + " " + classes.edit
                                    }
                                    onClick={ () => this.apriDialogCombinazione()}
                                  />
                                </IconButton>
                          </Tooltip>
                        </GridItem>
                        { combinazioni && combinazioni.length > 0 && (
                          <GridItem xs={12} sm={12} md={6} lg={6}>
                            <List>
                              { combinazioni.map( (c) => {
                                return (
                                  <ListItem key={c.id}>
                                    <ListItemText primary={c["Descrizione"]} />
                                    <ListItemIcon>
                                      <Edit onClick={ () => this.apriDialogCombinazione(c)} />
                                    </ListItemIcon>
                                    <ListItemIcon>
                                      <Clear onClick={() => this.handleDeleteCombinazione(c.id)} />
                                    </ListItemIcon>
                                  </ListItem>
                                )
                              })}
                            </List>
                          </GridItem>
                        )}        
                    </GridContainer>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} lg={6}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <h3>Costi gruppi</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    { gruppi && gruppi.length > 0 && (
                      <GridContainer>
                        { gruppi.map( (g) => {

                          const costogruppo = getCostoGruppo(cliente["Costi pasti"], g.id, "azienda")

                          return (
                            <GridItem key={g.id} xs={12} sm={12} md={12} lg={12}>
                              <CustomInput
                                  labelText={g["Nome"]}
                                  id={g.id}
                                  formControlProps={{ fullWidth: true }}
                                  inputProps = {{
                                    name: g.id,
                                    type: "number",
                                    step:"0.01",
                                    value: costogruppo["Valore"] && costogruppo["Valore"] > 0 ? costogruppo["Valore"] : g["Prezzo"],
                                    onChange: this.handleChangeCostoGruppo
                                  }}
                                />
                            </GridItem>
                          )
                        })}
                      </GridContainer>
                    )}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button type="submit" color="success">Salva</Button>
              <Button type="button" color="transparent" onClick={ () => this.tornaIndietro() }>Indietro</Button>
            </GridItem>
          </GridContainer>
        </form>
        <Dialog
            open={this.state.showDialogCombinazione}
            onClose={this.chiudiDialogCombinazione}
            aria-labelledby="form-dialog-title"
            fullWidth={true}
        >
            <DialogTitle id="form-dialog-title">{ this.state.newCombinazione && this.state.newCombinazione.id ? 'Modifica combinazione' : 'Aggiungi combinazione'}</DialogTitle>
            <form onSubmit={this.handleSubmitCombinazione}>
              <DialogContent>
                  <CustomInput
                    labelText="Descrizione"
                    id="descrizione"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: "Descrizione",
                      required: true,
                      value: this.state.newCombinazione["Descrizione"],
                      onChange: this.handleChangeCombinazione
                    }}
                  />
                  { gruppi && gruppi.map( (g) => {
                    return (
                      <FormControlLabel key={g._id}
                        control={
                            <Checkbox
                            checked={this.checkGruppoCombinazione(this.state.newCombinazione["Gruppi"], g.id)}
                            onChange={() => this.handleCheckGruppoCombinazione(g.id, !this.state.newCombinazione["Gruppi"].includes(g._id))}
                            color="primary"
                            />
                            }
                        label={g["Nome"]}
                      />
                    )
                    }
                  )}
                  <CustomInput
                    labelText="Prezzo azienda"
                    id="prezzoAzienda"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                      name: "Prezzoazienda",
                      required: true,
                      value: this.state.newCombinazione["Prezzoazienda"],
                      onChange: this.handleChangeCombinazione,
                      type: "number"
                    }}
                  />
              </DialogContent>
            <DialogActions>
                <Button onClick={this.chiudiDialogCombinazione} color="transparent">Annulla</Button>
                <Button type="submit" color="success">{ this.state.newCombinazione && this.state.newCombinazione.id ? 'Modifica' : 'Aggiungi'}</Button>
            </DialogActions>
            </form>
        </Dialog>
        </ScaffoldBase>
      </div>
    );
  }

  validate = (cliente) => {
    // Controllo che i dati inseriti siano corretti prima di inviare la richiesta di salvataggio
    let result = {
      esito: true,
      messaggio: ""
    }

    if (cliente === null ) {
      result.esito = false;
      result.messaggio = "Il cliente deve essere valorizzato";
      return result;
    }

    if (cliente['ragioneSociale'] == null || cliente['ragioneSociale'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire la ragione sociale del cliente. CAMPO OBBLIGATORIO";
      return result;
    }

    return result;
  }

  salvaCliente = cliente => {
    console.log('salvaCliente')
    console.log(cliente)
    let controlloValidazione = this.validate(cliente);

    if (controlloValidazione.esito === true) {
      console.log('salva sul db')
    }
    else {
     console.log(controlloValidazione.messaggio)
    }
  }
}

function mapStateToProps({ clienti, giriconsegna, combinazioni, gruppi, authentication }) {
  return {
    currentCliente: clienti.currentCliente,
    giriconsegna: giriconsegna.items,
    combinazioni: combinazioni.items,
    gruppi: gruppi.items,
    azienda: authentication.user["Azienda"]
  }
}

function mapDispatchToProps( dispatch ){
  return {
    addCliente: (cliente) => dispatch(addCliente(cliente)),
    updateCliente: (id, cliente) => dispatch(updateCliente(id, cliente)),
    ricaricaClienti: () => dispatch(fetchClienti()),
    caricaCliente: (id) => dispatch(fetchCliente(id)),
    caricaGiriConsegna: (filtro) => dispatch(fetchGiriconsegna(filtro)),
    caricaCombinazioni: (filtro) => dispatch(fetchCombinazioni(filtro)),
    azzeraCombinazioni: () => dispatch(resetCombinazioni()),
    aggiungiCombinazione: (comb) => dispatch(addCombinazione(comb)),
    cancellaCombinazione: (id) => dispatch(deleteCombinazione(id)),
    aggiornaCombinazione: (id, comb) => dispatch(updateCombinazione(id, comb)),
    caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioCliente));
