import { REACT_APP_BACKEND } from '../index.js';

const ARRAY_OPERATORI = [
    '=',
    '_ne',
    '_lt',
    '_gt',
    '_lte',
    '_gte',
    '_contains',
    '_containss'
]

export function filterParser(filtro=[]) {
    let res = ""

    if (filtro.length <= 0)
        return res

    // faccio il parsing degli oggetti che contiene il filtro
    filtro.forEach( ( f ) => {

        // controllo che i parametri del filtro siano correttamente configurati
        if ( !f.campo || f.campo.trim().length <= 0 )
            return

        if (!f.operatore || f.operatore.trim().length <= 0 || !ARRAY_OPERATORI.includes(f.operatore))
            return

        if (!f.valore)
            return

        // popolo la query string risultato
        if (res.length > 0 )
            res += "&"

        res += `${f.campo}${f.operatore === '=' ? f.operatore : f.operatore+'=' }${f.valore}`
    })


    return res
}

export function getBigData(collezione, queryString, skip=100) {

    const url = `${REACT_APP_BACKEND}${collezione}`

    const path = `${url}/count${queryString}`

    // faccio una chiamata utilizzando il path e facendomi restituire il conteggio delle righe
    const a = Promise.resolve()
    .then( () => { 
        return fetch(path)
        .then( (res) => { 
            return res.status === 200 ? res.text() : null;
          })
        .then( (data) => { 
            let count = null;
      
            try {
                count = JSON.parse(data);
                count = count !== 0 ? count : 0;
            } catch(e) {
                count = 0;
            } finally {
              return count
            }
          })
        })
    .then( (cont) => {

         let arrayPromise = []

         for (let i= 0; i< (cont / skip); i++) {
            const newPromise = fetch(`${url}${queryString}&_start=${i*skip}&_limit=${skip}`)
            .then( (res) => { 
                return res.status === 200 ? res.text() : null;
              })
            .then( (data) => { 
            let items = null;
        
            try {
                items = JSON.parse(data);
                items = items !== null ? items : null;
            } catch(e) {
                items = 0;
            } finally {
                return items
            }
            })
            
            arrayPromise.push(newPromise)
         }

         return arrayPromise
    })
    .then( (a) => {
        const res = Promise.all(a).then( (values) => {
            let results = []

            values.forEach( (gruppoItems) => {
                gruppoItems.forEach( (item) => {
                    results = [ ...results, item]
                })
            })

            return results

        })
        return res
    })

    return a
}