import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux reducers
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Tooltip from "@material-ui/core/Tooltip";
import Add from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Chip from "@material-ui/core/Chip";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Clear from "@material-ui/icons/Clear"
import FilterNone from "@material-ui/icons/FilterNone"


// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";

// core Theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom Components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

// utils
import { creaGiorniTemplate } from "utils/templatemenu"


import { fetchPietanze } from "actions/pietanze"
import { fetchGruppi } from "actions/gruppi"
import { fetchTemplatemenu, addTemplatemenu, updateTemplatemenu } from "actions/templatemenu"
import { ListItemText } from "@material-ui/core";

class TemplateMenuDettaglio extends Component {

    state = {
        title: "Inserisci template menu",
        nome: "",
        ordinamento: 0,
        giorni: creaGiorniTemplate(),
        giornoSelezionato: null,
        openDialog: false,
        pastoDaModificare: "",
        cercaNome: "",
        pietanzeDaAggiungere: [],
        pietanzeFiltrate: [],
        messaggio: ''
    }

    componentDidMount() {
        const { caricaPietanze, azienda, caricaGruppi, caricaTemplatemenu, match } = this.props

        const filtroAzienda = [{'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]

        caricaPietanze(filtroAzienda)
        caricaGruppi(filtroAzienda)

        const idTemplate = match.params.id

        if ( idTemplate && idTemplate.length > 0 ) {

        Promise.resolve()
        .then( () => { return caricaTemplatemenu(idTemplate) })
        .then( (res) => {
            if (res) {
                if (res["Giorni"] && res["Giorni"].length > 0 )
                    this.setState({ nome: res["Nome"], giorni: res["Giorni"], title: "Modifica template menu"}) 
                else 
                    this.setState({ nome: res["Nome"], title: "Modifica template menu"})  
            }
        })
            
        }
    }

    visualizzaMenuGiorno = (idxGiorno) => {
        this.setState({ giornoSelezionato: idxGiorno })
    }

    // funzione che disegna il menu in base all'indice del giorno passato
    disegnaMenuGiorno = (idx) => {
        if (idx >= 0 ) {
            return (
                <GridItem>
                    <GridContainer>
                        { this.disegnaMenuPastoGiorno('pranzo', idx) }
                        { this.disegnaMenuPastoGiorno('cena', idx) }
                    </GridContainer>
                </GridItem>
            )
        }
        else 
            return
    }

    // funzione che disegna il menu del pasto del giorno selezionato
    disegnaMenuPastoGiorno = (pasto, idxGiorno) => {

        const { giorni } = this.state

        const giorno = giorni.find( (g) => {
            return g.idx === idxGiorno
        })

        let listaPietanze = []

        if (giorno)
            listaPietanze = giorno[pasto]

        const { pietanze, classes } = this.props

        // scorro le pietanze del menu
        const pietanzeDaMostrare = listaPietanze.map( (p) => {
            // per ogni pietanza devo recuperare le informazioni dalla lista delle pietanze
            let infoPietanza = pietanze.find( (piet) => {
                return piet._id === p
            })

            return infoPietanza
        }).sort( (a, b) => {
            if (a["Gruppo"]["Ordinamento"] < b["Gruppo"]["Ordinamento"])
                return -1

            if (a["Gruppo"]["Ordinamento"] > b["Gruppo"]["Ordinamento"])
                return 1

            return 0
        })

        return (
            <GridItem xs={12} sm={6} md={6} lg={6}>
                <h5>{pasto}</h5>
                <Tooltip
                    id="tooltip-top"
                    title="Aggiungi pietanza"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="Aggiungi pietanza"
                      className={classes.tableActionButton}
                    >
                      <Add
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                        onClick={ () => this.apriModaleInserimento(pasto)}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Duplica dall'altro pasto"
                    placement="top"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <IconButton
                      aria-label="Duplica dall'altro pasto"
                      className={classes.tableActionButton}
                    >
                      <FilterNone
                        className={
                          classes.tableActionButtonIcon + " " + classes.edit
                        }
                        onClick={ () => this.duplicaMenuDaAltroPasto(giorno, pasto)}
                      />
                    </IconButton>
                  </Tooltip>
                {   pietanzeDaMostrare && pietanzeDaMostrare.length > 0 && (
                    <List>
                        { pietanzeDaMostrare.map( (p, idx) => {
                            if (p)
                                return (<ListItem key={p._id}>
                                    <ListItemText primary={p["Nome"]} secondary={p["Gruppo"]["Nome"]} />
                                    <ListItemIcon>
                                        <IconButton onClick={() => this.togliPietanzaDaMenu(p._id, pasto, idxGiorno)}>
                                            <Clear />
                                        </IconButton>
                                    </ListItemIcon>
                                </ListItem>)
                            else 
                                return <p key={idx}>Caricamento...</p>
                        })}
                    </List>
                )
                }
            </GridItem> 
        )
    }

    duplicaMenuDaAltroPasto = (giornoMenu, pastoCorrente) => {
        const pastoDaDuplicare = pastoCorrente === 'pranzo' ? 'cena' : 'pranzo'
        const pietanzeDaAggiungere = giornoMenu[pastoDaDuplicare]

        const { pietanze } = this.props

        const pietanzeDaDuplicare = pietanze.filter( (p) => {
            return pietanzeDaAggiungere.includes(p._id)
        })

        if (pietanzeDaDuplicare.length > 0 ) {
            this.setState({ openDialog: true, pietanzeDaAggiungere: pietanzeDaDuplicare, cercaNome: "", pastoDaModificare: pastoCorrente })
        }

    }

    // apre la maschera modale di inserimento
    apriModaleInserimento = (pasto) => {
        this.setState({ openDialog: true, pastoDaModificare: pasto, cercaNome: "" })
    }

    // azzera i valori utilizzati nella maschera modale
    azzeraModale = () => {
        this.setState({ openDialog: false, pastoDaModificare: "", cercaNome: "", pietanzeDaAggiungere: []})
    }

    // gestice la chiusura diversa da aggiungi nella maschera modale
    chiudiModaleInserimento = () => {
        this.azzeraModale()
    }

    // gestisce la chiusura tramite pulsante aggiungi nella maschera modale
    aggiungiPietanzeDaModale = () => {
        const { pietanzeDaAggiungere, pastoDaModificare, giorni, giornoSelezionato } = this.state

        let giorno = giorni.find( (g) => {
            return g.idx === giornoSelezionato
        })

        if (giorno) {
            pietanzeDaAggiungere.forEach( (pda) => {
                if ( !giorno[pastoDaModificare].includes(pda._id))
                    giorno[pastoDaModificare].push(pda._id)
            })
        }

        this.azzeraModale()
    }

    // filtra le pietanze nella maschera modale
    filtraPietanzePerNome = (e) => {
        const { pietanze} = this.props

        const pietanzeFiltrate = pietanze.filter( (p) => {
            return p["Nome"].toLowerCase().includes(e.target.value.toLowerCase())
        })

        this.setState({ cercaNome: e.target.value, pietanzeFiltrate })
    }

    // gestisce la selezione della pietanza nella maschera modale
    selezionaPietanza = (pietanza) => {
        const { pietanzeDaAggiungere } = this.state

        const pietanzaPresente = pietanzeDaAggiungere.find( (p) => {
            return p._id === pietanza._id
        })

        if (!pietanzaPresente) 
            this.setState({ pietanzeDaAggiungere: [ ...pietanzeDaAggiungere, pietanza ]})
    }

    // Gestisco l'aggiornamento dei campi della form
    handleChange = (e) => {
        let valoreProprieta = e.target.value;
        let nomeProprieta = e.target.name

        if (nomeProprieta === 'Nome')
            this.setState({ nome: valoreProprieta })
        else if (nomeProprieta === 'Ordinamento')
            this.setState({ ordinamento: valoreProprieta })
    }

    // gestisco il submit della form
    handleSubmit = (e) => {
        if (!e)
            return;

        e.preventDefault()


        const values = serializeForm(e.target, { hash: true, empty: true })
        const { giorni } = this.state
        const { azienda, currentTemplatemenu, updateTemplate, addTemplate } = this.props

        values['Giorni'] = giorni
        values['Azienda'] = azienda._id

        if (currentTemplatemenu) {
            // aggiorno il template esistente
            Promise.resolve()
            .then( () => updateTemplate(currentTemplatemenu._id, values))
            .then( () => {
                this.setState({ messaggio: "Template modificato"})
            })
            .catch( () => this.setState({ messaggio: "Impossibile modificare il template"}) )
        }
        else {
            // inserisco un nuovo template
            Promise.resolve()
            .then( () => { return addTemplate(values)})
            .then( (template) => {
                const { history } = this.props

                if (template)
                    history.push(`/dashboard/template-menu/${template._id}/edit`)
                else
                    history.push('/dashboard/template-menu')
            })
        }
    }

    // torna alla lista dei template
    tornaAllaLista = () => {
        const { history } = this.props

        history.push('/dashboard/template-menu')
    }

    // gestisco la chiusura dello snack
    handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        this.setState({ messaggio: '' });
    };
    
    // gestisco l'uscita dallo snack
    handleExitedScnak = () => {
        this.setState({ messaggio: ''})
    };

    togliPietanzaDaMenu = (idPietanza, pasto, idx) => {

        console.log(idPietanza)
        console.log(pasto)
        console.log(idx)

        const { giorni } = this.state
        
        let giorniNew = giorni.slice()

        console.log(giorniNew)

        for ( let i=0; i < giorniNew.length; i++) {
            if ( i === idx) {
                console.log(giorniNew[i][pasto])
                giorniNew[i][pasto] = giorniNew[i][pasto].filter( (p) => {
                    return p !== idPietanza
                })
            }
        }

        this.setState({ giorni: giorniNew})

    }

    render() {
        const { giorni, giornoSelezionato, cercaNome, pietanzeDaAggiungere, pietanzeFiltrate, nome, title, ordinamento } = this.state
        const { classes } = this.props

        return (
            <ScaffoldBase title={title}>
                <form onSubmit={this.handleSubmit}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                    <CustomInput
                        labelText="Nome"
                        id="nome"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            name: "Nome",
                            required: true,
                            value: nome,
                            onChange: this.handleChange
                        }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} lg={6}>
                    <CustomInput
                        labelText="Ordinamento"
                        id="Ordinamento"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            type: 'number',
                            name: "Ordinamento",
                            value: ordinamento,
                            onChange: this.handleChange
                        }}
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={2} lg={1}>
                        <h3>Giorni</h3>
                        <List>
                            { giorni && giorni.length > 0 && giorni.map( (giorno, idx) => {
                                return (
                                    <ListItem key={idx} divider={true} selected={giornoSelezionato === idx } onClick={() => this.visualizzaMenuGiorno(idx)} style={{cursor: 'pointer'}}>
                                        {giorno.nome}
                                    </ListItem>
                                )
                            })}
                        </List>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10} lg={11}>
                        { giornoSelezionato !== null && giornoSelezionato !== undefined && (
                            this.disegnaMenuGiorno(giornoSelezionato)
                        )}
                        { giornoSelezionato === null && (
                            <h4>Seleziona un giorno per visualizzare/modificare il menu</h4>
                        )}
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <Button type="submit" color="success">Salva</Button>
                    <Button color="transparent" onClick={ () => this.tornaAllaLista() }>Indietro</Button>
                </GridContainer>
                </form>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.chiudiModaleInserimento}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">Aggiungi pietanza</DialogTitle>
                    <DialogContent>
                        { pietanzeDaAggiungere && pietanzeDaAggiungere.length > 0 && pietanzeDaAggiungere.map( (p) => {
                            return (<Chip key={p._id} label={p["Nome"]} />)
                        })}
                        <CustomInput
                            labelText="Nome"
                            id="nome"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                            name: "Nome",
                            value: cercaNome,
                            placeholder: "Cerca pietanza per nome",
                            onChange: this.filtraPietanzePerNome
                            }}
                        />
                        { pietanzeFiltrate && pietanzeFiltrate.length > 0 && pietanzeFiltrate.length <= 10 && (
                            <Table>
                                <TableBody>
                                { pietanzeFiltrate.map( (p) => {
                                    return (
                                    <TableRow key={p._id} onClick={() => this.selezionaPietanza(p)}>
                                        <TableCell>{p["Nome"]}</TableCell>
                                        <TableCell>{p["Gruppo"] && p["Gruppo"]["Nome"] && p["Gruppo"]["Nome"].length > 0 ? 'Primi' : ""}</TableCell>
                                    </TableRow>)
                                })}
                                </TableBody>
                            </Table>
                        )
                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.chiudiModaleInserimento} color="transparent">Annulla</Button>
                        <Button onClick={this.aggiungiPietanzeDaModale} color="success">Aggiungi</Button>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                    autoHideDuration={3000}
                    onClose={this.handleCloseSnack}
                    onExited={this.handleExitedSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messaggio}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnack}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
            </ScaffoldBase>
        )
    }
}

function mapDispatchToProps( dispatch ) {
    return {
        caricaPietanze: (filtro) => dispatch(fetchPietanze(filtro)),
        caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro)),
        caricaTemplatemenu: (id) => dispatch(fetchTemplatemenu(id)),
        addTemplate: (template) => dispatch(addTemplatemenu(template)),
        updateTemplate: (id, template) => dispatch(updateTemplatemenu(id, template))
    }
  }
  
  function mapStateToProps({ pietanze, gruppi, authentication, templatemenu }) {
    return { 
        pietanze: pietanze.items,
        azienda: authentication.user["Azienda"],
        gruppi: gruppi.items,
        currentTemplatemenu: templatemenu.currentTemplatemenu
    }
  }

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(TemplateMenuDettaglio));