import React, { Component } from "react";

// React-Redux reducers
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";

// core Theme components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

const defaultColor = "#3e2a23"

class ScaffoldBase extends Component {
    render() {

        const { classes, title, subtitle, children, azienda } = this.props

        const coloreBackGroundHeader = azienda && azienda["Colore primario"] ? azienda["Colore primario"] : defaultColor

        return(
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader style={{ backgroundColor: coloreBackGroundHeader }}>
                                <h4 className={classes.cardTitleWhite}>{title}</h4>
                                <p className={classes.cardCategoryWhite}>{subtitle}</p>
                            </CardHeader>
                            <CardBody>
                                { children }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )    
    }
}

function mapStateToProps({ authentication }) {
    return { 
        azienda: authentication.user["Azienda"]
     };
  }

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, null)
  )(withRouter(ScaffoldBase));