import adminRoutes from "routes/admin.jsx";
import adminCustomerRoutes from "routes/admin-customer.jsx";
import employeeRoutes from "routes/employee.jsx";

export function getRoutesForProfile( profile){

    const profiles = [ 'amministratore', 'cliente', 'dipendente']

    if ( !profile || !profiles.includes(profile))
        return null;
    
    let routes = null

    switch (profile) {
        case 'amministratore':
            routes = adminRoutes;
        break;
        case 'cliente':
            routes = adminCustomerRoutes;
        break;
        case 'dipendente':
            routes = employeeRoutes;
        break;
        default:
            routes = null;
    }

    return routes;
}