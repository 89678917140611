import { combineReducers } from "redux";

import { GET_AMMINISTRATORI } from "../actions/amministratori";
import { GET_CLIENTI, GET_CLIENTE, DELETE_CLIENTE } from "../actions/clienti";
import { GET_RECLAMI, GET_RECLAMO, DELETE_RECLAMO } from "../actions/reclami";
import { GET_RICHIESTE, GET_RICHIESTA, DELETE_RICHIESTA } from "../actions/richieste";
import { GET_PIETANZE, GET_PIETANZA, DELETE_PIETANZA } from "../actions/pietanze";
import { GET_ALLERGENI } from "../actions/allergeni";
import { GET_SOTTOGRUPPI } from "../actions/sottogruppi";
import { GET_GRUPPI, GET_GRUPPO } from "../actions/gruppi";
import { GET_MENUS, GET_MENU, DELETE_MENU } from "../actions/menu";
import { GET_PERSONE, GET_PERSONA, DELETE_PERSONA } from "../actions/persone";
import { GET_ORDINAZIONI, GET_ORDINAZIONE, DELETE_ORDINAZIONE } from "../actions/ordinazioni";
import { SET_USER, GET_LOGO } from "../actions/authentication";
import { GET_GIRICONSEGNA } from "actions/giriconsegna"; 
import { GET_TEMPLATEMENU, GET_TEMPLATEMENUS, DELETE_TEMPLATEMENU } from "actions/templatemenu";
import { GET_COMBINAZIONE, GET_COMBINAZIONI, DELETE_COMBINAZIONE } from "actions/combinazioni";

const initialAmministratori = {
    items : []
}

const initialClienti = {
    items: [],
    currentCliente: null
}

const initialPietanze = {
    items: [],
    currentPietanza: null
}

const initialAllergeni = {
    items: []
}

const initialGruppi = {
    items: [],
    currentGruppo: null
}

const initialSottogruppi = {
    items: []
}

const initialMenu = {
    items: []
}

const initialPersone = {
    items: []
}

const initialReclamo = {
    items: [],
    currentReclamo: null
}

const initialRichiesta = {
    items: [],
    currentRichiesta: null
}

const initialOrdinazioni = {
    items: []
}

const initialUser = {
    logged: false,
    user: null,
    logo: ""
}

const initialGiriconsegna = {
    items: []
}

const initialTemplateMenu = {
    items: [],
    currentTemplatemenu: null
}

const initialCombinazioni = {
    items: [],
    currentCombinazione: null
}

function amministratori( state=initialAmministratori, action ) {
    switch(action.type) {
        case GET_AMMINISTRATORI:
            const { items } = action;
            return { ...state, items };
        default:
            return state ;
    }
}

function clienti(state=initialClienti, action) {
    switch(action.type) {
        case GET_CLIENTI:
            const { items } = action
            return { ...state, items, currentCliente: null };
        case GET_CLIENTE:
            const { cliente } = action
            return { ...state, currentCliente: cliente }
        case DELETE_CLIENTE:
            return { ...state, currentCliente: null }
        default:
            return state ;
    }
}

function combinazioni(state=initialCombinazioni, action) {
    switch(action.type) {
        case GET_COMBINAZIONI:
            const { items } = action
            return { ...state, items };
        case GET_COMBINAZIONE:
            const { combinazione } = action
            return { ...state, currentCombinazione: combinazione }
        case DELETE_COMBINAZIONE:
            return { ...state, currentCombinazione: null}
        default:
            return state;
    }
}

function pietanze(state=initialPietanze, action) {
    switch(action.type) {
        case GET_PIETANZE:
            const { items } = action
            return { ...state, items, currentPietanza: null };
        case GET_PIETANZA:
            const { pietanza } = action
            return { ...state, currentPietanza: pietanza }
        case DELETE_PIETANZA:
            return { ...state, currentPietanza: null }
        default:
            return state;
    }
}

function allergeni(state=initialAllergeni, action) {
    switch(action.type) {
        case GET_ALLERGENI:
            const { items } = action
            return { ...state, items };
        default:
            return state;
    }
}

function gruppi(state=initialGruppi, action) {
    switch(action.type) {
        case GET_GRUPPI:
            const { items } = action
            return { ...state, items };
        case GET_GRUPPO:
            const { gruppo } = action
            return { ...state, currentGruppo: gruppo}
        default:
            return state;
    }
}

function sottogruppi(state=initialSottogruppi, action) {
    switch(action.type) {
        case GET_SOTTOGRUPPI:
            const { items } = action
            return { ...state, items };
        default:
            return state;
    }
}

function menu(state=initialMenu, action) {
    switch(action.type) {
        case GET_MENUS:
            const { items } = action
            return { ...state, items };
        case GET_MENU:
            const { menu } = action
            return { ...state, currentMenu: menu }
        case DELETE_MENU:
            return { ...state, currentMenu: null}
        default:
            return state;
    }
}

function templatemenu(state=initialTemplateMenu, action) {
    switch(action.type) {
        case GET_TEMPLATEMENUS:
            const { items } = action
            return { ...state, items, currentTemplatemenu: null };
        case GET_TEMPLATEMENU:
            const { templatemenu } = action
            return { ...state, currentTemplatemenu: templatemenu }
        case DELETE_TEMPLATEMENU:
            return { ...state, currentTemplatemenu: null}
        default:
            return state;
    }
}

function persone(state=initialPersone, action) {
    switch(action.type) {
        case GET_PERSONE:
            const { items } = action
            return { ...state, items, currentPersona: null };
        case GET_PERSONA:
            const { persona } = action
            return { ...state, currentPersona: persona }
        case DELETE_PERSONA:
            return { ...state, currentPersona: null }
        default:
            return state;
    }
}

function reclami(state=initialReclamo, action) {
    switch(action.type) {
        case GET_RECLAMI:
            const { items } = action
            return { ...state, items, currentReclamo: null };
        case GET_RECLAMO:
            const { reclamo } = action
            return { ...state, currentReclamo: reclamo }
        case DELETE_RECLAMO:
            return { ...state, currentReclamo: null }
        default:
            return state;
    }
}

function richieste(state=initialRichiesta, action) {
    switch(action.type) {
        case GET_RICHIESTE:
            const { items } = action
            return { ...state, items, currentRichiesta: null };
        case GET_RICHIESTA:
            const { richiesta } = action
            return { ...state, currentRichiesta: richiesta }
        case DELETE_RICHIESTA:
            return { ...state, currentRichiesta: null }
        default:
            return state;
    }
}

function ordinazioni(state=initialOrdinazioni, action) {
    switch(action.type) {
        case GET_ORDINAZIONI:
            const { items } = action
            return { ...state, items, currentOrdinazione: null };
        case GET_ORDINAZIONE:
            const { ordinazione } = action
            return { ...state, currentOrdinazione: ordinazione }
        case DELETE_ORDINAZIONE:
            return { ...state, currentOrdinazione: null }
        default:
            return state;
    }
}

function authentication(state=initialUser, action) {
    switch(action.type) {
        case SET_USER:
            const { logged, user } = action
            return { ...state, logged, user}
        case GET_LOGO:
            const { logo } = action
            return { ...state, logo }
        default: 
            return state;
    }
}

function giriconsegna(state=initialGiriconsegna, action) {
    switch(action.type) {
        case GET_GIRICONSEGNA:
            const { items } = action
            return { ...state, items }
        default:
            return state;
    }
}

export default combineReducers({
    allergeni,
    amministratori,
    authentication, 
    clienti,
    combinazioni,
    persone,
    reclami,
    richieste,
    giriconsegna,
    gruppi,
    menu,
    ordinazioni,
    pietanze,
    sottogruppi,
    templatemenu
});
