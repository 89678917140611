import React, { Component } from "react";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography } from "@material-ui/core";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import Background from '../../assets/img/login.jpg';


// theme components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

var sectionStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: `cover`,
    backgroundPosition: `top center`,
    height: `auto`,
    minHeight: `100vh`
};


class RecuperoPassword extends Component{

    state = {
        email: ""
    }

    handleChange = (e) => {
        const nomeCampo = e.target.name;
        const valoreCampo = e.target.value

        this.setState((state) => {
            state[nomeCampo] = valoreCampo

            return state;
        })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        
    }

    render() {

        return(
            <section style={ sectionStyle }>
                <GridContainer justify={"center"} alignItems={"center"}>
                    <GridItem xs={12} sm={10} md={8} lg={6}>
                        <Card>
                            <CardHeader>
                                <h4>Recupero password</h4>
                            </CardHeader>
                            <CardBody>
                                <GridItem>
                                    <Typography>Inserisci l'indirizzo email con cui accedi alla tua area riservata. Ti verrà inviata una mail che ti consentirà di cambiare la tua password.</Typography>
                                </GridItem>
                                <form onSubmit={this.handleSubmit}>
                                    <GridItem xs={12} sm={8} md={6} lg={6}>
                                        <CustomInput
                                            labelText="Email"
                                            id="email"
                                            formControlProps={{ fullWidth: true }}
                                            inputProps={{
                                                name: "email",
                                                type: "email",
                                                required: true,
                                                value: this.state.email,
                                                onChange: this.handleChange
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem>
                                        <Button type="submit" color="success" >Recupera</Button>
                                    </GridItem>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>

        )
    }
}

function mapDispatchToProps( dispatch ){
    return {
    }
}

export default compose(
    withStyles(dashboardStyle),
    connect(null, mapDispatchToProps)
  )(withRouter(RecuperoPassword));
