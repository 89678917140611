/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

// utils
import { getRoutesForProfile } from "utils/authentication.js";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import image from "assets/img/sidebar-2.jpg";
//import logo from "assets/img/reactlogo.png";
//import logo from "assets/img/logo_tmp.png";

const switchRoutes = (routes) => (
  <Switch>
    {routes && routes.map((prop, key) => {
      if (prop.redirect)
        return <Redirect from={prop.path} to={prop.to} key={key} />;
      return <Route path={prop.path} component={prop.component} key={key} />;
    })}
  </Switch>
);

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false
    };
    this.resizeFunction = this.resizeFunction.bind(this);
  }
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  resizeFunction() {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  }
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      if (this.refs.mainPanel) {
        let ps = new PerfectScrollbar(this.refs.mainPanel);
      }
    }
    window.addEventListener("resize", this.resizeFunction);
  }
  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      if (this.refs.mainPanel) {
        this.refs.mainPanel.scrollTop = 0;
        if (this.state.mobileOpen) {
          this.setState({ mobileOpen: false });
        }
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeFunction);
  }
  render() {
    const { classes, loggedIn, userLogged, logo, ...rest } = this.props;

    const routes = getRoutesForProfile(userLogged["Profilo"])
    const nomeAzienda = userLogged && userLogged["Azienda"] && userLogged["Azienda"]["Ragione sociale"] ? userLogged["Azienda"]["Ragione sociale"] : "Dasporto"

    return (
      <div className={classes.wrapper}>
      { loggedIn && (
        <div>
          <Sidebar
            routes={routes}
            logoText={nomeAzienda}
            logo={logo}
            image={image}
            handleDrawerToggle={this.handleDrawerToggle}
            open={this.state.mobileOpen}
            color="blue"
            {...rest}
          />
          <div className={classes.mainPanel} ref="mainPanel">
            <Header
              routes={routes}
              handleDrawerToggle={this.handleDrawerToggle}
              {...rest}
            />
            <div className={classes.content}>
              <div className={classes.container}>{switchRoutes(routes)}</div>
            </div>
            <Footer />
          </div>
        </div>
      )}
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapStateToProps({ authentication }) {
  return {
      loggedIn: authentication.logged,
      userLogged: authentication.user,
      logo: authentication.logo
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, null)
)(withRouter(Dashboard));
