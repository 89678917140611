import React, { Component } from "react";
import serializeForm from "form-serialize";
import { sha256 } from 'js-sha256';

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addPersona, updatePersona, fetchPersona } from "actions/persone";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

// utils
import { checkEmailPresente } from "utils/persone"

const nuovoDipendente = {
  "Nome" : "",
  "Cognome" : "",
  "Codice Fiscale" : "",
  "Matricola" : "",
  "Email" : "",
  "Username" : "",
  "Password" : "",
  "Azienda" : ""
}

class DettaglioDipendente extends Component {

  state = {
    dipendente: nuovoDipendente,
    title : "",
    subtitle: "Dettaglio dipendente",
    messaggio: ""
  }

  componentDidMount() {
    const { match, caricaDipendente } = this.props

    const idDipendente = match.params.id

    if ( idDipendente && idDipendente.length > 0 ) {

      Promise.resolve()
      .then( () => caricaDipendente(idDipendente))
      .then( () => {
        const { currentDipendente } = this.props
        this.setState({ dipendente:  currentDipendente,
                        title: "Modifica dipendente" })
      })
    }
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {
    if (!e)
      return;

    e.preventDefault()
    const { currentDipendente, addDipendente, updateDipendente, utenteLoggato } = this.props

    const values = serializeForm(e.target, { hash: true, empty: true })

    // gestisco lo stato
    values["Stato"] = "in fase di attivazione";

    // gestisco il profilo
    values["Profilo"] = "dipendente";

    if ((currentDipendente && values["Password"] !== currentDipendente["Password"]) || (!currentDipendente && values["Password"].length > 0 ))
      values["Password"] = sha256(values["Password"])

      // gestisco l'appartenenza all'azienda
    if ( !currentDipendente && utenteLoggato && utenteLoggato["Azienda"])
      values["Azienda"] = utenteLoggato["Azienda"]._id


    Promise.resolve()
    .then( () => {
      let valido = this.validate(values)

      return valido
    })
    .then( (valido) => {
      const { esito, messaggio } = valido

      if (esito === true) {
        if ( currentDipendente) {
          // Modifico il dipendente esistente
          updateDipendente(currentDipendente["_id"], values);
        }
        else {
          Promise.resolve()
          .then( () => {
            return addDipendente(values)
          })
          .then( (dipendente) => {
            const { history } = this.props
    
            if (dipendente && dipendente._id !== undefined){
              history.push(`/dashboard/dipendenti/${dipendente._id}/edit`)
            }
            else
              history.push('/dashboard/dipendenti')
          })
        }
      }
      else { 
        this.setState({ messaggio })
      }  
    })
  }

  // Gestisco l'aggiornamento dei campi della form
  handleChange = (e) => {
    let nomeProprieta = e.target.name;
    let valoreProprieta = e.target.value;
    let updatedDipendente = {};

    updatedDipendente[nomeProprieta] = valoreProprieta;

    this.setState({ dipendente: { ...this.state.dipendente, ...updatedDipendente } })
  }

  // pulsante torna indietro
  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // gestisco la chiusura dello snack
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '' });
  };

  // gestisco l'uscita dallo snack
  handleExitedScnak = () => {
    this.setState({ messaggio: ''})
  };
  

  render() {
    const { dipendente, title, subtitle } = this.state
    const { classes } = this.props

    return (
      <div>
        <ScaffoldBase title={title} subtitle={subtitle}>
          <form onSubmit={this.handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Nome"
                  id="nome"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Nome",
                    required: true,
                    value: dipendente["Nome"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Cognome"
                  id="cognome"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Cognome",
                    value: dipendente["Cognome"],
                    required: true,
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Codice Fiscale"
                  id="codiceFiscale"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Codice Fiscale",
                    value: dipendente["Codice Fiscale"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Matricola"
                  id="matricola"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Matricola",
                    value: dipendente["Matricola"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Email"
                  id="email"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    type: "email",
                    name: "Email",
                    value: dipendente["Email"],
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Username"
                  id="username"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Username",
                    value: dipendente["Username"],
                    required: true,
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Password"
                  id="password"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    type: "password",
                    name: "Password",
                    value: dipendente["Password"],
                    required: true,
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" color="success">Salva</Button>
                <Button color="transparent" onClick={ () => this.tornaIndietro() }>Indietro</Button>
              </GridItem>
            </GridContainer>
          </form>   
          <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                    autoHideDuration={3000}
                    onClose={this.handleCloseSnack}
                    onExited={this.handleExitedSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messaggio}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnack}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
        </ScaffoldBase>
      </div>
    );
  }

  async validate(dipendente){
    // Controllo che i dati inseriti siano corretti prima di inviare la richiesta di salvataggio
    let result = {
      esito: true,
      messaggio: ""
    }

    if (dipendente === null ) {
      result.esito = false;
      result.messaggio = "Il dipendente deve essere valorizzato";
      return result;
    }

    // controllo la presenza del nome
    if (dipendente['Nome'] == null || dipendente['Nome'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire il Nome del dipendente. CAMPO OBBLIGATORIO";
      return result;
    }

    // controllo la presenza del cognome
    if (dipendente['Cognome'] == null || dipendente['Cognome'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire il Cognome del dipendente. CAMPO OBBLIGATORIO";
      return result;
    }

    // controllo la presenza dell'username / email
    if (dipendente['Username'] == null || dipendente['Username'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire lo Username del dipendente. CAMPO OBBLIGATORIO";
      return result;
    }

    // controllo la presenza di email doppi
    if (dipendente["Email"] !== null) {
      const { currentDipendente } = this.props

      // Devo cercare tra le persone se esistono persone con la stessa email
      const controlloEmailDuplicate = await checkEmailPresente(dipendente["Email"], currentDipendente && currentDipendente['id'] ? currentDipendente.id : '')

      if ( controlloEmailDuplicate === false) {
        result.esito = false;
        result.messaggio = "L'email inserita è già stata utilizzata da un'altra persona"

        return result
      }
    }
    
    return result;
  }
}

function mapStateToProps({ persone, authentication }) {
  return {
    currentDipendente: persone.currentPersona,
    utenteLoggato: authentication.user
  }
}

function mapDispatchToProps( dispatch ){
  return {
    addDipendente: (dipendente) => dispatch(addPersona(dipendente)),
    updateDipendente: (id, dipendente) => dispatch(updatePersona(id, dipendente)),
    caricaDipendente: (id) => dispatch(fetchPersona(id))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioDipendente));
