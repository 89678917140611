
import { getBigData } from "utils/api"
import { trovaCombinazioneOrdinazione } from "utils/combinazioni"
import { getCostoGruppo } from "utils/costigruppi"

// funzione che controlla se è stata selezionata un'unica pietanza del gruppo
export function controllaUnicaPietanzaGruppo(pietanza, pietanzeOrdinate) {
    let res = true

    let gruppi = []

    // scorro i gruppi presenti nelle ordinazioni effettuate
    pietanzeOrdinate.forEach( (p) => {
        if (!gruppi.includes(p["Gruppo"]))
            gruppi.push(p["Gruppo"])
    })

    if (gruppi.includes(pietanza["Gruppo"]))
        res = false;

    return res
}

export function controllaUnaPietanzaPerGruppo(pietanzeOrdinate, menu, gruppi) {
    let res = true

    let idGruppiMenu = []

    // recupero gli id dei gruppi che fanno parte del menu
    if (menu["Pietanze"] && menu["Pietanze"].length > 0 ) {
        menu["Pietanze"].forEach( (p) => {
            if ( p["Gruppo"] && p["Gruppo"].length > 0 && !idGruppiMenu.includes(p["Gruppo"])) {
                idGruppiMenu.push(p["Gruppo"])
            }
        })
    }

    // filtro i gruppi in base ai gruppi presenti nel menu
    const gruppiDaControllare = gruppi.filter( (g) => {
        if (idGruppiMenu.includes(g._id))
            return g
        else
            return null
    })

    // scorro i gruppi e controllo che ci sia al massimo una pietanza per gruppo
    gruppiDaControllare.forEach( (g) => {
        let pietanze = pietanzeOrdinate.filter( (p) => {
            return g._id === p["Gruppo"] || g._id === p["Gruppo"]._id
        })

        if ( pietanze.length === 0) {
            res = false
            return
        }
    })

    return res
}


export function disabilitaMenu(giornoPasto, oraUltimaPrenotazione, ordinazioniAltroPasto, ordinazioniAltroPastoPresenti) {
    let res = true

    if (ordinazioniAltroPastoPresenti && ordinazioniAltroPastoPresenti.length > 0 ) {
        return true
    }

    let adesso = new Date()

    let dataOraScadenzaPrenotazione = new Date(giornoPasto)
    //dataOraScadenzaPrenotazione = createNewDate(dataOraScadenzaPrenotazione) 

    if (oraUltimaPrenotazione.trim().length > 0) {
        const hours = oraUltimaPrenotazione.substring(0,2)
        const minutes = oraUltimaPrenotazione.substring(3,5)

        dataOraScadenzaPrenotazione.setTime( dataOraScadenzaPrenotazione.getTime() + (1* 60 * 60 * 1000))
        dataOraScadenzaPrenotazione.setMilliseconds(0)
        dataOraScadenzaPrenotazione.setSeconds(0)
        dataOraScadenzaPrenotazione.setMinutes(minutes)
        dataOraScadenzaPrenotazione.setHours(hours)
    }

    res =  dataOraScadenzaPrenotazione && dataOraScadenzaPrenotazione.getTime() < adesso.getTime() ? true : false

    // gestisco il fatto di disabilitare l'altro pasto nel caso in cui un pasto sia già stato ordinato
    /*if (ordinazioniAltroPasto && ordinazioniAltroPasto.length > 0 ) {
        res = true
    }*/

    return res
}

// dato un insieme di ordinazioni le raggruppa per cliente e per gruppo di pietanze
/*
     [
        { 
            id: 'idcliente',
            ragionesociale: 'ragione sociale cliente',
            ordinazioni : [
                { 
                    idgruppo: 'idgruppo', 
                    nomegruppo: 'nomeGruppo', 
                    pietanze: [
                        { id: 'idpietanza', nome: 'nomepietanza', conteggio: 'conteggiopietanza' },
                        { id: 'idpietanza', nome: 'nomepietanza', conteggio: 'conteggiopietanza' },
                        { id: 'idpietanza', nome: 'nomepietanza', conteggio: 'conteggiopietanza' },
                        etc etc
                    ],
                    totalePietanze: 'contatore totale',
                    totalePrezzo: 'prezzo totale da pagare'
                },
                { 
                    idgruppo: 'idgruppo', 
                    nomegruppo: 'nomeGruppo', 
                    pietanze: [
                        { id: 'idpietanza', nome: 'nomepietanza', conteggio: 'conteggiopietanza' },
                        { id: 'idpietanza', nome: 'nomepietanza', conteggio: 'conteggiopietanza' },
                        { id: 'idpietanza', nome: 'nomepietanza', conteggio: 'conteggiopietanza' },
                        etc etc
                    ],
                    totalePietanze: 'contatore totale',
                    totalePrezzo: 'prezzo totale da pagare'
                }
            ]
        }
    ]
*/
export function raggruppaOrdinazioniClienti(giorno, pasto, clienti=[], gruppi=[]) {

    return Promise.resolve()
    .then( () => {
        let arrayPromise = []

        clienti.forEach( (cliente) => {

            const queryStringOrdinazioni = `?Data=${giorno}&Pasto=${pasto}&Azienda=${cliente._id}`
            const newPromise = getBigData('ordinazionis', queryStringOrdinazioni)
            .then( (ordinazioni) => {
                const raggruppamenti = raggruppaPietanzeGruppi(gruppi, ordinazioni)

                const nuovoCliente = { 'id': cliente._id, 'ragioneSociale': cliente["Ragione sociale"], 'ordinazioni': raggruppamenti }

                return nuovoCliente
            })

            arrayPromise.push(newPromise)

        })
        
        return arrayPromise

    })
    .then( (aPromise) => {
        return Promise.all(aPromise)
    })
    .then( (result) => { return result })

}

export function raggruppaPietanzeGruppi(gruppi=[], ordinazioni=[]) {
    let res = []

    gruppi.sort( (a, b) => {
        if (a['Ordinamento'] < b['Ordinamento'])
            return -1

        if (a['Ordinamento'] > b['Ordinamento'])
            return 1

        return 0
    }).forEach( (gruppo) => {
        // scorro le ordinazioni per filtrare quelle che hanno pietanze facenti parte del gruppo
        let pietanzeGruppo = []
        let totalePietanze = 0
        let totalePrezzo = 0

        ordinazioni.forEach( (ordinazione) => {
            const pietanzeOrdinate = ordinazione["Pietanze"]

            pietanzeOrdinate && pietanzeOrdinate.forEach( (piet) => {

                if ( piet["Gruppo"] === gruppo._id ) {

                    totalePietanze += 1

                    // controllo se la pietanza è già presente nelle pietanze del gruppo
                    let pietanzaPresente = pietanzeGruppo.find( (p) => {
                        return p.id === piet._id
                    })

                    if (pietanzaPresente) {
                        // aggiorno solo il contatore
                        const contatore = pietanzaPresente.contatore +1
                        totalePrezzo = ( parseFloat(pietanzaPresente["Prezzo"]) * parseFloat(contatore) ).toFixed(2)

                        pietanzeGruppo = pietanzeGruppo.map( (p) => {
                            if (p.id === piet._id)
                                return { ...p, contatore }
                            else
                                return p
                        })

                    }
                    else {
                        // aggiungo la pietanza al gruppo di pietanze
                        const pietanzaDaAggiungere = { 'id': piet._id, 'nome': piet["Nome"], 'Prezzo': piet["Prezzo"], 'contatore': 1 }
                        totalePrezzo = (parseFloat(piet["Prezzo"]) * parseFloat(1)).toFixed(2)
                        pietanzeGruppo = [ ...pietanzeGruppo, pietanzaDaAggiungere]
                    }
                }
            })
        })

        const raggruppamento = { 'id': gruppo._id, 'Gruppo': gruppo["Nome"], 'Pietanze': pietanzeGruppo, 'totalePietanze': totalePietanze, 'totalePrezzo': totalePrezzo }

        res = [ ...res, raggruppamento ]
    })

    return res
}


// funzione che dato un insieme di ordinazioni le ordina in base al gruppo e al cliente per stampare le etichette
export function raggruppaOrdinazioniGruppoCliente(ordinazioni, gruppi=[]) {
    let res = []

    /* campi riga
    ragioneSociale,
    giorno,
    pasto,
    idOrdinazione,
    idPietanza,
    Cognome,
    Nome,
    Matricola,
    nomePietanza

    */

    // scorro le ordinazioni e inserisco le pietanze nell'apposito contenitore dei gruppi
    ordinazioni.forEach( (ordinazione) => {
        const pietanze = ordinazione["Pietanze"]

        pietanze.forEach( (pietanza) => {
            const idGruppoPietanza = pietanza["Gruppo"]

            const gruppoPietanza = gruppi.find( (g) => {
                return g.id === idGruppoPietanza
            })

            const datiEtichetta = {
                'ragioneSociale': ordinazione["Azienda"]["Ragione sociale"],
                'giorno': `${new Date(ordinazione["Data"]).toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'})}`,
                'pasto': ordinazione["Pasto"],
                'idOrdinazione': ordinazione._id,
                'idPietanza': pietanza._id,
                'nomePietanza': pietanza["Nome"],
                'Cognome': ordinazione["Persone"] && ordinazione["Persone"]["Cognome"] ? ordinazione["Persone"]["Cognome"] : "",
                'Nome': ordinazione["Persone"] && ordinazione["Persone"]["Nome"] ? ordinazione["Persone"]["Nome"] : "",
                'Matricola': ordinazione["Persone"] && ordinazione["Persone"]["Matricola"] ? ordinazione["Persone"]["Matricola"] : "",
                "GruppoOrdinamento" : gruppoPietanza && gruppoPietanza["Ordinamento"] ? gruppoPietanza["Ordinamento"] : 0
            }
            
            res.push(datiEtichetta)
        })
    })

    // effettuo l'ordinamento del risultato
    // l'ordinamento deve essere 
    // Cliente / Gruppo / Nome Pietanza / Nome dipendente
    res.sort( (a, b) => {
        // ordino per ragione sociale dell'azienda
        if ( a.ragioneSociale > b.ragioneSociale)
            return 1
        if ( a.ragioneSociale < b.ragioneSociale )
            return -1
        // ordino per gruppo
        if (a.GruppoOrdinamento > b.GruppoOrdinamento)
            return 1
        if (a.GruppoOrdinamento < b.GruppoOrdinamento)
            return -1
        // ordino per nome pietanza
        if (a.nomePietanza > b.nomePietanza)
            return 1
        if (a.nomePietanza < b.nomePietanza)
            return -1
        // ordino per nome dipendente
        if (a.Cognome > b.Cognome)
            return 1
        if (a.Cognome < b.Cognome)
            return -1
        if (a.Nome > b.Nome)
            return 1
        if (a.Nome < b.Nome)
            return -1
        return 0
    })

    return res

}

export function raggruppaPietanzeClientiGiri(gruppi=[], ordinazioni=[], clienti=[]) {

    // ordino i clienti in base al giro e successivamente in base al nome
    const clientiOrdinatiGiro = clienti.sort( (a, b) => {
        if (!a['Giroconsegna'])
            return 0

        if (a['Giroconsegna']['Ordinamento'] > b['Giroconsegna']['Ordinamento'] )
            return 1
        
        if (a['Giroconsegna']['Ordinamento'] < b['Giroconsegna']['Ordinamento'] )
            return -1
        
        if ( a["Ragione sociale"] > b["Ragione sociale"])
            return 1

        if ( a["Ragione sociale"] < b["Ragione sociale"])
            return -1

        return 0
    })

    let res = []
    let gruppiPietanze = raggruppaPietanzeGruppi(gruppi, ordinazioni)
    
    // ho dato per definito che i giri sono 2 piu una colonna per il totale complessivo
    let numeroColonneTotali = clientiOrdinatiGiro.length + 3

    // scorro ogni gruppo di pietanze per parsarle ed aggiungerle al risultato
    gruppiPietanze.forEach( (gruppo) => {
        let pietanze = gruppo['Pietanze']

        // ordino le pietanze per nome
        pietanze.sort( (a, b) => {
            if (a['nome'] > b['nome'])
                return 1
            
            if (a['nome'] < b['nome'])
                return -1

            return 0
        }).forEach( (p) => {
            let rigaPietanza = { id: p.id, Nome: p.nome }
            let valori = []

            let giroCorrente = ""
            let totaleGiro = 0

            clientiOrdinatiGiro.forEach( (cliente, idx) => {

                if ( !cliente["Giroconsegna"])
                    return
                    
                // controllo se devo cambiare il giro
                if (giroCorrente !== "" && cliente['Giroconsegna']._id !== giroCorrente) {
                    
                    // aggiungo all'array dei valori il totale del giro
                    valori.push(totaleGiro)

                    // azzero il totale del giro
                    totaleGiro = 0
                }

                giroCorrente = cliente['Giroconsegna']._id

                let num = recuperaNumeroPietanzeOrdinatePerCliente(ordinazioni, p, cliente)
                valori.push(num)
                totaleGiro += num

                // devo controllare se sono alla fine per aggiungere l'ultimo totale del giro
                if (idx === (clientiOrdinatiGiro.length -1)) {
                    valori.push(totaleGiro)
                }
            })

            const { contatore } = p

            // aggiungo all'array delle quantità il valore totale finale
            valori.push(contatore)

            rigaPietanza = { ...rigaPietanza, valori}

            res.push(rigaPietanza)

        })
    })
    let valori = []
    for (let i = 0; i < numeroColonneTotali; i++) {
        valori.push(0)
    }

    let rigaTotali = { Nome: 'Totale', valori }

    // devo recuperare i totali. scorro su tutte le righe
    for ( let i = 0; i < res.length ; i++) {
        const { valori } = res[i]

        // scorro i valori di ogni riga
        for ( let j= 0; j < valori.length ; j++) {
            rigaTotali.valori[j] = rigaTotali.valori[j] + valori[j]
        }
    }

    res.push(rigaTotali)

    return res

}

function recuperaNumeroPietanzeOrdinatePerCliente(ordinazioni, pietanza, cliente) {
    let res = 0

    // filtro le ordinazioni in base al cliente
    const ordinazioniCliente = ordinazioni.filter( (o) => {
        return o['Azienda']._id === cliente._id
    })

    ordinazioniCliente.forEach( (o) => {
        const pietanzeOrdinazione = o["Pietanze"]

        const pietanzaTrovata = pietanzeOrdinazione.find( (p) => {
            return p.id === pietanza.id
        })

        if (pietanzaTrovata)
            res += 1
    })

    return res
}

// funzione che recupera il recordset delle informazioni sul venduto del cliente
export function recuperaVenduto(ordinazioni, subclienti, combinazioni, gruppi) {
    let res = {
        corpo: [],
        totali: {
            gruppi: [],
            combinazioni: [],
            totale: 0
        }
    }

    // ordino i subclienti per ragione sociale
    subclienti.sort( (a, b) => {
        if (a["Ragione sociale"] && b["Ragione sociale"]) {
            if (a["Ragione sociale"] > b["Ragione sociale"])
                return 1;
            if (a["Ragione sociale"] < b["Ragione sociale"])
                return -1
            return 0
        }
        else
            return 0
    })
    .map( (sc) => {
        
        let rigaSubcliente = {
            id: sc.id,
            ragioneSociale: sc["Ragione sociale"],
            combinazioni: [],
            totalecombinazioni: { quantita: 0, importo: 0 },
            gruppi: [],
            totalegruppi: { quantita: 0, importo: 0 },
            totale: 0
        }

        // recupero gli importi dei gruppi una volta per subcliente
        let importiGruppi = []

        gruppi.forEach( (g) => {
            let importoGruppo = getCostoGruppo( sc["Costi pasti"], g.id, 'azienda')

            importoGruppo = importoGruppo ? importoGruppo["Valore"] : 0

            importiGruppi[g.id] = importoGruppo
        })

        // prendo tutte le ordinazioni del subcliente
        const ordinazioniSubCliente = ordinazioni.filter( (o) => {
            return o["Azienda"] && o["Azienda"].id === sc.id
        })

        // prendo tutte le combinazioni del subcliente
        const combinazioniSubCliente = combinazioni && combinazioni.filter( (c) => {
            return c["Azienda"] && c["Azienda"].id === sc.id
        })

        // scorro le ordinazioni del subcliente e le parso
        ordinazioniSubCliente.forEach( (osc) => {
            // recupero la combinazione che fa match con l'ordinazione
            const combinazioneMatch = trovaCombinazioneOrdinazione(osc, combinazioniSubCliente)

            if ( combinazioneMatch ) {
                // ho trovato una combinazione che fa match
                // recupero il prezzo della combinazione
                const combinazioneTrovata = rigaSubcliente.combinazioni.find( (c) => {
                    return c.id === combinazioneMatch.id
                })

                let importoDaAggiungere = parseFloat(combinazioneMatch["Prezzoazienda"])

                // se la combinazione è già presente devo aggiornare il contatore 
                if ( combinazioneTrovata ) {
                    combinazioneTrovata.quantita +=1
                    combinazioneTrovata.importo = parseFloat(combinazioneTrovata.importo) + importoDaAggiungere
                    combinazioneTrovata.importo = parseFloat(combinazioneTrovata.importo.toFixed(2))

                }
                else {
                    // altrimenti devo aggiungere la nuova combinazione all'elenco
                    rigaSubcliente.combinazioni.push( { id: combinazioneMatch.id,
                                                        nome: combinazioneMatch["Descrizione"],
                                                        quantita: 1,
                                                        prezzo: importoDaAggiungere,
                                                        importo: importoDaAggiungere
                    })
                }

                // aggiorno il totale delle combinazioni del subcliente
                rigaSubcliente.totalecombinazioni.quantita += 1
                rigaSubcliente.totalecombinazioni.importo = parseFloat(rigaSubcliente.totalecombinazioni.importo) + importoDaAggiungere
                rigaSubcliente.totalecombinazioni.importo = parseFloat(rigaSubcliente.totalecombinazioni.importo.toFixed(2))

                // aggoprmp il totale del subcliente
                rigaSubcliente.totale = parseFloat(rigaSubcliente.totale) + importoDaAggiungere
                rigaSubcliente.totale = parseFloat(rigaSubcliente.totale.toFixed(2))

                // aggiorno il totale complessivo
                const combinazioniTotaliTrovata = res.totali.combinazioni.find( (c) => {
                    return combinazioneMatch.id === c.id
                })

                if (combinazioniTotaliTrovata) {
                    // la combinazione totali è già presente
                    combinazioniTotaliTrovata.quantita += 1
                    combinazioniTotaliTrovata.importo = parseFloat(combinazioniTotaliTrovata.importo) + importoDaAggiungere
                    combinazioniTotaliTrovata.importo = parseFloat(combinazioniTotaliTrovata.importo.toFixed(2))
                }
                else { 
                    // la combinazione non è presente. Devo aggiungerla 
                    res.totali.combinazioni.push( {
                        id: combinazioneMatch.id,
                        nome: combinazioneMatch["Descrizione"],
                        quantita: 1,
                        importo: importoDaAggiungere
                    })
                }

                // aggiorno complessivo
                res.totali.totale = parseFloat(res.totali.totale) + importoDaAggiungere
                res.totali.totale = parseFloat(res.totali.totale.toFixed(2))

            }
            else {
                // non esiste nessuna combinazione che fa match
                // recupero i costi dei gruppi

                // recupero le pietanze dell'ordinazione
                const pietanze = osc["Pietanze"]

                if ( pietanze.length > 0 ) {
                    
                    // per ogni pietanza recupero il gruppo
                    pietanze.forEach( (pietanza) => {
                        const idGruppoPietanza = pietanza["Gruppo"]

                        // recupero l'oggetto gruppo a partire dall'id presente nella pietanza
                        const gruppoPietanza = gruppi.find( (g) => {
                            return g.id === idGruppoPietanza
                        })

                        if ( gruppoPietanza ) {
                            // il gruppo della pietanza esiste

                            // controllo se il gruppo della pietanza è già presente nel risultato
                            const gruppoRisultato = rigaSubcliente.gruppi.find( (g) => {
                                return g.id === gruppoPietanza.id
                            })

                            let importoDaAggiungere = parseFloat(importiGruppi[gruppoPietanza.id])

                            // se il gruppo esiste già nel risultato devo solamente aggiornare il contatore
                            // e l'importo
                            if ( gruppoRisultato ) {
                                gruppoRisultato.quantita += 1
                                gruppoRisultato.importo = parseFloat(gruppoRisultato.importo) + importoDaAggiungere
                                gruppoRisultato.importo = parseFloat(gruppoRisultato.importo.toFixed(2))
                            }
                            else {
                                // devo aggiungere il gruppo

                                // recupero il nome del gruppo
                                const gruppoCorrente = gruppi.find( (g) => {
                                    return g.id === idGruppoPietanza
                                })

                                rigaSubcliente.gruppi.push( {
                                    id: idGruppoPietanza,
                                    nome: gruppoCorrente && gruppoCorrente["Nome"] ? gruppoCorrente["Nome"] : "",
                                    ordinamento: gruppoCorrente && gruppoCorrente["Ordinamento"] ? gruppoCorrente["Ordinamento"] : 0,
                                    quantita: 1,
                                    prezzo: parseFloat(importiGruppi[idGruppoPietanza]),
                                    importo: parseFloat(importiGruppi[idGruppoPietanza])
                                })

                            }

                            // aggiorno il totale dei gruppi
                            rigaSubcliente.totalegruppi.quantita += 1
                            rigaSubcliente.totalegruppi.importo = parseFloat(rigaSubcliente.totalegruppi.importo) + importoDaAggiungere
                            rigaSubcliente.totalegruppi.importo = parseFloat(rigaSubcliente.totalegruppi.importo.toFixed(2))

                            // aggiorno il totale del subcliente
                            rigaSubcliente.totale = parseFloat(rigaSubcliente.totale) + importoDaAggiungere
                            rigaSubcliente.totale = parseFloat(rigaSubcliente.totale.toFixed(2))

                            // aggiorno il totale complessivo
                            const gruppoTotaliTrovato = res.totali.gruppi.find( (g) => {
                                return g.id === gruppoPietanza.id
                            })

                            // gruppo trovato
                            if ( gruppoTotaliTrovato ) {
                                // il gruppo è già presente nei totali
                                // aggiorno solamente i dati
                                gruppoTotaliTrovato.quantita += 1
                                gruppoTotaliTrovato.importo = parseFloat(gruppoTotaliTrovato.importo) + importoDaAggiungere
                                gruppoTotaliTrovato.importo = parseFloat(gruppoTotaliTrovato.importo.toFixed(2))

                            } else {
                                // aggiungo il gruppo ai totali
                                res.totali.gruppi.push({
                                    id: gruppoPietanza.id,
                                    nome: gruppoPietanza && gruppoPietanza["Nome"] ? gruppoPietanza["Nome"] : "",
                                    ordinamento: gruppoPietanza && gruppoPietanza["Ordinamento"] ? gruppoPietanza["Ordinamento"] : 0,
                                    quantita: 1,
                                    importo: importoDaAggiungere
                                })
                            }

                            // aggiorno il totale complessivo finale
                            res.totali.totale = parseFloat(res.totali.totale) + importoDaAggiungere
                            res.totali.totale = parseFloat(res.totali.totale.toFixed(2))

                        }
                    })
                }

            }
        })

        res.corpo.push(rigaSubcliente)
        
    })

    return res
}