import React, { Component } from "react";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import Background from '../../assets/img/login.jpg';
import logo from '../../assets/img/logo.png';


// theme components
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";

import { fetchPersonaDaCredenziali } from "actions/authentication.js";

var sectionStyle = {
    backgroundImage: `url(${Background})`,
    backgroundSize: `cover`,
    backgroundPosition: `top center`,
    height: `auto`,
    minHeight: `100vh`
};


class Login extends Component{

    state = {
        username: "",
        password: "",
        ricordami: false
    }

    handleChange = (e) => {
        const nomeCampo = e.target.name;
        const valoreCampo = nomeCampo !== 'ricordami' ? e.target.value : e.target.checked === true

        this.setState((state) => {
            state[nomeCampo] = valoreCampo

            return state;
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();

        const { login } = this.props
        const { username, password } = this.state
        
        login(username, password)
        .then( (utente) => {
            const { history } = this.props

            if (typeof utente !== 'undefined' && utente[0])
                sessionStorage.setItem('my-token', utente[0]._id)
            history.push('/dashboard')
        })
        
    }

    render() {

        return(
            <section style={ sectionStyle }>
                <GridContainer justify={"center"} alignItems={"center"}>
                    <GridItem xs={11} sm={8} md={6} lg={3} >
                        <Card>
                            <CardHeader>
                                <img src={logo} alt="Logo" style={{maxWidth: '100%', marginTop: '20px'}} />
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={this.handleSubmit}>
                                    <CustomInput
                                        labelText="Username"
                                        id="username"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            name: "username",
                                            required: true,
                                            value: this.state.username,
                                            onChange: this.handleChange
                                        }}
                                    />
                                    <CustomInput
                                        labelText="Password"
                                        id="password"
                                        formControlProps={{ fullWidth: true }}
                                        inputProps={{
                                            name: "password",
                                            type: "password",
                                            required: true,
                                            value: this.state.password,
                                            onChange: this.handleChange
                                        }}
                                    />
                            
                                    <CheckBox
                                        name="ricordami"
                                        id="ricordami"
                                        onChange={this.handleChange}
                                        checked={this.state.ricordami}
                                    /> Ricordami
                                    <GridContainer>
                                        <GridItem>
                                        <Button type="submit" color="success" >Accedi</Button>
                                        </GridItem>
                                    </GridContainer>
                                </form>
                                    
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </section>
        )
    }
}

function mapStateToProps({ authentication }) {
    return {
        loggedIn: authentication.logged
    }
}

function mapDispatchToProps( dispatch ){
    return {
        login: (username, password) => dispatch(fetchPersonaDaCredenziali(username, password))
    }
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
  )(withRouter(Login));
