import { REACT_APP_BACKEND } from "../../../index.js";

import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addPietanza, updatePietanza, fetchPietanze, fetchPietanza } from "../../../actions/pietanze";
import { fetchAllergeni } from "../../../actions/allergeni";
import { fetchGruppi } from "../../../actions/gruppi";
import { fetchSottogruppi, azzeraSottogruppi } from "../../../actions/sottogruppi";
import { addFile, deleteFile } from "../../../actions/upload";

// core material design ui components
import FormControl from "@material-ui/core/FormControl/FormControl";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import CheckBox from "@material-ui/core/Checkbox/Checkbox";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import withStyles from "@material-ui/core/styles/withStyles";

// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

const nuovaPietanza = {
  "Nome" : "",
  "Descrizione" : "",
  "Gruppo" : "",
  "Sottogruppo" : "",
  "Allergeni" : "",
  "Immagine" : "",
  "Prezzo" : 0,
  "Stato" : "",
}

class DettaglioPietanza extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
  }

  state = {
    pietanza: nuovaPietanza,
    title : "",
    subtitle: "Dettaglio pietanza",
    messaggio: ""
  }

  componentDidMount() {
    const { match, caricaPietanza, caricaAllergeni, caricaGruppi, caricaSottogruppi, resetSottogruppi } = this.props

    caricaAllergeni();
    caricaGruppi();

    const idPietanza = match.params.id

    if ( idPietanza && idPietanza.length > 0 ) {

      Promise.resolve()
      .then( () => caricaPietanza(idPietanza))
      .then( () => {
        // carico i sottogruppi della pietanza
        const { currentPietanza } = this.props

        if (currentPietanza && currentPietanza["Gruppo"]) {
          const gruppo = currentPietanza["Gruppo"]._id ? currentPietanza["Gruppo"]._id : currentPietanza["Gruppo"];
          caricaSottogruppi(gruppo);
        }
      })
      .then( () => {
        const { currentPietanza } = this.props
        this.setState({ pietanza: currentPietanza,
                        title: "Modifica pietanza" })
      })
    }
    else {
      resetSottogruppi()
      this.setState({ title: "Aggiungi nuova pietanza"})
    }
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {
    if (!e)
      return;

    e.preventDefault()
    const { currentPietanza, addPietanza, updatePietanza, azienda } = this.props

    const values = serializeForm(e.target, { hash: true, empty: true })

    // pulisco i valori di allergeni nulli
    if (values["Allergeni"] && values["Allergeni"].length > 0) {
      values["Allergeni"] = values["Allergeni"].filter( (a) => {
        return a.length > 0;
      })
    }

    if (!values["Allergeni"] || values["Allergeni"].length === 0) {
      values["Allergeni"] = [];
    }

    if (values["Gruppo"] === "") {
      this.setState({ messaggio: "Seleziona il gruppo a cui appartiene la pietanza"})
      return
    }
    
    if (values["Sottogruppo"] === "")
      values["Sottogruppo"] = null

    values["Azienda"] = azienda._id

    const immaginePietanza = this.fileInput.current.files[0]

    if ( currentPietanza) {
      // Modifico la pietanza esistente
      Promise.resolve()
      .then( () => { return updatePietanza(currentPietanza["_id"], values) } )
      .then( (pietanza) => {
        if (pietanza) {
          if (immaginePietanza ) {
              const { aggiungiFile } = this.props
              return aggiungiFile(immaginePietanza, pietanza._id, 'pietanze', 'Immagine')
          }
          else
            return false;
        }
        else 
          return false;
      })
      .then( (res) => {
        if (res === true) {
          const { currentPietanza, cancellaFile } = this.props

          if (currentPietanza) {
            const img = currentPietanza["Immagine"]

            if (img && img._id) {
              cancellaFile(img._id)
            }
          }
        }
      })
      .then( () => this.setState({ messaggio: "Pietanza modificata correttamente"} ))
      .catch( () => this.setState({ messaggio: "Impossibile modificare la pietanza"}))
    }
    else {
      Promise.resolve()
      .then( () => {
        return addPietanza(values)
      })
      .then( (pietanza) => {
        const { history } = this.props

        if (pietanza){
          if (immaginePietanza ) {
            Promise.resolve()
            .then( () => {
              const { aggiungiFile } = this.props
              return aggiungiFile(immaginePietanza, pietanza._id, 'pietanze', 'Immagine')
            })
          }

          history.push(`/dashboard/pietanze/${pietanza._id}/edit`)
        }
        else
          history.push('/dashboard/pietanze')
      })
    }
  }

  // Gestisco l'aggiornamento dei campi della form
  handleChange = (e) => {
    let nomeProprieta = e.target.name;
    let valoreProprieta = e.target.value;
    let updatedPietanza = {};

    if (nomeProprieta === 'Allergeni') {
      if (e.target.checked === true) {
        const { pietanza } = this.state
        // aggiungo l'allergene alla lista
        const allergene = this.recuperaAllergeneDaLista(valoreProprieta)
        updatedPietanza[nomeProprieta] = [ ...pietanza["Allergeni"], ...allergene ]
      }
      else {
        // tolgo l'allergene dalla lista
        const { pietanza } = this.state

        const allergeniPietanza = pietanza["Allergeni"]

        if (allergeniPietanza && allergeniPietanza.length > 0 ) {
          const allergeniRimanenti = allergeniPietanza.filter( (a) => {
            return a._id !== valoreProprieta;
          })

          updatedPietanza[nomeProprieta] = allergeniRimanenti
        }
        
      }
    }
    else {
      updatedPietanza[nomeProprieta] = valoreProprieta;

      if (nomeProprieta === 'Gruppo') {

        const { gruppi } = this.props

        const gruppoAssegnato = gruppi.find( (g) => {
          return g._id === valoreProprieta
        })

        if (gruppoAssegnato && gruppoAssegnato['Prezzo'] > 0 ) {
          updatedPietanza['Prezzo'] = gruppoAssegnato['Prezzo']
        }
      }
    }

    // se ho aggiornato la proprietà Gruppo ed il suo valore è valido
    // ricarico i sottogruppi
    if (nomeProprieta === "Gruppo") {
      const { caricaSottogruppi, resetSottogruppi } = this.props

      if (valoreProprieta && valoreProprieta.length > 0 ) {
        caricaSottogruppi(valoreProprieta);
      }
      else {
        resetSottogruppi();
      }
    }

    this.setState({ pietanza: { ...this.state.pietanza, ...updatedPietanza } })
  }

  // recupero l'allergene passato con id dalla lista allergeni
  recuperaAllergeneDaLista = (allergene) => {
    const { allergeni } = this.props

    if (allergeni) {
      return allergeni.filter( (a) => {
        return a._id === allergene;
      })
    }
  }

  // pulsante torna indietro
  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // controllo se l'allergene fa parte degli allergeni della pietanza
  allergenePresente = (allergene) => {
    const { pietanza } = this.state
    const allergeniPietanza = pietanza["Allergeni"]

    if ( allergeniPietanza.length <= 0 )
      return false;

    let res = false;
    const idAllergeni = allergeniPietanza.map( (a) => { return a._id});

    if ( idAllergeni.includes(allergene) === true) {
      res = true;
    }

    return res;
  }

  // gestisco la chiusura dello snack
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '' });
  };
    
  // gestisco l'uscita dallo snack
  handleExitedScnak = () => {
    this.setState({ messaggio: ''})
  };

  render() {
    const { pietanza, title, subtitle } = this.state
    const { allergeni, gruppi, sottogruppi, classes } = this.props

    return (
      <div>
        <ScaffoldBase title={title} subtitle={subtitle}>
        <form onSubmit={this.handleSubmit} encType="multipart/form-data">
            { pietanza && pietanza["Immagine"] && (
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <img src={`${REACT_APP_BACKEND}${pietanza["Immagine"].url}` } />
                </GridItem>
                </GridContainer>
            )}
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Nome"
                id="nome"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: "Nome",
                  required: true,
                  value: pietanza["Nome"],
                  onChange: this.handleChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Descrizione"
                id="descrizione"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: "Descrizione",
                  value: pietanza["Descrizione"],
                  onChange: this.handleChange
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12} style={{ margin: "20px 0px" }}>
              <input type="file" name="Immagine" ref={ this.fileInput } />
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <FormControl>
                <InputLabel htmlFor="Gruppo">Gruppo</InputLabel>
                  <Select
                    value={pietanza["Gruppo"] 
                    ? pietanza["Gruppo"]._id && pietanza["Gruppo"]._id.length > 0 ? pietanza["Gruppo"]._id : pietanza["Gruppo"]
                    : ""}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'Gruppo',
                      id: 'Gruppo'
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    { gruppi && gruppi.length > 0 && gruppi.map( (g) => {
                      return (
                        <MenuItem key={g._id} value={g._id}>{g["Nome"]}</MenuItem>
                      )
                    })
                    }
                  </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
              <FormControl>
                <InputLabel htmlFor="Sottogruppo">Sottogruppo</InputLabel>
                  <Select
                    value={pietanza["Sottogruppo"] 
                    ? pietanza["Sottogruppo"]._id && pietanza["Sottogruppo"]._id.length > 0 ? pietanza["Sottogruppo"]._id : pietanza["Sottogruppo"]
                    : ""}
                    onChange={this.handleChange}
                    inputProps={{
                      name: 'Sottogruppo',
                      id: 'Sottogruppo',
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    { sottogruppi && sottogruppi.length > 0 && sottogruppi.map( (sg) => {
                      return (
                        <MenuItem key={sg._id} value={sg._id}>{sg["Nome"]}</MenuItem>
                      )
                    })
                    }
                  </Select>
                </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={6} lg={6}>
                <CustomInput
                      labelText="Prezzo"
                      id="prezzo"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "number",
                        name: "Prezzo",
                        value: pietanza["Prezzo"],
                        step: 2,
                        onChange: this.handleChange
                      }}
                    />
            </GridItem>
            { pietanza && pietanza._id && allergeni && allergeni.length > 0 && (
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                      <h3>Allergeni</h3>
                    </GridItem>
                    { allergeni.map( (a) => {
                      return (
                        <GridItem xs={12} sm={12} md={6} lg={6} key={a._id}>
                          <CheckBox
                            name="Allergeni"
                            id={`${a._id}`}
                            onChange={this.handleChange}
                            value={a._id}
                            checked={this.allergenePresente(a._id)}
                          /> {a["Descrizione"]}
                        </GridItem>
                      )
                    })}
                  </GridContainer>
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}>
              <Button type="submit" color="success">Salva</Button>
              <Button type="button" color="transparent" onClick={ () => this.tornaIndietro() }>Indietro</Button>
            </GridItem>
          </GridContainer>
        </form>

        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
            autoHideDuration={3000}
            onClose={this.handleCloseSnack}
            onExited={this.handleExitedSnack}
            ContentProps={{
                'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{this.state.messaggio}</span>}
            action={[
                <IconButton
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    className={classes.close}
                    onClick={this.handleCloseSnack}
                >
                    <CloseIcon />
                </IconButton>
            ]}
        />
        </ScaffoldBase>
      </div>
    );
  }

  validate = (pietanza) => {
    // Controllo che i dati inseriti siano corretti prima di inviare la richiesta di salvataggio
    let result = {
      esito: true,
      messaggio: ""
    }

    if (pietanza === null ) {
      result.esito = false;
      result.messaggio = "La pietanza deve essere valorizzata";
      return result;
    }

    if (pietanza['Nome'] == null || pietanza['Nome'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire il nome della pietanza. CAMPO OBBLIGATORIO";
      return result;
    }

    return result;
  }

  salvaPietanza = pietanza => {
    let controlloValidazione = this.validate(pietanza);

    if (controlloValidazione.esito === true) {
      console.log('salva sul db')
    }
    else {
     console.log(controlloValidazione.messaggio)
    }
  }
}

function mapStateToProps({ pietanze, allergeni, gruppi, sottogruppi, authentication }) {
  return {
    currentPietanza: pietanze.currentPietanza,
    allergeni: allergeni.items,
    gruppi: gruppi.items,
    sottogruppi: sottogruppi.items,
    azienda: authentication.user['Azienda']
  }
}

function mapDispatchToProps( dispatch ){
  return {
    addPietanza: (pietanza) => dispatch(addPietanza(pietanza)),
    updatePietanza: (id, pietanza) => dispatch(updatePietanza(id, pietanza)),
    ricaricaPietanze: () => dispatch(fetchPietanze()),
    caricaPietanza: (id) => dispatch(fetchPietanza(id)),
    caricaAllergeni: () => dispatch(fetchAllergeni()),
    caricaGruppi: () => dispatch(fetchGruppi()),
    caricaSottogruppi: (nomeGruppo) => dispatch(fetchSottogruppi(nomeGruppo)),
    resetSottogruppi: () => dispatch(azzeraSottogruppi()),
    aggiungiFile: (file, refId, ref, field) => dispatch(addFile(file, refId, ref, field)),
    cancellaFile: (id) => dispatch(deleteFile(id))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioPietanza));
