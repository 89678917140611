import React, { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { withRouter } from "react-router";

// React-Redux reducers
import { connect } from "react-redux";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import { getOrdinazioni } from 'actions/ordinazioni'
import { getBigData, filterParser } from "utils/api";

class ListaOrdinazioni extends Component {

    componentDidMount() {
        const { setOrdinazioni, utenteLoggato } = this.props

        Promise.resolve()
        .then( () => {
            return this.caricaOrdinazioniDipendente(utenteLoggato.id)
        })
        .then( (ordinazioni) => {
            // imposto le ordinazioni
            setOrdinazioni(ordinazioni)
        })
    }

    // funzione che carica le ordinazioni del dipendente
    caricaOrdinazioniDipendente = ( idDipendente ) => {
        if ( !idDipendente || idDipendente.length <= 0)
            return null

        let filtroOrdinazioni = [{ 'campo': 'Persone._id', 'operatore': '=', 'valore': idDipendente} ]

        let queryString = filterParser(filtroOrdinazioni)

        if (queryString.trim().length > 0)
            queryString = `?${queryString}`

        return getBigData('ordinazionis', queryString)
    }

    

    render() {

        const { ordinazioni, classes } = this.props

        return(
            <ScaffoldBase title="Le mie ordinazioni">
                <GridContainer>
                    { ordinazioni && ordinazioni.items && ordinazioni.items.length > 0 && (
                        <GridItem xs={12} sm={12} md={12}>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Giorno</TableCell>
                                        <TableCell>Pranzo/Cena</TableCell>
                                        <TableCell>Pietanze</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { ordinazioni.items
                                      .sort( (a,b) => {
                                          if ( a["Data"] > b["Data"])
                                            return -1
                                          if ( a["Data"] < b["Data"])
                                            return 1
                                          return 0
                                      })
                                      .map( (o) => {
                                        let giorno = new Date(o["Data"])
                                        return (
                                            <TableRow key={o._id}>
                                                <TableCell>{giorno.toLocaleDateString( 'it-IT', { month: '2-digit', day: '2-digit', year: 'numeric'})}</TableCell>
                                                <TableCell>{o["Pasto"]}</TableCell>
                                                <TableCell>
                                                    { o["Pietanze"] && o["Pietanze"].length > 0 && o["Pietanze"].map( (p) => {
                                                        return (
                                                            <p key={p._id}>{p["Nome"]}</p>
                                                        )
                                                    })}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </GridItem>
                    )}
                    {
                        (!ordinazioni || !ordinazioni.items || ordinazioni.items.length === 0) && (
                            <Typography>
                                Non risultano ordinazioni presenti
                            </Typography>
                        )
                    }
                </GridContainer>
            </ScaffoldBase>
        )
    }
}

ListaOrdinazioni.propTypes = {
    classes: PropTypes.object.isRequired
  };

function mapDispatchToProps( dispatch ) {
    return {
        setOrdinazioni: (ordinazioni) => dispatch(getOrdinazioni(ordinazioni))
    }
}

function mapStateToProps({ authentication, ordinazioni}) {
    return { 
        utenteLoggato: authentication.user,
        ordinazioni 
    };
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaOrdinazioni));