import React, { Component } from "react";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { fetchMenu, aggiungiPietanza, togliPietanza } from "actions/menu";
import { fetchPietanze } from "actions/pietanze";
import { fetchGruppi } from "actions/gruppi";

// core material design ui components
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Clear from "@material-ui/icons/Clear";
import withStyles from "@material-ui/core/styles/withStyles";


// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";



// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import GruppiPietanze from "views/GruppiPietanze/GruppiPietanze";

const myListStyle = {
    border: "1px solid brown",
    maxHeight: "100%",
    minHeight: "100px"
}

class DettaglioMenu extends Component {
    state = {
        title: "Menu giornaliero",
        cercaPietanzeNome: ""
    }

  componentDidMount() {
    const { match, caricaMenu, caricaGruppi } = this.props

    caricaGruppi()

    const idMenu = match.params.id

    if ( idMenu && idMenu.length > 0 ) {
      caricaMenu(idMenu)
    }
  }


  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // gestisco l'inizio del drag
  dragStart = (event) => {
      event.dataTransfer.setData("id", event.target.id)
  }

  dropStop = (event) => {
      let idPietanza = event.dataTransfer.getData("id")

      if (idPietanza && idPietanza.length > 0 ) {
        const { aggiungiPietanza, currentMenu } = this.props
        currentMenu["Templatemenu"] = null
        aggiungiPietanza(idPietanza, currentMenu)
      }
  }

  dragOver = (event) => {
      event.preventDefault()
  }

  togliPietanzaDaMenu = (idPietanza) => {
      if (idPietanza.length > 0 ) {
          const { togliPietanza, currentMenu } = this.props

          currentMenu["Templatemenu"] = null

          togliPietanza(idPietanza, currentMenu)
      }
  }

  // gestisco il filtro sulle pietanze
  handleFilterByName = (e) => {
    const { caricaPietanze, azienda } = this.props
    this.setState({ cercaPietanzeNome: e.target.value})

    const filtro = [
      { 'campo': 'Nome', 'operatore': '_contains', 'valore': e.target.value},
      { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }
    ]

    caricaPietanze(filtro)
  }

  pulsanteElimina = ( params={} ) => {
      return (
          <IconButton onClick={ () => this.togliPietanzaDaMenu(params['id_pietanza']) }>
            <Clear />
          </IconButton>
      )
  }

  render() {

    const { currentMenu, pietanze, gruppi } = this.props
    const { title, cercaPietanzeNome } = this.state

    return (
      <div>
        <ScaffoldBase title={title} subtitle="Trascina le pietanze per definire il menu del giorno">
            { currentMenu && (
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12} lg={12}>
                        <h3>{new Date(currentMenu["Data"]).toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric' })} ({currentMenu["Pasto"]})</h3>
                    </GridItem>
                    <GridItem xs={4} sm={5} md={6} lg={6}>
                        <h4>Pietanze</h4>
                        <CustomInput
                            labelText="Nome"
                            id="nome"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                name: "Nome",
                                value: cercaPietanzeNome,
                                placeholder: "Cerca pietanza per nome",
                                onChange: this.handleFilterByName
                            }}
                        />
                        { pietanze && pietanze.items.length > 0 && (
                            <List>
                                { pietanze.items.map( (p) => {
                                    return (
                                        <ListItem id={p._id} key={p._id} onDragStart={this.dragStart} onDragOver={ this.dragOver } draggable={true}>
                                            <ListItemText primary={p["Nome"]} />
                                        </ListItem>
                                    )
                                })}
                            </List>
                        )}
                    </GridItem>
                    <GridItem xs={8} sm={7} md={6} lg={6}>
                        <h4>Piatti del giorno</h4>
                        <div style={myListStyle} onDragOver={ this.dragOver } onDrop={this.dropStop }>
                            { 
                                currentMenu["Pietanze"] && (
                                    <GruppiPietanze gruppi={gruppi} pietanze={currentMenu["Pietanze"]} actionIcon={this.pulsanteElimina} />
                            )}
                        </div>
                        { false && currentMenu["Pietanze"] && (
                            <List  >
                                { currentMenu["Pietanze"].map( (p) => {
                                    return (
                                        <ListItem key={p._id}>
                                            <ListItemText primary={p["Nome"]} />
                                            <ListItemIcon>
                                                    <Clear onClick={ () => this.togliPietanzaDaMenu(p._id)} />
                                            </ListItemIcon>
                                        </ListItem>
                                    )
                                }) }
                            </List>
                        )}
                        
                    </GridItem>
                </GridContainer>
            )}
            
        </ScaffoldBase>
      </div>
    );
  }

}

function mapStateToProps({ menu, pietanze, gruppi, authentication }) {
  return {
    currentMenu: menu.currentMenu,
    pietanze,
    gruppi: gruppi.items,
    azienda: authentication.user["Azienda"]
  }
}

function mapDispatchToProps( dispatch ){
  return {
    caricaMenu: (id) => dispatch(fetchMenu(id)),
    caricaPietanze: (filtro) => dispatch(fetchPietanze(filtro)),
    aggiungiPietanza: (idPietanza, menu) => dispatch(aggiungiPietanza(idPietanza, menu)),
    togliPietanza: (idPietanza, menu) => dispatch(togliPietanza(idPietanza, menu)),
    caricaGruppi: () => dispatch(fetchGruppi())
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioMenu));
