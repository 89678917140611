import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";
import CustomInput from "components/CustomInput/CustomInput";

// React-Redux reducers
import { connect } from "react-redux";

import {
  fetchDipendenti,
  fetchPersona,
  deletePersona
} from "actions/persone";

import { compose } from "redux";
import { withRouter } from "react-router";

class ListaDipendenti extends React.Component {

  state = {
    filtro: {
      Nome: { 'campo': 'Nome', 'valore': ''},
      Cognome: { 'campo': 'Cognome', 'valore': ''},
    }
  }

  componentDidMount() {
    const { loadDipendenti, utenteLoggato} = this.props
      
    if (utenteLoggato["Azienda"])
      loadDipendenti([ { 'campo': 'Azienda', 'valore': utenteLoggato['Azienda']._id } ]);
  }

  // funzione utilizzata per spostarsi nella pagina di inserimento nuovo dipendente
  aggiungiDipendente() {
    const { history } = this.props

    history.push('/dashboard/dipendenti/add')
  }

  // cancella il dipendente
  cancellaDipendente(id) {
    const { delDipendente } = this.props
    Promise.resolve()
    .then( () => delDipendente(id) )
    .then(() => {
      const { loadDipendenti, utenteLoggato } = this.props

      if (utenteLoggato["Azienda"])
        loadDipendenti([ {'campo': 'Azienda', 'valore': utenteLoggato['Azienda']._id } ])
    })
  }

  apriModificaDipendente(id) {
    const { history } = this.props

    history.push('/dashboard/dipendenti/'+id+'/edit')
  }

  // applico il filtro
  handleFilterBy = (e) => {
    const proprieta = e.target.name
    const valore = e.target.value

    const { filtro } = this.state
    const { loadDipendenti, utenteLoggato } = this.props

    filtro[proprieta] = { 'campo': proprieta, 'valore': valore }

    this.setState({ filtro })

    let filtroLoad = [
      { 'campo': 'Azienda', 'valore': utenteLoggato['Azienda']._id}
    ]

    Object.keys(filtro).forEach( (k) => {
      filtroLoad.push(filtro[k])
    })

    loadDipendenti(filtroLoad)

  }

  render() {
    const { classes, dipendenti } = this.props;
    const { filtro } = this.state

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button onClick={() => this.aggiungiDipendente()} style={{background: /*"linear-gradient(60deg, #7d6960, #3e2a23)"*/"linear-gradient(60deg, #022f65, #004494)", color: "#ffffff"}} variant="contained"><AddIcon />
              Aggiungi nuovo dipendente
            </Button>
          </GridItem>
        </GridContainer>
        <ScaffoldBase title="Lista dipendenti" subtitle="Dettaglio dipendenti">
          <GridContainer>
            <GridItem xs={6} sm={6} md={6} lg={6} >
                <CustomInput
                  labelText="Nome"
                  id="nome"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Nome",
                    value: filtro.Nome.valore,
                    placeholder: "Cerca dipendente per nome",
                    onChange: this.handleFilterBy
                  }}
                />
            </GridItem>
            <GridItem xs={6} sm={6} md={6} lg={6} >
                <CustomInput
                  labelText="Cognome"
                  id="cognome"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Cognome",
                    value: filtro.Cognome.valore,
                    placeholder: "Cerca dipendente per cognome",
                    onChange: this.handleFilterBy
                  }}
                />
            </GridItem>
          </GridContainer>
          { dipendenti.items && dipendenti.items.length > 0 &&
          <GridItem xs={12} sm={12} md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Azione</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Cognome</TableCell>
                  <TableCell>Codice Fiscale</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                    dipendenti.items && dipendenti.items.map( (dipendente) => 
                      <TableRow key={dipendente._id}>
                        <TableCell>
                            <Tooltip
                              id="tooltip-top"
                              title="Modifica"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes.tableActionButton}
                              >
                                <Edit
                                  className={
                                    classes.tableActionButtonIcon + " " + classes.edit
                                  }
                                  onClick={() => this.apriModificaDipendente(dipendente._id)}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top-start"
                              title="Elimina"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Close"
                                className={classes.tableActionButton}
                              >
                                <Close
                                  className={
                                    classes.tableActionButtonIcon + " " + classes.close
                                  }
                                  onClick={() => this.cancellaDipendente(dipendente._id)}
                                />
                              </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{dipendente["Nome"]}</TableCell>
                        <TableCell>{dipendente["Cognome"]}</TableCell>
                        <TableCell>{dipendente["Codice fiscale"]}</TableCell>
                      </TableRow>
                    ) 
                  }
              </TableBody>
            </Table>
          </GridItem>
          }

          { (!dipendenti.items || dipendenti.items.length <= 0) && 
            <GridItem>
              <p>Dipendenti non disponibili</p>
            </GridItem> 
          }
        </ScaffoldBase>
      </div>
    );
  }
}

ListaDipendenti.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
    loadDipendenti: (filtro) => dispatch(fetchDipendenti(filtro)),
    setDipendente: (id) => dispatch(fetchPersona(id)),
    delDipendente: (id) => dispatch(deletePersona(id))
  }
}

function mapStateToProps({ persone, authentication }) {
  return { 
    dipendenti: persone,
    utenteLoggato: authentication.user
  };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaDipendenti));
