const GIORNI_SETTIMANA = ["Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato", "Domenica"]


// Crea i giorni dei template iniziali
export function creaGiorniTemplate() {
    let res = []

    const giorniVisualizzati = 5

    for (let i = 0; i < giorniVisualizzati; i++) {
        res.push({'idx': i, 'nome': GIORNI_SETTIMANA[i], 'pranzo': [], 'cena': [] })
    }

    return res
}