import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addReclamo, updateReclamo, fetchReclamo, fetchReclami } from "actions/reclami";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

// utils
//import { checkEmailPresente } from "utils/persone"

const nuovoReclamo = {
    "Giorno" : "",
    "Oggetto" : "",
    "Descrizione" : "",
    "Proponente" : "",
    "Ricevente" : "",
    "Evasione" : ""
  }
  
  class DettaglioReclamo extends Component {
  
    state = {
      reclamo: nuovoReclamo,
      title : "",
      subtitle: "Dettaglio reclamo",
      messaggio: ""
    }

  componentDidMount() {
    const { match, caricaReclamo } = this.props

    const idReclamo = match.params.id

    if ( idReclamo && idReclamo.length > 0 ) {

      Promise.resolve()
      .then( () => caricaReclamo(idReclamo))
      .then( () => {
        const { currentReclamo } = this.props
        this.setState({ reclamo:  currentReclamo,
                        title: "Dettaglio reclamo" })
      })
    }
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {

    if (!e)
      return;

    e.preventDefault()

    const { currentReclamo, updateReclamo, history, caricaReclami, azienda } = this.props

    const values = serializeForm(e.target, { hash: true, empty: true })

    values["Evasione"] = "si"

    if ( currentReclamo) {
        // Modifico il reclamo esistente
        Promise.resolve()
        .then( () => { updateReclamo(currentReclamo["_id"], values) })
        .then( () => {
          const filtroReclami = [
            { campo: 'Ricevente', operatore : '=', valore: azienda._id },
            { campo: 'Evasione', operatore : '=', valore: 'no' }]

          caricaReclami(filtroReclami)
        })
        .then( () => { history.push('/dashboard/reclami') })
    }
  }

  // pulsante torna indietro
  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // gestisco la chiusura dello snack
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '' });
  };

  // gestisco l'uscita dallo snack
  handleExitedSnack = () => {
    this.setState({ messaggio: ''})
  };
  

  render() {
    const { reclamo, title, subtitle } = this.state
    const { classes } = this.props

    return (
      <div>
        <ScaffoldBase title={title} subtitle={subtitle}>
          <form onSubmit={this.handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Proponente"
                  id="proponente"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Proponente",
                    value: reclamo && reclamo["Proponente"] && reclamo["Proponente"]["Ragione sociale"].length > 0 ? reclamo["Proponente"]["Ragione sociale"] : "",
                    readOnly: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Oggetto"
                  id="oggetto"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Oggetto",
                    value: reclamo["Oggetto"],
                    readOnly: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                      fullWidth={true}
                      id="descrizione"
                      label="Descrizione"
                      name="Descrizione"
                      multiline
                      rows="4"
                      value={reclamo["Descrizione"]}
                      margin="normal" 
                      readOnly={true}
                      />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" color="success">Evadi</Button>
                <Button color="transparent" onClick={ () => this.tornaIndietro() }>Indietro</Button>
              </GridItem>
            </GridContainer>
          </form>   
          <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                    autoHideDuration={3000}
                    onClose={this.handleCloseSnack}
                    onExited={this.handleExitedSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messaggio}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnack}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
        </ScaffoldBase>
      </div>
    );
  }
}

function mapStateToProps({ reclami, authentication }) {
  return {
    currentReclamo: reclami.currentReclamo,
    utenteLoggato: authentication.user,
    azienda: authentication.user["Azienda"]
  }
}

function mapDispatchToProps( dispatch ){
  return {
    addReclamo: (reclamo) => dispatch(addReclamo(reclamo)),
    updateReclamo: (id, reclamo) => dispatch(updateReclamo(id, reclamo)),
    caricaReclamo: (id) => dispatch(fetchReclamo(id)),
    caricaReclami: (filtro) => dispatch( fetchReclami(filtro))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioReclamo));
