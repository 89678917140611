import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from "utils/api"

export const GET_GRUPPI = "GET_GRUPPI";
export const GET_GRUPPO = "GET_GRUPPO";

export const getGruppi = gruppi => ({
  type: GET_GRUPPI,
  items: gruppi
});

export const getGruppo = gruppo => ({
  type: GET_GRUPPO,
  gruppo
})

// recupera i gruppi
export const fetchGruppi = (filtro=[]) => dispatch => {

  let queryString = filterParser(filtro)

  if (queryString.length > 0 )
    queryString = '?'+queryString

    return fetch(`${REACT_APP_BACKEND}gruppis${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
	  .then( (data) => { 
      let gruppi = null;

      try {
        gruppi = JSON.parse(data);
        gruppi = gruppi !== null ? gruppi : null;
      } catch(e) {
        gruppi = null;
      } finally {
        dispatch(getGruppi(gruppi));
        return gruppi
      }
    })
  }

export const fetchGruppo = (id) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}gruppis/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let gruppo = null;

    try {
      gruppo = JSON.parse(data);
      gruppo = gruppo !== null ? gruppo : null;
    } catch(e) {
      gruppo = null;
    } finally {
      dispatch(getGruppo(gruppo));
      return gruppo
    }
  })
}

export const updateGruppo = ( id, gruppo) => dispatch => (
  fetch(`${REACT_APP_BACKEND}gruppis/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(gruppo)
  })
)