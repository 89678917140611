import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Restaurant from "@material-ui/icons/Restaurant";
// @material-ui/icons

// core components

import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import { Typography } from "@material-ui/core";

class Dashboard extends React.Component {
  state = {
    value: 0
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };

  handleChangeIndex = index => {
    this.setState({ value: index });
  };
  render() {
    return (
      <div style={{ textAlign: "center", height: "100%", width: "100%", alignContent: "center"}}>
          <Restaurant style={{ fontSize: "72px"}} />
          <Typography>Dasporto.net - L'applicazione per gestire i tuoi pasti aziendali</Typography>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
