export function dateFromUTCtoString( data , separator='/') {
    let res = "";

    try {
        res = new Date(data);

        //res = res.toLocaleDateString('it-IT');

        let dd = res.getDate()

        if (dd < 10)
            dd = '0'+dd
            
        const mm = res.getMonth() + 1
        const yyyy = res.getFullYear()

        res = `${dd}${separator}${mm}${separator}${yyyy}`
    }
    catch ( err ) {
        res = "";
    }

    return res;
}

export function formatDateToYYYYMMDD(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
}

export function createNewDate(date=null) {
    let newDate;

    if (date === null)
        newDate = new Date();
    else 
        newDate = new Date(date);

    newDate.setMinutes(newDate.getTimezoneOffset())

    return newDate;
}

// recupera il primo giorno del mese a partire dal giorno di oggi
export function getFirstMonthDay() {
    const today = new Date()

    const month = today.getMonth()
    const year = today.getFullYear()

    const firstDay = new Date(year, month, 1)

    return firstDay
}

// genera la data da utilizzare per effettuare i filtri su strapi.
// quando le date di strapi saranno corrette si potrà cancellare
export function convertDateForStrapi(date) {

    let newDate = new Date(date)

    newDate.setMilliseconds(0)
    newDate.setSeconds(0)
    newDate.setMinutes(0)
    newDate.setHours(0)

    newDate.setMinutes(newDate.getTimezoneOffset())

    return newDate
}

export function convertDateFromStrapi(date) {

    let resultDate = new Date(date)

    resultDate.setMinutes(-date.getTimezoneOffset())

    return resultDate
}

// calcola il giorno d'inizio della settimana a partire dal giorno passato
export function getWeekStartDay(giorno) {
    
    const giornoSettimana = giorno.getDay()
    
    let giorniDaTogliere = 0

    // gestisco il caso della domenica.
    // imposto a -1 in modo che sottraendo aggiunge 1
    if (giornoSettimana === 0)
        giorniDaTogliere = -1
    else if (giornoSettimana === 6)
        giorniDaTogliere = -2
    else if ( giornoSettimana > 1)
        giorniDaTogliere = giornoSettimana -1

    let result = giorno

    result.setDate(giorno.getDate() - giorniDaTogliere)

    let res = formatDateToYYYYMMDD(result)

    result = new Date(res)

    return result

}

export function addDays(giorno, giorniDaAggiungere) {
    let res = new Date(giorno)

    let giornoTime = res.getTime()
    let giorniDaAggiungereTime = giorniDaAggiungere * 1000 * 60 * 60 * 24

    res.setTime( giornoTime + giorniDaAggiungereTime)

    return res
}