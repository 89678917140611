import React, { Component } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// React-Redux
import { connect } from "react-redux";
import { withRouter } from "react-router";

// actions
import { setUser, fetchPersonaAutenticata } from "actions/authentication.js";

import Login from "layouts/Login/Login.jsx";
import Dashboard from "layouts/Dashboard/Dashboard.jsx";
import RecuperoPassword from "layouts/RecuperoPassword/RecuperoPassword";

// definisco una route privata
const PrivateRoute = ({ component: Component, ...rest }) => {
    return <Route {...rest} render={(props) => (
      rest.loggedIn === true
        ? <Component {...props} />
        : <Redirect to='/login' />
    )} />
}

class App extends Component {

    componentDidMount() {
        // recupero l'eventuale token
        const token = sessionStorage.getItem('my-token')

        if ( token && token.trim().length > 0 ) {
            const { caricaPersona } = this.props
            caricaPersona(token)
        }
    }

    render() {
        const { loggedIn } = this.props

        let home = loggedIn  ? "/dashboard" : "/login";

        return (
                <Switch>
                    { !loggedIn && (
                            <Route exact path="/login" component={ Login } />
                    )}
                    { !loggedIn && (
                        <Route exact path="/recupero-password" component={ RecuperoPassword} />
                    )}
                    <PrivateRoute path="/dashboard" component={ Dashboard} loggedIn={loggedIn} />
                    <Redirect from="/" to={home} />
                </Switch>
        )
    }
}

function mapStateToProps({ authentication }) {
    return {
        loggedIn: authentication.logged
    }
}

function mapDispatchToProps( dispatch ) {
    return {
      setUser: (logged, userProfile) => dispatch(setUser(logged, userProfile)),
      caricaPersona: (id) => dispatch(fetchPersonaAutenticata(id))
    }
  }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))