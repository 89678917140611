import { getBigData, filterParser } from "utils/api"

// funzione che cerca se l'email passata è già presente
export async function checkEmailPresente(email, id='') {

    let result = false

    let filtro = [{ 'campo': 'Email', 'operatore': '=', 'valore': email}]

    if (id.trim().length > 0 ) {
        filtro.push({ 'campo': 'id', 'operatore' : '_ne', 'valore': id })
    }

    let queryString = filterParser(filtro)

    if ( queryString.trim().length > 0) 
        queryString = `?${queryString}`

    const arrayPersone = await getBigData('persones', queryString).then( (res) => { return res })

    if (arrayPersone.length === 0)
        result = true

    return result
}