import React, { Component } from "react";
import PropTypes from "prop-types";
import serializeForm from "form-serialize";
import { sha256 } from 'js-sha256';

import { compose } from "redux";
import { withRouter } from "react-router";

// React-Redux reducers
import { connect } from "react-redux";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { updatePersona } from "actions/persone"

const initialDipendente = {
    "Nome": "",
    "Cognome": "",
    "Codice Fiscale": "",
    "Matricola": "",
    "Username": "",
    "Nuova Password": "",
    "Conferma Password": "",
    "Email": ""
}

class Profilo extends Component {

    state = {
        dipendente: initialDipendente,
        messaggioErrore: ""
    }

    componentDidMount() {

        const { currentPersona } = this.props
        const { dipendente } = this.state

        const persona = { ...dipendente, ...currentPersona }

        this.setState({ dipendente: persona })
    }

    handleChange = ( e ) => {
        let nomeProprieta = e.target.name;
        let valoreProprieta = e.target.value;
        let updateDipendente = {};


        updateDipendente[nomeProprieta] = valoreProprieta;
        this.setState({ dipendente: { ...this.state.dipendente, ...updateDipendente } })
    }

    handleSubmit = ( e ) => {
        if (!e)
        return;

        e.preventDefault()
        const { updatePersona, currentPersona } = this.props

        const values = serializeForm(e.target, { hash: true })

        let messaggio = ""

        // controllo sulla nuova password inserita
        if ( values["Nuova Password"] && values["Nuova Password"].length > 0 ) {
            if (!values["Conferma Password"] || values["Conferma Password"].trim().length <= 0) {
                messaggio = "Inserire la password di conferma"
            }
            else if (values["Nuova Password"] !== values["Conferma Password"]) {
                messaggio = "La password di conferma non coincide con la nuova password inserita"
            }
            else {
                // la password è corretta.
                // tolgo da values i valori di nuova password e password di conferma
                // e aggiorno per inviare la modifica
                values["Password"] = sha256(values["Conferma Password"])
                values["Nuova Password"] = null
                values["Conferma Password"] = null
            }
        }

        if ( messaggio.trim().length > 0 ) {
            this.setState({ messaggioErrore: messaggio })
        }
        else {
            if (currentPersona) {
                updatePersona(currentPersona._id, values)
                .then( () => this.setState({ messaggioErrore: "" }))
            }
        }
    }

    render() {

        const { dipendente, messaggioErrore } = this.state
        const isAdmin = dipendente && (dipendente['Profilo'] === 'amministratore' || dipendente['Profilo'] === 'cliente') ? true : false

        return(
            <ScaffoldBase title="Il mio profilo">
                { messaggioErrore && messaggioErrore.trim().length > 0 && (
                    <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={true}
                    ContentProps={{
                      'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{messaggioErrore}</span>}
                  />
                )}
                <form onSubmit={this.handleSubmit}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                            labelText="Nome"
                            id="nome"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                name: "Nome",
                                required: true,
                                value: dipendente["Nome"],
                                readOnly: !isAdmin,
                                onChange: this.handleChange
                            }}
                            labelProps={{
                                shrink: true
                              }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                            labelText="Cognome"
                            id="cognome"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                name: "Cognome",
                                value: dipendente["Cognome"],
                                required: true,
                                readOnly: !isAdmin,
                                onChange: this.handleChange
                            }}
                            labelProps={{
                                shrink: true
                              }}
                            />
                        </GridItem>
                        {
                            !isAdmin && (
                                <GridItem xs={12} sm={12} md={6}>
                                    <CustomInput
                                    labelText="Codice Fiscale"
                                    id="codiceFiscale"
                                    formControlProps={{ fullWidth: true }}
                                    inputProps={{
                                        name: "Codice Fiscale",
                                        value: dipendente["Codice Fiscale"],
                                        readOnly: true
                                    }}
                                    labelProps={{
                                        shrink: true
                                    }}
                                    />
                                </GridItem>
                            )
                        }

                        { !isAdmin && (
                            <GridItem xs={12} sm={12} md={6}>
                                <CustomInput
                                labelText="Matricola"
                                id="matricola"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                    name: "Matricola",
                                    value: dipendente["Matricola"],
                                    readOnly: true
                                }}
                                labelProps={{
                                    shrink: true
                                }}
                                />
                            </GridItem>
                        )}
                        
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                            labelText="Email"
                            id="email"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                name: "Email",
                                value: dipendente["Email"],
                                onChange: this.handleChange
                            }}
                            labelProps={{
                                shrink: true
                              }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}></GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                            labelText="Nuova password"
                            id="nuovaPassword"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                type: "password",
                                name: "Nuova Password",
                                value: dipendente["Nuova Password"],
                                onChange: this.handleChange
                            }}
                            labelProps={{
                                shrink: true
                              }}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <CustomInput
                            labelText="Conferma Password"
                            id="confermaPassword"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                type: "password",
                                name: "Conferma Password",
                                value: dipendente["Conferma Password"],
                                onChange: this.handleChange
                            }}
                            labelProps={{
                                shrink: true
                              }}
                            />
                            { messaggioErrore && messaggioErrore.length > 0 && (
                                <span style={{ color: "red", fontSize: "80%", fontWeight: "bold" }}>{ messaggioErrore }</span>
                            )}
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <Button type="submit" color="success" >Salva</Button>
                        </GridItem>
                    </GridContainer>
                </form>
            </ScaffoldBase>
        )
    }
}

Profilo.propTypes = {
    classes: PropTypes.object.isRequired
  };

function mapStateToProps({ authentication }) {
    return {
        currentPersona: authentication.user
    }
}

function mapDispatchToProps( dispatch ){
    return {
        updatePersona: (id, dipendente) => dispatch(updatePersona(id, dipendente)),
    }
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(Profilo));