import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api.js';

export const GET_RICHIESTE = "GET_RICHIESTE";
export const GET_RICHIESTA = "GET_RICHIESTA";
export const DELETE_RICHIESTA = "DELETE_RICHIESTA";

export const getRichieste = richieste => ({
  type: GET_RICHIESTE,
  items: richieste
});

// recupera le richieste
export const fetchRichieste = (filter=[]) => dispatch => {

  let queryString = filterParser(filter)

  if (queryString.length > 0 )
    queryString = `?${queryString}`

  return fetch(`${REACT_APP_BACKEND}richiestes${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
    .then( (data) => { 
      let richieste = null;

      try {
        richieste = JSON.parse(data);
        richieste = richieste !== null ? richieste : null;
      } catch(e) {
        richieste = null;
      } finally {
        dispatch(getRichieste(richieste));
      }
    })
  }

export const getRichiesta = richiesta => ({
  type: GET_RICHIESTA,
  richiesta
})

export const fetchRichiesta = (id) => dispatch => {
    return fetch(`${REACT_APP_BACKEND}richiestes/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let richiesta = null;

    try {
      richiesta = JSON.parse(data);
      richiesta = richiesta !== null ? richiesta : null;
    } catch(e) {
      richiesta = null;
    } finally {
      dispatch(getRichiesta(richiesta));
    }
  })
}

// aggiunge una richiesta
export const addRichiesta = ( richiesta ) => dispatch => {
  fetch(`${REACT_APP_BACKEND}richiestes`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(richiesta)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let richiesta = null;

    try {
        richiesta = JSON.parse(data);
        richiesta = richiesta !== null ? richiesta : null;
    } catch(e) {
        richiesta = null;
    } finally {
      return richiesta;
    }
  })
}

// aggiorna la richiesta identificato dall'id passato
export const updateRichiesta = ( id, richiesta ) => dispatch => {
  fetch(`${REACT_APP_BACKEND}richiestes/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(richiesta)
  })
}

// cancello la richiesta identificato dall'id passato
export const deleteRichiesta = (id) => dispatch => {
  fetch(`${REACT_APP_BACKEND}richiestes/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
}
