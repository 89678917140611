import { REACT_APP_BACKEND } from '../index.js';

export const GET_SOTTOGRUPPI = "GET_SOTTOGRUPPI";

export const getSottogruppi = sottogruppi => ({
  type: GET_SOTTOGRUPPI,
  items: sottogruppi
});

// recupera i sottogruppi
export const fetchSottogruppi = (gruppo="") => dispatch => {

  let path = `${REACT_APP_BACKEND}sottogruppis`;
  if (gruppo !== "") {
    path+= `?Gruppo=${gruppo}`
  }

  fetch( path )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
	  .then( (data) => { 
      let sottogruppi = null;

      try {
        sottogruppi = JSON.parse(data);
        sottogruppi = sottogruppi !== null ? sottogruppi : null;
      } catch(e) {
        sottogruppi = null;
      } finally {
        dispatch(getSottogruppi(sottogruppi));
      }
    })
}

export const azzeraSottogruppi = () => dispatch => {
  dispatch(getSottogruppi(null))
}