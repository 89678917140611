import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, ListItemIcon } from "@material-ui/core";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


// core components

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// React-Redux reducers
import { connect } from "react-redux";

import { compose } from "redux";
import { withRouter } from "react-router";

const gruppoHeaderStyle = {
    padding: "10px 10px",
    backgroundColor: "#7d6960",
    color: "white"
}

class GruppiPietanze extends React.Component {

  render() {
    const { pietanze, gruppi, actionIcon } = this.props;

    return (
        <div>
            { pietanze && pietanze.length > 0 && (
                <div>
                {
                    gruppi && gruppi.length > 0 && gruppi.map( (g) => {
                        const pietanzeGruppo = pietanze.filter( (p) => {
                            return g._id === p["Gruppo"]._id || g._id === p["Gruppo"]
                        })

                        return (
                            <List key={g._id}>
                                <ListItem key={g._id} style={gruppoHeaderStyle}>
                                    <ListItemText primary={g["Nome"]} />
                                </ListItem>
                                { pietanzeGruppo.map( (p) => {
                                    return (
                                        <ListItem key={p._id}>
                                            <ListItemText primary={p["Nome"]} />
                                            { actionIcon && (
                                                <ListItemIcon>
                                                    { actionIcon({'id_pietanza': p._id }) }
                                                </ListItemIcon>
                                            )}
                                        </ListItem>
                                    )} )
                                }
                            </List>
                    )
                    })
                }
                </div>
            )}
            {!pietanze && (
                <Typography>Pietanze non presenti</Typography>
            )}
        </div>
    )
  }
}

GruppiPietanze.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withStyles(dashboardStyle),
  connect(null, null)
)(withRouter(GruppiPietanze));
