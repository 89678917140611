import React from "react";
import ReactDOM from "react-dom";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import { Router } from "react-router-dom";

import "assets/css/material-dashboard-react.css?v=1.5.0";

import clienti from "./reducers";

import App from "components/App/App.jsx";

const hist = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(clienti, composeEnhancers(applyMiddleware(thunk)));

let url = "";

if(process.env.NODE_ENV === 'development') {
  url = 'https://strapi-demo.dasporto.net/'
  //url = 'http://strapi.tecnodatasystem.eu/'
}
else if ( process.env.NODE_ENV === 'production') {
  url = 'https://strapi.dasporto.net/'
}

export const REACT_APP_BACKEND = url;

ReactDOM.render(
<Provider store={store}>
  <Router history={hist}>
    <App />
  </Router>
</Provider>,
  document.getElementById("root")
);
