import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api';

export const GET_CLIENTI = "GET_CLIENTI";
export const GET_CLIENTE = "GET_CLIENTE";
export const DELETE_CLIENTE = "DELETE_CLIENTE";


export const getClienti = clienti => ({
  type: GET_CLIENTI,
  items: clienti
});

// recupera i clienti
export const fetchClienti = (filter=[]) => dispatch => {
    let queryString = filterParser(filter)

    if (queryString.length > 0 ){
      queryString = '?'+queryString+"&Stato=attivo"
    }else{
      queryString = ''
    }
      

    return fetch(`${REACT_APP_BACKEND}aziendes${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
	  .then( (data) => { 
      let clienti = null;

      try {
        clienti = JSON.parse(data);
        clienti = clienti !== null ? clienti : null;
      } catch(e) {
        clienti = null;
      } finally {
        dispatch(getClienti(clienti));
      }
    })
  }

export const getCliente = cliente => ({
  type: GET_CLIENTE,
  cliente
})

export const fetchCliente = (id) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}aziendes/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let cliente = null;

    try {
      cliente = JSON.parse(data);
      cliente = cliente !== null ? cliente : null;
    } catch(e) {
      cliente = null;
    } finally {
      dispatch(getCliente(cliente));
      return cliente
    }
  })
}

// aggiunge un cliente
export const addCliente = ( cliente ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}aziendes`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(cliente)
  })
)

// aggiorna il cliente identificato dall'id passato
export const updateCliente = ( id, cliente ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}aziendes/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(cliente)
  })
)

// cancello il cliente identificato dall'id passato
export const deleteCliente = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}aziendes/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
)




