import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { updateGruppo, fetchGruppo } from "actions/gruppi";
import { fetchPietanze, updatePietanza } from "actions/pietanze";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";


class DettaglioDipendente extends Component {

  state = {
    gruppo: null,
    title : "",
    subtitle: "Dettaglio gruppo",
    messaggio: ""
  }

  componentDidMount() {
    const { match, caricaGruppo } = this.props

    const idGruppo = match.params.id

    if ( idGruppo && idGruppo.length > 0 ) {

      Promise.resolve()
      .then( () => caricaGruppo(idGruppo))
      .then( () => {
        // carico le pietanze per permettere poi di assegnargli il prezzo del gruppo
        const { caricaPietanze, azienda } = this.props
        const filtroPietanze = [ { 'campo': 'Gruppo', 'operatore': '=', 'valore': idGruppo },
                                 { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]

        caricaPietanze(filtroPietanze)
      })
      .then( () => {
        const { currentGruppo } = this.props
        this.setState({ gruppo:  currentGruppo,
                        title: "Modifica gruppo" })
      })
    }
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {
    if (!e)
      return;

    e.preventDefault()
    const { currentGruppo, updateGruppo } = this.props

    const values = serializeForm(e.target, { hash: true, empty: true })

    Promise.resolve()
    .then( () => {
        updateGruppo(currentGruppo._id, values)
    })
    .then( () => {
        this.setState({ messaggio: "Gruppo aggiornato"})
    })
  }

  // Gestisco l'aggiornamento dei campi della form
  handleChange = (e) => {
    let nomeProprieta = e.target.name;
    let valoreProprieta = e.target.value;
    let updatedGruppo = {};

    updatedGruppo[nomeProprieta] = valoreProprieta;

    this.setState({ gruppo: { ...this.state.gruppo, ...updatedGruppo } })
  }

  // pulsante torna indietro
  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // gestisco la chiusura dello snack
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '' });
  };

  // gestisco l'uscita dallo snack
  handleExitedScnak = () => {
    this.setState({ messaggio: ''})
  };

  // assegno il prezzo del gruppo a tutte le pietanze che ne fanno parte
  assegnaPrezzoGruppo() {
    const { gruppo } = this.state

    if (gruppo && gruppo["Prezzo"] && parseFloat(gruppo["Prezzo"]) > 0 ) {
      const prezzo = gruppo['Prezzo']

      Promise.resolve()
      .then( () => {
        const { pietanze, aggiornaPietanza } = this.props

        pietanze.forEach( (p) => {
          p['Prezzo'] = prezzo

          aggiornaPietanza(p.id, p)
        })
      })
      .then( () => {
        this.setState({ messaggio: "Il prezzo del gruppo è stato assegnato alle pietanze appartenenti"})
      })
      .catch( () => {
        this.setState({ messaggio: "Si è verificato un problema nella procedura"})
      })
      
    }
  }
  

  render() {
    const { gruppo, title, subtitle } = this.state
    const { classes } = this.props

    return (
      <div>
          { gruppo && (
              <ScaffoldBase title={title} subtitle={subtitle}>
              <form onSubmit={this.handleSubmit}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Nome"
                      id="nome"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "Nome",
                        required: true,
                        value: gruppo["Nome"],
                        readOnly: true,
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <CustomInput
                      labelText="Prezzo"
                      id="prezzo"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        type: "number",
                        name: "Prezzo",
                        value: gruppo["Prezzo"],
                        step: 2,
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                      <Button onClick={() => this.assegnaPrezzoGruppo()}>Applica prezzo a pietanze</Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button type="submit" color="success">Salva</Button>
                    <Button color="transparent" onClick={ () => this.tornaIndietro() }>Indietro</Button>
                  </GridItem>
                </GridContainer>
              </form>   
              <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                autoHideDuration={3000}
                onClose={this.handleCloseSnack}
                onExited={this.handleExitedSnack}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.messaggio}</span>}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleCloseSnack}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
              />
            </ScaffoldBase>
          )}
      </div>
    );
  }
}

function mapStateToProps({ gruppi, authentication, pietanze }) {
  return {
    currentGruppo: gruppi.currentGruppo,
    utenteLoggato: authentication.user,
    azienda: authentication.user['Azienda'],
    pietanze: pietanze.items
  }
}

function mapDispatchToProps( dispatch ){
  return {
    updateGruppo: (id, gruppo) => dispatch(updateGruppo(id, gruppo)),
    caricaGruppo: (id) => dispatch(fetchGruppo(id)),
    caricaPietanze: (filtro) => dispatch(fetchPietanze(filtro)),
    aggiornaPietanza: (id, pietanza) => dispatch(updatePietanza(id, pietanza))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioDipendente));
