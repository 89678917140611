import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api';

export const GET_TEMPLATEMENUS = "GET_TEMPLATEMENUS";
export const GET_TEMPLATEMENU = "GET_TEMPLATEMENU";
export const DELETE_TEMPLATEMENU = "DELETE_TEMPLATEMENU";


export const getTemplatemenus = template => ({
  type: GET_TEMPLATEMENUS,
  items: template
});

// recupera i menu
export const fetchTemplatemenus = (filter) => dispatch => {

    let queryString = filterParser(filter)

    if (queryString.length > 0 )
      queryString = '?'+queryString

    return fetch(`${REACT_APP_BACKEND}templatemenus${queryString}` )
    .then( (res) => { 
        return res.status === 200 ? res.text() : null;
    })
    .then( (data) => { 
        let menus = null;

        try {
            menus = JSON.parse(data);
            menus = menus !== null ? menus : null;
        } catch(e) {
            menus = null;
        } finally {
            dispatch(getTemplatemenus(menus));
            return menus
        }
    })
  }

export const getTemplatemenu = templatemenu => ({
  type: GET_TEMPLATEMENU,
  templatemenu
})

export const fetchTemplatemenu = (id) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}templatemenus/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let menu = null;

    try {
        menu = JSON.parse(data);
        menu = menu !== null ? menu : null;
    } catch(e) {
        menu = null;
    } finally {
      dispatch(getTemplatemenu(menu));
      return menu
    }
  })
}

// aggiunge un template menu
export const addTemplatemenu = ( template ) => dispatch => {
    return fetch(`${REACT_APP_BACKEND}templatemenus`, 
    { headers: { "Content-Type" : "application/json"}, 
      method: "POST", 
      body: JSON.stringify(template)
    })
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
    .then( (data) => { 
      let templatemenu = null;
  
      try {
        templatemenu = JSON.parse(data);
        templatemenu = templatemenu !== null ? templatemenu : null;
      } catch(e) {
        templatemenu = null;
      } finally {
        return templatemenu;
      }
    })
  }
  
  // aggiorna il template menu identificato dall'id passato
  export const updateTemplatemenu = ( id, templatemenu ) => dispatch => {
    fetch(`${REACT_APP_BACKEND}templatemenus/${id}`, 
    { headers: { "Content-Type" : "application/json"}, 
      method: "PUT", 
      body: JSON.stringify(templatemenu)
    })
    .then( () => dispatch(fetchTemplatemenu(id)))
  }

// cancello il templatemenu identificato dall'id passato
export const deleteTemplatemenu = (id) => dispatch => {
    return fetch(`${REACT_APP_BACKEND}templatemenus/${id}`, 
    { headers: {  "Content-Type" : "application/json"}, 
      method: "DELETE"
    })
}