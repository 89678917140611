import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { FormControl, Typography, Table, TableHead, TableBody, TableRow, TableCell } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import CircularProgress from "@material-ui/core/CircularProgress"

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { fetchOrdinazioni } from "actions/ordinazioni"
import { fetchClienti } from "actions/clienti";
import { fetchGruppi } from "actions/gruppi";
import { fetchPersone, azzeraPersone } from "actions/persone";

// React-Redux reducers
import { connect } from "react-redux";

import { compose } from "redux";
import { withRouter } from "react-router";

import { formatDateToYYYYMMDD } from "utils/date.js"
import { getValoreCampoDaFiltro, setValoreCampoFiltro } from "utils/filter.js"
import { filterParser, getBigData } from "utils/api";

const initialFiltro = [
  { 'campo': 'Data', 'campoUI': 'Data','operatore': '=', 'valore': formatDateToYYYYMMDD(new Date()) },
  { 'campo': 'Azienda', 'campoUI': 'Azienda', 'operatore': '=', 'valore': ''},
  { 'campo': 'Pasto', 'campoUI': 'Pasto', 'operatore': '=', 'valore': '' },
  { 'campo': 'Dipendente', 'campoUI': 'Persone', 'operatore': '=', 'valore': '' }
]

class ListaOrdinazioniAdmin extends React.Component {

  state = {
    filtro: initialFiltro,
    messaggio: "",
    showCircularProgress: false,
    ordinazioni: null
  }

  componentDidMount() {
    const { caricaClienti, azienda, caricaGruppi } = this.props

    const filtroClientiAzienda = [
      { 'campo': '_id', 'operatore': '_ne', 'valore': azienda._id}
    ]

    caricaClienti(filtroClientiAzienda)
    caricaGruppi()
  }

  handleChange = (e) => { 
    const nomeProprieta = e.target.name
    let valoreProprieta = e.target.value

    const { filtro } = this.state
    const { resetDipendenti, caricaDipendenti } = this.props

    let newFiltro = setValoreCampoFiltro('campoUI', nomeProprieta, valoreProprieta, filtro)

    this.setState({ filtro: newFiltro })

    // gestisco il cambio di cliente ricaricando o meno i suoi dipendenti
    if ( nomeProprieta === 'Azienda') {
      if ( valoreProprieta.trim().length > 0) {
        // ricarico i nuovi dipendenti
        let filtroDipendenti = [
          { campo : 'Azienda', operatore: '=', valore: valoreProprieta },
          { campo: 'Profilo', operatore: '=', valore: 'dipendente'}
        ]

        caricaDipendenti(filtroDipendenti)
      }
      else {
        // resetto la lista dei dipendenti
        resetDipendenti()
      }
    }
  }

    // gestisco la chiusura dello snack
    handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    this.setState({ messaggio: '' });
    }

    // gestisco l'uscita dallo snack
    handleExitedSnack = () => {
    this.setState({ messaggio: ''})
    }

  

  cerca = () => {
    const { filtro } = this.state
    const giorno = getValoreCampoDaFiltro('campo', 'Data', filtro)
    const cliente = getValoreCampoDaFiltro('campo', 'Azienda', filtro)
    const pasto = getValoreCampoDaFiltro('campo', 'Pasto', filtro)
    const dipendente = getValoreCampoDaFiltro('campo', 'Dipendente', filtro)

    // controllo che siano impostati i campi necessari per cercare le ordinazioni
    if ( !giorno || giorno.valore === "") {
        this.setState( { messaggio: "Imposta il giorno"})
        return
    }   

    if ( !cliente || cliente.valore === "") {
        this.setState({ messaggio: "Imposta il cliente"})
        return
    }

    if ( !pasto || pasto.valore === "") {
        this.setState({ messaggio: "Imposta il pasto"})
        return
    }

    this.setState({ showCircularProgress: true })

    let dataFiltro = giorno.valore

    let filtroOrdinazioni = [
        { 'campo': 'Azienda', 'operatore': '=', 'valore': cliente.valore },
        { 'campo': 'Data', 'operatore' : '=', 'valore': dataFiltro },
        { 'campo': 'Pasto', 'operatore': '=', 'valore': pasto.valore }
    ]

    if ( dipendente && dipendente.valore.length > 0 ) {
        filtroOrdinazioni.push( { 'campo': 'Persone', 'operatore': '=', 'valore': dipendente.valore })
    }

    let queryString = filterParser(filtroOrdinazioni)

    if (queryString.trim().length > 0)
        queryString = `?${queryString}`

    getBigData(`ordinazionis`, queryString, 100)
    .then( (ordinazioni) => {
        this.setState( { ordinazioni: ordinazioni, showCircularProgress: false  })
    })

  }

  // funzione che apre la scheda di inserimento di una nuova ordinazione
  apriAggiungiOrdinazione = () => {
    const { history } = this.props

    history.push('/dashboard/lista-ordinazioni/add')
  }

  apriDettaglioOrdinazione = (id) => {
    const { history } = this.props

    history.push(`/dashboard/lista-ordinazioni/${id}`)
  }

  render() {
    const { clienti, dipendenti } = this.props
    const { filtro, showCircularProgress, ordinazioni } = this.state

    const giorno = getValoreCampoDaFiltro('campo', 'Data', filtro)
    const cliente = getValoreCampoDaFiltro('campo', 'Azienda', filtro)
    const pasto = getValoreCampoDaFiltro('campo', 'Pasto', filtro)
    const dipendente = getValoreCampoDaFiltro('campo', 'Dipendente', filtro)

    // eseguo l'ordinamento delle ordinazioni in base al nome e cognome dei dipendenti
    ordinazioni && ordinazioni.sort( (a, b) => {
      if (a["Persone"] && b["Persone"]) {
        if (a["Persone"]["Nome"] && a["Persone"]["Cognome"] && b["Persone"]["Nome"] && b["Persone"]["Cognome"]) {
          if ( a["Persone"]["Cognome"] > b["Persone"]["Cognome"])
            return 1
          if ( a["Persone"]["Cognome"] < b["Persone"]["Cognome"])
            return -1
          if ( a["Persone"]["Nome"] > b["Persone"]["Nome"])
            return 1
          if (a["Persone"]["Nome"] < b["Persone"]["Nome"])
            return -1
          return 0
        }
        else
          return 0
      }
      else 
        return 0
    })
    
    return (
        <ScaffoldBase title="Lista ordinazioni" subtitle="Consulta le ordinazioni effettuate nel periodo indicato nei filtri">
            <GridContainer>
              <GridItem xs={12} sm={4} md={2} lg={2}>
                <CustomInput
                  labelText="Giorno"
                  id="giorno"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Data",
                    type: "Date",
                    value: giorno && giorno.valore ? giorno.valore : "",
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={2} lg={2}>
                <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                  <InputLabel htmlFor="Pasto">Pasto</InputLabel>
                  <Select
                    value={ pasto && pasto.valore ? pasto.valore : ""}
                    onChange={this.handleChange}
                    inputProps={{
                      
                      name: 'Pasto',
                      id: 'pasto'
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    <MenuItem value="pranzo">
                      Pranzo
                    </MenuItem>
                    <MenuItem value="cena">
                      Cena
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                  <InputLabel htmlFor="Cliente">Cliente</InputLabel>
                  <Select
                    onChange={this.handleChange}
                    value={ cliente && cliente.valore ? cliente.valore : ""}
                    inputProps={{
                      name: 'Azienda',
                      id: 'cliente'
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    { clienti && clienti.length > 0 && clienti.map( (c) => {
                      return (
                        <MenuItem key={c._id} value={c._id}>{c["Ragione sociale"]}</MenuItem>
                      )
                    })
                    }
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                  <InputLabel htmlFor="Dipendente">Dipendente</InputLabel>
                  <Select
                    onChange={this.handleChange}
                    value={ dipendente && dipendente.valore ? dipendente.valore : ""}
                    inputProps={{
                      name: 'Persone',
                      id: 'dipendente'
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    { dipendenti && dipendenti.length > 0 && dipendenti
                        .sort( (a, b) => {
                            if (a['Cognome'] && a['Nome']) {
                                if (a['Cognome'] > b['Cognome'])
                                    return 1
                                if (a['Cognome'] < b['Cognome'])
                                    return -1
                                if (a['Nome'] > b['Nome'])
                                    return 1
                                if (a['Nome'] < b['Nome'])
                                    return -1
                                return 0
                            }
                            else 
                                return 0
                        })
                        .map( (d) => {
                        return (
                            <MenuItem key={d._id} value={d._id}>{`${d['Cognome']} ${d['Nome']}`}</MenuItem>
                        )
                    })
                    }
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                <Button type="submit" color="success" onClick={this.cerca} style={{ marginTop: "27px" }}>Visualizza</Button>
                <Button type="submit" color="warning" onClick={this.apriAggiungiOrdinazione} style={{ marginTop: "27px" }}>Aggiungi ordinazione</Button><br />
              </GridItem>
            </GridContainer>
            <GridContainer>
              { showCircularProgress && (
                <CircularProgress />
              )}

              { showCircularProgress === false && ordinazioni && ordinazioni.length > 0 && (
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Giorno</TableCell>
                      <TableCell>Pasto</TableCell>
                      <TableCell>Persona</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    { ordinazioni && ordinazioni.map( (o) => {
                        let dataGiorno = new Date(o["Data"]).toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'})

                        return (
                          <TableRow key={o.id} onClick={() => { this.apriDettaglioOrdinazione(o.id) }}>
                            <TableCell>{dataGiorno}</TableCell>
                            <TableCell>{o["Pasto"]}</TableCell>
                            <TableCell>{`${o["Persone"]["Cognome"]} ${o["Persone"]["Nome"]}`}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              )}
              { showCircularProgress === false && ordinazioni && ordinazioni.length === 0 && (
                <Typography>Non sono presenti ordinazioni con i filtri inseriti. Prova a cambiare i filtri sopra</Typography>
              )}
            </GridContainer>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

                open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false }
                autoHideDuration={4000}
                onClose={this.handleCloseSnack}
                onExited={this.handleExitedSnack}
                message={this.state.messaggio}
            />
        </ScaffoldBase>
    );
  }
}

ListaOrdinazioniAdmin.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
      caricaOrdinazioni: (filtro) => dispatch(fetchOrdinazioni(filtro)),
      caricaClienti: (filtro) => dispatch(fetchClienti(filtro)),
      caricaGruppi: () => dispatch(fetchGruppi()),
      caricaDipendenti: (filtro) => dispatch( fetchPersone(filtro)),
      resetDipendenti: () => dispatch(azzeraPersone()),
  }
}

function mapStateToProps({ ordinazioni, clienti, authentication, gruppi, persone }) {
  return { 
      ordinazioni: ordinazioni.items,
      clienti: clienti.items,
      azienda: authentication.user.Azienda,
      gruppi: gruppi.items,
      dipendenti: persone.items
   };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaOrdinazioniAdmin));
