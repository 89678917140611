import React, { Component } from "react"
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from "@material-ui/core/FormLabel"
import FormGroup from "@material-ui/core/FormGroup"
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";


// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

// React-Redux reducers
import { connect } from "react-redux";

import { compose } from "redux";
import { withRouter } from "react-router";
import { fetchOrdinazione, addOrdinazione, updateOrdinazione, deleteOrdinazione } from "actions/ordinazioni";
import { fetchPersone, azzeraPersone } from "actions/persone";
import { fetchClienti } from "actions/clienti";
import { fetchMenus, azzeraMenu } from "actions/menu";
import { fetchGruppi } from "actions/gruppi";

import { formatDateToYYYYMMDD } from "utils/date.js"


class DettaglioOrdinazioneAdmin extends Component {

    state = {
      ordinazione: null,
      titoloPagina: "Dettaglio ordinazione",
      messaggio: "",
      showCircularProgress: true
    }
  
    componentDidMount() {
      const { match, azienda, caricaClienti, caricaGruppi } = this.props

      let filtroClienti = [
        { campo: 'Azienda riferimento', operatore: '=', valore: azienda.id }
      ]

      let filtroGruppi = [
        { campo: "Azienda", operatore: "=", valore: azienda.id }
      ]

      caricaClienti(filtroClienti)
      caricaGruppi(filtroGruppi)

      // se è impostato l'id recupero l'ordinazione
      const idOrdinazione = match.params.id

      if ( idOrdinazione && idOrdinazione.length > 0 ) {
        this.caricaInfoOrdinazione(idOrdinazione)
      }
      else {
        this.setState({ showCircularProgress: false })
      }
    }

    componentWillUnmount() {
      const { resetMenus } = this.props
      resetMenus()
    }

    // Gestisco l'aggiornamento dei campi della form
    handleChange = (e) => {
      let nomeProprieta = e.target.name;
      let valoreProprieta = e.target.value;
      let updatedOrdinazione = {};

      updatedOrdinazione[nomeProprieta] = valoreProprieta;

      let valorePasto = null

      if (updatedOrdinazione["Pasto"] && updatedOrdinazione["Pasto"].trim().length > 0)
        valorePasto = updatedOrdinazione["Pasto"]
      else if ( this.state.ordinazione && this.state.ordinazione["Pasto"] && this.state.ordinazione["Pasto"].trim().length > 0 )
        valorePasto = this.state.ordinazione["Pasto"]

      // se ho cambiato azienda devo ricaricare i dipendenti
      if ( nomeProprieta === 'Azienda' && valoreProprieta.trim().length > 0) {

        if ( valoreProprieta.trim().length > 0 ) {
          const { caricaDipendenti } = this.props

          let filtroDipendenti = [ 
            { campo : 'Azienda', operatore: '=', valore: valoreProprieta },
            { campo: 'Profilo', operatore: '=', valore: 'dipendente'}
          ]

          caricaDipendenti(filtroDipendenti)
        }
        else {
          // altrimenti azzero la lista dei dipendenti
          const { resetDipendenti } = this.props
          resetDipendenti()
        }
        
      }

      const { caricaMenus, resetMenus } = this.props

      // gestisco il cambio del giorno per visualizzare il menu
      if ( nomeProprieta === 'Data' || nomeProprieta === 'Pasto') {
        // controllo se è valorizzato
        if ( valoreProprieta.trim().length > 0 ) {
          // devo controllare se è impostato il pasto ( pranzo / cena) per caricare il menu
          const { ordinazione } = this.state

          resetMenus()

          // azzero anche le pietanze dell'ordinazione. Perchè se cambia il menu le pietanze devono essere reimpostate
          updatedOrdinazione["Pietanze"] = null

          if ( ordinazione && ((ordinazione["Pasto"] && ordinazione["Pasto"].trim().length > 0) || (ordinazione["Data"] && ordinazione["Data"].trim().length > 0)))  {
            // il pasto è valorizzato. Carico il men

            let filtroMenu = [
              { campo: "Pasto", operatore: "=", valore: valorePasto },
              { campo: "Data", operatore: "=", valore: ordinazione["Data"] }
            ]

            caricaMenus(filtroMenu)
          }
          else {
            // il pasto non è valorizzato. Non mostro niente
            resetMenus()
          }
        }
        else {
          // non è valorizzato il giorno.
          // devo togliere il menu e le eventuali scelte
          resetMenus()
        }
      }

      this.setState({ ordinazione: { ...this.state.ordinazione, ...updatedOrdinazione } })

    }

    // Gestisco il check sulla pietanza del menu
    handleCheck = ( checked, pietanza) => {
      const { ordinazione } = this.state
      let updatedOrdinazione = {}
      let updatedPietanze = []

      if ( ordinazione && ordinazione["Pietanze"])
        updatedPietanze = ordinazione["Pietanze"].map( (p) => { return p })

      if ( !checked) {
        // aggiungo alla lista
        updatedPietanze.push(pietanza)   
      }
      else {
        // tolgo dalla lista
        updatedPietanze = updatedPietanze.filter( (o) => {
          return o.id !== pietanza.id
        })
      }

      updatedOrdinazione["Pietanze"] = updatedPietanze

      this.setState({ ordinazione:  { ...this.state.ordinazione, ...updatedOrdinazione }})
    }

    handleCheckAggiuntaExtraOrario = (checked) => {

      let updatedOrdinazione = {}
      updatedOrdinazione["Aggiunta Extra Orario"] = !checked

      this.setState({ ordinazione:  { ...this.state.ordinazione, ...updatedOrdinazione }})

    }

    // Carico le informazioni dell'ordinazione
    caricaInfoOrdinazione = (id) => {
      const { caricaOrdinazione, caricaDipendenti, caricaMenus } = this.props

      this.setState({ showCircularProgress: true })

      Promise.resolve().then( () => {
        return caricaOrdinazione(id)
      })
      .then( (o) => {
        
        if ( o ) {
          // carico la lista dei dipendenti
          let filtroDipendenti = [
            { campo: 'Azienda', operatore: '=', valore: o["Azienda riferimento"]},
            { campo: 'Profilo', operatore: '=', valore: 'dipendente'}
          ]

          caricaDipendenti(filtroDipendenti)

          // carico il menu del giorno
          if ( o["Data"].trim().length > 0 && o["Pasto"].trim().length > 0 ) {
            let filtroMenu = [
              { campo: "Pasto", operatore: "=", valore: o["Pasto"] },
              { campo: "Data", operatore: "=", valore: o["Data"] }
            ]

            caricaMenus(filtroMenu)
          }
        }

        return o
      })
      .then( (o) => { 
        this.setState({ ordinazione: o, showCircularProgress: false })
      })
    }

    // Gestisce la cancellazione dell'ordinazione
    eliminaOrdinazione = () => {
      const { ordinazione } = this.state
      const { cancellaOrdinazione, history } = this.props

      if ( ordinazione && ordinazione.id) {
        Promise.resolve()
        .then ( () => {
          return cancellaOrdinazione(ordinazione.id)
        })
        .then( () => {
          history.push( '/dashboard/lista-ordinazioni')
        })
      }
    }

    salvaOrdinazione = () => {
      const { ordinazione } = this.state
      const { inserisciOrdinazione, aggiornaOrdinazione, history } = this.props

      // controllo che siano inseriti tutti i dati necessari per l'ordinazione
      if ( ordinazione) {
        if ( !ordinazione["Data"]) {
          this.setState({ messaggio: "Data dell'ordinazione non inserita"})
          return
        }

        if ( !ordinazione["Pasto"] && (ordinazione["Pasto"] !== 'pranzo' || ordinazione["Pasto"] !== 'cena') ) {
          this.setState({ messaggio: "Pasto dell'ordinazione non valido"})
          return
        }

        if ( !ordinazione["Azienda"]) {
          this.setState({ messaggio: "Cliente dell'ordinazione non inserito"})
          return
        }

        if ( !ordinazione["Persone"]) {
          this.setState({ messaggio: "Dipendente dell'ordinazione non inserito"})
          return
        }

        if ( !ordinazione["Pietanze"] || ordinazione["Pietanze"].length === 0) {
          this.setState({ messaggio: "Pietanze dell'ordinazione non inserite"})
          return
        }

        // salvo l'ordinazione
        if ( ordinazione.id && ordinazione.id.length > 0 ) {
          // l'ordinazione ha già l'id quindi è già stata inserita. Devo aggiornarla
          Promise.resolve()
          .then( () => {
            return aggiornaOrdinazione(ordinazione.id, ordinazione)
          })
          .then( () => {
            // goto url ordinazione
            this.caricaInfoOrdinazione(ordinazione.id)
          })
          .then( () => {
            this.setState({ messaggio: "Ordinazione modificata"})
          })
        }
        else {
          // l'ordinazione non è presente. la inserisco come nuova
          Promise.resolve()
          .then( () => {
            return inserisciOrdinazione(ordinazione)
          })
          .then( (o) => {
            // goto url ordinazione
            if (o) {
              history.push(`/dashboard/lista-ordinazioni/${o.id}`)
              this.caricaInfoOrdinazione(o.id)
            }
              
          })
        }
          
      }
      else {
        this.setState({ messaggio: 'Ordinazione non valida!'})
      }
    }

    // gestisco la chiusura dello snack
    handleCloseSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({ messaggio: '', disabledButton: false });
    }
  
    // gestisco l'uscita dallo snack
    handleExitedScnak = () => {
      this.setState({ messaggio: ''})
    }

    render () {
      const { titoloPagina, ordinazione, showCircularProgress} = this.state
      const { clienti, dipendenti, menu, gruppi, classes } = this.props

      const giorno = ordinazione && ordinazione["Data"] ? ordinazione["Data"] : null
      const pasto = ordinazione && ordinazione["Pasto"] ? ordinazione["Pasto"] : null
      const cliente = ordinazione && ordinazione["Azienda"] ? ordinazione["Azienda"].id ? ordinazione["Azienda"].id : ordinazione["Azienda"] : null
      const dipendente = ordinazione && ordinazione["Persone"] ? ordinazione["Persone"].id ? ordinazione["Persone"].id : ordinazione["Persone"] : null
      const aggiuntaExtraOrario = ordinazione && ordinazione["Aggiunta Extra Orario"] ? ordinazione["Aggiunta Extra Orario"] : false

      const currentMenu = menu && menu.length > 0 ? menu[0] : null

      let idPietanzeOrdinazione = []

      // recupero tutti gli id delle pietanze dell'ordinazione per controllare
      // se devono essere checkate
      if ( ordinazione && ordinazione["Pietanze"])
        idPietanzeOrdinazione = ordinazione["Pietanze"].map( (p) => { return p.id } )

      return (
          <ScaffoldBase title={titoloPagina}>
            { showCircularProgress === true && (
                <CircularProgress />
            )}
            { showCircularProgress === false && (
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={4} md={2} lg={2}>
                    <CustomInput
                      labelText="Giorno"
                      id="data"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "Data",
                        type: "Date",
                        value: giorno ? formatDateToYYYYMMDD(giorno) : "",
                        onChange: this.handleChange
                      }}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={3} md={2} lg={2}>
                    <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                      <InputLabel htmlFor="Pasto">Pasto</InputLabel>
                      <Select
                        value={ pasto ? pasto : ""}
                        onChange={this.handleChange}
                        inputProps={{
                          
                          name: 'Pasto',
                          id: 'pasto'
                        }}
                      >
                        <MenuItem value="">
                          <em>Nessuno</em>
                        </MenuItem>
                        <MenuItem value="pranzo">
                          Pranzo
                        </MenuItem>
                        <MenuItem value="cena">
                          Cena
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4} lg={4}>
                    <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                      <InputLabel htmlFor="Cliente">Cliente</InputLabel>
                      <Select
                        onChange={this.handleChange}
                        value={ cliente ? cliente : ""}
                        inputProps={{
                          name: 'Azienda',
                          id: 'cliente'
                        }}
                      >
                        <MenuItem value="">
                          <em>Nessuno</em>
                        </MenuItem>
                        { clienti && clienti.length > 0 && clienti.map( (c) => {
                          return (
                            <MenuItem key={c._id} value={c._id}>{c["Ragione sociale"]}</MenuItem>
                          )
                        })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4} lg={4}>
                    <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                      <InputLabel htmlFor="Dipendente">Dipendente</InputLabel>
                      <Select
                        onChange={this.handleChange}
                        value={ dipendente ? dipendente : ""}
                        inputProps={{
                          name: 'Persone',
                          id: 'dipendente'
                        }}
                      >
                        <MenuItem value="">
                          <em>Nessuno</em>
                        </MenuItem>
                        { dipendenti && dipendenti.length > 0 && dipendenti
                            .sort( (a, b) => {
                                if (a['Cognome'] && a['Nome']) {
                                    if (a['Cognome'] > b['Cognome'])
                                        return 1
                                    if (a['Cognome'] < b['Cognome'])
                                        return -1
                                    if (a['Nome'] > b['Nome'])
                                        return 1
                                    if (a['Nome'] < b['Nome'])
                                        return -1
                                    return 0
                                }
                                else 
                                    return 0
                            })
                            .map( (d) => {
                            return (
                                <MenuItem key={d._id} value={d._id}>{`${d['Cognome']} ${d['Nome']}`}</MenuItem>
                            )
                        })
                        }
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={4} md={4} lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="Aggiunta Extra Orario"
                          checked={aggiuntaExtraOrario}
                          onChange={() => this.handleCheckAggiuntaExtraOrario(aggiuntaExtraOrario)}
                          value="Aggiunta Extra Orario"
                        />
                      }
                      label="Aggiunta Extra Orario"
                    />                  
                  </GridItem>
                </GridContainer>
                <GridContainer>
                  { currentMenu && (
                    <div style={{ width: "100%" }} >
                        { gruppi && gruppi.map( (g) => {

                          const pietanzeGruppo = currentMenu["Pietanze"] && currentMenu["Pietanze"].filter( (p) =>{
                            return p["Gruppo"] === g.id
                          })

                          return (
                            <div key={g.id} style={{ width: "100%"}}>
                              <FormControl style={{ width: "100%"}}>
                                <FormLabel style={{ backgroundColor: "#C3C3C3", width: "100%", height: "24px", padding: "5px" }}>{g["Nome"]}</FormLabel>
                                <FormGroup>
                                  { pietanzeGruppo && pietanzeGruppo.map( (p) => {

                                        let checked = idPietanzeOrdinazione.includes(p.id) ? true : false;

                                        return (
                                          <FormControlLabel key={p._id}
                                              control={
                                                  <Checkbox
                                                  checked={checked}
                                                  onChange={() => this.handleCheck(checked, p)}
                                                  color="primary"
                                                  />
                                              }
                                              label={p["Nome"]}
                                          />
                                          ) 
                                        })
                                    }
                                </FormGroup>
                              </FormControl>
                            </div>
                          )
                        })}
                    </div>
                  )}
                </GridContainer>
                <GridContainer>
                  <TextField
                    id="Note"
                    label="Note"
                    name="Note"
                    multiline
                    rows="4"
                    defaultValue=""
                    className={classes.textField}
                    margin="normal"
                    variant="standard"
                    fullWidth="true"
                    onChange={ this.handleChange}
                    value={ordinazione && ordinazione["Note"] ? ordinazione["Note"] : "" }
                  />
                </GridContainer>
                <GridContainer>
                  <Button type="submit" color="success" onClick={() => this.salvaOrdinazione()}>Salva</Button>
                  { ordinazione && ordinazione.id && (
                    <Button color="danger" onClick={() => this.eliminaOrdinazione()}>Elimina</Button>
                  )}
                </GridContainer>
                <Snackbar
                  anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                  }}
                  open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                  autoHideDuration={3000}
                  onClose={this.handleCloseSnack}
                  onExited={this.handleExitedSnack}
                  ContentProps={{
                      'aria-describedby': 'message-id',
                  }}
                  message={<span id="message-id">{this.state.messaggio}</span>}
                  action={[
                      <IconButton
                          key="close"
                          aria-label="Close"
                          color="inherit"
                          className={classes.close}
                          onClick={this.handleCloseSnack}
                      >
                          <CloseIcon />
                      </IconButton>
                  ]}
                />
              </div>
            )}
            
          </ScaffoldBase>
      )
    }
}

DettaglioOrdinazioneAdmin.propTypes = {
    classes: PropTypes.object.isRequired
  };
  
  function mapDispatchToProps( dispatch ) {
    return {
      caricaOrdinazione: (id) => dispatch(fetchOrdinazione(id)),
      caricaClienti: (filtro) => dispatch(fetchClienti(filtro)),
      caricaDipendenti: (filtro) => dispatch(fetchPersone(filtro)),
      resetDipendenti: () => dispatch(azzeraPersone()),
      caricaMenus: (filtro) => dispatch(fetchMenus(filtro)),
      resetMenus: () => dispatch(azzeraMenu()),
      caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro)),
      inserisciOrdinazione: (ordinazione) => dispatch(addOrdinazione(ordinazione)),
      aggiornaOrdinazione: (id, ordinazione) => dispatch(updateOrdinazione(id, ordinazione)),
      cancellaOrdinazione: (id) => dispatch(deleteOrdinazione(id))
    }
  }
  
  function mapStateToProps({ ordinazioni, clienti, persone, authentication, menu, gruppi }) {
    return {
        currentOrdinazione: ordinazioni.currentOrdinazione,
        clienti: clienti.items,
        dipendenti: persone.items,
        azienda: authentication.user["Azienda"],
        menu: menu.items,
        gruppi: gruppi.items
     }
  }
  
  export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
  )(withRouter(DettaglioOrdinazioneAdmin));