import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api.js';

export const GET_ORDINAZIONI = "GET_ORDINAZIONI";
export const GET_ORDINAZIONE = "GET_ORDINAZIONE";
export const DELETE_ORDINAZIONE = "DELETE_ORDINAZIONE";

export const getOrdinazioni = ordinazioni => ({
  type: GET_ORDINAZIONI,
  items: ordinazioni
});

// recupera le ordinazioni
export const fetchOrdinazioni = (filter=[]) => dispatch => {

    let queryString = filterParser(filter)

    if (queryString.length > 0 )
      queryString = `?${queryString}`

    return fetch(`${REACT_APP_BACKEND}ordinazionis${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
	  .then( (data) => { 
      let ordinazioni = null;

      try {
        ordinazioni = JSON.parse(data);
        ordinazioni = ordinazioni !== null ? ordinazioni : null;
      } catch(e) {
        ordinazioni = null;
      } finally {
        dispatch(getOrdinazioni(ordinazioni));
      }
    })
  }

export const azzeraOrdinazioni = () => dispatch => {
  dispatch(getOrdinazioni(null))
}

// recupera le ordinazioni della persona per il giorno indicato
export const fetchOrdinazioniPersona = ( persona ) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}ordinazionis?Persone=${persona}`)
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let ordinazioni = null;

    try {
      ordinazioni = JSON.parse(data);
      ordinazioni = ordinazioni !== null ? ordinazioni : null;
    } catch(e) {
      ordinazioni = null;
    } finally {
      dispatch(getOrdinazioni(ordinazioni));
      return ordinazioni;
    }
  })
}

export const getOrdinazione = ordinazione => ({
  type: GET_ORDINAZIONE,
  ordinazione
})

export const fetchOrdinazione = (id) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}ordinazionis/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let ordinazione = null;

    try {
      ordinazione = JSON.parse(data);
      ordinazione = ordinazione !== null ? ordinazione : null;
    } catch(e) {
      ordinazione = null;
    } finally {
      dispatch(getOrdinazione(ordinazione));
      return ordinazione
    }
  })
}

// aggiunge un'ordinazione
export const addOrdinazione = ( ordinazione ) => dispatch => {

  return fetch(`${REACT_APP_BACKEND}ordinazionis`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(ordinazione)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let ordinazione = null;

    try {
      ordinazione = JSON.parse(data);
      ordinazione = ordinazione !== null ? ordinazione : null;
    } catch(e) {
      ordinazione = null;
    } finally {
      return ordinazione
    }
  })
}

// aggiorna l'ordinazione identificata dall'id passato
export const updateOrdinazione = ( id, ordinazione ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}ordinazionis/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(ordinazione)
  })
)

// cancello l'ordinazione identificata dall'id passato
export const deleteOrdinazione = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}ordinazionis/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
)
