import { REACT_APP_BACKEND } from '../index.js';
import { filterParser, getBigData } from "utils/api.js";

export const GET_PIETANZE = "GET_PIETANZE";
export const GET_PIETANZA = "GET_PIETANZA";
export const DELETE_PIETANZA = "DELETE_PIETANZA";

export const getPietanze = pietanze => ({
  type: GET_PIETANZE,
  items: pietanze
});

// recupera le pietanze

export const fetchPietanze = (filtro=[]) => dispatch => {

  let queryString = filterParser(filtro)
  
  if (queryString.length > 0 )
      queryString = '?'+queryString

  getBigData('pietanzes', queryString)
  .then((res) => {
    dispatch(getPietanze(res))
  })
}



// recupera le pietanze in base a filtri
export const fetchPietanzeByFilter = (filter=[]) => dispatch => {

  let queryString = filterParser(filter)

  if (queryString.length > 0 )
    queryString = '?'+queryString

  // eseguo la chiamata
  return fetch(`${REACT_APP_BACKEND}pietanzes${queryString}`)
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let pietanze = null;

    try {
      pietanze = JSON.parse(data);
      pietanze = pietanze !== null ? pietanze : null;
    } catch(e) {
      pietanze = null;
    } finally {
      dispatch(getPietanze(pietanze));
    }
  })
}

export const getPietanza = pietanza => ({
  type: GET_PIETANZA,
  pietanza
})

export const fetchPietanza = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}pietanzes/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let pietanza = null;

    try {
      pietanza = JSON.parse(data);
      pietanza = pietanza !== null ? pietanza : null;
    } catch(e) {
      pietanza = null;
    } finally {
      dispatch(getPietanza(pietanza));
    }
  })
)

// aggiunge una pietanza
export const addPietanza = ( pietanza ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}pietanzes`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(pietanza)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let pietanza = null;

    try {
        pietanza = JSON.parse(data);
        pietanza = pietanza !== null ? pietanza : null;
    } catch(e) {
      pietanza = null;
    } finally {
      return pietanza;
    }
  })
)

// aggiorna la pietanza identificata dall'id passato
export const updatePietanza = ( id, pietanza ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}pietanzes/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(pietanza)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let pietanza = null;

    try {
        pietanza = JSON.parse(data);
        pietanza = pietanza !== null ? pietanza : null;
    } catch(e) {
      pietanza = null;
    } finally {
      return pietanza;
    }
  })
)

// cancello la pietanza identificata dall'id passato
export const deletePietanza = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}pietanzes/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
)
