
import { REACT_APP_BACKEND } from '../index.js';
import { sha256 } from 'js-sha256';
export const SET_USER = "SET_USER";
export const GET_LOGO = "GET_LOGO";

export const setUser = (logged, user) => ({
  type: SET_USER,
  logged,
  user
});

export const getLogo = (logo) => ({
  type: GET_LOGO,
  logo
});

// cerca la persona in base a username e password
export const fetchPersonaDaCredenziali = (username, password) => dispatch => {

  const sha256Password = sha256(password);

  return fetch(`${REACT_APP_BACKEND}persones?Username=${username}&Password=${sha256Password}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 

    let persona = null;

    try {
      persona = JSON.parse(data);
      persona = persona !== null ? persona : null;
    } catch(e) {
      dispatch(setUser(false, null))
    }
    finally {
      if ( persona && persona.length > 0 && persona[0].Azienda.Stato == 'attivo') {
        dispatch(setUser(true, persona[0]))
        dispatch(fetchLogo(persona[0].Azienda._id))
        return persona
      }
      else {
        dispatch(setUser(false, null))
      }
        
    }
  })
}

// carica la persona precedentemente loggata
export const fetchPersonaAutenticata = ( id ) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}persones/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let persona = null;

    try {
      persona = JSON.parse(data);
      persona = persona !== null ? persona : null;
    } catch(e) {
      persona = null;
    } finally {
      if ( persona ) {
        dispatch(setUser(true, persona))
        dispatch(fetchLogo(persona.Azienda._id))
      }
      else {
        dispatch(setUser(false, null))
      }
    }
  })
}

export const fetchLogo = (idAzienda) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}aziendes/${idAzienda}`)
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 

    let azienda = null;

    try {
      azienda = JSON.parse(data);
      azienda = azienda !== null ? azienda : null;
    } catch(e) {
      dispatch(getLogo("assets/img/logo_tmp.png"))
    }
    finally {
      if ( azienda && azienda.Logo) {
        dispatch(getLogo(`${REACT_APP_BACKEND}${azienda.Logo.url}`))
      }
      else 
        dispatch(getLogo("assets/img/logo_tmp.png"))
    }
  })
}

export const logout = () => dispatch => {
  dispatch(setUser(false, null))
  sessionStorage.clear()
}