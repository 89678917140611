import React, { Component } from "react";
import ReactExport from "react-export-excel";
import PropTypes from "prop-types";

import { compose } from "redux";
import { withRouter } from "react-router";

// React-Redux reducers
import { connect } from "react-redux";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import GridItem from "components/Grid/GridItem.jsx";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button"

import { formatDateToYYYYMMDD, getFirstMonthDay } from 'utils/date'
import { getValoreCampoDaFiltro, setValoreCampoFiltro } from 'utils/filter'
import { getBigData, filterParser } from "utils/api"
import { getCostoGruppo } from "utils/costigruppi"
import { stampaReportCostiDipendenti } from "utils/print"
import { trovaCombinazioneOrdinazione } from "utils/combinazioni";

import { fetchGruppi } from "actions/gruppi"
import { fetchCombinazioni } from "actions/combinazioni"

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const styleFooter = { 
    fontSize: "1.2em",
    fontWeight: "bold"
}

const styleHeader = {
    fontSize: "1.2em",
    fontWeight: "bold"
}

const styleTotali = {
    fontWeight: "bold"
}

const styleCells = {
    borderColor: "#e3e3e3",
    borderStyle: "solid",
    borderWidth: "0px 1px 1px 1px"
}

class ReportPastiDipendenti extends Component {

    state = { 
        filtro: [
            { 'campo': 'Data', 'campoUI': 'Dal', 'operatore': '_gte', 'valore': getFirstMonthDay() },
            { 'campo': 'Data', 'campoUI': 'Al', 'operatore': '_lte', 'valore': new Date()},
            { 'campo': 'Azienda', 'operatore': '=', 'valore': ''}
        ],
         data: { giornoDal: null,
                 giornoAl: null,
                 corpo: [],
                 totali: []
               },
        dataCsv: null
    }

    componentDidMount() {
        const { filtro } = this.state
        const { azienda, caricaGruppi, caricaCombinazioni } = this.props

        if ( azienda) {
            setValoreCampoFiltro('campo', 'Azienda', azienda._id, filtro)

            // carico i gruppi in base al filtro sull'azienda di riferimento
            const filtroGruppi = [ {'campo': 'Azienda', 'operatore': '=', 'valore': azienda["Azienda riferimento"].id }]
            caricaGruppi(filtroGruppi)

            // carico le combinazioni dell'azienda loggata
            const filtroCombinazioni = [ {'campo': 'Aziendariferimento', 'operatore': '=','valore': azienda["Azienda riferimento"].id },
                                         {'campo': 'Azienda', 'operatore': '=', 'valore': azienda.id }]

            caricaCombinazioni(filtroCombinazioni)
        }
            
    }

    handleChange = (e) => {
        const nomeProprieta = e.target.name
        const valoreProprieta = e.target.value

        const { filtro } = this.state

        let newFiltro = setValoreCampoFiltro('campoUI', nomeProprieta, valoreProprieta, filtro)

        this.setState({ filtro: newFiltro })
    }

    // gestisce il recupero delle info per la stampa del pasto completo
    recuperaReportPastoCompleto = () => {
        let data = { corpo: [], totali: [] }

        const { filtro } = this.state
        const { azienda } = this.props

        const costiPastoCompleto = azienda && azienda["Costi pasti"] ? azienda["Costi pasti"] : null

        const costoPastoCompletoAzienda = costiPastoCompleto && costiPastoCompleto["Costo azienda"] && costiPastoCompleto["Costo azienda"]["Pasto completo"] && costiPastoCompleto["Costo azienda"]["Pasto completo"]["Valore"] ? costiPastoCompleto["Costo azienda"]["Pasto completo"]["Valore"] : 0   
        const costoPastoCompletoDipendente = costiPastoCompleto && costiPastoCompleto["Costo dipendente"] && costiPastoCompleto["Costo dipendente"]["Pasto completo"] && costiPastoCompleto["Costo dipendente"]["Pasto completo"]["Valore"] ? costiPastoCompleto["Costo dipendente"]["Pasto completo"]["Valore"] : 0

        let costoTotaleAzienda = 0
        let costoTotaleDipendente = 0

        const giornoDalStampa = getValoreCampoDaFiltro('campoUI', 'Dal', filtro)
        const giornoAlStampa = getValoreCampoDaFiltro('campoUI', 'Al', filtro)

        if ( giornoDalStampa && giornoDalStampa.valore)
            data['giornoDal'] = new Date(giornoDalStampa.valore)

        if ( giornoAlStampa && giornoAlStampa.valore)
            data['giornoAl'] = new Date(giornoAlStampa.valore)

        Promise.resolve()
        .then( () =>  { 

            let newFiltro = JSON.parse(JSON.stringify(filtro))
            const dataDal = getValoreCampoDaFiltro('campoUI', 'Dal', newFiltro) 
            const dataAl = getValoreCampoDaFiltro('campoUI', 'Al', newFiltro)

            newFiltro = setValoreCampoFiltro('campoUI', 'Dal', dataDal.valore, newFiltro)
            newFiltro = setValoreCampoFiltro('campoUI', 'Al', dataAl.valore, newFiltro)

            let queryStringOrdinazioni = filterParser(newFiltro)
            if (queryStringOrdinazioni.trim().length > 0)
                queryStringOrdinazioni = `?${queryStringOrdinazioni}`

            return getBigData('ordinazionis', queryStringOrdinazioni) 
        })
        .then( (ordinazioni) => {
            const { azienda } = this.props

            let filtroDipendenti =  [
                { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id },
                { 'campo': 'Profilo', 'operatore': '=', 'valore': 'dipendente' }
            ]
            let queryStringDipendenti = filterParser(filtroDipendenti)
            if (queryStringDipendenti.trim().length > 0)
                queryStringDipendenti = `?${queryStringDipendenti}`
            return getBigData('persones', queryStringDipendenti).then( (dipendenti) => { return { ordinazioni, dipendenti }})
        })
        .then( (res) => { 
            const { ordinazioni, dipendenti } = res
            let totalePasti = 0

            dipendenti.sort( (a, b) => {
                if (a['Cognome'] < b['Cognome'])
                    return -1
                if (a['Cognome'] > b['Cognome'])
                    return 1
                if (a['Nome'] < b['Nome'])
                    return -1
                if (a['Nome'] > b['Nome'])
                    return 1
                return 0
            })
            .forEach( (dipendente) => {

                let costoAzienda = 0
                let costoDipendente = 0

                const ordinazioniDipendente = ordinazioni.filter( (ordinazione) => {
                    
                    return ordinazione["Persone"] && ordinazione["Persone"]._id === dipendente._id
                })

                totalePasti += ordinazioniDipendente.length
                const pastiDipendente = ordinazioniDipendente && ordinazioniDipendente.length ? ordinazioniDipendente.length : 0

                costoAzienda = costoPastoCompletoAzienda * pastiDipendente
                costoDipendente = costoPastoCompletoDipendente * pastiDipendente

                costoTotaleAzienda += costoAzienda
                costoTotaleDipendente += costoDipendente

                data.corpo.push({ 'id': dipendente._id, 'dipendente': `${dipendente["Cognome"]} ${dipendente["Nome"]}`, 'numPasti' : pastiDipendente, 'costoAzienda': costoAzienda.toFixed(2), 'costoDipendente': costoDipendente.toFixed(2) } )
            })

            data.totali.push({ 'totalePasti': totalePasti, 'totaleCostoAzienda': costoTotaleAzienda.toFixed(2), 'totaleCostoDipendente': costoTotaleDipendente.toFixed(2)})

            this.setState({ data })

        })

    }

    // gestice il recupero delle info per la stampa generica dei gruppi
    recuperaReportGruppiPasti = () => {
        let data = { corpo: [], totali: [] }

        const { filtro } = this.state
        const { azienda, gruppi } = this.props

        const costiGruppi = azienda && azienda["Costi pasti"] ? azienda["Costi pasti"] : null

        let costi = { azienda: [], dipendente: [] }

        const giornoDalStampa = getValoreCampoDaFiltro('campoUI', 'Dal', filtro)
        const giornoAlStampa = getValoreCampoDaFiltro('campoUI', 'Al', filtro)

        if ( giornoDalStampa && giornoDalStampa.valore)
            data['giornoDal'] = new Date(giornoDalStampa.valore)

        if ( giornoAlStampa && giornoAlStampa.valore)
            data['giornoAl'] = new Date(giornoAlStampa.valore)

        // recupero i costi dei gruppi sia per l'azienda che per il dipendente una volta per tutti
        gruppi.forEach( (g) => {
            // costi azienda
            const costoAzienda = getCostoGruppo(costiGruppi, g._id, 'azienda')
            costi.azienda[g._id] = costoAzienda.Valore

            // costi dipendente
            const costoDipendente = getCostoGruppo(costiGruppi, g._id, 'dipendente')
            costi.dipendente[g._id] = costoDipendente.Valore
        })

        Promise.resolve()
        .then( () =>  {

            let newFiltro = JSON.parse(JSON.stringify(filtro))
            const dataDal = getValoreCampoDaFiltro('campoUI', 'Dal', newFiltro) 
            const dataAl = getValoreCampoDaFiltro('campoUI', 'Al', newFiltro)
            newFiltro = setValoreCampoFiltro('campoUI', 'Dal', dataDal.valore, newFiltro)
            newFiltro = setValoreCampoFiltro('campoUI', 'Al', dataAl.valore, newFiltro)

            let queryStringOrdinazioni = filterParser(newFiltro)
            if (queryStringOrdinazioni.trim().length > 0)
                queryStringOrdinazioni = `?${queryStringOrdinazioni}`

            return getBigData('ordinazionis', queryStringOrdinazioni) 
        })
        .then( (ordinazioni) => {
            const { azienda } = this.props

            let filtroDipendenti =  [
                { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id },
                { 'campo': 'Profilo', 'operatore': '=', 'valore': 'dipendente' }
            ]
            let queryStringDipendenti = filterParser(filtroDipendenti)
            if (queryStringDipendenti.trim().length > 0)
                queryStringDipendenti = `?${queryStringDipendenti}`
            return getBigData('persones', queryStringDipendenti).then( (dipendenti) => { return { ordinazioni, dipendenti }})
        })
        .then( (res) => {
            const { ordinazioni, dipendenti } = res

            let totalePasti = 0
            let totaleCostoAzienda = 0
            let totaleCostoDipendente = 0

            // recupero le ordinazioni fatte dal dipendente nel periodo indicato
            dipendenti.sort( (a, b) => {
                if (a['Cognome'] < b['Cognome'])
                    return -1
                if (a['Cognome'] > b['Cognome'])
                    return 1
                if (a['Nome'] < b['Nome'])
                    return -1
                if (a['Nome'] > b['Nome'])
                    return 1
                return 0
            })
            .forEach( (dipendente) => {
                
                let totaleDipendente = 0
                let costoTotaleDipendente = 0 
                let costoTotaleAzienda = 0

                const ordinazioniDipendente = ordinazioni.filter( (ordinazione) => {
                    
                    return ordinazione["Persone"] && ordinazione["Persone"]._id === dipendente._id
                })

                // creo l'oggetto che contiene i gruppi delle pietanze
                let gruppiOrdinati = gruppi.map( (g) => {
                    return { id: g._id, gruppo: g.Nome, quantita: 0 }
                })

                // scorro ogni ordinazione del dipendente e cerco le pietanze che appartengono ad ogni gruppo
                ordinazioniDipendente.forEach( (ordinazione) => {
                    const pietanze = ordinazione["Pietanze"]

                    if (pietanze.length > 0 ) {
                        pietanze.forEach( (pietanza) => {
                            const gruppoPietanza = pietanza["Gruppo"]

                            totalePasti += 1

                            gruppiOrdinati.forEach( (go) => {

                                let costoGruppoDipendente = costi.dipendente[go.id] ? parseFloat(costi.dipendente[go.id]).toFixed(2) : 0
                                let costoGruppoAzienda = costi.azienda[go.id] ? parseFloat(costi.azienda[go.id]).toFixed(2) : 0

                                if (gruppoPietanza === go.id) {
                                    go.quantita += 1

                                    go.costo = parseFloat( go.quantita * costoGruppoDipendente).toFixed(2)
                                    totaleDipendente += 1
                                    costoTotaleDipendente += parseFloat(costoGruppoDipendente)
                                    costoTotaleAzienda += parseFloat(costoGruppoAzienda)
                                }
                            })
                        })
                    }
                })

                totaleCostoAzienda += parseFloat(costoTotaleAzienda)
                totaleCostoDipendente += parseFloat(costoTotaleDipendente)

                data.corpo.push({ 'id': dipendente._id, 'dipendente': `${dipendente["Cognome"]} ${dipendente["Nome"]}`, 'gruppi': gruppiOrdinati, 'totali': totaleDipendente, 'totaleCostoDipendente': costoTotaleDipendente.toFixed(2), 'totaleCostoAzienda': costoTotaleAzienda.toFixed(2) } )
            })

            data.totali.push({ 'totalePasti': totalePasti, 'totaleCostoAzienda': totaleCostoAzienda.toFixed(2), 'totaleCostoDipendente': totaleCostoDipendente.toFixed(2) })

            this.setState({ data })

        })


    }

    visualizzaReport = () => {
        const { azienda, gruppi, combinazioni } = this.props
        const { filtro } = this.state

        Promise.resolve()
        .then( () =>  {

            let newFiltro = JSON.parse(JSON.stringify(filtro))
            const dataDal = getValoreCampoDaFiltro('campoUI', 'Dal', newFiltro) 
            const dataAl = getValoreCampoDaFiltro('campoUI', 'Al', newFiltro)
            newFiltro = setValoreCampoFiltro('campoUI', 'Dal', dataDal.valore, newFiltro)
            newFiltro = setValoreCampoFiltro('campoUI', 'Al', dataAl.valore, newFiltro)

            let queryStringOrdinazioni = filterParser(newFiltro)
            if (queryStringOrdinazioni.trim().length > 0)
                queryStringOrdinazioni = `?${queryStringOrdinazioni}`

            return getBigData('ordinazionis', queryStringOrdinazioni) 
        })
        .then( (ordinazioni) => {
            const { azienda } = this.props

            let filtroDipendenti =  [
                { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id },
                { 'campo': 'Profilo', 'operatore': '=', 'valore': 'dipendente' }
            ]
            let queryStringDipendenti = filterParser(filtroDipendenti)
            if (queryStringDipendenti.trim().length > 0)
                queryStringDipendenti = `?${queryStringDipendenti}`
            return getBigData('persones', queryStringDipendenti).then( (dipendenti) => { return { ordinazioni, dipendenti }})
        })
        .then( (res) => {
            const { ordinazioni, dipendenti } = res
            
            let data = this.recuperaCostiOrdinazioniDipendenti(azienda, ordinazioni, combinazioni, gruppi, dipendenti)

            // recupero i giorni di filtro
            const giornoDalStampa = getValoreCampoDaFiltro('campoUI', 'Dal', filtro)
            const giornoAlStampa = getValoreCampoDaFiltro('campoUI', 'Al', filtro)

            if ( giornoDalStampa && giornoDalStampa.valore)
                data['giornoDal'] = new Date(giornoDalStampa.valore)

            if ( giornoAlStampa && giornoAlStampa.valore)
                data['giornoAl'] = new Date(giornoAlStampa.valore)

            // scrive i dati per l'Excel
            let tempCsv = stampaReportCostiDipendentiExcel(data)

            console.log(tempCsv)
            
            this.setState({ data, dataCsv: tempCsv })

        })
    }

    // gestisce la stampa del report
    stampaReport = () => {
        const { data } = this.state
        
        stampaReportCostiDipendenti(data)
    }

    // recupera costi ordinazioni dipendenti
    // recupera le ordinazioni dei dipendenti e genera il JSON con i vari costi suddivisi per combinazioni
    // e gruppi di pasti
    recuperaCostiOrdinazioniDipendenti = (azienda, ordinazioni, combinazioni, gruppi, dipendenti) => {
        
        /*
        ALGORITMO:
        Per ogni dipendente
            - prendo le ordinazioni effettuate

            Per ogni ordinazione del dipendente
                - Recupero se esiste una combinazione che fa match con l'ordinazione
                - altrimenti recupero i costi dei gruppi
        
        DATI:
        I dati saranno composti da un array contenente le informazioni del dipendente, i costi delle combinazioni ed i costi dei gruppi

         */

        let data = {
            corpo: [],
            totali: {
                gruppi: [],
                combinazioni: []
            }
        }

        // opzione nascondi valori 0
        let visualizzaValoriZero = false

        // Recupero i costi dei gruppi una volta sola inizialmente
        let costiGruppiAzienda = []
        let costiGruppiDipendente = []
        gruppi.forEach( (g) => {
            let costoGruppoDipendente = getCostoGruppo( azienda["Costi pasti"], g.id, 'dipendente')
            let costoGruppoAzienda = getCostoGruppo( azienda["Costi pasti"], g.id, 'azienda')

            costoGruppoDipendente = costoGruppoDipendente ? costoGruppoDipendente["Valore"] : 0
            costoGruppoAzienda = costoGruppoAzienda ? costoGruppoAzienda["Valore"] : 0

            costiGruppiAzienda[g.id] = costoGruppoAzienda
            costiGruppiDipendente[g.id] = costoGruppoDipendente
        })
        
        // scorro i dipendenti
        dipendenti.sort( (a, b) => {
            if (a['Cognome'] < b['Cognome'])
                return -1
            if (a['Cognome'] > b['Cognome'])
                return 1
            if (a['Nome'] < b['Nome'])
                return -1
            if (a['Nome'] > b['Nome'])
                return 1
            return 0
        })
        .forEach( (dipendente) => {

            // recupero le ordinazioni effettuate dal dipendente
            const ordinazioniEffettuate = ordinazioni.filter( (o) => {
                return o["Persone"] && o["Persone"].id === dipendente.id
            })

            // inizializzo la riga del dipendente
            let rigaDipendente = { id: dipendente.id, 
                                   nome: dipendente["Nome"], 
                                   cognome: dipendente["Cognome"], 
                                   matricola: dipendente["Matricola"], 
                                   combinazioni: [],
                                   totalecombinazioni: { quantita: 0, costoDipendente: 0, costoAzienda: 0 },
                                   gruppi: [],
                                   totalegruppi: { quantita: 0, costoDipendente: 0, costoAzienda: 0 }
                                 }

            // inizializzo i valori dei gruppi per far vedere anche i valori a 0
            if ( visualizzaValoriZero === true) {
                gruppi.forEach( (g) => {
                    rigaDipendente.gruppi.push({
                        id: g.id,
                        nome: g["Nome"],
                        quantita: 0,
                        costo: 0,
                        costoDipendente: 0,
                        costoAzienda: 0
                    })
                })
            }
            
            // scorro le ordinazioni effettuate
            ordinazioniEffettuate.forEach( (o) => {

                // recupero la combinazione che fa match con l'ordinazione
                const combinazioneMatch = trovaCombinazioneOrdinazione(o, combinazioni)

                if ( combinazioneMatch ) {
                    // ho trovato una combinazione che fa match
                    // recupero i costi della combinazione

                    const combinazioneTrovata = rigaDipendente.combinazioni.find( (cp) => {
                        return cp.id === combinazioneMatch.id
                    })

                    let costoDipendenteDaAggiungere = parseFloat(combinazioneMatch["Prezzodipendente"])
                    let costoAziendaDaAggiungere = parseFloat(combinazioneMatch["Prezzoazienda"])

                    // se la combinazione è già presente devo aggiornare il contatore delle combinazioni presenti
                    if (combinazioneTrovata) {
                        // aggiorno la quantita

                        combinazioneTrovata.quantita += 1
                        combinazioneTrovata.costoDipendente = parseFloat(combinazioneTrovata.costoDipendente) + costoDipendenteDaAggiungere
                        combinazioneTrovata.costoDipendente = parseFloat(combinazioneTrovata.costoDipendente.toFixed(2))
                        combinazioneTrovata.costoAzienda = parseFloat(combinazioneTrovata.costoAzienda) + costoAziendaDaAggiungere
                        combinazioneTrovata.costoAzienda = parseFloat(combinazioneTrovata.costoAzienda.toFixed(2))

                    }
                    else {
                        // altrimenti devo aggiungere la nuova combinazione all'elenco

                        let costoDipendenteDaAggiungere = parseFloat(combinazioneMatch["Prezzodipendente"])
                        let costoAziendaDaAggiungere = parseFloat(combinazioneMatch["Prezzoazienda"])

                        rigaDipendente.combinazioni.push( { id: combinazioneMatch.id, 
                                                        nome: combinazioneMatch["Descrizione"],
                                                        quantita: 1, 
                                                        costoDipendente: costoDipendenteDaAggiungere.toFixed(2),
                                                        costoAzienda: costoAziendaDaAggiungere.toFixed(2) }
                                                    )
                    }

                    // aggiorno il totale delle combinazioni del dipendente
                    rigaDipendente.totalecombinazioni.quantita += 1
                    rigaDipendente.totalecombinazioni.costoDipendente = parseFloat(rigaDipendente.totalecombinazioni.costoDipendente) + costoDipendenteDaAggiungere
                    rigaDipendente.totalecombinazioni.costoDipendente = parseFloat(rigaDipendente.totalecombinazioni.costoDipendente.toFixed(2))
                    rigaDipendente.totalecombinazioni.costoAzienda = parseFloat(rigaDipendente.totalecombinazioni.costoAzienda) + costoAziendaDaAggiungere
                    rigaDipendente.totalecombinazioni.costoAzienda = parseFloat(rigaDipendente.totalecombinazioni.costoAzienda.toFixed(2))

                    // aggiorno il totale complessivo
                    const combinazioniTotaliTrovata = data.totali.combinazioni.find( (c) => {
                        return combinazioneMatch.id === c.id
                    })

                    if (combinazioniTotaliTrovata) {
                        // la combinazione è gia presente
                        combinazioniTotaliTrovata.quantita += 1
                        combinazioniTotaliTrovata.costoDipendente = parseFloat(combinazioniTotaliTrovata.costoDipendente) + costoDipendenteDaAggiungere
                        combinazioniTotaliTrovata.costoDipendente = parseFloat(combinazioniTotaliTrovata.costoDipendente.toFixed(2))
                        combinazioniTotaliTrovata.costoAzienda = parseFloat(combinazioniTotaliTrovata.costoAzienda) + costoAziendaDaAggiungere
                        combinazioniTotaliTrovata.costoAzienda = parseFloat(combinazioniTotaliTrovata.costoAzienda.toFixed(2))
                    }
                    else {
                        // la combinazione non è presente. devo aggiungerla
                        data.totali.combinazioni.push( { id: combinazioneMatch.id, 
                                                         nome: combinazioneMatch["Descrizione"], 
                                                         quantita: 1, 
                                                         costoDipendente: costoDipendenteDaAggiungere,
                                                         costoAzienda: costoAziendaDaAggiungere
                                                        })
                    }

                }
                else {
                    // non esiste nessuna combinazione che fa match
                    // recupero i costi dei gruppi

                    // recupero le pietanze dell'ordinazione
                    const pietanze = o["Pietanze"]

                    if (pietanze.length > 0 ) {
                        // per ogni pietanza recupero il gruppo
                        pietanze.forEach( (pietanza) => {
                            // il gruppo è composto dal campo id e non dall'intera collezione,
                            // quindi devo filtrare tra i gruppi quello che ha l'id del gruppo selezionato

                            const idGruppoPietanza = pietanza["Gruppo"]

                            const gruppoPietanza = gruppi.find( (g) => {
                                return g.id === idGruppoPietanza
                            })

                            if ( gruppoPietanza ) {
                                // il gruppo della pietanza esiste
                                // controllo se il gruppo è già presente nel risultato
                                const gruppoRisultato = rigaDipendente.gruppi.find( (g) => {
                                    return g.id === gruppoPietanza.id
                                })

                                let costoDipendenteDaAggiungere = parseFloat(costiGruppiDipendente[gruppoPietanza.id])
                                let costoAziendaDaAggiungere = parseFloat(costiGruppiAzienda[gruppoPietanza.id])

                                // se il gruppo esiste già nel risultato devo solamente aggiornare il contatore e il costo
                                if (gruppoRisultato) {

                                    gruppoRisultato.quantita += 1
                                    gruppoRisultato.costoDipendente = parseFloat(gruppoRisultato.costoDipendente) + costoDipendenteDaAggiungere
                                    gruppoRisultato.costoDipendente = parseFloat(gruppoRisultato.costoDipendente.toFixed(2))
                                    gruppoRisultato.costoAzienda = parseFloat(gruppoRisultato.costoAzienda) + costoAziendaDaAggiungere
                                    gruppoRisultato.costoAzienda = parseFloat(gruppoRisultato.costoAzienda.toFixed(2))

                                }
                                else {
                                    // devo aggiungere il gruppo
    
                                    // recupero il nome del gruppo
                                    const gruppoCorrente = gruppi.find( (g) => {
                                        return g.id === idGruppoPietanza
                                    })
    
                                    rigaDipendente.gruppi.push({
                                        id: idGruppoPietanza,
                                        nome: gruppoCorrente && gruppoCorrente["Nome"] ? gruppoCorrente["Nome"] : "",
                                        ordinamento: gruppoCorrente["Ordinamento"] ? gruppoCorrente["Ordinamento"] : 0,
                                        quantita: 1,
                                        costoDipendente: parseFloat(costiGruppiDipendente[idGruppoPietanza]), 
                                        costoAzienda: parseFloat(costiGruppiAzienda[idGruppoPietanza])
                                    })
                                }

                                rigaDipendente.totalegruppi.quantita += 1
                                rigaDipendente.totalegruppi.costoDipendente = parseFloat(rigaDipendente.totalegruppi.costoDipendente) + costoDipendenteDaAggiungere
                                rigaDipendente.totalegruppi.costoDipendente = parseFloat(rigaDipendente.totalegruppi.costoDipendente.toFixed(2))
                                rigaDipendente.totalegruppi.costoAzienda = parseFloat(rigaDipendente.totalegruppi.costoAzienda) + costoAziendaDaAggiungere
                                rigaDipendente.totalegruppi.costoAzienda = parseFloat(rigaDipendente.totalegruppi.costoAzienda.toFixed(2))

                                // aggiorno il totale complessivo
                                const gruppoTotaliTrovato = data.totali.gruppi.find( (g) => {
                                    return g.id === gruppoPietanza.id
                                })

                                if (gruppoTotaliTrovato) {
                                    // il gruppo è già presente nei totali. aggiorno solamente i dati

                                    gruppoTotaliTrovato.quantita += 1
                                    gruppoTotaliTrovato.costoDipendente = parseFloat(gruppoTotaliTrovato.costoDipendente) + costoDipendenteDaAggiungere
                                    gruppoTotaliTrovato.costoDipendente = parseFloat(gruppoTotaliTrovato.costoDipendente.toFixed(2))
                                    gruppoTotaliTrovato.costoAzienda = parseFloat(gruppoTotaliTrovato.costoAzienda) + costoAziendaDaAggiungere
                                    gruppoTotaliTrovato.costoAzienda = parseFloat(gruppoTotaliTrovato.costoAzienda.toFixed(2))

                                }   
                                else {
                                    // aggiungo il gruppo ai totali
                                    data.totali.gruppi.push( { id: gruppoPietanza.id, 
                                                               nome: gruppoPietanza["Nome"], 
                                                               quantita: 1,
                                                               ordinamento: gruppoPietanza["Ordinamento"] ? gruppoPietanza["Ordinamento"] : 0,
                                                               costoDipendente: costoDipendenteDaAggiungere,
                                                               costoAzienda: costoAziendaDaAggiungere
                                                            })
                                }
                            }
                        })
                    }

                }
            })

            if ( ordinazioniEffettuate.length > 0 || visualizzaValoriZero === true )
                data.corpo.push(rigaDipendente)
        })

        console.log(data)
        return data
    }


  render() {

    const { filtro, data, dataCsv } = this.state
    const dataInizio = getValoreCampoDaFiltro('campoUI', 'Dal', filtro)
    const dataFine = getValoreCampoDaFiltro('campoUI', 'Al', filtro)

    // il valore che ritorna il metodo render deve essere sempre un oggetto solo.
    // Non possono essere più elementi HTML paralleli
    return (
        <ScaffoldBase title="Riepilogo mensile" subtitle="Costi dipendenti">

            <GridContainer>
            <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                    labelText="Dal"
                    id="dal"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                        name: "Dal",
                        type: "Date",
                        value: dataInizio ? formatDateToYYYYMMDD(dataInizio.valore) : undefined,
                        onChange: this.handleChange
                    }}
                    labelProps={{
                        shrink: true
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={4} md={4} lg={4}>
                <CustomInput
                    labelText="Al"
                    id="al"
                    formControlProps={{ fullWidth: true }}
                    inputProps={{
                        name: "Al",
                        type: "Date",
                        value: dataFine ? formatDateToYYYYMMDD(dataFine.valore) : undefined,
                        onChange: this.handleChange
                    }}
                    labelProps={{
                        shrink: true
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={2} md={2} lg={2} >
                <Button type="submit" color="success" style={{ marginTop: "27px"}} onClick={this.visualizzaReport}>Visualizza</Button>
                { data && data.corpo && data.corpo.length > 0 && (
                    <Button type="submit" color="info" size="sm" onClick={this.stampaReport} style={{ marginTop: "27px" }}>Stampa report</Button>
                )}
                { dataCsv && dataCsv.length > 0 && (
                    <ExcelFile element={<Button color="info" size="sm" style={{ marginTop: "27px" }}>Stampa report Excel</Button>}>
                        <ExcelSheet data={dataCsv} name="Report Costi">
                            <ExcelColumn label="Nominativo" value="cognomeNome"/>
                            <ExcelColumn label="Pasto" value="nome"/>
                            <ExcelColumn label="Q.tà" value="quantita"/>
                            <ExcelColumn label="Dipendente" value="costoDipendente"/>
                            <ExcelColumn label="Azienda" value="costoAzienda"/>
                        </ExcelSheet>
                    </ExcelFile>
                )}
            </GridItem>
        </GridContainer>
        <GridContainer>
            { data.corpo && data.corpo.length > 0 && (
                <div style={{ width: "100%"}}>
                    <GridContainer style={styleHeader}>
                        <GridItem xs={3} sm={3} md={3} lg={3} style={styleCells}>Dipendente</GridItem>
                        <GridItem xs={3} sm={3} md={3} lg={3} style={styleCells}>Pasto</GridItem>
                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>Q.tà</GridItem>
                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>Costo dipendente</GridItem>
                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>Costo azienda</GridItem>
                    </GridContainer>

                    { data.corpo.map( (riga) => {
                        let combinazioni = riga.combinazioni
                        let gruppi = riga.gruppi

                        return (
                            <div key={riga.id}>
                                { combinazioni.map( (c, idx) => {
                                    return (
                                        <GridContainer key={c.id}>
                                            <GridItem xs={3} sm={3} md={3} lg={3} style={styleCells}>{combinazioni.length > 0 && idx === 0 ? `${riga.cognome} ${riga.nome}` : "" }</GridItem>
                                            <GridItem xs={3} sm={3} md={3} lg={3} style={styleCells}>{c.nome}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{c.quantita}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{c.costoDipendente ? c.costoDipendente : 0 }</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{ c.costoAzienda ? c.costoAzienda : 0 }</GridItem>
                                        </GridContainer>
                                    )}
                                )}
                                { combinazioni && combinazioni.length > 0 && (
                                    <GridContainer style={styleTotali}>
                                        <GridItem xs={6} sm={6} md={6} lg={6} style={styleCells}>Totale combinazioni</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{riga.totalecombinazioni.quantita}</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{riga.totalecombinazioni.costoDipendente ? riga.totalecombinazioni.costoDipendente : 0 }</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{ riga.totalecombinazioni.costoAzienda ? riga.totalecombinazioni.costoAzienda : 0 }</GridItem>
                                    </GridContainer>
                                )}

                                { gruppi.sort( (a, b) => { 
                                    if (a.ordinamento < b.ordinamento)
                                        return -1
                                    if (a.ordinamento > b.ordinamento)
                                        return 1
                                    return 0
                                })
                                .map( (g, idx) => {
                                    return (
                                        <GridContainer key={g.id}>
                                            <GridItem xs={3} sm={3} md={3} lg={3} style={styleCells}>{ combinazioni.length === 0 && idx === 0 ? `${riga.cognome} ${riga.nome}` : ""}</GridItem>
                                            <GridItem xs={3} sm={3} md={3} lg={3} style={styleCells}>{g.nome}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{g.quantita}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{ g.costoDipendente ? g.costoDipendente: 0}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{ g.costoAzienda ? g.costoAzienda : 0}</GridItem>
                                        </GridContainer> 
                                    )
                                })}

                                { gruppi && gruppi.length > 0 && (
                                    <GridContainer style={styleTotali}>
                                        <GridItem xs={6} sm={6} md={6} lg={6} style={styleCells}>Totale gruppi</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{riga.totalegruppi.quantita}</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{riga.totalegruppi.costoDipendente ? riga.totalegruppi.costoDipendente : 0 }</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{ riga.totalegruppi.costoAzienda ? riga.totalegruppi.costoAzienda : 0 }</GridItem>
                                    </GridContainer>
                                )}
                            </div>
                        )
                    })}

                    { data.totali && data.totali.combinazioni && data.totali.combinazioni.length > 0 && (
                        <div>
                            { data.totali.combinazioni.map( (c) => {
                                return (
                                    <GridContainer key={c.id} style={styleFooter}>
                                        <GridItem xs={6} sm={6} md={6} lg={6} style={styleCells}>{`Totale ${c.nome}`}</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{c.quantita}</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{c.costoDipendente ? c.costoDipendente : 0 }</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{c.costoAzienda ? c.costoAzienda : 0 }</GridItem>
                                    </GridContainer>
                                )
                            })}
                        </div>
                    )}
                    { data.totali && data.totali.gruppi && data.totali.gruppi.length > 0 && (
                        <div>
                            { data.totali.gruppi.sort( (a, b) => {
                                if (a.ordinamento < b.ordinamento)
                                    return -1
                                if (a.ordinamento < b.ordinamento)
                                    return 1
                                return 0
                            })
                            .map( (g) => {
                                return (
                                    <GridContainer key={g.id} style={styleFooter}>
                                        <GridItem xs={6} sm={6} md={6} lg={6} style={styleCells}>{`Totale ${g.nome}`}</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{g.quantita}</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{g.costoDipendente ? g.costoDipendente : 0 }</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2} style={styleCells}>{g.costoAzienda ? g.costoAzienda : 0 }</GridItem>
                                    </GridContainer>
                                )
                            })}
                        </div>
                    )}
                </div>
            )}
        </GridContainer>
          
        </ScaffoldBase>
    );
  }
}

ReportPastiDipendenti.propTypes = {
    classes: PropTypes.object.isRequired
  };

function mapDispatchToProps( dispatch ) {
    return {
        caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro)),
        caricaCombinazioni: (filtro) => dispatch(fetchCombinazioni(filtro))
    }
}

function mapStateToProps({ authentication , gruppi, combinazioni }) {
    return { 
        azienda: authentication.user["Azienda"],
        gruppi: gruppi.items,
        combinazioni: combinazioni.items
    };
}

function stampaReportCostiDipendentiExcel(data) {
    
    const { corpo } = data

    let row = [], temp1, temp2
    corpo.forEach( (riga) => {

        const { cognome, nome, matricola, combinazioni, gruppi, totalecombinazioni, totalegruppi } = riga

        combinazioni.forEach( (c, idx) => {
            temp1 = idx === 0 ? `${cognome} ${nome} ${ matricola.trim().length > 0 ? ' - ('+matricola+')' : ""}` : ""
            row.push({ ...c, cognomeNome: temp1})
        })

        if (combinazioni.length > 0 ) {
            row.push({ ...totalecombinazioni, cognomeNome:"Totale",nome:"",ordinamento:"",id:""})
        }

        gruppi.forEach( (g, idx) => {
            temp2 = idx === 0 ? `${cognome} ${nome} ${ matricola.trim().length > 0 ? ' - ('+matricola+')' : ""}` : ""
            row.push({ ...g, cognomeNome: temp2 })
        })

        if (gruppi.length > 0 ) {
            row.push({ ...totalegruppi, cognomeNome:"Totale",nome:"",ordinamento:"",id:""})
        }
    })

    return row

}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ReportPastiDipendenti));
