import React, { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { withRouter } from "react-router";

// React-Redux reducers
import { connect } from "react-redux";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions"
import CircularProgress from "@material-ui/core/CircularProgress"

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";


// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { formatDateToYYYYMMDD, createNewDate } from "utils/date"
import { controllaUnicaPietanzaGruppo, controllaUnaPietanzaPerGruppo, disabilitaMenu } from "utils/ordinazioni"

import { fetchMenus } from 'actions/menu'
import { fetchOrdinazioni, addOrdinazione, updateOrdinazione, deleteOrdinazione } from 'actions/ordinazioni'
import { fetchGruppi } from 'actions/gruppi'
import SelectInput from "@material-ui/core/Select/SelectInput";

const gruppoHeaderStyle = {
    padding: "10px 10px",
    backgroundColor: "#7d6960",
    color: "white",
    width: "100%"
}

class MenuGiornaliero extends Component {

    state = {
        giorno : formatDateToYYYYMMDD(new Date()),
        menuPranzo: [],
        menuCena: [],
        ordinazioniPranzo: [],
        ordinazioniCena: [],
        messaggio: '',
        disabledButton: false,
        showCircularProgress: false
    }

    componentDidMount() {
        const { caricaGruppi } = this.props

        caricaGruppi()
        this.caricaMenu(this.state.giorno)
    }

    handleChange = (e) => {
        if (e.target.value) {
            //const giornoInizio = new Date(event.target.value)
            this.caricaMenu(e.target.value)
            
        }
    }

    // gestisce il check sulla pietanza
    handleCheck = (checked, tipologia, pietanza) => {
        const { ordinazioniPranzo, ordinazioniCena } = this.state
        const { azienda } = this.props

        let ordinazioni = tipologia === 'pranzo' ? ordinazioniPranzo : ordinazioniCena

        if ( !checked ) {
            // aggiungo alla lista

            let aggiungiPietanza = true
            let messaggio = ""

            if ( azienda["Flag unica pietanza gruppo"] === true) {
                aggiungiPietanza = controllaUnicaPietanzaGruppo(pietanza, ordinazioni)
                messaggio = "Puoi ordinare al massimo una pietanza per gruppo"
            }
                
            if ( aggiungiPietanza === true)
                ordinazioni.push(pietanza)
            else {
                if (messaggio && messaggio.length > 0 )
                    this.setState({ messaggio })
            }
        }
        else { 
            // tolgo dalla lista
            ordinazioni = ordinazioni && ordinazioni.filter( (o) => {
                return o._id !== pietanza._id
            })
        }

        if (tipologia === 'pranzo')
            this.setState({ ordinazioniPranzo: ordinazioni})
        else if ( tipologia === 'cena') 
            this.setState({ ordinazioniCena: ordinazioni })

    }

    // gestisce la conferma dell'ordinazione
    handleSubmit = ( tipologia ) => {

        this.setState({ disabledButton: true })

        const { ordinazioni, utenteLoggato, aggiornaOrdinazione, aggiungiOrdinazione, azienda, gruppi } = this.props
        const { giorno, menuPranzo, menuCena } = this.state

        const { ordinazioniPranzo, ordinazioniCena } = this.state

        const ordinazioniPrecedente = ordinazioni.items.filter( ( o ) => {
            return o["Pasto"] === tipologia
        })

        const ordinazionePrecedente = ordinazioniPrecedente && ordinazioniPrecedente.length > 0 ? ordinazioniPrecedente[0] : null
        
        const pietanzeDaConfermare = tipologia === 'pranzo' ? ordinazioniPranzo : ordinazioniCena

        if (this.state.disabledButton === true)
            return

        if (ordinazionePrecedente) {
            ordinazionePrecedente["Pietanze"] = pietanzeDaConfermare.map( (p) => { return p._id})

            if ( !ordinazionePrecedente["Persone"] && ordinazionePrecedente["Persone"].id.length <= 0) {
                this.setState({ messaggio: "Si è verificato un problema nel salvataggio dell'ordinazione. Contatta l'amministratore."})
            }
            else {
                Promise.resolve()
                .then( aggiornaOrdinazione(ordinazionePrecedente._id, ordinazionePrecedente))
                .then( () => this.caricaMenu(formatDateToYYYYMMDD(giorno)))
                .then( () => this.setState({ messaggio: 'Ordinazione modificata'}))
            }
            
        }
        else {

            let aggiungi = true

            // effettuo il controllo di una pietanza per gruppo
            if (azienda["Flag unica pietanza gruppo"] === true && azienda["Flag pietanza obbligatoria gruppo"]) {
                const menu = tipologia === 'pranzo' ? menuPranzo : menuCena
                aggiungi = controllaUnaPietanzaPerGruppo(pietanzeDaConfermare, menu, gruppi)
            }

            if (aggiungi === true) {

                let ordinazioneNuova = {
                    "Giorno": new Date(giorno),
                    "Data": formatDateToYYYYMMDD(giorno),
                    "Persone": utenteLoggato._id,
                    "Stato": "inoltrata",
                    "Pietanze" : pietanzeDaConfermare.map( (p) => { return p._id }),
                    "Pasto": tipologia,
                    "Azienda": azienda._id
                }

                if ( !ordinazioneNuova["Persone"] || ordinazioneNuova["Persone"].length <= 0) {
                    this.setState({ messaggio: "Nessuna persona associata a questa ordinazione"})
                }
                else {
                    Promise.resolve()
                    .then(aggiungiOrdinazione(ordinazioneNuova))
                    .then( () => this.caricaMenu(giorno))
                    .then( () => this.setState({ messaggio: 'Ordinazione effettuata' }))
                }
            }
            else {
                this.setState({ messaggio: 'Devi scegliere almeno una pietanza per ogni gruppo' })
            }
        }
    }

    handleDelete = ( giorno, tipologia) => {
        this.setState({ showCircularProgress: true })

        const { ordinazioni, cancellaOrdinazione } = this.props

        setTimeout( () => {
            let giornocorrente = createNewDate(giorno)
            giornocorrente.setMinutes(giornocorrente.getMinutes() - giornocorrente.getTimezoneOffset())
            giornocorrente = formatDateToYYYYMMDD(giornocorrente)

            const ordinazioniGiorno = ordinazioni && ordinazioni.items && ordinazioni.items.filter( (o) => {
                return o["Data"] === giorno && o["Pasto"] === tipologia
            })

            const ordinazioneDaCancellare = ordinazioniGiorno && ordinazioniGiorno.length > 0 ? ordinazioniGiorno[0] : null

            if ( ordinazioneDaCancellare && ordinazioneDaCancellare._id) {
                Promise.resolve()
                .then( cancellaOrdinazione(ordinazioneDaCancellare._id) )
                .then( () => this.caricaMenu(giornocorrente))
                .then( () => this.setState({ messaggio: 'Ordinazione cancellata', showCircularProgress: false }))
            }
            else {
                this.setState({ showCircularProgress: false })
            }
        }, 500)
        
    }

    parsaOrdinazioni = ( ordinazioni ) => {

        let res = {
            pranzo: [],
            cena: []
        }

        const pranzi = ordinazioni.filter( (o) => {
            return o["Pasto"] === 'pranzo'
        })

        let pranzo = pranzi && pranzi.length > 0 ? pranzi[0] : null
        const pietanzePranzo = pranzo && pranzo["Pietanze"].map( (p) => {
            return p
        })

        const cene = ordinazioni.filter( (o) => {
            return o["Pasto"] === 'cena'
        })

        let cena = cene && cene.length > 0 ? cene[0] : null
        const pietanzeCena = cena && cena["Pietanze"].map( (p) => {
            return p
        })

        res['pranzo'] = pietanzePranzo ? pietanzePranzo : []
        res['cena'] = pietanzeCena ? pietanzeCena : []

        return res
    }

    recuperaMenuPasto = ( pasto ) => {
        const { menu } = this.props

        const menusPasto =  menu && menu.items && menu.items.length > 0 && menu.items.filter( (m) => {
            return m["Pasto"] === pasto;
        })

        const menuPasto = menusPasto && menusPasto.length > 0 ? menusPasto[0] : []

        return menuPasto
    }

    caricaMenu = ( giorno ) => {
            const { caricaMenus, caricaOrdinazioni, utenteLoggato, azienda } = this.props

            Promise.resolve()
            .then( () => {
                // devo caricare i menu del giorno.
                const filtroMenu = [ 
                    { campo: 'Data', operatore: '=', valore: giorno},
                    { campo: 'Azienda', operatore: '=', valore: azienda["Azienda riferimento"]}
                ]

                return caricaMenus(filtroMenu)
            })
            .then( () => {
                // carico le ordinazioni della persona nel giorno specificato
                const filtroOrdinazioni = [
                    { campo: 'Persone', operatore: '=', valore: utenteLoggato.id },
                    { campo: 'Data', operatore: '=', valore: giorno }
                ]

                return caricaOrdinazioni(filtroOrdinazioni)


            .then( () => {
                const menuPranzo = this.recuperaMenuPasto('pranzo')
                const menuCena = this.recuperaMenuPasto('cena')

                return { menuPranzo, menuCena }
            })
            .then( (a) => {

                const { ordinazioni } = this.props
                const { menuPranzo, menuCena } = a

                let ordinato = this.parsaOrdinazioni(ordinazioni.items)
                return { ordinato , menuPranzo, menuCena }
            } )
            .then( ( res ) => { 

                const { menuPranzo, menuCena, ordinato } = res
                this.setState({
                    menuPranzo: menuPranzo,
                    menuCena: menuCena,
                    giorno: giorno,
                    ordinazioniPranzo: ordinato['pranzo'], 
                    ordinazioniCena: ordinato['cena'] }) 
                })
            })
    }

    disegnaMenu = (pasto, tipologia) => {
        const { classes, ordinazioni, gruppi, azienda } = this.props
        const { ordinazioniPranzo, ordinazioniCena } = this.state

        let ordinazioniPasto = tipologia === 'pranzo' ? ordinazioniPranzo : ordinazioniCena
        let ordinazioniAltroPasto = tipologia === 'pranzo' ? ordinazioniCena : ordinazioniPranzo
        
        ordinazioniPasto = ordinazioniPasto.map( (p) => { return p._id })

        const ordinazioniCorrentiPresenti = ordinazioni && ordinazioni.items && ordinazioni.items.filter( (o) => {
            return o["Pasto"] === tipologia
        })

        const ordinazioniAltroPastoPresenti = ordinazioni && ordinazioni.items && ordinazioni.items.filter( (o) => {
            return o["Pasto"] !== tipologia
        })

        const labelPulsanteOrdina = ordinazioniCorrentiPresenti && ordinazioniCorrentiPresenti.length > 0 ? 'Modifica' : 'Ordina'

        const { "Ora ultima prenotazione pranzo": oraUltimaPrenotazionePranzo, 
                "Ora ultima prenotazione cena": oraUltimaPrenotazioneCena } = azienda

        let oraUltimaPrenotazione = ""

        if (tipologia === 'pranzo')
            oraUltimaPrenotazione = oraUltimaPrenotazionePranzo
        else if (tipologia === 'cena') {
            oraUltimaPrenotazione = oraUltimaPrenotazioneCena
        }


        // controllo se il menu è disabilitato perchè è chiuso la possibilità di ordinare
        let adesso = new Date()

        let dataOraScadenzaPrenotazione = new Date(pasto["Data"])

        if (oraUltimaPrenotazione.trim().length > 0) {
            const hours = oraUltimaPrenotazione.substring(0,2)
            const minutes = oraUltimaPrenotazione.substring(3,5)

            dataOraScadenzaPrenotazione.setTime( dataOraScadenzaPrenotazione.getTime() + (1* 60 * 60 * 1000))
            dataOraScadenzaPrenotazione.setMilliseconds(0)
            dataOraScadenzaPrenotazione.setSeconds(0)
            dataOraScadenzaPrenotazione.setMinutes(minutes)
            dataOraScadenzaPrenotazione.setHours(hours)
        }

        let menuDisabilitatoOrario =  dataOraScadenzaPrenotazione && dataOraScadenzaPrenotazione.getTime() < adesso.getTime() ? true : false

        let azioneDisabilitata = disabilitaMenu(pasto["Data"], oraUltimaPrenotazione, ordinazioniAltroPasto, ordinazioniAltroPastoPresenti)

        return (
            <ExpansionPanel defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography className={classes.heading}>{tipologia}</Typography>
                </ExpansionPanelSummary>
                { pasto && pasto["Pietanze"] && pasto["Pietanze"].length > 0 && (
                    <div>
                        <ExpansionPanelDetails style={{ display: "inline-block", width: "100%" }}>
                            { gruppi && gruppi.map( (g) => {
                                
                                const pietanzeGruppo = pasto["Pietanze"].filter( (p) => {
                                    return g._id === p["Gruppo"]._id || g._id === p["Gruppo"]
                                })

                                return (    
                                        <div key={g._id}>
                                            <FormControl fullWidth={true}>
                                                <FormLabel style={gruppoHeaderStyle}>{g["Nome"]}</FormLabel>
                                                <FormGroup>
                                                    { pietanzeGruppo && pietanzeGruppo.map( (p) => {
                                                        const checked = ordinazioniPasto && ordinazioniPasto.includes(p._id) ? true : false

                                                        return (
                                                            <FormControlLabel key={p._id}
                                                                control={
                                                                    <Checkbox
                                                                    disabled={azioneDisabilitata}
                                                                    checked={checked}
                                                                    onChange={() => this.handleCheck(checked, tipologia, p)}
                                                                    color="primary"
                                                                    />
                                                                }
                                                                label={p["Nome"]}
                                                            />
                                                            )
                                                        }
                                                        )
                                                    }
                                                </FormGroup>
                                            </FormControl>
                                        </div>
                                    )
                                })
                            }
                        </ExpansionPanelDetails>
                        {
                            ( (!menuDisabilitatoOrario) && ((ordinazioniCorrentiPresenti && ordinazioniCorrentiPresenti.length > 0) || 
                             (ordinazioniPasto && ordinazioniPasto.length > 0 && azioneDisabilitata === false))) && (
                                <ExpansionPanelActions>
                                    {
                                    ordinazioniCorrentiPresenti && ordinazioniCorrentiPresenti.length > 0 && (
                                    <Button type="submit" color="danger" onClick={() => this.handleDelete(pasto["Data"], tipologia)}>Cancella</Button>
                                    )}
                                    <Button disabled={this.state.disabledButton} type="submit" color="success" onClick={() => this.handleSubmit(tipologia)}>{labelPulsanteOrdina}</Button>
                                </ExpansionPanelActions>
                            )
                        }
                    </div>
                )}
                {!pasto && (
                    <ExpansionPanelDetails>
                        <Typography>Menu non definito</Typography>
                    </ExpansionPanelDetails>
                )}
            </ExpansionPanel>
        )
    }

    // gestisco la chiusura dello snack
    handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        this.setState({ messaggio: '', disabledButton: false });
      };
    
    // gestisco l'uscita dallo snack
    handleExitedScnak = () => {
    this.setState({ messaggio: ''})
    };

    render() {

        const { menuPranzo, menuCena, showCircularProgress } = this.state
        const { classes, azienda } = this.props
        
        return (
            <ScaffoldBase title="Menu del giorno" subtitle="Seleziona il giorno per scegliere il menu">
                { !showCircularProgress && showCircularProgress === false && (
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                            <CustomInput
                                labelText="Giorno"
                                id="giorno"
                                formControlProps={{ fullWidth: true }}
                                inputProps={{
                                name: "Giorno",
                                type: "Date",
                                value: this.state.giorno,
                                onChange: this.handleChange
                                }}
                            />
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            { azienda && azienda["Tipologia pasti"] && (azienda["Tipologia pasti"] === 'pranzo' || azienda["Tipologia pasti"] === 'pranzo/cena') && (
                                <GridItem xs={12} sm={12} md={12} lg={6}>
                                    { this.disegnaMenu( menuPranzo, 'pranzo') }
                                </GridItem>
                            )}

                            { azienda && azienda["Tipologia pasti"] && (azienda["Tipologia pasti"] === 'cena' || azienda["Tipologia pasti"] === 'pranzo/cena') && (
                                <GridItem xs={12} sm={12} md={12} lg={6}>
                                    { this.disegnaMenu( menuCena, 'cena') }
                                </GridItem>
                            )}
                        </GridContainer>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                            autoHideDuration={3000}
                            onClose={this.handleCloseSnack}
                            onExited={this.handleExitedSnack}
                            ContentProps={{
                                'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{this.state.messaggio}</span>}
                            action={[
                                <IconButton
                                    key="close"
                                    aria-label="Close"
                                    color="inherit"
                                    className={classes.close}
                                    onClick={this.handleCloseSnack}
                                >
                                    <CloseIcon />
                                </IconButton>
                            ]}
                        />
                    </div>
                )}

                { showCircularProgress === true && (
                    <CircularProgress />
                )}
                
            </ScaffoldBase>
        )
    }
}

MenuGiornaliero.propTypes = {
    classes: PropTypes.object.isRequired
  };

function mapDispatchToProps( dispatch ) {
    return {
        caricaMenus: (filtro) => dispatch(fetchMenus(filtro)),
        aggiungiOrdinazione: (ordinazione) => dispatch(addOrdinazione(ordinazione)),
        aggiornaOrdinazione: (id, ordinazione) => dispatch(updateOrdinazione(id, ordinazione)),
        cancellaOrdinazione: (id) => dispatch(deleteOrdinazione(id)),
        caricaOrdinazioni: (filtro) => dispatch(fetchOrdinazioni(filtro)),
        caricaGruppi: () => dispatch(fetchGruppi())
    }
}

function mapStateToProps({ menu, authentication, ordinazioni, gruppi}) {
    return { 
        menu, 
        utenteLoggato: authentication.user,
        azienda: authentication.user["Azienda"],
        ordinazioni,
        gruppi: gruppi.items
    };
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(MenuGiornaliero));