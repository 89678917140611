/* STRUTTURA DEL CAMPO JSON 
Costi:
{
    Costo azienda: {
        Pasto completo: {
            Misura: '',
            Valore: ''
        },
        Gruppi: [
            { 
              Gruppo: '',
              Misura: '',
              Valore: ''
            },
            { 
              Gruppo: '',
              Misura: '',
              Valore: ''
            }
        ]

    },
    Costo dipendente: {

    }
}
*/


/* campoCosto = campo del database con dentro tutti i valori 
   idGruppo = id del gruppo da cercare
   spettanza = indica se il costo da reperire è a carico dell'azienda o del dipendente
*/
export function getCostoGruppo(campoCosto, idGruppo, spettanza) {

    if (!campoCosto)
        return []
        
    let res = { 'Misura': '', 'Valore': '' }

    if (idGruppo.trim() === '' || spettanza.trim() === '')
        return res

    const arrayGruppi = spettanza === 'azienda' 
        ? campoCosto["Costo azienda"] && campoCosto["Costo azienda"]["Gruppi"] ? campoCosto["Costo azienda"]["Gruppi"] : [] 
        : campoCosto["Costo dipendente"] && campoCosto["Costo dipendente"]["Gruppi"] ? campoCosto["Costo dipendente"]["Gruppi"] : []

    const gruppoTrovato = arrayGruppi.find( (g) => {
        return g.Gruppo === idGruppo
    })

    if (gruppoTrovato)
        res = gruppoTrovato

    return res
}

/* campoCosto = campo del database con dentro tutti i valori 
   idGruppo = id del gruppo da cercare
   valore = { 'Misura': 'x', 'Valore': 'y' }
   spettanza = indica se il costo da reperire è a carico dell'azienda o del dipendente
*/
export function setCostoGruppo(campoCosto, idGruppo, valore, spettanza) {

    const campoSpettanza = spettanza === 'azienda' ? "Costo azienda" : "Costo dipendente"

    if ( campoCosto[campoSpettanza] && campoCosto[campoSpettanza]["Gruppi"]) {
        campoCosto[campoSpettanza]["Gruppi"].forEach( (g) => {
            if (g.Gruppo === idGruppo) {
                g["Valore"] = valore.Valore
            }
        })
    }
}

// inizializza i costi dei Gruppi o ritorna il costo già valorizzato del cliente
export function inizializzaCostiGruppi(gruppi, campoCosto) {
    let res = { }

    // se esiste già il campo Costi pasti
    if (campoCosto)
        return campoCosto

    res = {
        "Costo azienda" : {
            "Gruppi" : []
        },
        "Costo dipendente" : {
            "Gruppi" : []
        }
    }

    gruppi.forEach(gruppo => {

        res["Costo azienda"]["Gruppi"] = [ ...res["Costo azienda"]["Gruppi"], { 'Gruppo': gruppo._id, 'Misura': '', 'Valore': 0 }]
        res["Costo dipendente"]["Gruppi"] = [ ...res["Costo dipendente"]["Gruppi"], { 'Gruppo': gruppo._id, 'Misura': '', 'Valore': 0 }]
    });

    return res
}