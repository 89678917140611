import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api';

export const GET_MENUS = "GET_MENUS";
export const GET_MENU = "GET_MENU";
export const DELETE_MENU = "DELETE_MENU";


export const getMenus = menu => ({
  type: GET_MENUS,
  items: menu
});

// recupera i menu
export const fetchMenus = (filter) => dispatch => {

    let queryString = filterParser(filter)

    if (queryString.length > 0 )
      queryString = '?'+queryString

    return fetch(`${REACT_APP_BACKEND}menus${queryString}` )
    .then( (res) => { 
    return res.status === 200 ? res.text() : null;
    })
    .then( (data) => { 
    let menu = null;

    try {
    menu = JSON.parse(data);
    menu = menu !== null ? menu : null;
    } catch(e) {
    menu = null;
    } finally {
      dispatch(getMenus(menu));
      return menu
    }
    })
  }

  export const azzeraMenu = () => dispatch => {
    dispatch(getMenus(null))
  }

export const fetchMenusFromDateToDate = ( startDate, endDate ) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}menus?Giorno_gte=${startDate}&Giorno_lte=${endDate}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
    .then( (data) => {
      
      let menu = null;

      try {
        menu = JSON.parse(data);
        menu = menu !== null ? menu : null;
      } catch(e) {
        menu = null;
      } finally {
        dispatch(getMenus(menu));
      }
    })
}

export const getMenu = menu => ({
  type: GET_MENU,
  menu
})

export const fetchMenu = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}menus/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let menu = null;

    try {
        menu = JSON.parse(data);
        menu = menu !== null ? menu : null;
    } catch(e) {
        menu = null;
    } finally {
      dispatch(getMenu(menu));
    }
  })
)

// aggiunge un menu
export const addMenu = ( menu ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}menus`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(menu)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let menu = null;

    try {
        menu = JSON.parse(data);
        menu = menu !== null ? menu : null;
    } catch(e) {
        menu = null;
    } finally {
      return menu;
    }
  })
)

// aggiorna il menu identificato dall'id passato
export const updateMenu = ( id, menu ) => dispatch => {
  fetch(`${REACT_APP_BACKEND}menus/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(menu)
  })
  .then( () => dispatch(fetchMenu(id)))
}

// cancello il menu identificato dall'id passato
export const deleteMenu = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}menus/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
)

// aggiungi pietanza al menu
export const aggiungiPietanza = ( pietanzaId, menu) => dispatch => {

  // recupero le pietanze per controllare che la pietanza non sia inserita due volte
  let aggiungi = true;
  if ( menu["Pietanze"] && menu["Pietanze"].length > 0 ) {
    // prendo tutti gli id delle pietanze
    let pietanze = menu["Pietanze"].map( ( p ) => {
      return p._id;
    })

    // controllo se l'id è già presente
    let pietanzeGiaPresenti = pietanze.filter( (p) => {
      return p === pietanzaId
    })

    if (pietanzeGiaPresenti.length > 0 )
      aggiungi = false;
  }

  // aggiungo la pietanza
  if (aggiungi === true)
    menu["Pietanze"] = [ ...menu["Pietanze"], pietanzaId ]

  // aggiorno il menu
  dispatch(updateMenu(menu._id, menu))
}


// elimina pietanza
export const togliPietanza = ( pietanzaId, menu) => dispatch => {
  if ( !menu["Pietanze"] || menu["Pietanze"].length <= 0 )
    return;

  // prendo le pietanze filtrando l'id
  menu["Pietanze"] = menu["Pietanze"].filter( (p) => {
    return p._id !== pietanzaId
  })

  // aggiorno il menu
  dispatch(updateMenu(menu._id, menu))
}