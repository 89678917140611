import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api.js';

export const GET_GIRICONSEGNA = "GET_GIRICONSEGNA";

export const getGiriconsegna = giri => ({
  type: GET_GIRICONSEGNA,
  items: giri
});

// recupera gli allergeni
export const fetchGiriconsegna = (filter=[]) => dispatch => {

    let queryString = filterParser(filter)

    if (queryString.length > 0 ) {
        queryString = `?${queryString}`
    }

    return fetch(`${REACT_APP_BACKEND}giriconsegnas${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
    .then( (data) => { 
        let giri = null;

        try {
            giri = JSON.parse(data);
            giri = giri !== null ? giri : null;
        } catch(e) {
            giri = null;
        } finally {
            dispatch(getGiriconsegna(giri));
            return giri
        }
    })
}