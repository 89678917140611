// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Person from "@material-ui/icons/Person";
import Assignment from "@material-ui/icons/Assignment";
import RestaurantMenu from "@material-ui/icons/RestaurantMenu";
import ExitToApp from "@material-ui/icons/ExitToApp";

// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";

// project's components
import Profilo from "views/Profilo/Profilo";
import MenuGiornaliero from "views/Dipendente/Ordinazioni/MenuGiornaliero";
import ListaOrdinazioni from "views/Dipendente/Ordinazioni/ListaOrdinazioni";
import Logout from "layouts/Logout/Logout";


const employeeRoutes = [
    {
        path: "/dashboard/profilo",
        sidebarName: "Profilo",
        navbarName: "Profilo",
        icon: Person,
        component: Profilo
    },
    {
        path: "/dashboard/menu-giornaliero",
        sidebarName: "Menu",
        navbarName: "Menu del giorno",
        icon: RestaurantMenu,
        component: MenuGiornaliero
    },
    {
        path: "/dashboard/ordinazioni",
        sidebarName: "Ordinazioni",
        navbarName: "Storico ordinazioni",
        icon: Assignment,
        component: ListaOrdinazioni
    },
    {
        path: "/dashboard/logout",
        sidebarName: "Logout",
        navbarName: "Logout",
        icon: ExitToApp,
        component: Logout
    },
    {
        path: "/dashboard",
        sidebarName: "Dashboard",
        navbarName: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        invisible: true
    }
];

export default employeeRoutes;