import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api.js';

export const GET_COMBINAZIONI = "GET_COMBINAZIONI";
export const GET_COMBINAZIONE = "GET_COMBINAZIONE";
export const DELETE_COMBINAZIONE = "DELETE_COMBINAZIONE";

export const getCombinazioni = combinazioni => ({
  type: GET_COMBINAZIONI,
  items: combinazioni
});

// recupera le combinazioni
export const fetchCombinazioni = (filter=[]) => dispatch => {

    let queryString = filterParser(filter)

    if (queryString.length > 0 )
      queryString = `?${queryString}`

    return fetch(`${REACT_APP_BACKEND}combinazionis${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
	  .then( (data) => { 
      let combinazioni = null;

      try {
        combinazioni = JSON.parse(data);
        combinazioni = combinazioni !== null ? combinazioni : null;
      } catch(e) {
        combinazioni = null;
      } finally {
        dispatch(getCombinazioni(combinazioni));
        return combinazioni
      }
    })
}

export const getCombinazione = combinazione => ({
  type: GET_COMBINAZIONE,
  combinazione
})

export const fetchCombinazione = (id) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}combinazionis/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let combinazione = null;

    try {
        combinazione = JSON.parse(data);
        combinazione = combinazione !== null ? combinazione : null;
    } catch(e) {
        combinazione = null;
    } finally {
      dispatch(getCombinazione(combinazione));
      return combinazione
    }
  })
}

// aggiunge una combinazione
export const addCombinazione = ( combinazione ) => dispatch => {
  return fetch(`${REACT_APP_BACKEND}combinazionis`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(combinazione)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let combinazione = null;

    try {
        combinazione = JSON.parse(data);
        combinazione = combinazione !== null ? combinazione : null;
    } catch(e) {
        combinazione = null;
    } finally {
      return combinazione;
    }
  })
}

// aggiorna la combinazione identificata dall'id passato
export const updateCombinazione = ( id, combinazione ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}combinazionis/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(combinazione)
  })
)

// cancello la combinazione identificata dall'id passato
export const deleteCombinazione = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}combinazionis/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
)

export const resetCombinazioni = () => dispatch => {
  dispatch(getCombinazioni(null))
}
