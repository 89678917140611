export function trovaCombinazioneOrdinazione(ordinazione, combinazioni) {
    let res = null

    // devo recuperare i gruppi presenti nell'ordinazione
    const pietanze = ordinazione["Pietanze"]

    if (!pietanze || pietanze.length === 0)
        return res

    let gruppiOrdinazione = []

    pietanze.forEach( (p) => {
        const gruppo = p["Gruppo"]

        if (gruppo && !gruppiOrdinazione.includes(gruppo))
            gruppiOrdinazione.push(gruppo)
    })

    // in base ai gruppi scorro le combinazioni e controllo se c'è una o più combinazioni che matchano
    combinazioni.forEach( (c) => {
        let corrispondenza = controllaMatchOrdinazioneCombinazione(gruppiOrdinazione, c)

        if (corrispondenza === true) {
            res = c
            return
        }
    })

    return res
}

// funzione che effettua il match dei gruppi dell'ordinazione con i gruppi della combinazione
// ritorna un valore intero che corrisponde al numero di gruppi che fanno match.
// Il valore ritornato corrisponde a:
// 0 => nessun match
// x => numero di gruppi che fanno match. più è alto più la combinazione matcha con l'ordinazione
function controllaMatchOrdinazioneCombinazione(gruppiOrdinazione, combinazione) {
    let res = false

    const gruppiCombinazione = combinazione["Gruppi"].map( (g) => {
        return g.id
    })

    // se non ci sono gruppi nella combinazione ritorno
    if (!gruppiCombinazione || gruppiCombinazione.length === 0)
        return res

    // ciclo sui gruppi dell'ordinazione
    let contatoreCorrispondenze = 0
    gruppiOrdinazione.forEach( (g) => {
        if ( gruppiCombinazione.includes( g ))
        contatoreCorrispondenze += 1
    })

    if (contatoreCorrispondenze === gruppiOrdinazione.length && gruppiCombinazione.length === gruppiOrdinazione.length )
        res = true

    return res
}