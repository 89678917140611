import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api';

export const GET_PERSONE = "GET_PERSONE";
export const GET_PERSONA = "GET_PERSONA";
export const DELETE_PERSONA = "DELETE_PERSONA";

export const getPersone = persone => ({
  type: GET_PERSONE,
  items: persone
});

// recupera le persone
export const fetchPersone = (filter=[]) => dispatch => {
  let queryString = filterParser(filter)

  if (queryString.length > 0 )
    queryString = '?'+queryString

  return fetch(`${REACT_APP_BACKEND}persones${queryString}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let persone = null;

    try {
      persone = JSON.parse(data);
      persone = persone !== null ? persone : null;
    } catch(e) {
      persone = null;
    } finally {
      dispatch(getPersone(persone));
    }
  })
}

// recupera i dipendenti
export const fetchDipendenti = (filter=[]) => dispatch => {

  let queryString = "?Profilo=dipendente"

  // effettuo il parsing dei filtri
  if (filter.length > 0 ) {

    // parsing del filtro sul nome
    const nome = filter.find( (f) => {
      return f.campo === 'Nome'
    })

    if (nome) {
      queryString += `&Nome_contains=${nome['valore']}`
    }

    // parsing del filtro sul cognome
    const cognome = filter.find( (f) => {
      return f.campo === 'Cognome'
    })

    if (cognome) {
      queryString += `&Cognome_contains=${cognome['valore']}`
    }

    // parsing del filtro sull'azienda
    const azienda = filter.find( (f) => {
      return f.campo === 'Azienda'
    })

    if (azienda) {
      queryString += `&Azienda=${azienda['valore']}`
    }
  }

  return fetch(`${REACT_APP_BACKEND}persones${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
	  .then( (data) => { 
      let persone = null;

      try {
        persone = JSON.parse(data);
        persone = persone !== null ? persone : null;
      } catch(e) {
        persone = null;
      } finally {
        dispatch(getPersone(persone));
      }
    })
  }

export const getPersona = persona => ({
  type: GET_PERSONA,
  persona
})

export const fetchPersona = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}persones/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let persona = null;

    try {
      persona = JSON.parse(data);
      persona = persona !== null ? persona : null;
    } catch(e) {
      persona = null;
    } finally {
      dispatch(getPersona(persona));
    }
  })
)

// aggiunge un dipendente
export const addPersona = ( persona ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}persones`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(persona)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let persona = null;

    try {
      persona = JSON.parse(data);
      persona = persona !== null ? persona : null;
    } catch(e) {
      persona = null;
    } finally {
      return persona;
    }
  })
)

// aggiorna la persona identificata dall'id passato
export const updatePersona = ( id, persona ) => dispatch => (
  fetch(`${REACT_APP_BACKEND}persones/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(persona)
  })
)

// cancello la persona identificata dall'id passato
export const deletePersona = (id) => dispatch => (
  fetch(`${REACT_APP_BACKEND}persones/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
)

// azzera la lista dei dipendenti
export const azzeraPersone = () => dispatch => (
  dispatch(getPersone(null))
)
