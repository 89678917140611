export function getValoreCampoDaFiltro(campoFiltro, nome, filtro) {
    let res = null

    if (!filtro)
        return null

    res = filtro.find( (item) => {
        return item[campoFiltro] === nome
    })

    return res
}

export function setValoreCampoFiltro(campoFiltro, nome, valore, filtro) {
    let res = filtro

    if (!nome || nome.trim().length === 0 )
        return res

    res.forEach( (item) => {
        if (item[campoFiltro] === nome)
            item.valore = valore

    })

    return res
}