import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import AddIcon from "@material-ui/icons/Add";
import { Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase"

// custom components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// React-Redux reducers
import { connect } from "react-redux";
import { fetchClienti, fetchCliente, deleteCliente } from "../../../actions/clienti";
import { compose } from "redux";
import { withRouter } from "react-router";

class ListaClienti extends React.Component {
  state = {
    value: 0
  };

  componentDidMount() {
    const { loadClienti } = this.props

    loadClienti();
  }

  // funzione utilizzata per spostarsi nella pagina di inserimento nuovo cliente
  aggiungiCliente() {
    const { history } = this.props

    history.push('/dashboard/clienti/add')
  }

  // cancella il cliente
  cancellaCliente(id) {
    const { delCliente, loadClienti } = this.props
    Promise.resolve()
    .then( () => delCliente(id) )
    .then(() => loadClienti())
  }

  apriModificaCliente(id) {
    const { history } = this.props

    history.push('/dashboard/clienti/'+id+'/edit')
  }

  render() {
    const { classes, clienti, utenteLoggato } = this.props;

    const aziendeClienti = clienti && clienti.items && clienti.items.filter( (c) => {
      return c._id !== utenteLoggato.Azienda._id
    })

    return (
      <div>
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <Button onClick={() => this.aggiungiCliente()} style={{background: "linear-gradient(60deg, #7d6960, #3e2a23)", color: "#ffffff"}} variant="contained"><AddIcon />
              Aggiungi nuovo cliente
            </Button>
          </GridItem>
        </GridContainer>
      <ScaffoldBase title="Lista clienti">
                { aziendeClienti && aziendeClienti.length > 0 &&
                <GridItem xs={12} sm={12} md={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Azione</TableCell>
                        <TableCell>Ragione Sociale</TableCell>
                        <TableCell>Indirizzo</TableCell>
                        <TableCell>Città</TableCell>
                        <TableCell>Telefono</TableCell>
                        <TableCell>Partita</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                          aziendeClienti && aziendeClienti.map( (cliente) => 
                            <TableRow key={cliente._id}>
                              <TableCell>
                                  <Tooltip
                                    id="tooltip-top"
                                    title="Modifica"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <IconButton
                                      aria-label="Edit"
                                      className={classes.tableActionButton}
                                    >
                                      <Edit
                                        className={
                                          classes.tableActionButtonIcon + " " + classes.edit
                                        }
                                        onClick={() => this.apriModificaCliente(cliente._id)}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip
                                    id="tooltip-top-start"
                                    title="Elimina"
                                    placement="top"
                                    classes={{ tooltip: classes.tooltip }}
                                  >
                                    <IconButton
                                      aria-label="Close"
                                      className={classes.tableActionButton}
                                    >
                                      <Close
                                        className={
                                          classes.tableActionButtonIcon + " " + classes.close
                                        }
                                        onClick={() => this.cancellaCliente(cliente._id)}
                                      />
                                    </IconButton>
                                  </Tooltip>
                              </TableCell>
                              <TableCell>{cliente["Ragione sociale"]}</TableCell>
                              <TableCell>{cliente["Indirizzo"]}</TableCell>
                              <TableCell>{cliente["Citta"]}</TableCell>
                              <TableCell>{cliente["Telefono"]}</TableCell>
                              <TableCell>{cliente["Partita IVA"]}</TableCell>
                            </TableRow>
                          ) 
                        }
                    </TableBody>
                  </Table>
                </GridItem>
                }

                { (!aziendeClienti || aziendeClienti.length <= 0) && 
                  <GridItem>
                    <p>Clienti non disponibili</p>
                  </GridItem> }
        </ScaffoldBase>
        </div>
    );
  }
}

ListaClienti.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
    loadClienti: () => dispatch(fetchClienti()),
    setCliente: (id) => dispatch(fetchCliente(id)),
    delCliente: (id) => dispatch(deleteCliente(id))
  }
}

function mapStateToProps({ clienti, authentication }) {
  return { 
    clienti,
    utenteLoggato: authentication.user 
  };
}

/*export default compose(
  withRouter(connect(null, mapDispatchToProps)),
  withStyles(dashboardStyle)
)(ListaClienti);*/

//export default withRouter(connect(null, mapDispatchToProps)(ListaClienti));

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaClienti));
