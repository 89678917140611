import jsPDF from "jspdf"
import JsBarcode from "jsbarcode"

// Gestione stampa report ordinazioni 
export function stampaReportOrdinazioni(data) {

    const config = {
        altezzaHeader: 30,
        altezzaRiga: 10,
        margineTop: 20,
        margineBottom: 40,
        margineRight: 20,
        margineLeft: 20,
        righePerPagina: 24,
        larghezzaPagina: 210,
        altezzaPagina: 297,
        margineLeftCelle: 3
    }

    const dati = data && data.clienti && data.clienti.length > 0 ? data.clienti : null
    const giorno = data.giorno && data.giorno.trim().length > 0 ? data.giorno : ''
    const pasto = data.pasto && data.pasto.trim().length > 0 ? data.pasto : ''

    let nomeFile = `Report_Ordinato_${giorno}`

    if ( dati.length === 1) {
        nomeFile += `_${dati[0].ragioneSociale}`
    }

    let doc = new jsPDF()

    let yCorrente = 0

    for (let c = 0; c < dati.length; c ++) {
        const cliente = dati[c]

        let disegnaCliente = false

        const gruppi = cliente.ordinazioni

        // controllo se devo disegnare la pagina del cliente
        for (let i = 0; i < gruppi.length ; i++) {
            const gruppo = gruppi[i]

            const pietanze = gruppo["Pietanze"]

            if (pietanze.length > 0 ) {
                disegnaCliente = true
                break
            }
        }

        if ( disegnaCliente === false ) {
            continue;
        }

        if (c > 0 ) {
            doc.addPage() 
        }

        yCorrente = 0
        let righeRimanenti = config.righePerPagina

        disegnaPaginaReportBase(doc, config)
        disegnaHeaderCliente(doc, config, cliente.ragioneSociale, pasto, giorno)

        // aggiorno la coordinata y dopo aver disegnato l'header
        yCorrente = yCorrente + config.altezzaHeader

        const gruppiOrdinazioni = cliente.ordinazioni

        if ( gruppiOrdinazioni.length > 0) {
            // disegno i gruppi e le rispettive pietanze
            gruppiOrdinazioni.forEach( (gruppo) => {

                // controllo se ci sono pietanze ordinate
                const pietanze = gruppo["Pietanze"]

                if (pietanze && pietanze.length > 0 ) {

                    // controllo se ci sono almeno due righe disponibili per disegnare un nuovo gruppo
                    if (righeRimanenti < 2) {
                        // non ci sono righe disponibili per disegnare un nuovo gruppo.
                        // creo una nuova pagina
                        doc.addPage()
                        yCorrente = 0
                        righeRimanenti = config.righePerPagina

                        // ridisegno la pagina principale
                        disegnaPaginaReportBase(doc, config)

                        // disegno l'header del cliente
                        disegnaHeaderCliente(doc, config, cliente.ragioneSociale, pasto, giorno)

                        // imposto la coordinata y dopo aver disegnato l'header
                        yCorrente = yCorrente + config.altezzaHeader
                    }

                    disegnaHeaderGruppi(doc, config, yCorrente, gruppo["Gruppo"])
                    righeRimanenti--

                    yCorrente += config.altezzaRiga

                    // disegno le pietanze
                    pietanze.forEach( (pietanza) => {

                        // devo controllare se c'è ancora posto per disegnare la riga
                        if (righeRimanenti > 0 ) {
                            disegnaRigaPietanza(doc, config, yCorrente, pietanza)
                            yCorrente += config.altezzaRiga
                            righeRimanenti--
                        }
                        else {
                            // devo creare una nuova pagina
                            doc.addPage()
                            yCorrente = 0
                            righeRimanenti = config.righePerPagina

                            // ridisegno la pagina principale
                            disegnaPaginaReportBase(doc, config)

                            // disegno l'header del cliente
                            disegnaHeaderCliente(doc, config, cliente.ragioneSociale, pasto, giorno)

                            // imposto la coordinata y dopo aver disegnato l'header
                            yCorrente = yCorrente + config.altezzaHeader

                            // disegno l'header del gruppo
                            disegnaHeaderGruppi(doc, config, yCorrente, gruppo["Gruppo"])

                            // aggiorno il contatore delle righe rimanenti
                            righeRimanenti--
                        }
                        
                    })
                }
            })

            // disegno i totali dei gruppi
            if ( righeRimanenti >= gruppiOrdinazioni.length) {
                // le righe rimanenti sono sufficienti per disegnare i totali dei gruppi
                gruppiOrdinazioni.forEach( (gruppo, idx) => {
                    disegnaTotaliGruppi(doc, config, yCorrente, gruppo)

                    if (idx === gruppiOrdinazioni.length -1 ) {
                        doc.line(config.margineLeft, yCorrente+(config.altezzaRiga / 2), config.larghezzaPagina-config.margineRight, yCorrente+(config.altezzaRiga / 2))
                    }

                    yCorrente += config.altezzaRiga
                })
            }
            else {
                // devo creare una nuova pagina
                doc.addPage()
                yCorrente = 0
                righeRimanenti = config.righePerPagina

                // ridisegno la pagina principale
                disegnaPaginaReportBase(doc, config)

                // disegno l'header del cliente
                disegnaHeaderCliente(doc, config, cliente.ragioneSociale, pasto, giorno)

                // imposto la coordinata y dopo aver disegnato l'header
                yCorrente = yCorrente + config.altezzaHeader

                gruppiOrdinazioni.forEach( (gruppo, idx) => {
                    disegnaTotaliGruppi(doc, config, yCorrente, gruppo)
                    
                    if (idx === gruppiOrdinazioni.length -1 ) {
                        doc.line(config.margineLeft, yCorrente+(config.altezzaRiga / 2), config.larghezzaPagina-config.margineRight, yCorrente+(config.altezzaRiga / 2))
                    }

                    yCorrente += config.altezzaRiga
                })
            }
        }

    }

    // disegno i numeri di pagina
    const numPagine = doc.internal.getNumberOfPages()

    if (numPagine > 0 ) {
        
        for (let np = 1; np <= numPagine; np ++) {
            doc.setPage(np)

            // stampo i campi Firma conducente e firma destinatario
            doc.setFontSize(10)
            doc.setFontStyle('bold')
            doc.text('Firma conducente', config.margineLeft, config.altezzaPagina-config.margineBottom+(config.altezzaRiga / 3 * 2))

            doc.text('Firma destinatario', config.larghezzaPagina - config.margineRight - 30, config.altezzaPagina-config.margineBottom+ (config.altezzaRiga / 3 * 2))
            
            // stampo il pie di pagina
            doc.setFontSize(8)
            doc.setFontStyle('normal')
            doc.text(`Stampato il ${ new Date().toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'}) } alle ore ${ new Date().toLocaleTimeString()}`, config.margineLeft, config.altezzaPagina-config.margineBottom+config.altezzaRiga+20)
            doc.text(`Pagina ${np} di ${numPagine}`, config.larghezzaPagina-config.margineRight-20, config.altezzaPagina-config.margineBottom+config.altezzaRiga+20)
        }
    }

    doc.save(`${nomeFile}.pdf`)
}

function disegnaPaginaReportBase(doc,config){
    
    const { margineLeft, margineTop, margineBottom, altezzaRiga, altezzaPagina } = config

    // aggiungo le righe verticali
    doc.line( margineLeft, margineTop+(altezzaRiga /2 ),  margineTop, altezzaPagina-margineBottom)
    doc.line(165, margineTop+(altezzaRiga /2 ), 165, altezzaPagina-margineBottom)
    doc.line(190, margineTop+(altezzaRiga /2 ), 190, altezzaPagina-margineBottom)

    // aggiungo la riga fine pagina
    doc.line(margineTop, altezzaPagina-margineBottom, 190, altezzaPagina-margineBottom)
}

function disegnaHeaderCliente(doc, config , ragioneSociale, pasto, giorno) {

    const { margineLeft, margineTop} = config

    doc.setFontSize(22)
    doc.setFontStyle('normal')
    doc.text(ragioneSociale.toUpperCase(), margineLeft, margineTop)
    
    doc.setFontSize(16)
    if (pasto.trim().length > 0 )
        doc.text(pasto.toUpperCase(), 100, margineTop)

    if (giorno.trim().length > 0 )
        doc.text(giorno, 160, margineTop)
}

function disegnaHeaderGruppi(doc, config , y, nomeGruppo) {

    const { margineLeft, margineRight, altezzaRiga, larghezzaPagina, margineLeftCelle } = config

    doc.line(margineLeft, y-(altezzaRiga / 2), larghezzaPagina-margineRight, y-(altezzaRiga / 2))
    doc.setFontSize(12)
    doc.setFontStyle('bold')
    doc.text(nomeGruppo, margineLeft+margineLeftCelle, y)
    doc.text('Q.tà', 170, y)
    doc.line(margineLeft, y+1, larghezzaPagina-margineRight, y+1)
}

function disegnaTotaliGruppi(doc, config , y, gruppo) {

    const { margineLeft, margineRight, margineLeftCelle, altezzaRiga, larghezzaPagina, } = config

    doc.setFontSize(16)
    doc.setFontStyle('bold')

    doc.text(`Totale ${gruppo["Gruppo"]}`, margineLeft+margineLeftCelle, y)

    const totalePietanze = gruppo.totalePietanze

    doc.text(totalePietanze.toString(), 170, y)

    // disegno le righe
    doc.line(margineLeft, y-(altezzaRiga / 2), larghezzaPagina-margineRight, y-(altezzaRiga / 2))

}

function disegnaRigaPietanza(doc, config , y, pietanza) {

    const { margineLeft, margineLeftCelle, } = config
    
    doc.setFontStyle('normal')
    doc.text(pietanza.nome, margineLeft+margineLeftCelle, y)
    doc.text(pietanza.contatore.toString(), 170, y)
}


// Gestione stampa etichette

export function stampaEtichette(data) {

    if (!data)
        return

    const scaleFactor = 2.83
    const distanzaRighe = 3
    const leftMargin = 4

    // per ogni etichetta devo stampare una pagina.
    // le dimensioni della pagina sono 72 mm x 36 mm
    let doc = new jsPDF('l', 'mm', [72*scaleFactor, 36*scaleFactor])

    for (let i = 0; i < data.length ; i++) {

        const riga = data[i]
        let yRiga = 0

        yRiga = 2

        doc.setFontSize(12)

        yRiga += distanzaRighe
        // scrivo il nome dell'azienda
        doc.setFontStyle('bold')
        doc.text(riga["ragioneSociale"], leftMargin, yRiga)

        doc.setFontSize(8)
        doc.setFontStyle('normal')

        yRiga += distanzaRighe
        // scrivo il giorno e la tipologia di pasto
        doc.text(`${riga['giorno']} ${riga["pasto"]}`, leftMargin, yRiga)

        let altezzaBarcode = 0
        // creo il barcode
        if ( riga.idOrdinazione.length > 0 && riga.idPietanza.length > 0 ) {
            const barcodeText = `${riga.idOrdinazione}|${riga.idPietanza}`

            const barcodeImage = generaBarcodeImageFromText(barcodeText)
            

            if (barcodeImage) {
                var width = doc.internal.pageSize.getWidth();
                altezzaBarcode = 10
                yRiga += distanzaRighe

                doc.addImage(barcodeImage, 'JPEG', leftMargin, yRiga, width - (leftMargin*2) , yRiga);
            }
        }
        
        // scrivo il nome e il cognome della persona
        const cognomeNomePersona = `${riga["Cognome"]} ${riga["Nome"]}`
        yRiga += altezzaBarcode + distanzaRighe
        yRiga += 2
        doc.setFontSize(10)
        doc.text(cognomeNomePersona, leftMargin, yRiga)

        let posizionePietanza = yRiga + distanzaRighe
        let posizioneMatricola = posizionePietanza

        if (riga["Matricola"] && riga["Matricola"].trim().length > 0 ) {
            doc.setFontSize(8)
            doc.text(`Matricola: ${riga["Matricola"]}`, leftMargin, posizioneMatricola)
            posizionePietanza += distanzaRighe
        }
            

        // scrivo il nome della pietanza
        posizionePietanza += 1
        doc.setFontSize(12)
        doc.setFontStyle('bold')
        doc.text(riga["nomePietanza"].toUpperCase(), leftMargin, posizionePietanza)

        if ( i < (data.length -1) ) {
            doc.addPage()
        }
    }

    doc.save('etichette.pdf')
}

function generaBarcodeImageFromText(text) {
    if ( text.trim().length <= 0)
        return null

    var canvas = document.createElement("canvas");
    JsBarcode(canvas, text, {format: "CODE128"});
    return canvas.toDataURL("image/png");
}

// Gestione stampa report ordinazioni suddivise per giro
export function stampaReportGiri(data) {
    
    let config = {
        larghezzaPagina: 297,
        altezzaPagina: 210,
        marginLeft: 15,
        marginRight: 10,
        marginTop: 20,
        marginBottom: 20,
        altezzaRiga: 7,
        colonnePrimaCella: 4
    }

    let doc = new jsPDF('l')

    const { pasto, giorno } = data
    
    doc.setFontSize(18)
    doc.setFontStyle('bold')
    doc.text( pasto, config.larghezzaPagina / 2, config.marginTop - config.altezzaRiga)
    doc.text(giorno, config.marginLeft, config.marginTop - config.altezzaRiga)

    doc.setFontStyle('normal')
    doc.setFontSize(11)
    
    disegnaScheletroReport(doc, config)

    disegnaDatiReportGiri(data, doc, config)

    doc.setFontSize(8)
    doc.setFontStyle('italic')
    doc.text(`Stampato il ${ new Date().toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'}) } alle ore ${ new Date().toLocaleTimeString()}`, 
    config.marginLeft, 
    config.altezzaPagina-config.marginBottom+config.altezzaRiga)

    doc.save('report-giri.pdf')
}

function disegnaScheletroReport(doc, config) {
    let { marginTop, marginBottom, marginLeft, marginRight, altezzaRiga, altezzaPagina, larghezzaPagina, colonnePrimaCella } = config

    let yCorrente = marginTop
    let yFinePagina = 0
    let xFinePagina = 0

    let numColonne = 14
    let larghezzaColonne = (larghezzaPagina - marginLeft - marginRight) / numColonne
    larghezzaColonne = parseInt(larghezzaColonne)

    config['larghezzaColonne'] = larghezzaColonne

    // calcolo la Y di fine pagina
    while ( yCorrente < (altezzaPagina - marginBottom) ) {
        if ( (yCorrente + altezzaRiga) >= (altezzaPagina - marginBottom)) {
            yFinePagina = yCorrente
        }
            
        yCorrente += altezzaRiga
    }

    yFinePagina = parseInt(yFinePagina)

    // calcolo la X di fine pagina

    let xCorrente = marginLeft + (larghezzaColonne * colonnePrimaCella )

    while ( (xCorrente + larghezzaColonne) <= (larghezzaPagina - marginLeft)) {
        xCorrente += larghezzaColonne
        xFinePagina = xCorrente
    }

    xFinePagina = parseInt(xFinePagina)

    // stampa delle righe

    // righe orizzontali
    yCorrente = marginTop

    while ( yCorrente <= yFinePagina ) {
        doc.line(marginLeft, yCorrente, xFinePagina, yCorrente)

        yCorrente += altezzaRiga
    }

    // disegno le righe verticali che dividono le colonne
    xCorrente = marginLeft
    doc.line(xCorrente, marginTop, xCorrente, yFinePagina)
    xCorrente += (larghezzaColonne * colonnePrimaCella )
    doc.line(xCorrente, marginTop, xCorrente, yFinePagina)
    xCorrente += larghezzaColonne

    while ( xCorrente <= xFinePagina) {
        doc.line(xCorrente, marginTop, xCorrente, yFinePagina)

        xCorrente += larghezzaColonne
    }

    // disegno la riga verticale della colonna finale
    doc.line(xFinePagina, marginTop, xFinePagina, yFinePagina)

}

function disegnaDatiReportGiri( data, doc, config) {

    const margineRiga = 2
    const { altezzaRiga, larghezzaColonne, marginTop, marginLeft, colonnePrimaCella } = config

    const { dati, clienti } = data

    // se non dovessero essere ordinati li ordino per giro e nome
    const clientiOrdinati = clienti.sort( (a, b) => {
        if (!a['Giroconsegna'])
            return 0

        if (a['Giroconsegna']['Ordinamento'] > b['Giroconsegna']['Ordinamento'] )
            return 1
        
        if (a['Giroconsegna']['Ordinamento'] < b['Giroconsegna']['Ordinamento'] )
            return -1
        
        if ( a["Ragione sociale"] > b["Ragione sociale"])
            return 1

        if ( a["Ragione sociale"] < b["Ragione sociale"])
            return -1

        return 0
    })

    let giroCorrente = null
    let yCorrente = marginTop + altezzaRiga -2
    let xCorrente = marginLeft + margineRiga + ( colonnePrimaCella * larghezzaColonne )

    let colonneGrassetto = []

    doc.setFontStyle('bold')

    // scorro i clienti e li disegno 
    for (let k = 0; k < clientiOrdinati.length ; k++) {

        if (!clientiOrdinati[k]["Giroconsegna"])
            continue

        // gestisco il cambio di giro stampando il totale
        if ( giroCorrente !== null && clientiOrdinati[k]['Giroconsegna'].id !== giroCorrente._id) {
            doc.setFontStyle('bold')
            doc.text( giroCorrente['Descrizione'], xCorrente, yCorrente)
            xCorrente += larghezzaColonne
            colonneGrassetto.push(k)
        }
        doc.setFontStyle('normal')

        giroCorrente = clientiOrdinati[k]['Giroconsegna']

        let nomeCliente = clientiOrdinati[k]['Ragione sociale']

        if (nomeCliente.length > 6)
            nomeCliente = nomeCliente.substring(0,5)

        doc.text( nomeCliente, xCorrente, yCorrente)

        xCorrente += larghezzaColonne

        // gestisco il totale dell'ultimo giro
        if ( k === (clientiOrdinati.length -1)) {
            doc.setFontStyle('bold')
            doc.text( clientiOrdinati[k]['Giroconsegna']['Descrizione'], xCorrente, yCorrente)
            xCorrente += larghezzaColonne
            colonneGrassetto.push(k+2)
        }
            
    }

    // scrivo l'intestazione del totale finale
    doc.text('Totale', xCorrente, yCorrente)
    colonneGrassetto.push(clientiOrdinati.length+2)


    yCorrente = marginTop + ( 2 * altezzaRiga)
    let yCorrenteTesto = yCorrente - margineRiga
    xCorrente = marginLeft + margineRiga

    // scorro le righe dei dati delle pietanze e disegno i valori
    for (let i= 0; i < dati.length ; i++) {
        if (i < dati.length-1)
            doc.setFontStyle('normal')
        else if (i < dati.length)
            doc.setFontStyle('bold')

        const { Nome, valori } = dati[i]

        xCorrente = marginLeft + margineRiga

        doc.text( Nome.toUpperCase(), xCorrente, yCorrenteTesto )

        xCorrente += (colonnePrimaCella * larghezzaColonne)

        // devo scorrere i valori per sportarmi nelle colonne
        for (let j = 0; j < valori.length ; j ++) {
            if (i < dati.length-1)
                doc.setFontStyle('normal')
            else if (i < dati.length)
                doc.setFontStyle('bold')
            
            if (colonneGrassetto.includes(j)) {
                doc.setFontStyle('bold')
            }
        
            doc.text(valori[j].toString(), xCorrente + (larghezzaColonne / 3) - margineRiga , yCorrenteTesto)

            xCorrente += larghezzaColonne
        }

        yCorrente += altezzaRiga
        yCorrenteTesto = yCorrente - margineRiga

    }

}

// Gestione stampa menu settimanale
export function stampaMenuSettimanale(data) {
    let config = {
        larghezzaPagina: 297,
        altezzaPagina: 210,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 20,
        marginBottom: 30,
        altezzaRiga: 6,
        numeroColonne: 5
    }

    const { pranzo, cena, giornoInizio, giornoFine } = data

    let doc = new jsPDF('l')

    disegnaScheletroMenuSettimanale(doc, giornoInizio, giornoFine, 'Pranzo', config)

    // stampo il pranzo
    disegnaDatiMenuSettimanale(pranzo, doc, config)

    doc.addPage('l')
    disegnaScheletroMenuSettimanale(doc, giornoInizio, giornoFine, 'Cena', config)

    // stampo la cena
    disegnaDatiMenuSettimanale(cena, doc, config)

    doc.save('menu-settimanale.pdf')
}

function disegnaScheletroMenuSettimanale(doc, giornoInizio, giornoFine, pasto, config) {

    const { larghezzaPagina, altezzaPagina, marginLeft, marginRight, marginTop, altezzaRiga, numeroColonne } = config
    const labelNote = "Si avvisa la gentile clientela che il menù può variare in base alla reperibilità dei prodotti."

    let xFinePagina = 0
    let yFinePagina = 0
    let xCorrente = marginLeft
    let yCorrente = marginTop

    let larghezzaColonne = (larghezzaPagina - marginLeft - marginRight) / numeroColonne

    config['larghezzaColonne'] = larghezzaColonne
    
    // stampo la label iniziale
    doc.setFontSize(16)
    doc.setFontStyle('bold')
    let labelMenu = `Menu settimana da ${giornoInizio} a ${giornoFine}`
    doc.text(labelMenu, marginLeft, (marginTop / 3 * 2))
    doc.text(pasto, larghezzaPagina - marginRight - 30, (marginTop / 3 * 2))

    // calcolo la x di fine pagina
    while ( (xCorrente + larghezzaColonne) <= (larghezzaPagina - marginLeft)) {
        xCorrente += larghezzaColonne
        xFinePagina = xCorrente
    }

    // calcolo la y di fine pagina
    while ( yCorrente <= ( altezzaPagina - marginTop)) {
        yCorrente += altezzaRiga
        yFinePagina = yCorrente
    }

    // disegno la riga in alto
    doc.line(marginLeft, marginTop, xFinePagina, marginTop)

    // disegno le righe verticali che dividono i giorni
    doc.line(marginLeft, marginTop, marginLeft, yFinePagina)

    for ( let i = 0; i < numeroColonne; i++) {
        doc.line(marginLeft + ((i+1) * larghezzaColonne), marginTop, marginLeft + ((i+1) * larghezzaColonne), yFinePagina)
    }

    // disegno le righe orizzontali.

    // la prima riga contiene il giorno ed è alta il doppio delle righe normali
    xCorrente = marginLeft
    yCorrente = marginTop

    yCorrente += altezzaRiga
    doc.line(xCorrente, yCorrente, xFinePagina, yCorrente)
    yCorrente += altezzaRiga

    while ( yCorrente <= yFinePagina) {
        doc.line(xCorrente, yCorrente, xFinePagina, yCorrente)
        yCorrente += altezzaRiga
    }

    // scrivo le note
    doc.setFontSize(12)
    doc.setFontStyle('italic')
    doc.text(labelNote, marginLeft , yFinePagina + (altezzaRiga * 2 / 3) )

}

function disegnaDatiMenuSettimanale( menu, doc, config) {
    const { marginLeft, marginTop, altezzaRiga, larghezzaColonne, numeroColonne } = config

    let xCorrente = 0
    let yCorrente = 0

    // stampo il menu
    for (let i = 0; i < numeroColonne; i++) {
        doc.setFontSize(14)
        yCorrente = marginTop + altezzaRiga - 1
        xCorrente = marginLeft + (i*larghezzaColonne) + (larghezzaColonne / 4 )
        // disegno il giorno
        doc.text( new Date(menu[i]['giorno']).toLocaleString('it-IT', { weekday: 'long'}).toUpperCase(), xCorrente, yCorrente)
        // disegno i dati
        
        const pietanze = menu[i]['pietanze']

        xCorrente = marginLeft + ( i*larghezzaColonne)
        yCorrente = marginTop + altezzaRiga

        doc.setFontSize(7)

        for ( let j = 0; j < pietanze.length ; j ++) {

            const { tipo, valore } = pietanze[j]

            if (tipo === 'gruppo'){
                doc.setFontStyle('bold')
                doc.setFontSize(10)
            }
            else {
                doc.setFontSize(8)
                doc.setFontStyle('normal')
            }
            
            if (valore.length >= 25) {

                let val1 = valore.substring(0,25).trim()
                let val2 = valore.substring(25, valore.length).trim()

                // controllo se il valore dell'andata a capo è minore o uguale a tre scrivo solo val 1
                if (val2.length <= 3) {
                    doc.text(val1.toUpperCase(), xCorrente + 2, yCorrente + (altezzaRiga * 2 / 3) )
                }
                else {
                    doc.setFontSize(7)
                    doc.text(val1.toUpperCase(), xCorrente + 2, yCorrente + (altezzaRiga * 1 / 3) +1 )
                    doc.text(val2.toUpperCase(), xCorrente + 2, yCorrente + (altezzaRiga * 2 / 3) +1 )
                }
            }
            else {
                doc.text(valore.toUpperCase(), xCorrente + 2, yCorrente + (altezzaRiga * 2 / 3) )
            }
            
            
            yCorrente += altezzaRiga 
        }
    }
}

// Gestione stampa report venduto
export function stampaReportVenduto(data) {
    let config = {
        altezzaPagina: 210,
        larghezzaPagina: 297,
        numeroColonne: 5,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 20,
        marginBottom: 30,
        altezzaRiga: 6,
        altezzaHeader: 8,
        marginBottomCell: 2
    }

    // calcolo la x di fine pagina
    let larghezzaColonne = parseInt((config.larghezzaPagina - config.marginLeft - config.marginRight) / config.numeroColonne)
    config['larghezzaColonne'] = larghezzaColonne
    let xMax = config.marginLeft
    while ( xMax + larghezzaColonne <= config.larghezzaPagina) {
        xMax += larghezzaColonne
    }

    config['xMax'] = xMax

    // calcolo la y di fine pagina
    let yMax = config.marginTop + config.altezzaHeader
    while ( yMax + config.altezzaRiga <= config.altezzaPagina) {
        yMax += config.altezzaRiga
    }

    config['yMax'] = yMax

    let doc = new jsPDF('l')

    // stampo l'header della pagina contenente le informazioni del report e l'header della tabella
    stampaBaseReportVenduto(doc, config, data)

    // stampo il corpo
    stampaCorpoReportVenduto(doc, config, data)

    // disegno i pie di pagina
    const numPagine = doc.internal.getNumberOfPages()

    if (numPagine > 0 ) {
        
        for (let np = 1; np <= numPagine; np ++) {
            doc.setPage(np)
            
            // stampo il pie di pagina
            doc.setFontSize(8)
            doc.setFontStyle('normal')
            doc.text(`Stampato il ${ new Date().toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'}) } alle ore ${ new Date().toLocaleTimeString()}`, config.marginLeft, yMax-config.altezzaRiga)
            doc.text(`Pagina ${np} di ${numPagine}`, config.larghezzaPagina-config.marginRight-20, yMax-config.altezzaRiga)
        }
    }

    doc.save('report-venduto.pdf')
}

function stampaBaseReportVenduto(doc, config, data, nuovaPagina=false) {
    const { marginLeft, marginTop, xMax, altezzaHeader, numeroColonne, larghezzaColonne } = config

    const { giornoDal, giornoAl } = data

    let giornoDalDate = new Date(giornoDal)
    let giornoAlDate = new Date(giornoAl)

    if (nuovaPagina === true) {
        doc.addPage('l')
    }

    // stampo l'intestazione della pagina
    doc.setFontSize(14)
    doc.setFontStyle('bold')
    let labelIntestazione = `Riepilogo venduto dal ${giornoDalDate.toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric'})} al ${giornoAlDate.toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric'})}`
    doc.text(labelIntestazione, marginLeft, marginTop * 2 / 3)

    // stampo le linee dell'header
    doc.line(marginLeft, marginTop, xMax, marginTop)
    doc.line(marginLeft, marginTop, marginLeft, marginTop + altezzaHeader)
    doc.line(marginLeft, marginTop+altezzaHeader, xMax, marginTop+altezzaHeader)
    doc.line(xMax, marginTop, xMax, marginTop+altezzaHeader)

    let xCorrente = marginLeft
    // stampo le linee verticali per dividere le colonne
    for (let i = 0; i < numeroColonne -1; i++) {
        doc.line( xCorrente + larghezzaColonne, marginTop, xCorrente + larghezzaColonne, marginTop + altezzaHeader)
        xCorrente += larghezzaColonne
    }

    doc.setFontSize(10)
    doc.setFontStyle('bold')
    xCorrente = marginLeft
    // stampo le etichette dell'header
    for (let i = 0; i < numeroColonne; i++) {
        let testo = ""
        switch (i) {
            case 0:
                testo = "Cliente"
            break;
            case 1:
                testo = "Pasto"
            break;
            case 2:
                testo = "Quantità"
            break;
            case 3:
                testo = "Prezzo"
            break;
            case 4:
                testo = "Totale"
            break;

            default:
                testo = ""
        }

        doc.text(testo, xCorrente + (larghezzaColonne / 2), marginTop + (altezzaHeader * 2 / 3), 'center')

        xCorrente += larghezzaColonne

    }
}

function stampaCorpoReportVenduto(doc, config, data) {
    const { marginTop, marginLeft, marginBottom, marginBottomCell, altezzaRiga, altezzaPagina, altezzaHeader, larghezzaColonne, xMax } = config

    const { corpo, totali } = data

    let yCorrente = marginTop + altezzaHeader
    let xCorrente = 0

    // scorro i clienti
    corpo.forEach( (riga) => {
        const { gruppi, combinazioni, totalecombinazioni, totalegruppi, totale } = riga

        // controllo se devo stampare su una nuova pagina
        if (yCorrente > ( altezzaPagina - marginBottom)) {
            stampaBaseReportVenduto(doc, config, data, true)
            yCorrente = marginTop + altezzaHeader
        }

        // stampo l'intestazione del subcliente
        doc.setFontSize(10)
        doc.setFontStyle('bold')
        
        xCorrente = marginLeft

        // intestazione
        let intestazioneCliente = riga.ragioneSociale
        doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
        doc.text(intestazioneCliente, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
        xCorrente += larghezzaColonne

        //disegno la riga di fine gruppo
        doc.line(xMax, yCorrente, xMax, yCorrente + altezzaRiga )

        yCorrente += altezzaRiga
        
        // disegno la riga sotto
        doc.line(marginLeft, yCorrente, xMax, yCorrente)


        // stampo i gruppi
        gruppi.sort( (a, b) => {
            if (a.ordinamento > b.ordinamento)
                return 1
            if (a.ordinamento < b.ordinamento)
                return -1
            return 0
        })
        .forEach( (gruppo, idx) => {

            // controllo se devo stampare su una nuova pagina
            if (yCorrente > ( altezzaPagina - marginBottom)) {
                stampaBaseReportVenduto(doc, config, data, true)
                yCorrente = marginTop + altezzaHeader
            }

            doc.setFontSize(7)
            doc.setFontStyle('normal')
            
            xCorrente = marginLeft

            // intestazione
            let intestazioneCliente = idx === 0 ? 'Gruppi' : ""
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(intestazioneCliente, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // nome pasto
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(gruppo.nome, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // quantita
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(gruppo.quantita.toString(), xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // prezzo
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(gruppo.prezzo.toFixed(2), xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // totale
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(gruppo.importo.toFixed(2), xCorrente + (larghezzaColonne / 2) +5, yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne

            //disegno la riga di fine gruppo
            doc.line(xMax, yCorrente, xMax, yCorrente + altezzaRiga )

            yCorrente += altezzaRiga
            
            // disegno la riga sotto
            doc.line(marginLeft, yCorrente, xMax, yCorrente)
        })

        if ( gruppi.length > 0 && totalegruppi.importo > 0 ) {
            // disegno il totale dei gruppi
            xCorrente = marginLeft

            doc.setFontStyle('bold')

            // intestazione
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(`Totale gruppi ${riga.ragioneSociale}`, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // nome pasto
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text("", xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // quantita
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(totalegruppi.quantita.toString(), xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // prezzo
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text("-", xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // totale
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(totalegruppi.importo.toFixed(2), xCorrente + (larghezzaColonne / 2) +5, yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne

            //disegno la riga di fine gruppo
            doc.line(xMax, yCorrente, xMax, yCorrente + altezzaRiga )

            yCorrente += altezzaRiga
            
            // disegno la riga sotto
            doc.line(marginLeft, yCorrente, xMax, yCorrente)
        }

        // stampo le combinazioni
        combinazioni.forEach( (combinazione, idx) => {
            // controllo se devo stampare su una nuova pagina
            if (yCorrente > ( altezzaPagina - marginBottom)) {
                stampaBaseReportVenduto(doc, config, data, true)
                yCorrente = marginTop + altezzaHeader
            }

            doc.setFontSize(7)
            doc.setFontStyle('normal')
            
            xCorrente = marginLeft

            // intestazione
            let intestazioneCliente = idx === 0 ? 'Combinazioni' : ""
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(intestazioneCliente, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // nome pasto
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(combinazione.nome, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // quantita
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(combinazione.quantita.toString(), xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // prezzo
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(combinazione.prezzo.toFixed(2), xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // totale
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(combinazione.importo.toFixed(2), xCorrente + (larghezzaColonne / 2) +5, yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne

            //disegno la riga di fine gruppo
            doc.line(xMax, yCorrente, xMax, yCorrente + altezzaRiga )

            yCorrente += altezzaRiga
            
            // disegno la riga sotto
            doc.line(marginLeft, yCorrente, xMax, yCorrente)
        })

        if (combinazioni.length > 0 && totalecombinazioni.importo > 0 ) {
            // disegno il totale delle combinazioni
            xCorrente = marginLeft

            doc.setFontStyle('bold')

            // intestazione
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(`Totale combinazioni ${riga.ragioneSociale}`, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // nome pasto
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text("", xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
            xCorrente += larghezzaColonne

            // quantita
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(totalecombinazioni.quantita.toString(), xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // prezzo
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text("-", xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne
            // totale
            doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
            doc.text(totalecombinazioni.importo.toFixed(2), xCorrente + (larghezzaColonne / 2) +5, yCorrente + ( altezzaRiga * 2 / 3), 'right')
            xCorrente += larghezzaColonne

            //disegno la riga di fine gruppo
            doc.line(xMax, yCorrente, xMax, yCorrente + altezzaRiga )
            yCorrente += altezzaRiga
            
            // disegno la riga sotto
            doc.line(marginLeft, yCorrente, xMax, yCorrente)
        }

        // disegno il totale del subcliente
        xCorrente = marginLeft
        doc.setFontSize(9)
        doc.setFontStyle('bold')

        // intestazione
        doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
        doc.text(`Totale venduto ${riga.ragioneSociale}`, xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
        xCorrente += larghezzaColonne

        // nome pasto
        doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
        doc.text("-", xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
        xCorrente += larghezzaColonne

        // quantita
        doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
        doc.text("-", xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
        xCorrente += larghezzaColonne
        // prezzo
        doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
        doc.text("-", xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
        xCorrente += larghezzaColonne
        // totale
        doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
        doc.text(totale ? totale.toFixed(2) : "" , xCorrente + (larghezzaColonne / 2) + 5, yCorrente + ( altezzaRiga * 2 / 3), 'right')
        xCorrente += larghezzaColonne

        //disegno la riga di fine gruppo
        doc.line(xMax, yCorrente, xMax, yCorrente + altezzaRiga )

        yCorrente += altezzaRiga
        
        // disegno la riga sotto
        doc.line(marginLeft, yCorrente, xMax, yCorrente)

        // controllo se devo stampare su una nuova pagina
        if (yCorrente > ( altezzaPagina - marginBottom)) {
            stampaBaseReportVenduto(doc, config, data, true)
            yCorrente = marginTop + altezzaHeader
        }
    })

    // stampo i totali finali del report
    xCorrente = marginLeft
    doc.setFontSize(10)
    doc.setFontStyle('bold')
    // intestazione
    doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
    doc.text('Totale venduto', xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
    xCorrente += larghezzaColonne

    // nome pasto
    doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
    doc.text("-", xCorrente + marginBottomCell, yCorrente + ( altezzaRiga * 2 / 3))
    xCorrente += larghezzaColonne

    // quantita
    doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
    doc.text("-", xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne
    // prezzo
    doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
    doc.text("-", xCorrente + (larghezzaColonne / 2), yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne
    // totale
    doc.line(xCorrente, yCorrente, xCorrente, yCorrente + altezzaRiga)
    doc.text(totali.totale ? totali.totale.toFixed(2) : "" , xCorrente + (larghezzaColonne / 2) +5, yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    //disegno la riga di fine gruppo
    doc.line(xMax, yCorrente, xMax, yCorrente + altezzaRiga )

    yCorrente += altezzaRiga
    
    // disegno la riga sotto
    doc.line(marginLeft, yCorrente, xMax, yCorrente)
}

// Gestione stampa report costi dipendenti
export function stampaReportCostiDipendenti(data) {
    let config = {}

    config = {
        larghezzaPagina: 210,
        altezzaPagina: 297,
        marginLeft: 15,
        marginRight: 15,
        marginTop: 20,
        marginBottom: 30,
        marginBottomCell: 2,
        numeroColonne: 5,
        altezzaHeader: 8,
        altezzaRiga: 7,
        orientation: 'p',
        fontSizeHeader: 14,
        fontSizeHeaderCell: 11,
        fontSizeBody: 9,
        fontSizeFooter: 8
    }

    // calcolo x e y massime
    // calcolo la x di fine pagina
    let larghezzaColonne = parseInt((config.larghezzaPagina - config.marginLeft - config.marginRight) / (config.numeroColonne +1) )
    config['larghezzaColonne'] = larghezzaColonne
    let xMax = config.marginLeft
    while ( xMax + larghezzaColonne <= ( config.larghezzaPagina - config.marginRight )) {
        xMax += larghezzaColonne
    }

    config['xMax'] = xMax

    // calcolo la y di fine pagina
    let yMax = config.marginTop + config.altezzaHeader
    while ( yMax + config.altezzaRiga <= config.altezzaPagina) {
        yMax += config.altezzaRiga
    }

    config['yMax'] = yMax

    let doc = new jsPDF(config.orientation)

    // stampo l'header della pagina contenente le informazioni del report e l'header della tabella
    stampaBaseReportCostiDipendenti(doc, config, data, false)

    // stampo il corpo
    stampaCorpoReportCostiDipendenti(doc, config, data)

    // disegno i pie di pagina
    const numPagine = doc.internal.getNumberOfPages()

    if (numPagine > 0 ) {
        
        for (let np = 1; np <= numPagine; np ++) {
            doc.setPage(np)
            
            // stampo il pie di pagina
            doc.setFontSize(config.fontSizeFooter)
            doc.setFontStyle('normal')
            doc.text(`Stampato il ${ new Date().toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'}) } alle ore ${ new Date().toLocaleTimeString()}`, config.marginLeft, yMax-config.altezzaRiga)
            doc.text(`Pagina ${np} di ${numPagine}`, config.larghezzaPagina-config.marginRight-20, yMax-config.altezzaRiga)
        }
    }

    doc.save('report-costi-dipendenti.pdf')
}

function stampaBaseReportCostiDipendenti(doc, config, data, nuovaPagina=false) {
    const { marginLeft, marginTop, xMax, altezzaHeader, numeroColonne, 
            larghezzaColonne, orientation, fontSizeHeader, fontSizeHeaderCell } = config

    const { giornoDal, giornoAl } = data

    if (nuovaPagina === true) {
        doc.addPage(orientation)
    }

    // stampo l'intestazione della pagina
    doc.setFontSize(fontSizeHeader)
    doc.setFontStyle('bold')
    let labelIntestazione = `Report costi dipendenti dal ${giornoDal.toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric'})} al ${giornoAl.toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric'})}`
    doc.text(labelIntestazione, marginLeft, marginTop * 2 / 3)

    let xCorrente = marginLeft
    let yCorrente = marginTop

    // stampo le linee dell'header
    xCorrente = marginLeft
    doc.line(marginLeft, yCorrente, xMax, yCorrente)
    doc.line(marginLeft, yCorrente, marginLeft, yCorrente + altezzaHeader)
    doc.line(marginLeft, yCorrente+altezzaHeader, xMax, yCorrente+altezzaHeader)
    doc.line(xMax, yCorrente, xMax, yCorrente+altezzaHeader)

    // stampo le linee verticali per dividere le colonne
    for (let i = 0; i < numeroColonne; i++) {
        let margineSinistro = xCorrente + larghezzaColonne

        if (i === 0)
            margineSinistro = xCorrente + (2*larghezzaColonne)

        doc.line( margineSinistro, yCorrente, margineSinistro, yCorrente + altezzaHeader)
        xCorrente += larghezzaColonne
    }

    doc.setFontSize(fontSizeHeaderCell)
    doc.setFontStyle('bold')
    xCorrente = marginLeft
    // stampo le etichette dell'header
    for (let i = 0; i < numeroColonne; i++) {
        
        let margineSinistro = xCorrente + (larghezzaColonne / 2)

        let testo = ""

        switch (i) {
            case 0:
                testo = "Nominativo"
                margineSinistro = xCorrente + larghezzaColonne
            break;

            case 1:
                testo = "Pasto"
            break;

            case 2:
                testo = "Q.tà"
            break;
            case 3:
                testo = "Dipendente"
            break;
            case 4:
                testo = "Azienda"
            break;

            default:
                testo = ""
        }

        doc.text(testo, margineSinistro, yCorrente + (altezzaHeader * 2 / 3), 'center')
        xCorrente += ( i === 0 ? (2*larghezzaColonne) : larghezzaColonne )

    }
}

function stampaCorpoReportCostiDipendenti(doc, config, data) {
    const { marginTop, marginLeft, altezzaHeader } = config
    
    const { corpo, totali } = data

    let yCorrente = 0
    let xCorrente = marginLeft

    let currentConfig = { yCorrente: yCorrente }

    currentConfig.yCorrente = marginTop + altezzaHeader

        // scorro le righe
        corpo.forEach( (riga) => {

            xCorrente = marginLeft

            const { cognome, nome, matricola, combinazioni, gruppi, totalecombinazioni, totalegruppi } = riga

            // stampo le combinazioni
            combinazioni.forEach( (c, idx) => {
                stampaRigaReportCostiDipendenti(doc, config, currentConfig, data, { ...c, cognomeNome: `${cognome} ${nome}`, matricola}, idx )
            })

            // stampo i totali delle combinazioni
            if (combinazioni.length > 0 ) {
                stampaRigaTotaliReportCostiDipendenti(doc, config, currentConfig, data, totalecombinazioni )
            }

            // stampo i gruppi
            gruppi.forEach( (g, idx) => {
                stampaRigaReportCostiDipendenti(doc, config, currentConfig, data, { ...g, cognomeNome: `${cognome} ${nome}`, matricola }, idx )
            })

            // stampo i totali dei gruppi
            if (gruppi.length > 0 ) {
                stampaRigaTotaliReportCostiDipendenti(doc, config, currentConfig, data, totalegruppi )
            }
        })

        // stampo i totali finali del report
        
        // totali combinazioni
        totali && totali.combinazioni && totali.combinazioni.forEach( (tc) => {
            stampaRigaTotaliFinaliReportCostiDipendenti(doc, config, currentConfig, data, tc)
        })

        // totali gruppi
        totali && totali.gruppi && totali.gruppi.forEach( (tg) => {
            stampaRigaTotaliFinaliReportCostiDipendenti(doc, config, currentConfig, data, tg)
        })
}

// stampa la riga del report
function stampaRigaReportCostiDipendenti(doc, config, currentConfig, data, riga, idx) {

    // recupero le informazioni della configurazione
    const { marginTop, marginLeft, marginBottom, marginBottomCell, altezzaRiga, altezzaPagina, altezzaHeader, 
        larghezzaColonne, xMax, fontSizeBody} = config

    // recupero le informazioni della riga da stampare
    const { nome, quantita, matricola, cognomeNome, costoDipendente, costoAzienda } = riga

        
    // controllo se devo stampare su una nuova pagina
    if (currentConfig.yCorrente > ( altezzaPagina - marginBottom)) {
        stampaBaseReportCostiDipendenti(doc, config, data, true)
        currentConfig.yCorrente = marginTop + altezzaHeader
    }

    let xCorrente = marginLeft

    doc.setFontSize(fontSizeBody)
    doc.setFontStyle('normal')

    // dipendente
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(idx === 0 ? `${cognomeNome} ${ matricola.trim().length > 0 ? ' - ('+matricola+')' : ""}` : "", xCorrente + marginBottomCell, currentConfig.yCorrente + ( altezzaRiga * 2 / 3 ))
    xCorrente +=  (2 * larghezzaColonne)

    // nome del pasto
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(nome, xCorrente +2, currentConfig.yCorrente + ( altezzaRiga * 2 / 3))
    xCorrente += larghezzaColonne

    // quantita pasti
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(quantita.toString(), xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    // costo azienda
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(costoDipendente ? `€ ${costoDipendente.toString().replace('.', ',')}` : "", xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne
    // costo dipendente
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(costoAzienda ? `€ ${costoAzienda.toString().replace('.', ',')}` : "", xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    //disegno la riga di fine gruppo
    doc.line(xMax, currentConfig.yCorrente, xMax, currentConfig.yCorrente + altezzaRiga )

    currentConfig.yCorrente += altezzaRiga
    
    // disegno la riga sotto
    doc.line(marginLeft, currentConfig.yCorrente, xMax, currentConfig.yCorrente)
}

function stampaRigaTotaliReportCostiDipendenti(doc, config, currentConfig, data, riga) {
    // recupero le informazioni della configurazione
    const { marginTop, marginLeft, marginBottom, marginBottomCell, altezzaRiga, altezzaPagina, altezzaHeader, 
        larghezzaColonne, xMax, fontSizeBody} = config

    // recupero le informazioni della riga da stampare
    const { quantita, costoDipendente, costoAzienda } = riga

        
    // controllo se devo stampare su una nuova pagina
    if (currentConfig.yCorrente > ( altezzaPagina - marginBottom)) {
        stampaBaseReportCostiDipendenti(doc, config, data, true)
        currentConfig.yCorrente = marginTop + altezzaHeader
    }

    let xCorrente = marginLeft

    doc.setFontSize(fontSizeBody)
    doc.setFontStyle('bold')

    // dipendente
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text('Totale', xCorrente + marginBottomCell, currentConfig.yCorrente + ( altezzaRiga * 2 / 3 ))
    xCorrente +=  (3 * larghezzaColonne)

    // quantita pasti
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(quantita.toString(), xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    // costo azienda
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(costoDipendente ? `€ ${costoDipendente.toString().replace('.', ',')}` : "", xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    // costo dipendente
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(costoAzienda ? `€ ${costoAzienda.toString().replace('.', ',')}` : "", xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    //disegno la riga di fine gruppo
    doc.line(xMax, currentConfig.yCorrente, xMax, currentConfig.yCorrente + altezzaRiga )

    currentConfig.yCorrente += altezzaRiga
    
    // disegno la riga sotto
    doc.line(marginLeft, currentConfig.yCorrente, xMax, currentConfig.yCorrente)
}

function stampaRigaTotaliFinaliReportCostiDipendenti(doc, config, currentConfig, data, riga) {
    // recupero le informazioni della configurazione
    const { marginTop, marginLeft, marginBottom, marginBottomCell, altezzaRiga, altezzaPagina, altezzaHeader, 
        larghezzaColonne, xMax, fontSizeHeaderCell} = config

    // recupero le informazioni della riga da stampare
    const { nome, quantita, costoDipendente, costoAzienda } = riga

        
    // controllo se devo stampare su una nuova pagina
    if (currentConfig.yCorrente > ( altezzaPagina - marginBottom)) {
        stampaBaseReportCostiDipendenti(doc, config, data, true)
        currentConfig.yCorrente = marginTop + altezzaHeader
    }

    let xCorrente = marginLeft

    doc.setFontSize(fontSizeHeaderCell)
    doc.setFontStyle('bold')

    // dipendente
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(`Totale ${nome}`, xCorrente + marginBottomCell, currentConfig.yCorrente + ( altezzaRiga * 2 / 3 ))
    xCorrente +=  (3 * larghezzaColonne)

    // quantita pasti
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(quantita.toString(), xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    // costo azienda
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(costoDipendente ? `€ ${costoDipendente.toString().replace('.', ',')}` : "", xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    // costo dipendente
    doc.line(xCorrente, currentConfig.yCorrente, xCorrente, currentConfig.yCorrente + altezzaRiga)
    doc.text(costoAzienda ? `€ ${costoAzienda.toString().replace('.', ',')}` : "", xCorrente + (larghezzaColonne / 2) +5, currentConfig.yCorrente + ( altezzaRiga * 2 / 3), 'right')
    xCorrente += larghezzaColonne

    //disegno la riga di fine gruppo
    doc.line(xMax, currentConfig.yCorrente, xMax, currentConfig.yCorrente + altezzaRiga )

    currentConfig.yCorrente += altezzaRiga
    
    // disegno la riga sotto
    doc.line(marginLeft, currentConfig.yCorrente, xMax, currentConfig.yCorrente)
}