import React, { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { withRouter } from "react-router";

// React-Redux reducers
import { connect } from "react-redux";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";

import { fetchOrdinazioni } from 'actions/ordinazioni'
import { fetchDipendenti } from 'actions/persone'
import { fetchGruppi } from 'actions/gruppi'
import { fetchMenus } from 'actions/menu'
import { formatDateToYYYYMMDD, createNewDate } from 'utils/date'
import { getValoreCampoDaFiltro, setValoreCampoFiltro } from 'utils/filter'

const gruppoHeaderStyle = {
    padding: "10px 10px",
    backgroundColor: "#7d6960",
    color: "white",
    width: "100%"
}

class GestioneOrdinazioniDipendenti extends Component {

    state = { 
        filtro: [
            { 'campoUI': 'Giorno', 'campo': 'Giorno', 'operatore': '_gt', 'valore': new Date()},
            { 'campoUI': 'Dipendente', 'campo': 'Persone._id', 'operatore': '=', 'valore': ''},
            { 'campoUI': 'Pasto', 'campo': 'Pasto', 'operatore': '=', 'valore': ''}
        ]
    }

    componentDidMount() {
        const { caricaDipendenti, caricaGruppi, azienda } = this.props

        const filtro = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]

        caricaDipendenti(filtro)
        caricaGruppi()
    }

    handleChange = (e) => {
        const nomeProprieta = e.target.name
        let valoreProprieta = e.target.value

        const { filtro } = this.state
        const { caricaOrdinazioni, caricaMenu } = this.props

        let newFiltro = setValoreCampoFiltro('campoUI', nomeProprieta, valoreProprieta, filtro)

        this.setState({ filtro: newFiltro })

        const filtroData = getValoreCampoDaFiltro('campoUI', 'Giorno', newFiltro)
        const filtroDipendente = getValoreCampoDaFiltro('campoUI', 'Dipendente', newFiltro)
        const filtroPasto = getValoreCampoDaFiltro('campoUI', 'Pasto', newFiltro)

        if ( filtroData.valore && filtroDipendente.valore.trim().length > 0 && filtroPasto.valore.trim().length > 0 ) {

            let giorno = filtroData.valore
            giorno = createNewDate(giorno)
            giorno.setMilliseconds(0)
            giorno.setSeconds(0)
            giorno.setMinutes(giorno.getTimezoneOffset())

            newFiltro = setValoreCampoFiltro('campo', 'Giorno', giorno, newFiltro)

            console.log(newFiltro)

            caricaOrdinazioni(newFiltro)
            caricaMenu(newFiltro)
        }
    }

    render() {

        const { filtro } = this.state
        const { dipendenti, gruppi, ordinazioni, menu } = this.props

        const data = getValoreCampoDaFiltro('campoUI', 'Giorno', filtro)
        const dipendente = getValoreCampoDaFiltro('campoUI', 'Dipendente', filtro)
        const pasto = getValoreCampoDaFiltro('campoUI', 'Pasto', filtro)

        const ordinazioniPasto = ordinazioni && ordinazioni.length > 0 ? ordinazioni[0] : null
        const menuPasto = menu && menu.length > 0 ? menu[0] : null

        return(
            <ScaffoldBase title="Gestione ordinazioni dipendenti">
                <GridContainer>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                        <CustomInput
                            labelText="Giorno"
                            id="giorno"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                            name: "Giorno",
                            type: "Date",
                            value: formatDateToYYYYMMDD(data.valore),
                            onChange: this.handleChange
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                        <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                            <InputLabel htmlFor="Dipendente">Dipendente</InputLabel>
                            <Select
                                onChange={this.handleChange}
                                value={dipendente.valore}
                                inputProps={{
                                name: 'Dipendente',
                                id: 'Dipendente'
                                }}
                            >
                                <MenuItem value="">
                                <em>Nessuno</em>
                                </MenuItem>
                                { dipendenti && dipendenti.length > 0 && dipendenti.map( (d) => {
                                    return (
                                        <MenuItem key={d._id} value={d._id}>{`${d["Cognome"]} ${d["Nome"]}`}</MenuItem>
                                    )
                                })
                                }
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                        <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                            <InputLabel htmlFor="Pasto">Pasto</InputLabel>
                            <Select
                                onChange={this.handleChange}
                                value={pasto.valore}
                                inputProps={{
                                name: 'Pasto',
                                id: 'Pasto'
                                }}
                            >
                                <MenuItem value="">
                                <em>Nessuno</em>
                                </MenuItem>
                                <MenuItem key={"pranzo"} value={"pranzo"}>
                                    Pranzo
                                </MenuItem>
                                <MenuItem key={"cena"} value={"cena"}>
                                    Cena
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    { gruppi && gruppi.length > 0 && gruppi.map( (g) => {
                        
                        // recupero le pietanze del gruppo
                        const pietanzeGruppo = menuPasto && menuPasto["Pietanze"].filter( (p) => {
                            return g._id === p["Gruppo"]._id || g._id === p["Gruppo"]
                        })

                        let disabilitaMenu = true

                        const adesso = new Date()
                    
                        let giornoPasto = menuPasto && menuPasto["Giorno"] ? menuPasto["Giorno"] : null
                        giornoPasto = createNewDate(giornoPasto)

                        giornoPasto.setTime( giornoPasto.getTime() + (1* 60 * 60 * 1000))
                        giornoPasto.setHours(23)
                        giornoPasto.setMinutes(59)
                        // TODO da migliorare l'ora di ultima prenotazione prendendola da database

                        disabilitaMenu = menuPasto && giornoPasto && giornoPasto.getTime() < adesso.getTime() ? true : false

                        return (    
                            <div key={g._id}>
                                <FormControl fullWidth={true}>
                                    <FormLabel style={gruppoHeaderStyle}>{g["Nome"]}</FormLabel>
                                    <FormGroup>
                                        { pietanzeGruppo && pietanzeGruppo.map( (p) => {
                                            const checked = ordinazioniPasto && ordinazioniPasto.includes(p._id) ? true : false

                                            return (
                                                <FormControlLabel key={p._id}
                                                    control={
                                                        <Checkbox
                                                        disabled={disabilitaMenu}
                                                        checked={checked}
                                                        onChange={() => this.handleCheck(checked, pasto.valore, p._id)}
                                                        color="primary"
                                                        />
                                                    }
                                                    label={p["Nome"]}
                                                />
                                                )
                                            }
                                            )
                                        }
                                    </FormGroup>
                                </FormControl>
                            </div>
                        )
                    })
                    }

                </GridContainer>
            </ScaffoldBase>
        )
    }
}

GestioneOrdinazioniDipendenti.propTypes = {
    classes: PropTypes.object.isRequired
  };

function mapDispatchToProps( dispatch ) {
    return {
        caricaOrdinazioni: (filtro) => dispatch(fetchOrdinazioni(filtro)),
        caricaDipendenti: (filtro) => dispatch(fetchDipendenti(filtro)),
        caricaGruppi: () => dispatch(fetchGruppi()),
        caricaMenu: (filtro) => dispatch(fetchMenus(filtro))
    }
}

function mapStateToProps({ authentication, ordinazioni, persone, gruppi, menu}) {
    return { 
        azienda: authentication.user["Azienda"],
        ordinazioni: ordinazioni.items,
        dipendenti: persone.items,
        gruppi: gruppi.items,
        menu: menu.items
    };
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(GestioneOrdinazioniDipendenti));