import { REACT_APP_BACKEND } from '../index.js';
import { filterParser } from 'utils/api.js';

export const GET_RECLAMI = "GET_RECLAMI";
export const GET_RECLAMO = "GET_RECLAMO";
export const DELETE_RECLAMO = "DELETE_RECLAMO";

export const getReclami = reclami => ({
  type: GET_RECLAMI,
  items: reclami
});

// recupera i reclami
export const fetchReclami = (filter=[]) => dispatch => {

  let queryString = filterParser(filter)

  if (queryString.length > 0 )
    queryString = `?${queryString}`

  return fetch(`${REACT_APP_BACKEND}reclamis${queryString}` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
    .then( (data) => { 
      let reclami = null;

      try {
        reclami = JSON.parse(data);
        reclami = reclami !== null ? reclami : null;
      } catch(e) {
        reclami = null;
      } finally {
        dispatch(getReclami(reclami));
      }
    })
  }

export const getReclamo = reclamo => ({
  type: GET_RECLAMO,
  reclamo
})

export const fetchReclamo = (id) => dispatch => {
    return fetch(`${REACT_APP_BACKEND}reclamis/${id}` )
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let reclamo = null;

    try {
      reclamo = JSON.parse(data);
      reclamo = reclamo !== null ? reclamo : null;
    } catch(e) {
      reclamo = null;
    } finally {
      dispatch(getReclamo(reclamo));
    }
  })
}

// aggiunge un reclamo
export const addReclamo = ( reclamo ) => dispatch => {
  fetch(`${REACT_APP_BACKEND}reclamis`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "POST", 
    body: JSON.stringify(reclamo)
  })
  .then( (res) => { 
    return res.status === 200 ? res.text() : null;
  })
  .then( (data) => { 
    let reclamo = null;

    try {
      reclamo = JSON.parse(data);
      reclamo = reclamo !== null ? reclamo : null;
    } catch(e) {
      reclamo = null;
    } finally {
      return reclamo;
    }
  })
}

// aggiorna il reclamo identificato dall'id passato
export const updateReclamo = ( id, reclamo ) => dispatch => {
  fetch(`${REACT_APP_BACKEND}reclamis/${id}`, 
  { headers: { "Content-Type" : "application/json"}, 
    method: "PUT", 
    body: JSON.stringify(reclamo)
  })
}

// cancello il reclamo identificato dall'id passato
export const deleteReclamo = (id) => dispatch => {
  fetch(`${REACT_APP_BACKEND}reclamis/${id}`, 
  { headers: {  "Content-Type" : "application/json"}, 
    method: "DELETE"
  })
}
