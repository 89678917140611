import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addReclamo, updateReclamo, fetchReclamo } from "actions/reclami";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";
import { createNewDate } from "utils/date"

// utils
//import { checkEmailPresente } from "utils/persone"

const nuovoReclamo = {
  "Giorno" : "",
  "Oggetto" : "",
  "Descrizione" : "",
  "Proponente" : "",
  "Ricevente" : ""
}

class DettaglioReclamo extends Component {

  state = {
    reclamo: nuovoReclamo,
    title : "",
    subtitle: "Dettaglio reclamo",
    messaggio: ""
  }

  componentDidMount() {
    const { match, caricaReclamo } = this.props

    const idReclamo = match.params.id

    if ( idReclamo && idReclamo.length > 0 ) {

      Promise.resolve()
      .then( () => caricaReclamo(idReclamo))
      .then( () => {
        const { currentReclamo } = this.props
        this.setState({ reclamo:  currentReclamo,
                        title: "Modifica reclamo" })
      })
    }
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {
    if (!e)
      return;

    e.preventDefault()
    const { currentReclamo, addReclamo, updateReclamo, utenteLoggato } = this.props

    const values = serializeForm(e.target, { hash: true, empty: true })
    /*
    // gestisco lo stato
    values["Stato"] = "in fase di attivazione";

    // gestisco il profilo
    values["Profilo"] = "dipendente";

    if ((currentDipendente && values["Password"] !== currentDipendente["Password"]) || (!currentDipendente && values["Password"].length > 0 ))
      values["Password"] = sha256(values["Password"])
    */
      // gestisco l'appartenenza all'azienda
    
    if ( !currentReclamo && utenteLoggato && utenteLoggato["Azienda"])
        values["Proponente"] = utenteLoggato["Azienda"]._id
        if( !values["Giorno"]){
            values["Giorno"] = createNewDate()
        }
        values["Ricevente"] = utenteLoggato['Azienda']['Azienda riferimento']

    Promise.resolve()
    .then( () => {
      let valido = this.validate(values)

      return valido
    })
    .then( (valido) => {
      const { esito, messaggio } = valido

      if (esito === true) {
        if ( currentReclamo) {
          // Modifico il dipendente esistente
          updateReclamo(currentReclamo["_id"], values);
        }
        else {
          Promise.resolve()
          .then( () => {
            return addReclamo(values)
          })
          .then( (reclamo) => {
            const { history } = this.props
    
            if (reclamo && reclamo._id !== undefined){
              history.push(`/dashboard/reclami/${reclamo._id}/edit`)
            }
            else
              history.push('/dashboard/reclami')
          })
        }
      }
      else { 
        this.setState({ messaggio })
      }  
    })
  }

  // Gestisco l'aggiornamento dei campi della form
  handleChange = (e) => {
    let nomeProprieta = e.target.name;
    let valoreProprieta = e.target.value;
    let updatedReclamo = {};

    updatedReclamo[nomeProprieta] = valoreProprieta;

    this.setState({ reclamo: { ...this.state.reclamo, ...updatedReclamo } })
  }

  // pulsante torna indietro
  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // gestisco la chiusura dello snack
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '' });
  };

  // gestisco l'uscita dallo snack
  handleExitedScnak = () => {
    this.setState({ messaggio: ''})
  };
  

  render() {
    const { reclamo, title, subtitle } = this.state
    const { classes } = this.props

    return (
      <div>
        <ScaffoldBase title={title} subtitle={subtitle}>
          <form onSubmit={this.handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Oggetto"
                  id="oggetto"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Oggetto",
                    value: reclamo["Oggetto"],
                    required: true,
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                      fullWidth={true}
                      id="descrizione"
                      label="Descrizione"
                      name="Descrizione"
                      multiline
                      rows="4"
                      value={reclamo["Descrizione"]}
                      onChange={this.handleChange}
                      margin="normal" />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" color="success">Salva</Button>
                <Button color="transparent" onClick={ () => this.tornaIndietro() }>Indietro</Button>
              </GridItem>
            </GridContainer>
          </form>   
          <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                    autoHideDuration={3000}
                    onClose={this.handleCloseSnack}
                    onExited={this.handleExitedSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messaggio}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnack}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
        </ScaffoldBase>
      </div>
    );
  }

  async validate(reclamo){
    // Controllo che i dati inseriti siano corretti prima di inviare la richiesta di salvataggio
    let result = {
      esito: true,
      messaggio: ""
    }

    if (reclamo === null ) {
      result.esito = false;
      result.messaggio = "Il reclamo deve essere valorizzato";
      return result;
    }

    // controllo la presenza del giorno
    if (reclamo['Giorno'] == null || reclamo['Giorno'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire il Giorno del reclamo. CAMPO OBBLIGATORIO";
      return result;
    }

    // controllo la presenza dell'oggetto
    if (reclamo['Oggetto'] == null || reclamo['Oggetto'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire l'Oggetto del reclamo. CAMPO OBBLIGATORIO";
      return result;
    }

    // controllo la presenza della descrizione
    if (reclamo['Descrizione'] == null || reclamo['Descrizione'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire la Descrizione del reclamo. CAMPO OBBLIGATORIO";
      return result;
    }
    
    return result;
  }
}

function mapStateToProps({ reclami, authentication }) {
  return {
    currentReclamo: reclami.currentReclamo,
    utenteLoggato: authentication.user
  }
}

function mapDispatchToProps( dispatch ){
  return {
    addReclamo: (reclamo) => dispatch(addReclamo(reclamo)),
    updateReclamo: (id, reclamo) => dispatch(updateReclamo(id, reclamo)),
    caricaReclamo: (id) => dispatch(fetchReclamo(id))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioReclamo));
