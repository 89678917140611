import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { updateCliente, fetchCliente } from "actions/clienti";
import { fetchGruppi } from "actions/gruppi";
import { updateCombinazione } from "actions/combinazioni";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import TextField from '@material-ui/core/TextField';
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import InputLabel from "@material-ui/core/InputLabel";
import Typography from "@material-ui/core/Typography"
import Snackbar from "@material-ui/core/Snackbar"
import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"


// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { setCostoGruppo, getCostoGruppo } from "utils/costigruppi"

class DettaglioCliente extends Component {
  state = {
    errore: null,
    azione: "Modifica",
    messaggio: "",
    cliente: null
  }

  componentDidMount() {
    const { caricaCliente, utenteLoggato, caricaGruppi } = this.props

    Promise.resolve()
    .then( () => { return caricaGruppi() })
    .then( () => {
      const idCliente = utenteLoggato && utenteLoggato["Azienda"] ? utenteLoggato["Azienda"]["_id"] : null

      if (idCliente && idCliente.length > 0) {
        return caricaCliente(idCliente)
      }
    })
    .then( (currentCliente) => {

      if ( currentCliente) {
        this.setState({ cliente: currentCliente })
      }
    })
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {
    if (!e)
      return;

    e.preventDefault()

    const { updateCliente, aggiornaCombinazione } = this.props
    const { cliente } = this.state

    let values = serializeForm(e.target, { hash: true })

    values["Costi pasti"] = cliente["Costi pasti"]
    values["Flag unica pietanza gruppo"] = cliente["Flag unica pietanza gruppo"]
    values["Flag pietanza obbligatoria gruppo"] = cliente["Flag pietanza obbligatoria gruppo"]
    values["Contatto amministrativo"] = cliente["Contatto amministrativo"] && cliente["Contatto amministrativo"].trim().length > 0 ? cliente["Contatto amministrativo"] : null
    values["Riferimento tecnico"] = cliente["Riferimento tecnico"] && cliente["Riferimento tecnico"].trim().length > 0 ? cliente["Riferimento tecnico"] : null

    // Filtro i campi dei gruppi che vengono salvati prima
    // cosi da pulire i campi passati al salvataggio dell'azienda
    Object.keys(values).forEach( function(item){
      if (item.includes('_Costo'))
        delete values[item]
    })

    if ( cliente) {
      // aggiorno l'azienda
      Promise.resolve()
      .then( () => {
        updateCliente(cliente["_id"], values);
      })
      .then( () => {
        // aggiorno le combinazioni
        let combinazioniDaSalvare = cliente["Combinazioniassociate"]

        combinazioniDaSalvare.forEach( (c) => {
          aggiornaCombinazione(c.id, c)
        })
      })
      .then( () => {
        this.setState({ messaggio: "Profilo azienda salvato"})
      })
    }
  }

  handleChange = (e) => {
    let nomeProprieta = e.target.name;
    let valoreProprieta = e.target.type !== 'checkbox' ? e.target.value : e.target.checked
    let updatedCliente = {};

    updatedCliente[nomeProprieta] = valoreProprieta;

    this.setState({ cliente: { ...this.state.cliente, ...updatedCliente } })
  }

  // gestisco la modifica dei costi dei gruppi lato dipendente
  handleChangeCostiGruppi = (e) => {
    const nomeProprieta = e.target.name
    const valoreProprieta = e.target.value

    // recupero l'id del gruppo da modificare
    let idGruppo = nomeProprieta.replace("costoDipendente-", "")

    if ( idGruppo.length > 0) {

      const { cliente } = this.state

      // creo una copia del campo Costi pasti
      let newCostiPasti = JSON.parse(JSON.stringify(cliente["Costi pasti"]))
      const valore = { 'Misura': '', 'Valore': valoreProprieta }

      // aggiorno il campo costi pasti
      setCostoGruppo(newCostiPasti, idGruppo, valore, 'dipendente')

      // creo una copia del cliente
      let newCliente = JSON.parse( JSON.stringify( cliente ) )
      
      // aggiorno i costi dei pasti del nuovo cliente
      newCliente["Costi pasti"] = newCostiPasti

      // aggiorno l'interfaccia
      this.setState({ cliente: newCliente })

    }

  }

  // gestisco la modifica del costo a carico del dipendente della combinazione
  handleChangeCostoDipendenteCombinazione = (e) => {
    let valoreProprieta = e.target.value
    let nomeProprieta = e.target.name

    let idCombinazione = nomeProprieta.replace("costoDipendente-", "")

    if ( idCombinazione.length > 0 ) {
      const { cliente } = this.state

      let newCombinazioneAssociate = cliente['Combinazioniassociate'].map( (c) => {
        if (c.id !== idCombinazione)
          return c
        else {
          c["Prezzodipendente"] = valoreProprieta
          return c
        }
      })

      // aggiorno la combinazione 
      let newCliente = cliente
      newCliente['Combinazioniassociate'] = newCombinazioneAssociate

      this.setState({ cliente: newCliente})
    }
  }

  // gestisco la chiusura dello snack
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '', disabledButton: false });
  };

  // gestisco l'uscita dallo snack
  handleExitedScnak = () => {
    this.setState({ messaggio: ''})
  };

  render() {

    const { cliente, messaggio } = this.state
    const { classes, gruppi } = this.props

    let combinazioni = cliente && cliente["Combinazioniassociate"] ? cliente["Combinazioniassociate"] : null

    gruppi.sort((a,b) => {
      if (a["Ordinamento"] > b["Ordinamento"])
        return 1

      if (a["Ordinamento"] < b["Ordinamento"])
        return -1
      
        return 0
    })

    return (
      <div>
        <ScaffoldBase title="Profilo azienda" subtitle="Dettaglio profilo azienda">
        { cliente && (
          <form onSubmit={this.handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  labelText="Ragione sociale"
                  id="ragioneSociale"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Ragione sociale",
                    required: true,
                    value: cliente["Ragione sociale"],
                    readOnly: true
                  }}
                />
              </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInput
                    labelText="Indirizzo"
                    id="indirizzo"
                    formControlProps={{ fullWidth: true }}
                    inputProps = {{
                      name: "Indirizzo",
                      defaultValue: cliente["Indirizzo"]
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="CAP"
                    id="cap"
                    formControlProps={{ fullWidth: true }}
                    inputProps = {{
                      name: "CAP",
                      value: cliente["CAP"],
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInput
                    labelText="Città"
                    id="citta"
                    formControlProps={{ fullWidth: true }}
                    inputProps = {{
                      name: "Citta",
                      value: cliente["Citta"],
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={1}>
                  <CustomInput
                    labelText="Provincia"
                    id="provincia"
                    formControlProps={{ fullWidth: true }}
                    inputProps = {{
                      name: "Provincia",
                      value: cliente["Provincia"],
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Telefono"
                    id="telefono"
                    formControlProps={{ fullWidth: true }}
                    inputProps = {{
                      name: "Telefono",
                      value: cliente["Telefono"],
                      onChange: this.handleChange
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <CustomInput
                    labelText="Partita IVA"
                    id="partitaIVA"
                    formControlProps={{ fullWidth: true }}
                    inputProps = {{
                      name: "Partita IVA",
                      value: cliente["Partita IVA"],
                      readOnly: true
                    }}
                  />
                </GridItem>
                { cliente && cliente["Tipologia pasti"].trim().length > 0 && (cliente["Tipologia pasti"] === 'pranzo' || cliente["Tipologia pasti"] === 'pranzo/cena' ) && (
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Ora ultima prenotazione pranzo"
                      id="oraUltimaPrenotazionePranzo"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "Ora ultima prenotazione pranzo",
                        type: "time",
                        value: cliente["Ora ultima prenotazione pranzo"],
                        readOnly: true,
                        disabled: true
                      }}
                      labelProps={{
                        shrink: true
                      }}
                    />
                  </GridItem>
                )}

                { cliente && cliente["Tipologia pasti"].trim().length > 0 && (cliente["Tipologia pasti"] === 'cena' || cliente["Tipologia pasti"] === 'pranzo/cena' ) && (
                  <GridItem xs={12} sm={12} md={4}>
                    <CustomInput
                      labelText="Ora ultima prenotazione cena"
                      id="oraUltimaPrenotazioneCena"
                      formControlProps={{ fullWidth: true }}
                      inputProps={{
                        name: "Ora ultima prenotazione cena",
                        type: "time",
                        value: cliente["Ora ultima prenotazione cena"],
                        readOnly: true,
                        disabled: true
                      }}
                      labelProps={{
                        shrink: true
                      }}
                    />
                </GridItem>
                )}
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                    <h3>Opzioni pasti dipendenti</h3>
                </GridItem>
                <GridItem>
                  <Checkbox 
                    name="Flag unica pietanza gruppo" 
                    id="flagUnicaPietanzaGruppo" 
                    onChange={this.handleChange} 
                    checked={cliente["Flag unica pietanza gruppo"] && cliente["Flag unica pietanza gruppo"] === true ? true : false } 
                  />
                  <InputLabel htmlFor="flagUnicaPietanzaGruppo">Unica pietanza gruppo</InputLabel>
                </GridItem>
                <GridItem>
                  <Checkbox 
                    name="Flag pietanza obbligatoria gruppo" 
                    id="flagPietanzaObbligatoriaGruppo" 
                    onChange={this.handleChange} 
                    checked={cliente["Flag pietanza obbligatoria gruppo"] && cliente["Flag pietanza obbligatoria gruppo"] === true ? true : false } 
                  />
                  <InputLabel htmlFor="flagPietanzaObbligatoriaGruppo">Pietanza obbligatoria gruppo</InputLabel>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12} lg={12}>
                  <h3>Contatti</h3>
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6} >
                    <TextField
                      fullWidth={true}
                      id="contattoAmministrativo"
                      label="Contatto amministrativo"
                      name="Contatto amministrativo"
                      multiline
                      rows="4"
                      value={cliente["Contatto amministrativo"]}
                      onChange={this.handleChange}
                      margin="normal" />
                </GridItem>
                <GridItem xs={12} sm={6} md={6} lg={6} >
                    <TextField
                      fullWidth={true}
                      id="riferimentoTecnico"
                      label="Riferimento tecnico"
                      name="Riferimento tecnico"
                      multiline
                      rows="4"
                      value={cliente["Riferimento tecnico"]}
                      onChange={this.handleChange}
                      margin="normal" />
                </GridItem>
              </GridContainer>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6} lg={6} >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                      <h3>Costi combinazioni</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                      { combinazioni && combinazioni.length > 0 && (
                        <div>
                          { combinazioni.map( (c) => {
                            return (
                              <GridContainer key={c.id} spacing={0}>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                  <Typography variant="subheading">{c["Descrizione"]}</Typography>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                  <CustomInput 
                                    labelText="Costo azienda"
                                    id={`costoAzienda${c.id}`}
                                    formControlProps={{ 
                                      fullWidth: true, 
                                      style:{ marginTop: 0 },
                                      disabled: true
                                    }}
                                    inputProps={{
                                      name: `costoAzienda-${c.id}`,
                                      type: "number",
                                      value: c["Prezzoazienda"],
                                      readOnly: true
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                  <CustomInput 
                                    labelText="Costo dipendente"
                                    id={`costoDipendente${c.id}`}
                                    formControlProps={{ 
                                      fullWidth: true,
                                      style:{ marginTop: 0 } 
                                    }}
                                    inputProps={{
                                      name: `costoDipendente-${c.id}`,
                                      type: "number",
                                      value: c["Prezzodipendente"],
                                      onChange: this.handleChangeCostoDipendenteCombinazione
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                            )
                          })}
                        </div>
                      )}
                  </GridItem>
                </GridContainer>
              </GridItem>

              <GridItem xs={12} sm={12} md={6} lg={6} >
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                      <h3>Costi gruppi</h3>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                      { gruppi && gruppi.length > 0 && (
                        <div>
                          { gruppi.map( (g) => {

                            let costoAzienda = getCostoGruppo( cliente["Costi pasti"], g.id, 'azienda')
                            let costoDipendente = getCostoGruppo( cliente["Costi pasti"], g.id, 'dipendente')

                            costoAzienda = costoAzienda ? costoAzienda["Valore"] : null
                            costoDipendente = costoDipendente ? costoDipendente["Valore"] : null

                            return (
                              <GridContainer key={g.id} spacing={0}>
                                <GridItem xs={12} sm={12} md={6} lg={6}>
                                  <Typography variant="subheading">{g["Nome"]}</Typography>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                  <CustomInput 
                                    labelText="Costo azienda"
                                    id={`costoAzienda${g.id}`}
                                    formControlProps={{ 
                                      fullWidth: true, 
                                      style:{ marginTop: 0 },
                                      disabled: true
                                    }}
                                    inputProps={{
                                      name: `costoAzienda-${g.id}`,
                                      type: "number",
                                      value: costoAzienda,
                                      readOnly: true
                                    }}
                                  />
                                </GridItem>
                                <GridItem xs={6} sm={6} md={3} lg={3}>
                                  <CustomInput 
                                    labelText="Costo dipendente"
                                    id={`costoDipendente${g.id}`}
                                    formControlProps={{ 
                                      fullWidth: true,
                                      style:{ marginTop: 0 } 
                                    }}
                                    inputProps={{
                                      name: `costoDipendente-${g.id}`,
                                      type: "number",
                                      value: costoDipendente,
                                      onChange: this.handleChangeCostiGruppi
                                    }}
                                  />
                                </GridItem>
                              </GridContainer>
                           )
                          })}
                        </div>
                      )}
                  </GridItem>
                </GridContainer>
              </GridItem>

            </GridContainer> 
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" color="success">Salva</Button>
              </GridItem>
            </GridContainer>
          </form>   
        )}
        { messaggio && messaggio.length > 0 && (
          <Snackbar
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={true}
              autoHideDuration={3000}
              onClose={this.handleCloseSnack}
              onExited={this.handleExitedSnack}
              ContentProps={{
                  'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{messaggio}</span>}
              action={[
                  <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.handleCloseSnack}
                  >
                      <CloseIcon />
                  </IconButton>
              ]}
          />
        )}
        </ScaffoldBase>
      </div>
    );
  }

  validate = (cliente) => {
    // Controllo che i dati inseriti siano corretti prima di inviare la richiesta di salvataggio
    let result = {
      esito: true,
      messaggio: ""
    }

    if (cliente === null ) {
      result.esito = false;
      result.messaggio = "Il cliente deve essere valorizzato";
      return result;
    }

    if (cliente['ragioneSociale'] == null || cliente['ragioneSociale'].length <= 0) {
      result.esito = false;
      result.messaggio = "Inserire la ragione sociale del cliente. CAMPO OBBLIGATORIO";
      return result;
    }

    return result;
  }

  salvaCliente = cliente => {
    console.log('salvaCliente')
    let controlloValidazione = this.validate(cliente);

    if (controlloValidazione.esito === true) {
      console.log('salva sul db')
    }
    else {
     console.log(controlloValidazione.messaggio)
    }
  }
}

function mapStateToProps({ clienti, authentication, gruppi }) {
  return {
    currentCliente: clienti.currentCliente,
    utenteLoggato: authentication.user,
    gruppi: gruppi.items
  }
}

function mapDispatchToProps( dispatch ){
  return {
    updateCliente: (id, cliente) => dispatch(updateCliente(id, cliente)),
    caricaCliente: (id) => dispatch(fetchCliente(id)),
    caricaGruppi: () => dispatch(fetchGruppi()),
    aggiornaCombinazione: (id, comb) => dispatch(updateCombinazione(id, comb))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioCliente));
