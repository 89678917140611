import { REACT_APP_BACKEND } from '../index.js';

export const GET_ALLERGENI = "GET_ALLERGENI";

export const getAllergeni = allergeni => ({
  type: GET_ALLERGENI,
  items: allergeni
});

// recupera gli allergeni
export const fetchAllergeni = () => dispatch => (
    fetch(`${REACT_APP_BACKEND}allergenis` )
    .then( (res) => { 
      return res.status === 200 ? res.text() : null;
    })
	  .then( (data) => { 
      let allergeni = null;

      try {
        allergeni = JSON.parse(data);
        allergeni = allergeni !== null ? allergeni : null;
      } catch(e) {
        allergeni = null;
      } finally {
        dispatch(getAllergeni(allergeni));
      }
    })
)