// @material-ui/icons
import Assessment from "@material-ui/icons/Assessment";
import Dashboard from "@material-ui/icons/Dashboard";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import RestaurantMenu from "@material-ui/icons/RestaurantMenu";
import FastFood from "@material-ui/icons/Fastfood";
import Assignment from "@material-ui/icons/Assignment";
import ExitToApp from "@material-ui/icons/ExitToApp";
import TableChart from "@material-ui/icons/TableChart";
import Request from "@material-ui/icons/QuestionAnswer";
import Claim from "@material-ui/icons/ReportProblem";
import ViewList from "@material-ui/icons/ViewList";

// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";

// project's components
import ListaClienti from "views/Amministratore/Clienti/ListaClienti.jsx";
import DettaglioCliente from "views/Amministratore/Clienti/DettaglioCliente.jsx";
import DettaglioMenu from "views/Amministratore/Menu/DettaglioMenu.jsx";
import ListaPietanze from "views/Amministratore/Pietanze/ListaPietanze.jsx";
import DettaglioPietanza from "views/Amministratore/Pietanze/DettaglioPietanza";
import DettaglioReclamo from "views/Amministratore/Reclami/DettaglioReclamo.jsx";
import ReclamiCliente from "views/Amministratore/Reclami/ListaReclami.jsx";
import DettaglioRichiesta from "views/Amministratore/Richieste/DettaglioRichiesta.jsx";
import RichiesteCliente from "views/Amministratore/Richieste/ListaRichieste.jsx";
import Profilo from "views/Profilo/Profilo";
import ListaOrdinazioni from "views/Amministratore/Ordinazioni/ListaOrdinazioni";
import Logout from "layouts/Logout/Logout";
import TemplateMenuLista from "views/Amministratore/Menu/TemplateMenuLista";
import TemplateMenuDettaglio from "views/Amministratore/Menu/TemplateMenuDettaglio";
import PlanningMenu from "views/Amministratore/Menu/PlanningMenu";
import ListaGruppi from "views/Amministratore/Pietanze/ListaGruppi";
import DettaglioGruppo from "views/Amministratore/Pietanze/DettaglioGruppo";
import RiepilogoVenduto from "views/Amministratore/Report/RiepilogoVenduto";
import DettaglioOrdinazioneAdmin from "views/Amministratore/Ordinazioni/DettaglioOrdinazioneAdmin";
import ListaOrdinazioniAdmin from "views/Amministratore/Ordinazioni/ListaOrdinazioniAdmin";

const adminRoutes = [
  {
      path: "/dashboard/profilo",
      sidebarName: "Profilo",
      navbarName: "Profilo",
      icon: Person,
      component: Profilo
  },
  {
    path: "/dashboard/clienti/:id/edit",
    sidebarName: "Clienti",
    navbarName: "Anagrafica clienti",
    icon: People,
    component: DettaglioCliente,
    invisible: true
  },
  {
    path: "/dashboard/clienti/add",
    sidebarName: "Clienti",
    navbarName: "Anagrafica clienti",
    icon: People,
    component: DettaglioCliente,
    invisible: true
  },
  {
    path: "/dashboard/clienti",
    sidebarName: "Clienti",
    navbarName: "Anagrafica clienti",
    icon: People,
    component: ListaClienti
  },
  {
    path: "/dashboard/gruppi/:id/edit",
    sidebarName: "Gestione gruppi",
    navbarName: "Anagrafica gruppi",
    icon: ViewList,
    component: DettaglioGruppo,
    invisible: true
  },
  {
    path: "/dashboard/gruppi",
    sidebarName: "Gestione gruppi",
    navbarName: "Anagrafica gruppi",
    icon: ViewList,
    component: ListaGruppi
  },
  {
    path: "/dashboard/pietanze/:id/edit",
    sidebarName: "Pietanze",
    navbarName: "Anagrafica pietanze",
    icon: FastFood,
    component: DettaglioPietanza,
    invisible: true
  },
  {
    path: "/dashboard/pietanze/add",
    sidebarName: "Pietanze",
    navbarName: "Anagrafica pietanze",
    icon: FastFood,
    component: DettaglioPietanza,
    invisible: true
  },
  {
    path: "/dashboard/pietanze",
    sidebarName: "Pietanze",
    navbarName: "Anagrafica pietanze",
    icon: FastFood,
    component: ListaPietanze
  },
  {
    path: "/dashboard/planning-menu",
    sidebarName: "Planning Menu",
    navbarName: "Planning Menu",
    icon: TableChart,
    component: PlanningMenu
  },
  {
    path: "/dashboard/menu/:id/edit",
    sidebarName: "Dettaglio Menu",
    navbarName: "Anagrafica Menu",
    icon: RestaurantMenu,
    component: DettaglioMenu,
    invisible: true
  },
  {
    path: "/dashboard/menu/add",
    sidebarName: "Menu",
    navbarName: "Anagrafica Menu",
    icon: RestaurantMenu,
    component: DettaglioMenu,
    invisible: true
  },
  {
    path: "/dashboard/template-menu/:id/edit",
    sidebarName: "Dettaglio template menu",
    navbarName: "Dettaglio template menu",
    icon: RestaurantMenu,
    component: TemplateMenuDettaglio,
    invisible: true
  },
  {
    path: "/dashboard/template-menu/add",
    sidebarName: "Template Menu",
    navbarName: "Template Menu",
    icon: RestaurantMenu,
    component: TemplateMenuDettaglio,
    invisible: true
  },
  {
    path: "/dashboard/template-menu",
    sidebarName: "Template Menu",
    navbarName: "Template Menu",
    icon: RestaurantMenu,
    component: TemplateMenuLista
  },
  {
    path: "/dashboard/ordinazioni",
    sidebarName: "Riepilogo ordinazioni",
    navbarName: "Riepilogo ordinazioni",
    icon: Assignment,
    component: ListaOrdinazioni
  },
  {
    path: "/dashboard/lista-ordinazioni/:id",
    sidebarName: "Dettaglio ordinazione",
    navbarName: "Dettaglio ordinazione",
    icon: Assignment,
    component: DettaglioOrdinazioneAdmin,
    invisible: true
  },
  {
    path: "/dashboard/lista-ordinazioni/add",
    sidebarName: "Lista ordinazioni",
    navbarName: "Lista ordinazioni",
    icon: Assignment,
    component: DettaglioOrdinazioneAdmin,
    invisible: true
  },
  {
    path: "/dashboard/lista-ordinazioni",
    sidebarName: "Lista ordinazioni",
    navbarName: "Lista ordinazioni",
    icon: Assignment,
    component: ListaOrdinazioniAdmin
  },
  {
    path: "/dashboard/dettaglio-ordinazione",
    sidebarName: "Dettaglio ordinazione",
    navbarName: "Dettaglio ordinazione",
    icon: Assignment,
    component: DettaglioOrdinazioneAdmin,
    invisible: true
  },
  {
    path: "/dashboard/reclami/:id/view",
    sidebarName: "Reclami",
    navbarName: "Reclami",
    icon: Claim,
    component: DettaglioReclamo,
    invisible: true
  },/*
  {
      path: "/dashboard/reclami/add",
      sidebarName: "Reclami",
      navbarName: "Reclami",
      icon: Claim,
      component: DettaglioReclamo,
      invisible: true
  },*/
  {
      path: "/dashboard/reclami",
      sidebarName: "Reclami",
      navbarName: "Reclami",
      icon: Claim,
      component: ReclamiCliente
  },
  {
      path: "/dashboard/richieste/:id/view",
      sidebarName: "Richieste",
      navbarName: "Richieste",
      icon: Request,
      component: DettaglioRichiesta,
      invisible: true
  },
  /*{
      path: "/dashboard/richieste/add",
      sidebarName: "Richieste",
      navbarName: "Richieste",
      icon: Request,
      component: DettaglioRichiesta,
      invisible: true
  },*/
  {
      path: "/dashboard/richieste",
      sidebarName: "Richieste",
      navbarName: "Richieste",
      icon: Request,
      component: RichiesteCliente
  },
  {
    path: "/dashboard/riepilogo-venduto",
    sidebarName: "Riepilogo venduto",
    navbarName: "Riepilogo venduto",
    icon: Assessment,
    component: RiepilogoVenduto
  },
  {
    path: "/dashboard/logout",
    sidebarName: "Logout",
    navbarName: "Logout",
    icon: ExitToApp,
    component: Logout
  },
  {
      path: "/dashboard",
      sidebarName: "Dashboard",
      navbarName: "Dashboard",
      icon: Dashboard,
      component: DashboardPage,
      invisible: true
  }
];

export default adminRoutes;
