import React, { Component } from "react";
import PropTypes from "prop-types";

import { compose } from "redux";
import { withRouter } from "react-router";

// React-Redux reducers
import { connect } from "react-redux";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Dialog from "@material-ui/core/Dialog/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent/DialogContent"
import DialogActions from "@material-ui/core/DialogActions/DialogActions"
import List from "@material-ui/core/List/List"
import ListItem from "@material-ui/core/ListItem/ListItem"
import ListItemText from "@material-ui/core/ListItemText/ListItemText"

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button"

import { fetchDipendenti } from 'actions/persone'
import { fetchGruppi } from "actions/gruppi"
import { getOrdinazioni } from "actions/ordinazioni"
import { formatDateToYYYYMMDD } from 'utils/date'
import { getValoreCampoDaFiltro, setValoreCampoFiltro } from 'utils/filter'
import { filterParser, getBigData } from "utils/api";

class ListaOrdinazioni extends Component {

    state = { 
        filtro: [
            { 'campo': 'Data', 'campoUI': 'Data','operatore': '=', 'valore': formatDateToYYYYMMDD(new Date()) },
            { 'campo': 'Azienda', 'campoUI': 'Azienda', 'operatore': '=', 'valore': ''},
            { 'campo': 'Persone', 'campoUI': 'Persone', 'operatore': '=', 'valore': ''},
            { 'campo': 'Pasto', 'campoUI': 'Pasto', 'operatore': '=', 'valore': '' }
        ],
        currentOrdinazione: null,
        messaggioIniziale: "Imposta i filtri e clicca sul pulsante Cerca per visualizzare le ordinazioni"
    }

    componentDidMount() {
        const { caricaDipendenti, azienda, caricaGruppi } = this.props

        // carico i dipendenti
        const filtroDipendenti = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]
        caricaDipendenti(filtroDipendenti)

        // carico i gruppi
        const filtroGruppi = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda["Azienda riferimento"]}]
        caricaGruppi(filtroGruppi)

        // imposto il filtro sull'id dell'azienda
        const { filtro } = this.state
        let newFiltro = setValoreCampoFiltro('campo', 'Azienda', azienda.id, filtro)

        this.setState({ filtro: newFiltro })
    }

    // gestisco il cambiamento dei parametri di filtro
    handleChange = (e) => {
        const nomeProprieta = e.target.name
        let valoreProprieta = e.target.value
    
        const { filtro } = this.state
    
        let newFiltro = setValoreCampoFiltro('campoUI', nomeProprieta, valoreProprieta, filtro)
    
        this.setState({ filtro: newFiltro })
    }

    // gestisco il click sul pulsante cerca
    handleCerca = (e) => {
        if (!e)
            return

        e.preventDefault()
        const { filtro } = this.state

        let queryString = ""

        // devo sistemare la data nel filtro percè sul db la data è memorizzata nel formato (giorno precedente ore 23)
        let filtroQuery = JSON.parse(JSON.stringify(filtro))

        let dataConvertita = getValoreCampoDaFiltro('campo', 'Data', filtroQuery)

        dataConvertita = dataConvertita && dataConvertita.valore ? dataConvertita.valore : ""

        filtroQuery = setValoreCampoFiltro('campo', 'Data', dataConvertita, filtroQuery)

        queryString = filterParser(filtroQuery)

        if (queryString.trim().length > 0)
            queryString = `?${queryString}`

        getBigData('ordinazionis', queryString, 100)
        .then( (res) => {
            const { setOrdinazioni } = this.props
            setOrdinazioni(res)
            this.setState({ messaggioIniziale: "" })
        })
    }

    // apre il dettaglio dell'ordinazione
    apriDettaglioOrdinazione = (ordinazione) => {
        this.setState({ currentOrdinazione: ordinazione })
    }

    // chiude il dettaglio
    chiudiDettaglioOrdinazione = () => {
        this.setState({ currentOrdinazione: null })
    }

    render() {

        const { ordinazioni, classes, dipendenti, gruppi } = this.props
        const { filtro, currentOrdinazione, messaggioIniziale } = this.state

        const giorno = getValoreCampoDaFiltro('campo', 'Data', filtro)
        const dipendente = getValoreCampoDaFiltro('campo', 'Persone', filtro)
        const pasto = getValoreCampoDaFiltro('campo', 'Pasto', filtro)

        return(
            <ScaffoldBase title="Elenco ordinazioni effettuate dai dipendenti">
                <GridContainer>
                    <GridItem xs={12} sm={4} md={2} lg={2}>
                        <CustomInput
                        labelText="Giorno"
                        id="giorno"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                            name: "Data",
                            type: "Date",
                            value: giorno && giorno.valore ? giorno.valore : "",
                            onChange: this.handleChange
                        }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={3} md={3} lg={3}>
                        <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                        <InputLabel htmlFor="Pasto">Pasto</InputLabel>
                        <Select
                            value={ pasto && pasto.valore ? pasto.valore : ""}
                            onChange={this.handleChange}
                            inputProps={{
                            
                            name: 'Pasto',
                            id: 'pasto'
                            }}
                        >
                            <MenuItem value="">
                            <em>Nessuno</em>
                            </MenuItem>
                            <MenuItem value="pranzo">
                            Pranzo
                            </MenuItem>
                            <MenuItem value="cena">
                            Cena
                            </MenuItem>
                        </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={4} md={4} lg={4}>
                        <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                        <InputLabel htmlFor="Dipendente">Dipendente</InputLabel>
                        <Select
                            onChange={this.handleChange}
                            value={ dipendente && dipendente.valore ? dipendente.valore : ""}
                            inputProps={{
                            name: 'Persone',
                            id: 'persone'
                            }}
                        >
                            <MenuItem value="">
                            <em>Nessuno</em>
                            </MenuItem>
                            { dipendenti && dipendenti.length > 0 && 
                                dipendenti.sort( (a, b) => {
                                    if (a['Cognome'] < b['Cognome'])
                                        return -1
                                    if (a['Cognome'] > b['Cognome'])
                                        return 1
                                    if (a['Nome'] < b['Nome'])
                                        return -1
                                    if (a['Nome'] > b['Nome'])
                                        return 1
                                    
                                    return 0
                                }).map( (d) => {
                                    return (
                                        <MenuItem key={d._id} value={d._id}>{d["Cognome"] && d["Cognome"].length > 0 ? d["Cognome"] : ""}{d["Nome"] && d["Nome"].length > 0 ? ` ${d["Nome"]}` : ""}</MenuItem>
                                    )
                            })
                            }
                        </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                        <Button type="submit" color="success" onClick={this.handleCerca} style={{ marginTop: "27px" }}>Cerca</Button><br />
                    </GridItem>
                </GridContainer>

                <GridContainer>
                    { ordinazioni && ordinazioni.length > 0 && (
                        <GridItem xs={12} sm={12} md={12}>
                            <Typography>Totale ordinazioni: { ordinazioni.length } </Typography>
                            <Table className={classes.table}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Dipendente</TableCell>
                                        <TableCell>Giorno</TableCell>
                                        <TableCell>Pranzo/Cena</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    { ordinazioni.sort( (a, b) => {
                                        if ( a["Persone"] && b["Persone"]) {
                                            if (a["Persone"]["Cognome"] < b["Persone"]["Cognome"])
                                                return -1
                                            if (a["Persone"]["Cognome"] > b["Persone"]["Cognome"])
                                                return 1
                                            if (a["Persone"]["Nome"] < b["Persone"]["Nome"])
                                                return -1
                                            if (a["Persone"]["Nome"] > b["Persone"]["Nome"])
                                                return 1
                                            return 0
                                        }

                                        return 0
                                    })
                                    .map( (o) => {
                                    return (
                                        <TableRow key={o._id} onClick={() => this.apriDettaglioOrdinazione(o)}>
                                            <TableCell>{ o["Persone"] ? `${o["Persone"]["Cognome"]} ${o["Persone"]["Nome"]}` : 'Persona non valorizzata'}</TableCell>
                                            <TableCell>{new Date(o["Data"]).toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric'})}</TableCell>
                                            <TableCell>{o["Pasto"]}</TableCell>
                                        </TableRow>
                                    )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </GridItem>
                    )}
                    {
                        ((messaggioIniziale === "" ) && (!ordinazioni || ordinazioni.length === 0)) && (
                            <Typography>
                                Non risultano ordinazioni presenti
                            </Typography>
                        )
                    }
                    { messaggioIniziale !== "" && ( 
                        <Typography>
                            {messaggioIniziale}
                        </Typography>
                    )}
                </GridContainer>
                { currentOrdinazione && (
                    <Dialog
                    open={currentOrdinazione !== null}
                    onClose={this.chiudiDettaglioOrdinazione}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">Dettaglio ordinazione</DialogTitle>
                    <DialogContent>
                        <GridContainer>
                            <GridItem xs={8} sm={8} md={8} lg={8}>
                                <Typography>{`Ordinazione del ${new Date(currentOrdinazione["Data"]).toLocaleDateString('it-IT', { day: '2-digit', month: '2-digit', year: 'numeric' })}`}</Typography>
                            </GridItem>
                            <GridItem xs={4} sm={4} md={4} lg={4}>
                                <Typography>Pasto: {currentOrdinazione["Pasto"]}</Typography>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                <Typography>Dipendente: { currentOrdinazione["Persone"] ? `${currentOrdinazione["Persone"]["Cognome"]} ${currentOrdinazione["Persone"]["Nome"]}` : ""}</Typography>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12} lg={12}>
                                { currentOrdinazione["Pietanze"] && currentOrdinazione["Pietanze"].length > 0 && (
                                    <List>
                                        {currentOrdinazione["Pietanze"].map( (p) => {

                                            const idGruppo = p["Gruppo"]
                                            let gruppo = null
                                            if (idGruppo.length > 0 ) {
                                                gruppo = gruppi.find( (g) => {
                                                    return g.id === idGruppo
                                                })
                                            }

                                            return (
                                                <ListItem>
                                                    <ListItemText primary={p["Nome"]} secondary={gruppo && gruppo["Nome"] && gruppo["Nome"].length > 0 ? gruppo["Nome"] : ""} />
                                                </ListItem>
                                            )
                                        })}
                                    </List>
                                )}
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.chiudiDettaglioOrdinazione} color="transparent">Chiudi</Button>
                    </DialogActions>
                </Dialog>
                )}
            </ScaffoldBase>
        )
    }
}

ListaOrdinazioni.propTypes = {
    classes: PropTypes.object.isRequired
  };

function mapDispatchToProps( dispatch ) {
    return {
        caricaDipendenti: (filtro) => dispatch(fetchDipendenti(filtro)),
        setOrdinazioni: (ordinazioni) => dispatch(getOrdinazioni(ordinazioni)),
        caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro))
    }
}

function mapStateToProps({ authentication, ordinazioni, persone, gruppi }) {
    return { 
        azienda: authentication.user["Azienda"],
        ordinazioni: ordinazioni.items,
        dipendenti: persone.items,
        gruppi: gruppi.items
    };
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaOrdinazioni));