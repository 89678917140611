import React, { Component } from "react";
import serializeForm from "form-serialize";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { addRichiesta, updateRichiesta, fetchRichiesta, fetchRichieste } from "actions/richieste";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import TextField from '@material-ui/core/TextField';

// Theme components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

// utils
//import { checkEmailPresente } from "utils/persone"

const nuovaRichiesta = {
    "Giorno" : "",
    "Oggetto" : "",
    "Descrizione" : "",
    "Richiedente" : "",
    "Ricevente" : "",
    "Evasione" : ""
  }
  
  class DettaglioRichiesta extends Component {
  
    state = {
      richiesta: nuovaRichiesta,
      title : "",
      subtitle: "Dettaglio richiesta",
      messaggio: ""
    }

  componentDidMount() {
    const { match, caricaRichiesta } = this.props

    const idRichiesta = match.params.id

    if ( idRichiesta && idRichiesta.length > 0 ) {

      Promise.resolve()
      .then( () => caricaRichiesta(idRichiesta))
      .then( () => {
        const { currentRichiesta } = this.props
        this.setState({ richiesta:  currentRichiesta,
                        title: "Dettaglio richiesta" })
      })
    }
  }

  // Gestisco la submit del form
  handleSubmit = (e) => {

    if (!e)
      return;

    e.preventDefault()

    const { currentRichiesta, updateRichiesta, history, caricaRichieste, azienda } = this.props

    const values = serializeForm(e.target, { hash: true, empty: true })

    values["Evasione"] = "si"

    if ( currentRichiesta) {
        // Modifico la richiesta esistente
        Promise.resolve()
        .then( () => {
          updateRichiesta(currentRichiesta["_id"], values);
        })
        .then( () => {
          const filtroRichieste = [ 
            { campo: 'Ricevente', operatore : '=', 'valore': azienda._id },
            { campo: 'Evasione', operatore : '=', valore: 'no' }
          ]

          caricaRichieste(filtroRichieste)
        })
        .then( () => {
          history.push('/dashboard/richieste')
        })
        
    }
  }

  // pulsante torna indietro
  tornaIndietro = () => {
    const { history } = this.props

    history.goBack();
  }

  // gestisco la chiusura dello snack
  handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '' });
  };

  // gestisco l'uscita dallo snack
  handleExitedSnack = () => {
    this.setState({ messaggio: ''})
  };
  

  render() {
    const { richiesta, title, subtitle } = this.state
    const { classes } = this.props

    return (
      <div>
        <ScaffoldBase title={title} subtitle={subtitle}>
          <form onSubmit={this.handleSubmit}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Richiedente"
                  id="richiedente"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Richiedente",
                    value: richiesta && richiesta["Richiedente"] && richiesta["Richiedente"]["Ragione sociale"].length > 0 ? richiesta["Richiedente"]["Ragione sociale"] : "",
                    readOnly: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Oggetto"
                  id="oggetto"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Oggetto",
                    value: richiesta["Oggetto"],
                    readOnly: true
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                      fullWidth={true}
                      id="descrizione"
                      label="Descrizione"
                      name="Descrizione"
                      multiline
                      rows="4"
                      value={richiesta["Descrizione"]}
                      margin="normal" 
                      readOnly={true}
                      />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <Button type="submit" color="success">Evadi</Button>
                <Button color="transparent" onClick={ () => this.tornaIndietro() }>Indietro</Button>
              </GridItem>
            </GridContainer>
          </form>   
          <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
                    autoHideDuration={3000}
                    onClose={this.handleCloseSnack}
                    onExited={this.handleExitedSnack}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.messaggio}</span>}
                    action={[
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={this.handleCloseSnack}
                        >
                            <CloseIcon />
                        </IconButton>
                    ]}
                />
        </ScaffoldBase>
      </div>
    );
  }
}

function mapStateToProps({ richieste, authentication }) {
  return {
    currentRichiesta: richieste.currentRichiesta,
    utenteLoggato: authentication.user,
    azienda: authentication.user["Azienda"]
  }
}

function mapDispatchToProps( dispatch ){
  return {
    addRichiesta: (richiesta) => dispatch(addRichiesta(richiesta)),
    updateRichiesta: (id, richiesta) => dispatch(updateRichiesta(id, richiesta)),
    caricaRichiesta: (id) => dispatch(fetchRichiesta(id)),
    caricaRichieste: (filtro) => dispatch( fetchRichieste(filtro))
  }
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(DettaglioRichiesta));
