import React, { Component } from "react";

// React-Redux reducers
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";

// import utilità
import { setValoreCampoFiltro, getValoreCampoDaFiltro } from "utils/filter";
import { formatDateToYYYYMMDD, getFirstMonthDay } from "utils/date";
import { filterParser, getBigData } from "utils/api";
import { stampaReportVenduto } from "utils/print";
import { recuperaVenduto } from "utils/ordinazioni"

// import actions
import { fetchClienti } from "actions/clienti"
import { fetchGruppi } from "actions/gruppi"
import { fetchCombinazioni } from "actions/combinazioni"

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress"

// core Theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";

// custom Components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

const initialFiltro = [
    { 'campo': 'Data', 'campoUI': 'Dal','operatore': '=', 'valore': formatDateToYYYYMMDD(getFirstMonthDay()) },
    { 'campo': 'Data', 'campoUI': 'Al','operatore': '=', 'valore':  formatDateToYYYYMMDD(new Date())},
    { 'campo': 'Cliente', 'campoUI': 'Azienda','operatore': '=', 'valore': "" }
]

const dasportoStyle = {
    headerTable: {
        height: '40px',
        margin: '5px 0px',
        paddingBottom: '15px',
        paddingTop: '10px',
        backgroundColor: '#bcaaa4',
        fontSize: '1.2em',
        fontWeight: 'bold'
    },
    rowHeader: {
        paddingTop: '10px',
        paddingBottom: '10px',
        fontSize: '1.3em',
        fontWeight: 'bold',
        backgroundColor: '#E3E3E3'
    },
    rowTable: {
        borderBottom: '1px solid #CCC'
    },
    cellTable: {
        borderRight: '1px solid #CCC'
    },
    totaliRow: {
        fontWeight: 'bold',
        borderBottom: '1px solid #CCC',
        paddingBottom: '15px',
        paddingTop: '10px'
    },
    totaliRowReport: {
        fontWeight: 'bold',
        backgroundColor: '#bcaaa4',
        fontSize: '1.2em',
        paddingBottom: '15px',
        paddingTop: '10px',
        borderTop: '2px sollid #CCC'
    }
}

class RiepilogoVenduto extends Component {

    state = {
        filtro: initialFiltro,
        data: null,
        showCircularProgress: false
    }

    componentDidMount() {
        const { caricaClienti, caricaGruppi, caricaCombinazioni, azienda } = this.props

        const filtroClienti = [ { 'campo' : 'Azienda riferimento', 'operatore' : '=', 'valore' : azienda._id}]
        const filtroGruppi = [ {'campo' : 'Azienda', 'operatore' : '=', 'valore': azienda._id }]
        const filtroCombinazioni = [ {'campo' : 'Aziendariferimento', 'operatore' : '=', 'valore': azienda._id }]

        caricaClienti(filtroClienti)
        caricaGruppi(filtroGruppi)
        caricaCombinazioni(filtroCombinazioni)
    }

    // funzione che controlla il cambio dei valori della form di filtro
    handleChange = (e) => {
        const nomeProprieta = e.target.name
        let valoreProprieta = e.target.value

        const { filtro } = this.state

        let newFiltro = setValoreCampoFiltro('campoUI', nomeProprieta, valoreProprieta, filtro)

        this.setState({ filtro: newFiltro })
    }

    // procedura che cerca i valori da stampare
    cerca = () => {

        const { filtro } = this.state
        this.setState({ showCircularProgress: true })

        const dalFiltro = getValoreCampoDaFiltro('campoUI', 'Dal', filtro)
        const alFiltro = getValoreCampoDaFiltro('campoUI', 'Al', filtro)
        const clienteFiltro = getValoreCampoDaFiltro('campo', 'Cliente', filtro)
        let filtroOrdinazioni = []

        if (dalFiltro && dalFiltro.valore) {
            filtroOrdinazioni.push({'campo': 'Data', 'operatore': '_gte', 'valore' : dalFiltro.valore })
        }

        if (alFiltro && alFiltro.valore) {
            filtroOrdinazioni.push({'campo': 'Data', 'operatore': '_lte', 'valore' : alFiltro.valore })
        }

        if (clienteFiltro && clienteFiltro.valore) {
            filtroOrdinazioni.push({'campo': 'Azienda', 'operatore': '=', 'valore' : clienteFiltro.valore })
        }

        let queryString = filterParser(filtroOrdinazioni)

        if (queryString.trim().length > 0)
            queryString = `?${queryString}`

        getBigData('ordinazionis', queryString)
        .then( (ordinazioni) => {
            const { clienti, gruppi, combinazioni } = this.props

            let clientiFiltro = clienti.sort( (a, b) => {
                if (a['Ragione sociale'] > b['Ragione sociale'])
                    return 1
                
                if (a['Ragione sociale'] < b['Ragione sociale'])
                    return -1

                return 0
            })

            if (clienteFiltro && clienteFiltro.valore) {
                clientiFiltro = clientiFiltro.filter( (c) => {
                    return c.id === clienteFiltro.valore
                })
            }

            let data = recuperaVenduto(ordinazioni, clientiFiltro, combinazioni, gruppi )
            data['giornoDal'] = dalFiltro.valore
            data['giornoAl'] = alFiltro.valore

            this.setState({ data, showCircularProgress: false })

        })
    }

    // procedura per stampare il report
    stampaReportVenduto = () => {
        const { data } = this.state
        stampaReportVenduto(data)
    }

    render() {

        const { filtro, data, showCircularProgress } = this.state
        const { clienti } = this.props

        const giornoDal = getValoreCampoDaFiltro('campoUI', 'Dal', filtro)
        const giornoAl = getValoreCampoDaFiltro('campoUI', 'Al', filtro)
        const cliente = getValoreCampoDaFiltro('campo', 'Cliente', filtro)

        return (
            <ScaffoldBase title="Riepilogo venduto">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                        <CustomInput
                            labelText="Dal"
                            id="dal"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                name: "Dal",
                                type: "Date",
                                required: true,
                                value: giornoDal && giornoDal.valore ? giornoDal.valore : "",
                                onChange: this.handleChange
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                        <CustomInput
                            labelText="Al"
                            id="al"
                            formControlProps={{ fullWidth: true }}
                            inputProps={{
                                name: "Al",
                                type: "Date",
                                required: true,
                                value: giornoAl && giornoAl.valore ? giornoAl.valore : "",
                                onChange: this.handleChange
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                        <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                            <InputLabel htmlFor="Cliente">Cliente</InputLabel>
                            <Select
                                    onChange={this.handleChange}
                                    value={ cliente && cliente.valore ? cliente.valore : ""}
                                    inputProps={{
                                    name: 'Azienda',
                                    id: 'cliente'
                                }}
                            >
                                <MenuItem value="">
                                <em>Nessuno</em>
                                </MenuItem>
                                { clienti && clienti.length > 0 && clienti.map( (c) => {
                                return (
                                    <MenuItem key={c._id} value={c._id}>{c["Ragione sociale"]}</MenuItem>
                                )
                                })
                                }
                            </Select>
                        </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3} lg={3}>
                        <Button type="submit" color="success" onClick={this.cerca} style={{ marginTop: "27px" }}>Visualizza</Button><br />
                        { data && (
                            <Button type="submit" color="info" size="sm" onClick={this.stampaReportVenduto} style={{ marginTop: "27px" }}>Stampa report</Button>
                        )}
                    </GridItem>
                </GridContainer>

                { showCircularProgress && (
                    <div style={{ textAlign: "center"}}>
                        <p>Elaborazione dati in corso...</p>
                        <CircularProgress />
                    </div>
                    
                )}

                { !showCircularProgress && data && data['corpo'] && (
                    <div>
                        <GridContainer style={dasportoStyle.headerTable}>
                            <GridItem xs={4} sm={4} md={4} lg={4}>Cliente</GridItem>
                            <GridItem xs={2} sm={2} md={2} lg={2}>Pasto</GridItem>
                            <GridItem xs={2} sm={2} md={2} lg={2} >Quantità</GridItem>
                            <GridItem xs={2} sm={2} md={2} lg={2}>Prezzo</GridItem>
                            <GridItem xs={2} sm={2} md={2} lg={2}>Totale</GridItem>
                        </GridContainer>
                        { data['corpo'] && data['corpo'].map( (riga, idxCliente) => {
                            return (
                                <div key={`cliente${idxCliente}`}>
                                    <GridContainer style={dasportoStyle.rowHeader}>
                                        <GridItem xs={12} sm={12} md={12} lg={12} >{riga.ragioneSociale}</GridItem>
                                    </GridContainer>
                                    { riga.gruppi
                                    .sort( (a,b) => {
                                        if (a.ordinamento && b.ordinamento) {
                                            if (a.ordinamento > b.ordinamento)
                                                return 1
                                            if (a.ordinamento < b.ordinamento)
                                                return -1
                                            return 0
                                        }
                                        else
                                            return 0

                                    }).map( (g, idx) => {
                                        return (
                                            <GridContainer key={idx} style={dasportoStyle.rowTable}>
                                                <GridItem xs={4} sm={4} md={4} lg={4} style={dasportoStyle.cellTable} >{ idx === 0 ? 'Gruppi' : ''}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{g.nome}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{g.quantita}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{g.prezzo && g.prezzo >= 0 ? g.prezzo.toFixed(2) : ''}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{g.importo && g.importo >= 0 ? g.importo.toFixed(2) : ''}</GridItem>
                                            </GridContainer>
                                        )
                                    })}

                                    { riga.totalegruppi.quantita > 0 && (
                                        <GridContainer style={dasportoStyle.totaliRow}>
                                            <GridItem xs={6} sm={6} md={6} lg={6}>{`Totale gruppi ${riga.ragioneSociale}`}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2}>{riga.totalegruppi.quantita}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2}>-</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2}>{riga.totalegruppi.importo && riga.totalegruppi.importo >= 0 ? riga.totalegruppi.importo.toFixed(2) : ''}</GridItem> 
                                        </GridContainer>
                                    )}
                                    
                                    { riga.combinazioni.map( (c, idx) => {
                                        return ( 
                                            <GridContainer key={idx} style={dasportoStyle.rowTable}>
                                                <GridItem xs={4} sm={4} md={4} lg={4} style={dasportoStyle.cellTable} >{ idx === 0 ? 'Combinazioni' : ''}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{c.nome}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{c.quantita}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{c.prezzo && c.prezzo >= 0 ? c.prezzo.toFixed(2) : ''}</GridItem>
                                                <GridItem xs={2} sm={2} md={2} lg={2} style={dasportoStyle.cellTable}>{c.importo && c.importo >= 0 ? c.importo.toFixed(2) : ''}</GridItem>
                                            </GridContainer>
                                        )
                                    })}
                                    { riga.totalecombinazioni.quantita > 0 && (
                                        <GridContainer style={dasportoStyle.totaliRow}>
                                            <GridItem xs={6} sm={6} md={6} lg={6}>{`Totale combinazioni ${riga.ragioneSociale}`}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2}>{riga.totalecombinazioni.quantita}</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2}>-</GridItem>
                                            <GridItem xs={2} sm={2} md={2} lg={2}>{riga.totalecombinazioni.importo && riga.totalecombinazioni.importo >= 0 ? riga.totalecombinazioni.importo.toFixed(2) : ''}</GridItem> 
                                        </GridContainer>
                                    )}

                                    <GridContainer style={dasportoStyle.totaliRow}>
                                        <GridItem xs={6} sm={6} md={6} lg={6}>{`Totale venduto ${riga.ragioneSociale}`}</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2}>-</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2}>-</GridItem>
                                        <GridItem xs={2} sm={2} md={2} lg={2}>{riga.totale && riga.totale >= 0 ? riga.totale.toFixed(2) : ''}</GridItem> 
                                    </GridContainer>
                                    
                                </div>
                            )
                        })}
                        { data['totali'] && (
                            <GridContainer style={dasportoStyle.totaliRowReport}>
                                <GridItem xs={6} sm={6} md={6} lg={6}>Totale venduto</GridItem>
                                <GridItem xs={2} sm={2} md={2} lg={2}>{data.totali.quantita}</GridItem>
                                <GridItem xs={2} sm={2} md={2} lg={2}>-</GridItem>
                                <GridItem xs={2} sm={2} md={2} lg={2}>{data.totali.totale && data.totali.totale >= 0 ? data.totali.totale.toFixed(2) : ''}</GridItem> 
                            </GridContainer>
                        )}
                    </div>
                )}

            </ScaffoldBase>
        )
    }
}

function mapDispatchToProps( dispatch ) {
    return {
        caricaClienti: (filtro) => dispatch(fetchClienti(filtro)),
        caricaGruppi: (filtro) => dispatch(fetchGruppi(filtro)),
        caricaCombinazioni: (filtro) => dispatch(fetchCombinazioni(filtro))
    }
  }
  
function mapStateToProps({ authentication, gruppi, clienti, combinazioni }) {
    return { 
        azienda: authentication.user["Azienda"],
        gruppi: gruppi.items,
        clienti: clienti.items,
        combinazioni: combinazioni.items
    }
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(RiepilogoVenduto));