import React, { Component } from "react";

// React-Redux
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx";

import { logout } from "actions/authentication"

class Logout extends Component{

    componentDidMount() {
        const token = sessionStorage.getItem('my-token')

        if (token) {

            Promise.resolve()
            .then( () => { 
                const { logout } = this.props
                return logout()
            })
            .then( () => {
                const { history } = this.props
                history.push('/login')
            })
            
        }    

    }

    render() {
        return (
            <Redirect to='/login' />
        )
    }
}

function mapDispatchToProps( dispatch ) {
    return {
      logout: () => dispatch(logout())
    }
  }

export default compose(
    withStyles(dashboardStyle),
    connect(null, mapDispatchToProps)
  )(withRouter(Logout));
