import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase"

// custom components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// React-Redux reducers
import { connect } from "react-redux";
import { fetchReclami, fetchReclamo } from "actions/reclami";
import { compose } from "redux";
import { withRouter } from "react-router";

class ListaReclami extends React.Component {
  state = {
  };

  componentDidMount() {
    const { loadReclami, azienda } = this.props

    if (azienda)
        loadReclami( [ { 'campo': 'Ricevente', 'operatore' : '=', 'valore': azienda._id },{ 'campo': 'Evasione', 'operatore' : '=', 'valore': 'no' } ] )
    
  }

  apriReclamo(id) {
    const { history } = this.props

    history.push('/dashboard/reclami/'+id+'/view')
  }

  render() {
    const { classes, reclami } = this.props;
    return (
      <div>
      <ScaffoldBase title="Lista reclami">
                { reclami && reclami.length > 0 &&
                <GridItem xs={12} sm={12} md={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Proponente</TableCell>
                        <TableCell>Giorno</TableCell>
                        <TableCell>Oggetto</TableCell>
                        <TableCell>Descrizione</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                          reclami && reclami.sort( function( a, b) {
                            if ( a["Giorno"] > b["Giorno"] )
                              return 1
                            if ( a["Giorno"] < b["Giorno"] )
                              return -1
                            return 0
                          }).map( (reclamo) => 
                            <TableRow key={reclamo._id} onClick={() => this.apriReclamo(reclamo._id)}>
                              <TableCell>{reclamo["Proponente"]["Ragione sociale"]}</TableCell>
                              <TableCell>{new Date(reclamo["Giorno"]).toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric' })}</TableCell>
                              <TableCell>{reclamo["Oggetto"]}</TableCell>
                              <TableCell>{reclamo["Descrizione"]}</TableCell>
                            </TableRow>
                          ) 
                        }
                    </TableBody>
                  </Table>
                </GridItem>
                }

                { (!reclami || reclami.length <= 0) && 
                  <GridItem>
                    <p>Reclami non disponibili</p>
                  </GridItem> }
        </ScaffoldBase>
        </div>
    );
  }
}

ListaReclami.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
    loadReclami: (filtro) => dispatch(fetchReclami(filtro)),
    setReclami: (id) => dispatch(fetchReclamo(id))
  }
}

function mapStateToProps({ reclami, authentication }) {
  return { 
    reclami: reclami.items,
    azienda: authentication.user["Azienda"]
  };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaReclami));
