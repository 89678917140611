import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import { FormControl, Typography } from "@material-ui/core";
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress"

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";
import Button from "components/CustomButtons/Button.jsx";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { fetchOrdinazioni } from "actions/ordinazioni"
import { fetchClienti } from "actions/clienti";
import { fetchGruppi } from "actions/gruppi";

import { formatDateToYYYYMMDD } from "utils/date"

// React-Redux reducers
import { connect } from "react-redux";

import { compose } from "redux";
import { withRouter } from "react-router";

import { getValoreCampoDaFiltro, setValoreCampoFiltro } from "utils/filter.js"
import { filterParser, getBigData } from "utils/api";
import { raggruppaPietanzeGruppi, raggruppaOrdinazioniClienti, raggruppaOrdinazioniGruppoCliente, raggruppaPietanzeClientiGiri } from "utils/ordinazioni"
import { stampaReportOrdinazioni, stampaEtichette, stampaReportGiri } from "utils/print"

const initialFiltro = [
  { 'campo': 'Data', 'campoUI': 'Data','operatore': '=', 'valore': formatDateToYYYYMMDD(new Date()) },
  { 'campo': 'Azienda', 'campoUI': 'Azienda', 'operatore': '=', 'valore': ''},
  { 'campo': 'Pasto', 'campoUI': 'Pasto', 'operatore': '=', 'valore': '' }
]

const gruppoHeaderStyle = {
  padding: "10px 10px",
  backgroundColor: "#7d6960",
  color: "white",
  height: "48px"
}

class ListaOrdinazioni extends React.Component {

  state = {
    filtro: initialFiltro,
    raggruppamenti: [],
    messaggio: "",
    printData: null,
    showDialog: false,
    dialogTitle: "",
    dialogMessage: "",
    dialogConfirmAction: null,
    showCircularProgress: false
  }

  componentDidMount() {
    const { caricaClienti, azienda, caricaGruppi } = this.props

    const filtroClientiAzienda = [
      { 'campo': '_id', 'operatore': '_ne', 'valore': azienda._id}
    ]

    caricaClienti(filtroClientiAzienda)
    caricaGruppi()
  }

  handleChange = (e) => { 
    const nomeProprieta = e.target.name
    let valoreProprieta = e.target.value

    const { filtro } = this.state

    let newFiltro = setValoreCampoFiltro('campoUI',nomeProprieta, valoreProprieta, filtro)

    this.setState({ filtro: newFiltro })
  }

  cerca = () => {
    const { gruppi } = this.props
    const { filtro } = this.state

    let queryString = ""

    // devo sistemare la data nel filtro percè sul db la data è memorizzata nel formato (giorno precedente ore 23)
    let filtroQuery = JSON.parse(JSON.stringify(filtro))

    let dataConvertita = getValoreCampoDaFiltro('campo', 'Data', filtroQuery)
    const pranzo = getValoreCampoDaFiltro('campoUI', 'Pasto', filtroQuery)

    if ( !dataConvertita.valore || dataConvertita.valore.length === 0 || !pranzo.valore || pranzo.valore.length === 0) {
      this.setState({ messaggio: "Devi impostare almeno il filtro sul giorno e sulla tipologia di pasto (pranzo/cena)"})
      return
    }

    dataConvertita = dataConvertita && dataConvertita.valore ? dataConvertita.valore : ""

    filtroQuery = setValoreCampoFiltro('campo', 'Data', dataConvertita, filtroQuery)

    queryString = filterParser(filtroQuery)

    if (queryString.trim().length > 0)
      queryString = `?${queryString}`

      getBigData('ordinazionis', queryString, 100)
      .then( (ordinazioni) => {

        const pietanzeGruppi = raggruppaPietanzeGruppi(gruppi, ordinazioni)
        this.setState({ raggruppamenti: pietanzeGruppi })

      })
  }

  mostraRiepilogoStampa = (tipologia) => {
    const { clienti } = this.props
    const { filtro } = this.state

    // devo sistemare la data nel filtro percè sul db la data è memorizzata nel formato (giorno precedente ore 23)
    let filtroQuery = JSON.parse(JSON.stringify(filtro))

    let dataConvertita = getValoreCampoDaFiltro('campo', 'Data', filtroQuery)
    let giornoFiltro = new Date(dataConvertita.valore).toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'})
    const pasto = getValoreCampoDaFiltro('campoUI', 'Pasto', filtroQuery)

    if ( !dataConvertita.valore || dataConvertita.valore.length === 0 || !pasto.valore || pasto.valore.length === 0) {
      this.setState({ messaggio: "Devi impostare almeno il filtro sul giorno e sulla tipologia di pasto (pranzo/cena)"})
      return
    }

    const clienteSelezionato = getValoreCampoDaFiltro('campo', 'Azienda', filtroQuery)
    let clientiDaFiltrare = clienti

    if (clienteSelezionato && clienteSelezionato.valore.length > 0 ) {
      clientiDaFiltrare = clienti.filter( (c) => {
        return c._id === clienteSelezionato.valore
      })
    }

    let descrizioneStampa = ""
    let dialogConfirmAction = null

    switch (tipologia) {
      case 'report':
        descrizioneStampa = 'il report delle ordinazioni'
        dialogConfirmAction = this.stampaReport
      break;
      
      case 'etichette':
        descrizioneStampa = 'le etichette'
        dialogConfirmAction = this.stampaEtichette
      break;

      case 'report-giri':
        descrizioneStampa = 'il report delle ordinazioni raggruppato per giro di consegna'
        dialogConfirmAction = this.stampaReportGiri
      break

      default:
        descrizioneStampa = 'Stampa errata'
        dialogConfirmAction = null
    }

    let dialogMessage = `Stai stampando ${descrizioneStampa} del giorno ${giornoFiltro} per il pasto ${pasto.valore}`

    if (tipologia !== 'report-giri') {
      if (clientiDaFiltrare.length > 1) {
        // scrivo che i clienti sono tutti
        dialogMessage += ` di tutti i tuoi clienti.`
      }
      else {
        // scrivo di quale cliente sto facendo la stampa
        const nomeCliente = clientiDaFiltrare && clientiDaFiltrare.length === 1 && clientiDaFiltrare[0]["Ragione sociale"].trim().length > 0 ? clientiDaFiltrare[0]["Ragione sociale"] : ""
  
        dialogMessage += ` del cliente ${nomeCliente}.`
      }
    }

    this.setState({
      showDialog: true,
      dialogTitle: 'Stampa report ordinazioni',
      dialogMessage: dialogMessage,
      dialogConfirmAction: dialogConfirmAction
    })
  }

  // chiede la conferma della stampa del report dei giri
  confermaStampaReportGiri = () => {

    this.setState({
      showDialog: true,
      dialogTitle: 'Stampa report giri',
      dialogMessage: '',
      dialogConfirmAction: this.stampaReportGiri()
    })
  }

  stampaReportGiri = () => {
    const { gruppi, clienti } = this.props
    const { filtro } = this.state

    let queryString = ""

    // devo sistemare la data nel filtro percè sul db la data è memorizzata nel formato (giorno precedente ore 23)
    let filtroQuery = JSON.parse(JSON.stringify(filtro))

    let dataConvertita = getValoreCampoDaFiltro('campo', 'Data', filtroQuery)
    let giornoFiltro = new Date(dataConvertita.valore).toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'})
    const pranzo = getValoreCampoDaFiltro('campoUI', 'Pasto', filtroQuery)

    if ( !dataConvertita.valore || dataConvertita.valore.length === 0 || !pranzo.valore || pranzo.valore.length === 0) {
      this.setState({ messaggio: "Devi impostare almeno il filtro sul giorno e sulla tipologia di pasto (pranzo/cena)"})
      return
    }

    dataConvertita = dataConvertita && dataConvertita.valore ? dataConvertita.valore : ""

    filtroQuery = setValoreCampoFiltro('campo', 'Data', dataConvertita, filtroQuery)

    // devo togliere dal filtro il valore relativo all'azienda perchè la stampa va fatta in modo complessivo
    filtroQuery = filtroQuery.filter( (f) => {
      return f['campo'] !== 'Azienda'
    })

    queryString = filterParser(filtroQuery)

    if (queryString.trim().length > 0)
      queryString = `?${queryString}`

      getBigData('ordinazionis', queryString, 100)
      .then( (ordinazioni) => {

        const pietanzeGiri = raggruppaPietanzeClientiGiri(gruppi, ordinazioni, clienti)

        const data = { dati: pietanzeGiri, clienti, pasto: pranzo.valore, giorno: giornoFiltro}

        stampaReportGiri(data)

        this.handleCloseDialog()

      })
  }

  // gestisce la stampa del report
  stampaReport = () => {
    const { gruppi, clienti } = this.props
    const { filtro } = this.state

    // devo sistemare la data nel filtro perchè sul db la data è memorizzata nel formato (giorno precedente ore 23)
    let filtroQuery = JSON.parse(JSON.stringify(filtro))

    let dataConvertita = getValoreCampoDaFiltro('campo', 'Data', filtroQuery)
    let giornoFiltro = new Date(dataConvertita.valore).toLocaleDateString('it-IT', { 'month': '2-digit', 'day': '2-digit', 'year': 'numeric'})
    const pasto = getValoreCampoDaFiltro('campoUI', 'Pasto', filtroQuery)

    if ( !dataConvertita.valore || dataConvertita.valore.length === 0 || !pasto.valore || pasto.valore.length === 0) {
      this.setState({ messaggio: "Devi impostare almeno il filtro sul giorno e sulla tipologia di pasto (pranzo/cena)"})
      return
    }

    dataConvertita = dataConvertita && dataConvertita.valore ? dataConvertita.valore : ""

    //const data = raggruppaOrdinazioniClienti(dataConvertita, pasto.valore, clienti, gruppi )

    const clienteSelezionato = getValoreCampoDaFiltro('campo', 'Azienda', filtroQuery)
    let clientiDaFiltrare = clienti

    if (clienteSelezionato && clienteSelezionato.valore.length > 0 ) {
      clientiDaFiltrare = clienti.filter( (c) => {
        return c._id === clienteSelezionato.valore
      })
    }

    raggruppaOrdinazioniClienti(dataConvertita, pasto.valore, clientiDaFiltrare, gruppi )
    .then( (data) => {
      const dati = { 'giorno': giornoFiltro, 'pasto': pasto.valore, 'clienti': data}
      this.setState({ printData: dati })
    })
    .then(() => {
      // elabora la stampa
      stampaReportOrdinazioni(this.state.printData)
    })
    .then( () => this.handleCloseDialog())

  }

  // gestische la stampa delle etichette
  stampaEtichette = () => {
    const { gruppi, azienda } = this.props
    const { filtro } = this.state

    this.setState({ showCircularProgress: true })

    let queryString = ""

    // devo sistemare la data nel filtro percè sul db la data è memorizzata nel formato (giorno precedente ore 23)
    let filtroQuery = JSON.parse(JSON.stringify(filtro))

    let dataConvertita = getValoreCampoDaFiltro('campo', 'Data', filtroQuery)
    const pranzo = getValoreCampoDaFiltro('campoUI', 'Pasto', filtroQuery)

    if ( !dataConvertita.valore || dataConvertita.valore.length === 0 || !pranzo.valore || pranzo.valore.length === 0) {
      this.setState({ messaggio: "Devi impostare almeno il filtro sul giorno e sulla tipologia di pasto (pranzo/cena)"})
      return
    }

    dataConvertita = dataConvertita && dataConvertita.valore ? dataConvertita.valore : ""

    filtroQuery = setValoreCampoFiltro('campo', 'Data', dataConvertita, filtroQuery)

    queryString = filterParser(filtroQuery)

    if (queryString.trim().length > 0)
      queryString = `?${queryString}`

      getBigData('ordinazionis', queryString, 100)
      .then( (ordinazioni) => {

        const data = raggruppaOrdinazioniGruppoCliente(ordinazioni, gruppi)
        let newData = data

        const gestioneEtichette = azienda["GestioneEtichette"]

        const etichetteAggiuntive = gestioneEtichette && gestioneEtichette.etichetteAggiuntive && gestioneEtichette.etichetteAggiuntive.length > 0 ? gestioneEtichette.etichetteAggiuntive : null

        if ( etichetteAggiuntive ) {
          // Devo scorrere tutte le etichette per inserire le etichette aggiuntive per ogni cliente

          newData = []

          let clienteCorrente = ""
          for (let i=0; i < data.length ; i++) {

            if ( clienteCorrente != "" && clienteCorrente != data[i].ragioneSociale) {
              // devo aggiungere le etichette aggiuntive

              etichetteAggiuntive.forEach( (etichetta) => {

                const extraLabel = {
                  'ragioneSociale': clienteCorrente,
                  'giorno': data[i].giorno,
                  'pasto': data[i].pasto,
                  'nomePietanza': etichetta,
                  'Cognome':'',
                  'Nome': '',
                  'idOrdinazione': '',
                  'idPietanza': ''
                }

                newData.push(extraLabel)

              })
            }

            clienteCorrente = data[i].ragioneSociale
            newData.push(data[i])

          }
        }
        
        stampaEtichette(newData)
      })
      .then( () => {
        this.setState( { showCircularProgress: false, showDialog: false })
      })
  }

   // gestisco la chiusura dello snack
   handleCloseSnack = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ messaggio: '' });
  };

  // gestisco l'uscita dallo snack
  handleExitedSnack = () => {
    this.setState({ messaggio: ''})
  };

  // gestisco la chiusura del dialog message
  handleCloseDialog = () => {
    this.setState({ showDialog: false, dialogTitle: "", dialogMessage: "", dialogConfirmAction: null, })
  }

  render() {
    const { clienti, classes} = this.props
    const { filtro, raggruppamenti, showDialog, dialogTitle, dialogMessage, dialogConfirmAction, showCircularProgress } = this.state

    const giorno = getValoreCampoDaFiltro('campo', 'Data', filtro)
    const cliente = getValoreCampoDaFiltro('campo', 'Azienda', filtro)
    const pasto = getValoreCampoDaFiltro('campo', 'Pasto', filtro)
    
    return (
        <ScaffoldBase title="Riepilogo ordinazioni" subtitle="Visualizza il riepilogo delle ordinazioni">
            <GridContainer>
              <GridItem xs={12} sm={4} md={2} lg={2}>
                <CustomInput
                  labelText="Giorno"
                  id="giorno"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    name: "Data",
                    type: "Date",
                    value: giorno && giorno.valore ? giorno.valore : "",
                    onChange: this.handleChange
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={3} md={3} lg={3}>
                <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                  <InputLabel htmlFor="Pasto">Pasto</InputLabel>
                  <Select
                    value={ pasto && pasto.valore ? pasto.valore : ""}
                    onChange={this.handleChange}
                    inputProps={{
                      
                      name: 'Pasto',
                      id: 'pasto'
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    <MenuItem value="pranzo">
                      Pranzo
                    </MenuItem>
                    <MenuItem value="cena">
                      Cena
                    </MenuItem>
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={4} md={4} lg={4}>
                <FormControl fullWidth={true} style={{ marginTop: "27px"}}>
                  <InputLabel htmlFor="Cliente">Cliente</InputLabel>
                  <Select
                    onChange={this.handleChange}
                    value={ cliente && cliente.valore ? cliente.valore : ""}
                    inputProps={{
                      name: 'Azienda',
                      id: 'cliente'
                    }}
                  >
                    <MenuItem value="">
                      <em>Nessuno</em>
                    </MenuItem>
                    { clienti && clienti.length > 0 && clienti.map( (c) => {
                      return (
                        <MenuItem key={c._id} value={c._id}>{c["Ragione sociale"]}</MenuItem>
                      )
                    })
                    }
                  </Select>
                </FormControl>
              </GridItem>
              <GridItem xs={12} sm={12} md={3} lg={3}>
                <Button type="submit" color="success" onClick={this.cerca} style={{ marginTop: "27px" }}>Visualizza</Button><br />
                <Button type="submit" color="info" size="sm" onClick={ () => this.mostraRiepilogoStampa('report-giri')} style={{ marginTop: "27px" }}>Stampa report giri</Button>
                <Button type="submit" color="info" size="sm" onClick={ () => this.mostraRiepilogoStampa('report')} style={{ marginTop: "27px" }}>Stampa report</Button>
                <Button type="submit" color="info" size="sm" onClick={() => this.mostraRiepilogoStampa('etichette')} style={{ marginTop: "27px" }}>Stampa etichette</Button>
              </GridItem>
            </GridContainer>
            <GridContainer>
              { raggruppamenti && raggruppamenti.length > 0 && (
                <div style={{ width: "100%"}}>
                  { raggruppamenti.map( (r) => {
                    return (
                      <div key={r.id}>
                        <GridItem xs={12} sm={12} md={12} lg={12} style={gruppoHeaderStyle} key={`gruppo${r.id}`}>
                          <Typography variant="subheading" style={{ paddingTop: "10px" }}>{r["Gruppo"]}</Typography>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12} lg={12}>
                          { r["Pietanze"] && r["Pietanze"].length > 0 && r["Pietanze"].map( (pietanzaGruppo) => {
                                    return (
                                      <GridContainer key={pietanzaGruppo.id}>
                                        <GridItem xs={9} sm={10} md={10} lg={10}>
                                          <Typography variant="subheading">{pietanzaGruppo["nome"]}</Typography>
                                        </GridItem>
                                        <GridItem xs={3} sm={2} md={2} lg={2}>
                                          <Typography variant="subheading">{pietanzaGruppo['contatore']}</Typography>
                                        </GridItem>
                                      </GridContainer>
                                    )
                                  })
                          }

                          {
                            r["Pietanze"].length === 0 && (
                              <Typography>-</Typography>
                            )
                          }
                          
                        </GridItem>
                      </div>
                    )
                  })
                }
                </div>
              )}
            </GridContainer>
            <Snackbar
              anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
              }}
              open={this.state.messaggio && this.state.messaggio.length > 0 ? true : false}
              autoHideDuration={3000}
              onClose={this.handleCloseSnack}
              onExited={this.handleExitedSnack}
              ContentProps={{
                  'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.messaggio}</span>}
              action={[
                  <IconButton
                      key="close"
                      aria-label="Close"
                      color="inherit"
                      className={classes.close}
                      onClick={this.handleCloseSnack}
                  >
                      <CloseIcon />
                  </IconButton>
              ]}
            />
            <Dialog onClose={this.handleCloseDialog} open={showDialog} >
              { dialogTitle && dialogTitle.trim().length > 0 && (
                <DialogTitle>
                  <IconButton aria-label="Close" className={classes.closeButton} onClick={this.handleCloseDialog} style={{ float: "right"}}>
                    <CloseIcon />
                  </IconButton>
                  {dialogTitle}
                </DialogTitle>
              )}
              { dialogMessage && dialogMessage.trim().length > 0 && (
                <DialogContent>
                  {dialogMessage}
                  { showCircularProgress && (
                    <div style={{ textAlign: "center"}}>
                      <p>Elaborazione stampa in corso...</p>
                      <CircularProgress />
                    </div>
                  )}
                </DialogContent>
              )}
              { dialogConfirmAction && !showCircularProgress && (
                <DialogActions>
                  <Button onClick={dialogConfirmAction} color="success">
                    Conferma
                  </Button>
                </DialogActions>
              )}
            </Dialog>
        </ScaffoldBase>
    );
  }
}

ListaOrdinazioni.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
      caricaOrdinazioni: (filtro) => dispatch(fetchOrdinazioni(filtro)),
      caricaClienti: (filtro) => dispatch(fetchClienti(filtro)),
      caricaGruppi: () => dispatch(fetchGruppi())
  }
}

function mapStateToProps({ ordinazioni, clienti, authentication, gruppi }) {
  return { 
      ordinazioni: ordinazioni.items,
      clienti: clienti.items,
      azienda: authentication.user.Azienda,
      gruppi: gruppi.items
   };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaOrdinazioni));
