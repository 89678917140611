import { REACT_APP_BACKEND } from "index.js";
import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import AddIcon from "@material-ui/icons/Add";
import { Button, FormControl } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import CustomInput from "components/CustomInput/CustomInput";

// theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// custom components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

// React-Redux reducers
import { connect } from "react-redux";

import {
  fetchPietanze,
  fetchPietanza,
  deletePietanza
} from "../../../actions/pietanze";

import { fetchGruppi } from "actions/gruppi"

import { compose } from "redux";
import { withRouter } from "react-router";

class ListaPietanze extends React.Component {

  state = {
    cercaNome: "",
    cercaGruppo: ""
  }

  componentDidMount() {
    const { loadPietanze, loadGruppi, azienda } = this.props

    const filtroPietanze = [ { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]

    loadPietanze(filtroPietanze);
    loadGruppi();
  }

  // funzione utilizzata per spostarsi nella pagina di inserimento nuova pietanza
  aggiungiPietanza() {
    const { history } = this.props

    history.push('/dashboard/pietanze/add')
  }

  // cancella la pietanza
  cancellaPietanza(id) {
    const { delPietanza, loadPietanze } = this.props
    Promise.resolve()
    .then( () => delPietanza(id) )
    .then(() => {
      const { azienda } = this.props

      const filtroPietanze = [ { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]

      loadPietanze(filtroPietanze)
    })
  }

  apriModificaPietanza(id) {
    const { history } = this.props

    history.push('/dashboard/pietanze/'+id+'/edit')
  }

  handleFilterByName = (e) => {
    const { loadPietanze, azienda } = this.props
    const { cercaGruppo } = this.state
    this.setState({ cercaNome: e.target.value})

    const filtro = [
      { 'campo': 'Nome', 'operatore': '_contains', 'valore': e.target.value},
      { 'campo': 'Gruppo', 'operatore': '=', 'valore': cercaGruppo },
      { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }
    ]
    
    loadPietanze(filtro)
  }

  handleFilterByGroup = (e) => {
    const { loadPietanze, azienda } = this.props
    const { cercaNome } = this.state
    this.setState({ cercaGruppo: e.target.value})

    const filtro = [
      { 'campo': 'Nome', 'operatore': '_contains', 'valore': cercaNome },
      { 'campo': 'Gruppo', 'operatore': '=', 'valore': e.target.value },
      { 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }
    ]
    
    loadPietanze(filtro)
  }

  render() {
    const { classes, pietanze, gruppi } = this.props;
    const { cercaNome, cercaGruppo } = this.state

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Button onClick={() => this.aggiungiPietanza()} style={{background: "linear-gradient(60deg, #7d6960, #3e2a23)", color: "#ffffff"}} variant="contained"><AddIcon />
              Aggiungi nuova pietanza
            </Button>
          </GridItem>
        </GridContainer>
        <ScaffoldBase title="Lista pietanze" subtitle="Dettaglio pietanze">
        <GridContainer>
          <GridItem xs={6} sm={6} md={6} lg={6} >
              <CustomInput
                labelText="Nome"
                id="nome"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  name: "Nome",
                  value: cercaNome,
                  placeholder: "Cerca pietanza per nome",
                  onChange: this.handleFilterByName
                }}
              />
          </GridItem>
          <GridItem xs={6} sm={6} md={6} lg={6}>
            <FormControl style={{ marginTop: "27px"}} fullWidth={true}>
              <InputLabel htmlFor="Gruppo">Gruppo</InputLabel>
              <Select
                value={cercaGruppo}
                inputProps={{
                  name: 'Gruppo',
                  id: 'Gruppo',
                  onChange:this.handleFilterByGroup
                }}
              >
                <MenuItem value="">
                  <em>Nessuno</em>
                </MenuItem>
                { gruppi && gruppi.items && gruppi.items.length > 0 && gruppi.items.map( (g) => {
                  return (
                    <MenuItem key={g._id} value={g._id}>{g["Nome"]}</MenuItem>
                  )
                })
                }
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        
          { pietanze.items && pietanze.items.length > 0 &&
          <GridItem xs={12} sm={12} md={12}>
            <Table className={classes.table}>
              <TableHead>
                <TableRow>
                  <TableCell>Azione</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Gruppo</TableCell>
                  <TableCell>Sottogruppo</TableCell>
                  <TableCell>Immagine</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                    pietanze.items && pietanze.items.map( (pietanza) => 
                      <TableRow key={pietanza._id}>
                        <TableCell>
                            <Tooltip
                              id="tooltip-top"
                              title="Modifica"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Edit"
                                className={classes.tableActionButton}
                              >
                                <Edit
                                  className={
                                    classes.tableActionButtonIcon + " " + classes.edit
                                  }
                                  onClick={() => this.apriModificaPietanza(pietanza._id)}
                                />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              id="tooltip-top-start"
                              title="Elimina"
                              placement="top"
                              classes={{ tooltip: classes.tooltip }}
                            >
                              <IconButton
                                aria-label="Close"
                                className={classes.tableActionButton}
                              >
                                <Close
                                  className={
                                    classes.tableActionButtonIcon + " " + classes.close
                                  }
                                  onClick={() => this.cancellaPietanza(pietanza._id)}
                                />
                              </IconButton>
                            </Tooltip>
                        </TableCell>
                        <TableCell>{pietanza["Nome"]}</TableCell>
                        <TableCell>
                          { pietanza["Gruppo"] && (
                            pietanza["Gruppo"].Nome
                          )}
                        </TableCell>
                        <TableCell>
                          { pietanza["Sottogruppo"] && (
                            pietanza["Sottogruppo"].Nome
                          )}
                        </TableCell>
                        <TableCell>
                          { pietanza["Immagine"] && 
                            <div className="center-cropped" style={{backgroundImage: "url(" + `${REACT_APP_BACKEND}${pietanza.Immagine.url}` + ")"}}></div>
                          }
                        </TableCell>
                      </TableRow>
                    ) 
                  }
              </TableBody>
            </Table>
          </GridItem>
          }

          { (!pietanze.items || pietanze.items.length <= 0) && 
            <GridItem>
              <p>Caricamento pietanze in corso...</p>
            </GridItem> 
          }
        </ScaffoldBase>
      </div>
    );
  }
}

ListaPietanze.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
    loadPietanze: (filtro) => dispatch(fetchPietanze(filtro)),
    setPietanza: (id) => dispatch(fetchPietanza(id)),
    delPietanza: (id) => dispatch(deletePietanza(id)),
    loadGruppi: () => dispatch(fetchGruppi())
  }
}

function mapStateToProps({ pietanze, gruppi, authentication }) {
  return { 
    pietanze, 
    gruppi, 
    azienda: authentication.user["Azienda"] };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaPietanze));
