// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";

// import ContentPaste from "@material-ui/icons/ContentPaste";
import People from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import Business from "@material-ui/icons/Business";
import Report from "@material-ui/icons/ListAlt";
import Report2 from "@material-ui/icons/List";
import ExitToApp from "@material-ui/icons/ExitToApp";
import Request from "@material-ui/icons/QuestionAnswer";
import Claim from "@material-ui/icons/ReportProblem";

// core components/views
import DashboardPage from "views/Dashboard/Dashboard.jsx";

// project's components
import ListaDipendenti from "views/Cliente/Dipendenti/ListaDipendenti.jsx";
import ReportPasti from "views/Cliente/Report/ReportPastiDipendenti.jsx";
import DettaglioDipendente from "views/Cliente/Dipendenti/DettaglioDipendente.jsx";
import DettaglioAzienda from "views/Cliente/Azienda/DettaglioAzienda.jsx";
import DettaglioReclamo from "views/Cliente/Reclami/DettaglioReclamo.jsx";
import ReclamiCliente from "views/Cliente/Reclami/ReclamiCliente.jsx";
import DettaglioRichiesta from "views/Cliente/Richieste/DettaglioRichieste.jsx";
import RichiesteCliente from "views/Cliente/Richieste/RichiesteCliente.jsx";
import Profilo from "views/Profilo/Profilo";
import ListaOrdinazioni from "views/Cliente/Ordinazioni/ListaOrdinazioni.jsx"
import GestioneOrdinazioniDipendenti from "views/Cliente/Ordinazioni/GestioneOrdinazioniDipendenti.jsx";
import Logout from "layouts/Logout/Logout";

const adminCustomerRoutes = [
    {
        path: "/dashboard/profilo-personale",
        sidebarName: "Profilo",
        navbarName: "Profilo",
        icon: Person,
        component: Profilo
    },
    {
        path: "/dashboard/profilo-azienda",
        sidebarName: "Profilo Azienda",
        navbarName: "Profilo Azienda",
        icon: Business,
        component: DettaglioAzienda,
        invisible: false
    },
    {
        path: "/dashboard/dipendenti/:id/edit",
        sidebarName: "Dipendenti",
        navbarName: "Anagrafica dipententi",
        icon: People,
        component: DettaglioDipendente,
        invisible: true
    },
    {
        path: "/dashboard/dipendenti/add",
        sidebarName: "Dipendenti",
        navbarName: "Anagrafica dipendenti",
        icon: People,
        component: DettaglioDipendente,
        invisible: true
    },
    {
        path: "/dashboard/dipendenti",
        sidebarName: "Dipendenti",
        navbarName: "Anagrafica dipendenti",
        icon: People,
        component: ListaDipendenti
    },
    {
        path: "/dashboard/report",
        sidebarName: "Riepilogo Costi",
        navbarName: "Riepilogo Costi",
        icon: Report,
        component: ReportPasti
    },/*
    {
        path: "/dashboard/gesione-ordinazioni",
        sidebarName: "Gestione ordinazioni",
        navbarName: "Gestione ordinazioni dipendenti",
        icon: Report2,
        component: GestioneOrdinazioniDipendenti
    },*/
    {
        path: "/dashboard/elenco-ordinazioni",
        sidebarName: "Ordinazioni effettuate",
        navbarName: "Elenco ordinazioni effettuate",
        icon: Report2,
        component: ListaOrdinazioni
    },
    {
        path: "/dashboard/reclami/:id/edit",
        sidebarName: "Reclami",
        navbarName: "Reclami",
        icon: Claim,
        component: DettaglioReclamo,
        invisible: true
    },
    {
        path: "/dashboard/reclami/add",
        sidebarName: "Reclami",
        navbarName: "Reclami",
        icon: Claim,
        component: DettaglioReclamo,
        invisible: true
    },
    {
        path: "/dashboard/reclami",
        sidebarName: "Reclami",
        navbarName: "Reclami",
        icon: Claim,
        component: ReclamiCliente
    },
    {
        path: "/dashboard/richieste/:id/edit",
        sidebarName: "Richieste",
        navbarName: "Richieste",
        icon: Request,
        component: DettaglioRichiesta,
        invisible: true
    },
    {
        path: "/dashboard/richieste/add",
        sidebarName: "Richieste",
        navbarName: "Richieste",
        icon: Request,
        component: DettaglioRichiesta,
        invisible: true
    },
    {
        path: "/dashboard/richieste",
        sidebarName: "Richieste",
        navbarName: "Richieste",
        icon: Request,
        component: RichiesteCliente
    },
    {
        path: "/dashboard/logout",
        sidebarName: "Logout",
        navbarName: "Logout",
        icon: ExitToApp,
        component: Logout
    },
    {
        path: "/dashboard",
        sidebarName: "Dashboard",
        navbarName: "Dashboard",
        icon: Dashboard,
        component: DashboardPage,
        invisible: true
    }
];

export default adminCustomerRoutes;