import React, { Component } from "react";

// React-Redux reducers
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";

// core material design ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from "@material-ui/core/TableBody";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import Clear from "@material-ui/icons/Clear";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

// core Theme components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";


// custom Components
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase";

import { fetchTemplatemenus, deleteTemplatemenu } from "actions/templatemenu"

class TemplateMenuLista extends Component {

    state = {
        openDialog: false,
        selectedTemplate: null
    }
    componentDidMount() {
        const { caricaTemplate, azienda } = this.props

        const filtro = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]

        caricaTemplate(filtro)
    }

    aggiungiTemplate = () => {
        const { history } = this.props

        history.push('/dashboard/template-menu/add')
    }

    apriDettaglioTemplate = (id) => {
        const { history } = this.props

        history.push(`/dashboard/template-menu/${id}/edit`)
    }

    apriModale = ( template ) => {
        this.setState({ openDialog: true, selectedTemplate: template })
    }

    eliminaTemplate = () => {
        const { eliminaTemplatemenu, azienda, caricaTemplate } = this.props
        const { selectedTemplate } = this.state

        eliminaTemplatemenu(selectedTemplate._id)
        .then( () => {
            const filtro = [{ 'campo': 'Azienda', 'operatore': '=', 'valore': azienda._id }]

            caricaTemplate(filtro)
        })
        .then( () => {
            this.setState({ openDialog: false, selectedTemplate: null })
        })
    }

    chiudiModale = () => {
        this.setState({ openDialog: false })
    }
    
    render() {
        const { templatemenu, classes } = this.props

        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Button onClick={() => this.aggiungiTemplate()} style={{background: "linear-gradient(60deg, #7d6960, #3e2a23)", color: "#ffffff"}} variant="contained"><AddIcon />
                            Nuovo template
                        </Button>
                    </GridItem>
                </GridContainer>
                <ScaffoldBase title="Elenco template menu">
                
                    { templatemenu && templatemenu.length > 0 && (
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Azione</TableCell>
                                    <TableCell>Nome</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            { templatemenu.sort( (a, b) => {
                                if (a['Ordinamento'] > b['Ordinamento'])
                                    return 1

                                if ( a['Ordinamento'] < b['Ordinamento'])
                                    return -1

                                return 0
                            })
                            .map( (template) => {
                                return (
                                <TableRow key={template._id}>
                                    <TableCell>
                                        <Tooltip
                                            id="tooltip-top"
                                            title="Elimina"
                                            placement="top"
                                            classes={{ tooltip: classes.tooltip }}
                                        >
                                            <IconButton
                                                aria-label="Edit"
                                                className={classes.tableActionButton}
                                            >
                                                <Clear
                                                className={
                                                    classes.tableActionButtonIcon + " " + classes.edit
                                                }
                                                onClick={() => this.apriModale(template)}
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell  onClick={() => this.apriDettaglioTemplate(template._id)}>
                                            {template["Nome"]}
                                    </TableCell>
                                </TableRow>)
                            })}
                            </TableBody>
                        </Table>
                    )}
                    { (!templatemenu || templatemenu.length === 0) && (
                        <GridContainer>
                            <h5>Template dei menu non presenti</h5>
                        </GridContainer>
                    )}
                </ScaffoldBase>
                <Dialog
                    open={this.state.openDialog}
                    onClose={this.chiudiModale}
                    aria-labelledby="form-dialog-title"
                    fullWidth={true}
                >
                    <DialogTitle id="form-dialog-title">Elimina template menu</DialogTitle>
                    <DialogContent>
                        Vuoi veramente eliminare questo template menu?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.chiudiModale} color="transparent">No</Button>
                        <Button onClick={this.eliminaTemplate} color="success">Si</Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

function mapDispatchToProps( dispatch ) {
    return {
        caricaTemplate: (filtro) => dispatch(fetchTemplatemenus(filtro)),
        eliminaTemplatemenu: (id) => dispatch(deleteTemplatemenu(id))
    }
  }
  
function mapStateToProps({ templatemenu, authentication }) {
    return { 
        templatemenu: templatemenu.items,
        azienda: authentication.user["Azienda"]
    }
}

export default compose(
    withStyles(dashboardStyle),
    connect(mapStateToProps, mapDispatchToProps)
)(withRouter(TemplateMenuLista));
