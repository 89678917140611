import { REACT_APP_BACKEND } from '../index.js';

export const GET_FILE = "GET_FILE";

export const getFile = file => ({
  type: GET_FILE,
  file
})

// aggiunge un file
export const addFile = ( file, refId, ref, field ) => dispatch => {

    let form = new FormData()
    form.append('files', file, file.name)
    form.append('refId', refId)
    form.append('ref', ref)
    form.append('field', field)

    fetch(`${REACT_APP_BACKEND}upload`, 
    {  
        method: "POST", 
        body: form
    })
    .then( (res) => { 
        const ret = res.status === 200 ? true : false;
        return ret;
    })
}

export const deleteFile = ( file ) => dispatch => {
    fetch(`${REACT_APP_BACKEND}upload/files/${file}`, 
    { headers: {  "Content-Type" : "application/json"}, 
        method: "DELETE"
    })
}
