import React from "react";
import PropTypes from "prop-types";

// @material-ui/core
import withStyles from "@material-ui/core/styles/withStyles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";

// core components
import GridItem from "components/Grid/GridItem.jsx";
import ScaffoldBase from "components/CustomScaffold/ScaffoldBase"

// custom components
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";

// React-Redux reducers
import { connect } from "react-redux";
import { fetchRichieste, fetchRichiesta } from "actions/richieste";
import { compose } from "redux";
import { withRouter } from "react-router";

class ListaRichieste extends React.Component {
  state = {
  };

  componentDidMount() {
    const { loadRichieste, azienda } = this.props

    if (azienda)
        loadRichieste( [ { 'campo': 'Ricevente', 'operatore' : '=', 'valore': azienda._id },{ 'campo': 'Evasione', 'operatore' : '=', 'valore': 'no' } ] )
    
  }

  apriRichiesta(id) {
    const { history } = this.props

    history.push('/dashboard/richieste/'+id+'/view')
  }

  render() {
    const { classes, richieste } = this.props;
    return (
      <div>
      <ScaffoldBase title="Lista richieste">
                { richieste && richieste.length > 0 &&
                <GridItem xs={12} sm={12} md={12}>
                  <Table className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Richiedente</TableCell>
                        <TableCell>Giorno</TableCell>
                        <TableCell>Oggetto</TableCell>
                        <TableCell>Descrizione</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                          richieste && richieste.sort( function(a,b) {
                            if ( a["Giorno"] > b["Giorno"])
                              return -1
                            if ( a["Giorno"] < b["Giorno"])
                              return 1
                            return 0
                          })
                          .map( (richiesta) => 
                            <TableRow key={richiesta._id} onClick={() => this.apriRichiesta(richiesta._id)}>
                              <TableCell>{richiesta["Richiedente"]["Ragione sociale"]}</TableCell>
                              <TableCell>{new Date(richiesta["Giorno"]).toLocaleDateString('it-IT', { month: '2-digit', day: '2-digit', year: 'numeric' })}</TableCell>
                              <TableCell>{richiesta["Oggetto"]}</TableCell>
                              <TableCell>{richiesta["Descrizione"]}</TableCell>
                            </TableRow>
                          ) 
                        }
                    </TableBody>
                  </Table>
                </GridItem>
                }

                { (!richieste || richieste.length <= 0) && 
                  <GridItem>
                    <p>Richieste non disponibili</p>
                  </GridItem> }
        </ScaffoldBase>
        </div>
    );
  }
}

ListaRichieste.propTypes = {
  classes: PropTypes.object.isRequired
};

function mapDispatchToProps( dispatch ) {
  return {
    loadRichieste: (filtro) => dispatch(fetchRichieste(filtro)),
    setRichieste: (id) => dispatch(fetchRichiesta(id))
  }
}

function mapStateToProps({ richieste, authentication }) {
  return { 
    richieste: richieste.items,
    azienda: authentication.user["Azienda"]
  };
}

export default compose(
  withStyles(dashboardStyle),
  connect(mapStateToProps, mapDispatchToProps)
)(withRouter(ListaRichieste));
